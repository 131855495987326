import dayjs from "dayjs";
import {
  LodgingSelection,
  LodgingSelectionEnum,
  Lodging,
  HotelEntryTypeEnum,
  HotelBookType,
  HOTEL_BOOK_TYPE,
  PRICE_FREEZE_ENTRY,
  HotelPriceFreezePurchaseEntry,
  HotelDetailsEntrySourceEnum,
} from "redmond";
import { History } from "history";
import queryStringParser from "query-string";
import {
  PATH_HOME,
  PATH_AVAILABILITY,
  PATH_SHOP,
  PATH_ANCILLARY_CUSTOMIZE,
  PATH_BOOK,
  PATH_FREEZE,
} from "../../../utils/paths";

export interface IHotelShopParsedQuery {
  lodgingId: string;
  fromDate: string;
  untilDate: string;
  adultsCount: number;
  children: number[];
  selectedLodgingIndex: number;
  lodgingSelection?: LodgingSelection;
  roomsCount?: number;
  petsCount?: number;
  entryPoint?: HotelEntryTypeEnum;
  preferencesRecommended?: boolean;
  hotelDetailsEntrySource?: HotelDetailsEntrySourceEnum;
}

export interface HotelShopQuery {
  lodgingId: string;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  selectedAccountIndex: number;
  selectedLodgingIndex: number | null;
  lodgingSelection?: LodgingSelection;
  roomsCount: number;
  petsCount?: number;
  fromHotelAvailability?: boolean;
  entryPoint?: string;
  recommendedBasedOnPreferences?: boolean;
  hotelDetailsEntrySource?: HotelDetailsEntrySourceEnum;
}

export const transformToStringifiedQuery = ({
  lodgingId,
  fromDate,
  untilDate,
  adultsCount = 2,
  children,
  selectedAccountIndex = 0,
  selectedLodgingIndex,
  lodgingSelection,
  roomsCount,
  petsCount,
  fromHotelAvailability,
  entryPoint,
  recommendedBasedOnPreferences,
  hotelDetailsEntrySource,
}: HotelShopQuery): string => {
  if (fromDate === null || untilDate === null) {
    return `?lodgingId=${lodgingId}`;
  }

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  const lodgingSelectionParam = encodeURIComponent(
    JSON.stringify(lodgingSelection)
  );
  let string = "";
  const childrenParams = children?.reduce(
    (ageString, age) => `${ageString}&children=${age}`,
    ""
  );
  if (roomsCount) {
    string = `?lodgingId=${lodgingId}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${childrenParams}&roomsCount=${roomsCount}&selectedAccountIndex=${selectedAccountIndex}&lodgingSelection=${lodgingSelectionParam}`;
  } else {
    string = `?lodgingId=${lodgingId}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${childrenParams}&selectedAccountIndex=${selectedAccountIndex}&lodgingSelection=${lodgingSelectionParam}`;
  }

  if (petsCount) {
    string += `&petsCount=${petsCount}`;
  }

  if (selectedLodgingIndex != null) {
    string += `&selectedLodgingIndex=${selectedLodgingIndex}`;
  }

  if (fromHotelAvailability !== undefined) {
    string += `&fromHotelAvailability=${fromHotelAvailability}`;
  }
  if (entryPoint) {
    string += `&entryPoint=${entryPoint}`;
  }
  if (recommendedBasedOnPreferences) {
    string += `&preferencesRecommended=true`;
  }
  if (hotelDetailsEntrySource) {
    string += `&hotelDetailsEntrySource=${hotelDetailsEntrySource }`;
  }
  return string;
};

export const transformToStringifiedAvailabilityQuery = (
  location: string,
  fromDate: Date | string | null,
  untilDate: Date | string | null,
  adultsCount: number | null,
  children: number[] | null,
  roomsCount: number,
  petsCount?: number,
  entryPoint?: HotelEntryTypeEnum,
): string => {
  location = encodeURIComponent(location);
  if (fromDate === null || untilDate === null) {
    return `?locationName=${location}`;
  }

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  let query = `?locationName=${location}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${children?.reduce(
    (ageString, age) => `${ageString}&children=${age}`,
    ""
  )}`;

  query += `&roomsCount=${roomsCount}`;
  if (petsCount) {
    query += `&petsCount=${petsCount}`;
  }

  if (entryPoint) {
    query += `&entryPoint=${entryPoint}`;
  }
  return query;
};

export const goToAvailability = ({
  history,
  lodging,
  fromDate,
  untilDate,
  adultsCount,
  children,
  roomsCount,
  petsCount,
  prevSuccessHotelFromDate,
  prevSuccessHotelUntilDate,
}: {
  history: History;
  lodging: Lodging | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  roomsCount: number;
  petsCount: number;
  prevSuccessHotelFromDate?: Date | null;
  prevSuccessHotelUntilDate?: Date | null;
}) => {
  if (!lodging) {
    return history.push(PATH_HOME);
  }

  const parsedQueryStringPrimitive = queryStringParser.parse(
    history.location.search
  );
  const lodgingSelection = JSON.parse(
    decodeURIComponent(parsedQueryStringPrimitive.lodgingSelection as string)
  );
  const locationToSearch =
    lodgingSelection.LodgingSelection === LodgingSelectionEnum.Place
      ? lodgingSelection.searchTerm
      : `${lodging.lodging.city}, ${
          lodging.lodging.state ? `${lodging.lodging.state?.name}` : ""
        }`;

  // TODO: This needs to include state to be accurate, we don't have it.
  const search = transformToStringifiedAvailabilityQuery(
    encodeURIComponent(locationToSearch),
    prevSuccessHotelFromDate ?? fromDate,
    prevSuccessHotelUntilDate ?? untilDate,
    adultsCount,
    children,
    roomsCount,
    petsCount,
  );

  history.push(`${PATH_AVAILABILITY}${search}`);
};

export const goToShop = ({ history }: { history: History }) => {
  history.push(`${PATH_SHOP}${history.location.search}`, {
    fromPage: location.pathname,
  });
};

export const goToAncillaryCustomize = ({ history }: { history: History }) => {
  history.push(`${PATH_ANCILLARY_CUSTOMIZE}${history.location.search}`);
};

/*
  note: if we eventually decide to have the user stay on the checkout page after a page refresh (e.g, it currently
  redirects the user to hotel/shop), this helper function will need to be extended such that it will set the user's
  URL query to help with page refresh.
*/
export const goToCheckout = ({
  history,
  hotelBookType,
}: {
  history: History;
  hotelBookType?: HotelBookType;
}) => {
  const params = new URLSearchParams(history.location.search);
  params.set(HOTEL_BOOK_TYPE, hotelBookType ?? HotelBookType.DEFAULT);
  history.push(`${PATH_BOOK}?${params.toString()}`);
};

export const handlePageRedirectOnSelectRoomProduct = ({
  history,
  isAddOnOptionAvailable,
  hotelBookType,
}: {
  history: History;
  isAddOnOptionAvailable?: boolean;
  hotelBookType?: HotelBookType;
}) => {
  if (isAddOnOptionAvailable) {
    goToAncillaryCustomize({ history });
  } else {
    goToCheckout({ history, hotelBookType });
  }
};

export const goToPriceFreezePurchase = ({
  history,
  priceFreezeEntry,
}: {
  history: History;
  priceFreezeEntry: HotelPriceFreezePurchaseEntry;
}) => {
  const params = new URLSearchParams(history.location.search);
  params.set(PRICE_FREEZE_ENTRY, priceFreezeEntry ?? "hotel_details");
  history.push(`${PATH_FREEZE}?${params.toString()}`);
};
