import { Box, ClickAwayListener, Popper } from "@material-ui/core";
import { ButtonWrap, CloseButtonIcon } from "halifax";
import React from "react";
import "./styles.scss";

export const StayTypeGenericDropdownPopper = (props: {
  isOpen: boolean;
  handleClose: () => void;
  anchorRef: React.RefObject<HTMLButtonElement | null>;
}) => {
  const { isOpen, handleClose, anchorRef } = props;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement="right-start"
        style={{ zIndex: 2 }}
      >
        <Box className="stay-type-generic-dropdown-tooltip">
          <Box className="stay-type-generic-dropdown-tooltip-arrow"></Box>
          <Box className="stay-type-generic-dropdown-tooltip-container">
            <Box>
              <Box className="stay-type-generic-dropdown-tooltip-header">
                <Box>
                  <Box className="stay-type-new-badge">New</Box>
                </Box>
                <ButtonWrap
                  className="stay-type-generic-dropdown-tooltip-close-button"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <CloseButtonIcon className="stay-type-generic-dropdown-tooltip-close-icon" />
                </ButtonWrap>
              </Box>
              <Box className="stay-type-generic-dropdown-tooltip-text-box">
                <span className="stay-type-generic-dropdown-tooltip-text">
                  We’ve added{" "}
                  <span className="stay-type-generic-dropdown-tooltip-text-bolded">
                    homes and apartments
                  </span>{" "}
                  to select locations. To only view homes and apartments, filter
                  by property type.
                </span>
              </Box>
              <Box className="stay-type-generic-dropdown-button-container">
                <ButtonWrap
                  className="stay-type-generic-dropdown-button"
                  onClick={handleClose}
                >
                  Got it!
                </ButtonWrap>
              </Box>
            </Box>
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};
