import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import { CfarCancellationPolicy } from "./component";
import {
  hotelCfarQuoteFromChosenRoomProductSelector,
  additionalInfoFromChosenRoomProductSelector,
} from "../../../../../shop/reducer";
import {
  isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector,
  isHotelCfarRefundDisplayNonRefPartialEnabledSelector,
  isHotelCfarModelV1EnabledSelector,
} from "../../../../reducer";
import {
  getAddedAncillariesPricingInCheckout,
  getOfferAmountToApplySelector,
} from "../../../../../book/reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  return {
    hotelCfarQuote: hotelCfarQuoteFromChosenRoomProductSelector(state),
    additionalInfo: additionalInfoFromChosenRoomProductSelector(state),
    isHotelCfarRefundDisplayShowAmountAdditionalPlaces:
      isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector(state),
    isHotelCfarRefundDisplayNonRefPartialEnabled:
      isHotelCfarRefundDisplayNonRefPartialEnabledSelector(state),
    isHotelCfarModelV1Enabled: isHotelCfarModelV1EnabledSelector(state),
    addedAncillary: getAddedAncillariesPricingInCheckout(state),
    offerAmountToApply: getOfferAmountToApplySelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CfarCancellationPolicyConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCfarCancellationPolicy = withRouter(
  connector(CfarCancellationPolicy)
);
