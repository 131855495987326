export const CURRENT_PRICE = "Current frozen price:";
export const COST_TO_FREEZE = "Cost to freeze x1 room:";
export const FROZEN_DURATION = "Frozen price duration:";
export const ROOM_TYPE = "Room type:";
export const FROZEN_PRICES_LIST_LINK = "/trips/?tripsFilter=Frozen%20Prices";
export const CTA_TEXT = "View frozen price";
export const WHATS_NEXT_HEADER = "What's next?";
export const BANNER_COPY = "Confirmed, your price is frozen.";
export const TITLE = (email: string) =>
  `An email confirmation will be sent to ${email}`;
export const SUBTITLE = (confirmationCode: string) =>
  `Your price freeze confirmation code is ${confirmationCode}`;
export const WHATS_NEXT_CONTENT = (date: string) =>
  `You have until <strong>${date}</strong> to purchase your hotel at the frozen price.`;
export const CURRENT_FROZEN_PRICE_FOOTER = "Taxes and fees not included";
export const formats = Object.freeze({
  DISPLAY_DATE: "ddd, MMM DD", // Sat, Jan 01
  SHORT_DATE: "MMM DD", // Sat, Jan 01
  REQUEST_DATE: "YYYY-MM-DD", // 2021-01-13
  SUMMARY_TIME: "h:mm A", // 5:30 PM
  VOID_BY_DATE: "ddd, MMM Do [at] h:mm A", // Sat, Jan 1st at 5:30 PM
});
