import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { Route } from "react-router-dom";
import { PATH_ANCILLARY_CUSTOMIZE } from "../../utils/paths";
import { HotelCustomize } from "./components";
import { HotelAncillaryConnectorProps } from "./container";
import { PORTAL_TITLE } from "../../lang/textConstants";

export interface IHotelAncillaryProps
  extends RouteComponentProps,
    HotelAncillaryConnectorProps {}

export const HotelAncillary = (props: IHotelAncillaryProps) => {
  const { isCustomizePageEnabled } = props;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  return (
    <Box className="hotel-ancillary-root">
      {isCustomizePageEnabled && (
        <Route path={PATH_ANCILLARY_CUSTOMIZE}>
          <HotelCustomize />
        </Route>
      )}
    </Box>
  );
};
