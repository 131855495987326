import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingSuccessModal } from "./component";
import {
  getConfirmationDetails,
  getEarnedString,
  getConfirmationEmail,
  getUserSelectedTravelersList,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import { getHotelShopSelectedLodging } from "../../../shop/reducer/selectors";
import { hotelFrozenPriceSummaryDetailsSelector } from "../../../freeze/reducer";
import {
  getHasUserSetHotelPreferences,
  getUserHotelPreferencesCallState,
} from "../../../availability/reducer";

const mapStateToProps = (state: IStoreState) => ({
  reservation: getConfirmationDetails(state),
  selectedLodging: getHotelShopSelectedLodging(state),
  earnString: getEarnedString(state),
  confirmationEmail: getConfirmationEmail(state),
  hotelPriceFreezeSummaryDetails: hotelFrozenPriceSummaryDetailsSelector(state),
  hasUserSetHotelPreferences: getHasUserSetHotelPreferences(state),
  userHotelPreferencesCallState: getUserHotelPreferencesCallState(state),
  selectedTravelersList: getUserSelectedTravelersList(state),
});

const mapDispatchToProps = {
  resetBookState: actions.resetBook,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingSuccessModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingSuccessModal = withRouter(
  connector(BookingSuccessModal)
);
