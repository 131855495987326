import { mapExperimentVariants } from "../../../../context/experiment-def";
import {
  boostPremierCollectionExperiment,
  BoostPremierCollectionPropVariantType,
} from "../../../../context/experiments";

const PREMIUM_STAYS_COUNTS = mapExperimentVariants(
  boostPremierCollectionExperiment,
  (variant) => {
    switch (variant) {
      case "control":
      case "available":
        return Infinity;
      case "2-boost":
      case "2-boost-randomized":
        return 2;
      case "3-boost":
      case "3-boost-randomized":
        return 3;
    }
  }
);

export function shouldShowPremiumStaysFilter(
  premiumLodgingsCount: number,
  boostPremierCollectionVariant: BoostPremierCollectionPropVariantType
): boolean {
  return (
    premiumLodgingsCount >
    (PREMIUM_STAYS_COUNTS[boostPremierCollectionVariant] ?? Infinity)
  );
}
