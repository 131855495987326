import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";
import { HotelFreezeSummaryConnectorProps } from "./container";

import "./styles.scss";
import * as t from "./textConstants";
import {
  ActionLink,
  BannerSeverity,
  Icon,
  IconName,
  NotificationBanner,
} from "halifax";
import { PATH_HOTEL_PRICE_FREEZE_TERMS } from "../../../../../../utils/paths";

export interface IHotelFreezeSummaryProps
  extends RouteComponentProps,
    HotelFreezeSummaryConnectorProps {
  isMobile?: boolean;
  middleSection?: JSX.Element;
}

export const HotelFreezeSummary = ({
  priceFreezeOffer,
  isMobile,
  middleSection,
}: IHotelFreezeSummaryProps) => {
  if (!priceFreezeOffer) {
    return null;
  }

  const explanationTexts = t.PRICE_FREEZE_EXPLANATION_TEXTS(
    priceFreezeOffer?.pricing.cap.fiat
  );

  return (
    <Box className={clsx("hotel-freeze-summary-root", { mobile: isMobile })}>
      <Box className={clsx("freeze-summary", { mobile: isMobile })}>
        <Box className={clsx("container", "frozen-price")}>
          <Typography variant="caption" className="title">
            {t.CURRENT_PRICE_FROZEN_TITLE}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {t.CURRENT_PRICE_FROZEN(
              priceFreezeOffer.pricing.frozenPricePerNight.fiat
            )}
          </Typography>
          <Typography variant="caption" className="caption">
            {t.TAXES_AND_FEES_NOT_INCLUDED}
          </Typography>
        </Box>
        <Box className={clsx("container", "fee")}>
          <Typography variant="caption" className="title">
            {t.COST_TO_FREEZE_TITLE}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {t.COST_TO_FREEZE(priceFreezeOffer.pricing.deposit.fiat)}
          </Typography>
        </Box>
        <Box className={clsx("container", "frozen-duration")}>
          <Typography variant="caption" className="title">
            {t.FROZEN_DURATION_TITLE}
          </Typography>
          <Box className="cta-section">
            <Typography variant="body1" className="subtitle">
              {t.FROZEN_DURATION(priceFreezeOffer.durationHours)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {middleSection}
      <Box
        className={clsx("freeze-explanation-container", { mobile: isMobile })}
      >
        <Box className={clsx("deposit-applied", "copy-container")}>
          <div>
            <Icon className="icon" name={IconName.MoneyFill} />
          </div>
          <Typography className="text" variant="body1">
            <span
              dangerouslySetInnerHTML={{
                __html: explanationTexts.depositAppliedText,
              }}
            ></span>
          </Typography>
        </Box>
        <Box className={clsx("increase", "copy-container")}>
          <div>
            <Icon className="icon" name={IconName.PriceFreezeArrow} />
          </div>
          <Typography className="text" variant="body1">
            <span
              dangerouslySetInnerHTML={{
                __html: explanationTexts.increaseText,
              }}
            ></span>
          </Typography>
        </Box>
        <Box className={clsx("decrease", "copy-container")}>
          <div>
            <Icon className="icon" name={IconName.PriceFreezeArrow} />
          </div>
          <Typography className="text" variant="body1">
            <span
              dangerouslySetInnerHTML={{
                __html: explanationTexts.decreaseText,
              }}
            ></span>
          </Typography>
        </Box>
      </Box>
      <Box className={clsx("no-reserve-notification-container")}>
        <NotificationBanner
          className="no-reserve-notification"
          html={explanationTexts.noReserveText}
          severity={BannerSeverity.INFO}
          icon={<Icon className="banner-icon" name={IconName.InfoCircle} />}
        />
      </Box>
      <Box className="terms-container">
        <ActionLink
          className="cta-button"
          onClick={() =>
            window.open(PATH_HOTEL_PRICE_FREEZE_TERMS, "_blank", "noopener")
          }
          content={
            <Typography className="cta-text" variant="body1">
              <span>{t.READ_TERMS}</span>
            </Typography>
          }
        />
      </Box>
    </Box>
  );
};
