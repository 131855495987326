import React, { useContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";
import {
  HOTEL_PRICE_FREEZE_CLICKED_COMPLETE_BUY,
  getHotelPriceFreezePurchaseOfferProperties,
} from "redmond";
import { DesktopHotelFreezeWorkflowConnectorProps } from "./container";
import { PaymentCard } from "../../../../../book/components";
import * as t from "./textConstants";
import { ClientContext } from "../../../../../../App";
import "./styles.scss";
import { HotelFreezeContactInfoForm } from "../HotelFreezeContactInfoForm";
import { HotelFreezePriceBreakdown } from "../HotelFreezePriceBreakdown";
import { HotelFreezeSummary } from "../HotelFreezeSummary";
import {
  RoomDetails,
  ButtonWrap,
  CheckoutStepper,
  ActionButton,
  emailRegex,
} from "halifax";
import { HotelFreezeReviewItinerary } from "../HotelFreezeReviewItinerary";
import { PATH_HOME } from "../../../../../../utils/paths";
import { HotelShopProgressBar } from "../../../../../shop/components/HotelShopProgressBar";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";

export interface IDesktopHotelFreezeWorkflowProps
  extends RouteComponentProps,
    DesktopHotelFreezeWorkflowConnectorProps {}

// interface IDesktopHotelFreezeStep {
//   reviewItineraryStep: number;
//   priceFreezeDurationStep?: number;
//   travelersStep: number;
//   paymentCardStep: number;
// }

export const DesktopHotelFreezeWorkflow = ({
  history,
  isBookingValid,
  contactInfo,
  roomInfoProduct,
  chosenProductIndex,
  selectedLodging,
  roomsCount,
  freezeBookingProgress,
  isPriceFreezePurchaseTravelerStepComplete,
  priceFreezeOffer,
  scheduleBook,
}: IDesktopHotelFreezeWorkflowProps) => {
  const { logo, isAgentPortal } = useContext(ClientContext);
  const [isDisableSave, setIsDisableSave] = useState<boolean>(
    !emailRegex.test(contactInfo.email)
  );
  //   const travelersDivRef = React.useRef<HTMLDivElement | null>(null);
  //   const paymentCardDivRef = React.useRef<HTMLDivElement | null>(null);

  const ClientLogo = (
    <ButtonWrap
      onClick={() => {
        history.push(PATH_HOME);
      }}
    >
      {logo}
    </ButtonWrap>
  );
  //const {
  //  reviewItineraryStep,
  //  travelersStep,
  //  paymentCardStep,
  //}: IDesktopHotelFreezeStep = (() => {
  //  return {
  //    reviewItineraryStep: 1,
  //    travelersStep: 2,
  //    paymentCardStep: 3,
  //  };
  //})();

  //   const scrollToCardContent = (
  //     divRef: React.MutableRefObject<HTMLDivElement | null>,
  //     position?: "center" | "top"
  //   ) => {
  //     const offsetTop = divRef?.current?.offsetTop ?? 0;
  //     const offsetHeight = divRef?.current?.offsetHeight ?? 0;
  //     const top = (() => {
  //       switch (position) {
  //         case "top":
  //           return offsetTop + offsetHeight;
  //         case "center":
  //         default:
  //           return offsetTop - offsetHeight;
  //       }
  //     })();

  //     window.scrollTo({ top, behavior: "smooth" });
  //   };

  return (
    // TODO: migrate CheckoutTemplate to Halifax for reusability
    <Box className="desktop-hotel-freeze-workflow-root">
      <CheckoutStepper
        steps={freezeBookingProgress}
        headerCopy={t.HEADER_COPY}
        subHeaderCopy={t.SUBHEADER_COPY}
        logo={ClientLogo}
        className={clsx("b2b", "combined-step")}
      />
      <Box className="progress-bar-wrapper">
        <HotelShopProgressBar
          useCustomizeStep={false}
          usePriceFreezeStep={true}
        />
      </Box>
      <Box className="desktop-hotel-freeze-workflow-container">
        <Box className={clsx("freeze-template-column", "left")}>
          <Box
            className={clsx(
              "freeze-template-card-content-container",
              "summary"
            )}
          >
            <HotelFreezeSummary />
          </Box>
          <Box
            className={clsx(
              "freeze-template-card-content-container",
              "review-itinerary"
            )}
          >
            <HotelFreezeReviewItinerary
              title={t.REVIEW_ITINERARY_TITLE(1)}
              isMobile={false}
            />
          </Box>
          <div
            className={clsx(
              "freeze-template-card-content-container",
              "travelers"
            )}
            // ref={travelersDivRef}
          >
            {/*<Box className="travelers-selection-wrapper">
              <HotelFreezeTravelers
                titleStep={travelersStep}
                useLockPriceLanguage={useLockPriceLanguage}
              />
            </Box>*/}
            <HotelFreezeContactInfoForm
              title={t.CONTACT_INFO_TITLE(2)}
              subtitle={t.CONTACT_INFO_SUBTITLE}
              isDisableSave={isDisableSave}
              setIsDisableSave={setIsDisableSave}
            />
          </div>
          <div
            className={clsx("freeze-template-card-content-container", "credit")}
            // ref={paymentCardDivRef}
          >
            <PaymentCard
              disabled={!isPriceFreezePurchaseTravelerStepComplete}
              paymentStepTitle={t.REWARDS_AND_PAYMENT_TITLE(3)}
            />
          </div>
          <div
            className={clsx(
              "freeze-template-card-content-container",
              "nonrefundable"
            )}
          >
            <Box className="nonrefundable-wrapper">
              <Typography variant="h6" className="title">
                {t.NONREFUNDABLE}
              </Typography>
              <Typography
                variant="body2"
                className="nonrefundable-payment-text"
              >
                {t.NONREFUNDABLE_PAYMENT}
              </Typography>
            </Box>
          </div>
        </Box>
        <Box className={clsx("freeze-template-column", "right")}>
          <Box className="hotel-freeze-right-content">
            <Box
              className={clsx(
                "freeze-template-card-content-container",
                "checkout-header"
              )}
            >
              <Typography className="header-copy" variant="h2">
                {t.CHECKOUT_BREAKDOWN_HEADER}
              </Typography>
            </Box>
            <Box
              className={clsx(
                "freeze-template-card-content-container",
                "payment-breakdown"
              )}
            >
              {!!roomInfoProduct && chosenProductIndex !== null && (
                <RoomDetails
                  roomInfoProduct={roomInfoProduct}
                  productIndex={chosenProductIndex}
                  lodging={selectedLodging}
                  hideAmenities
                  hideAllAmenities
                  roomsCount={roomsCount}
                  hideRoomBedDescription
                  hideRoomCapacity
                  showAmenitiesModal={
                    roomInfoProduct.roomInfo.amenities.length > 0
                  }
                />
              )}
              <HotelFreezePriceBreakdown />
            </Box>
            <Box className="complete-purchase-button-container">
              <ActionButton
                className="complete-purchase-button"
                defaultStyle="h4r-primary"
                // isAgentPortal here is to disable this feature for an agent on the checkout page BOPS-209
                // cap1 doesnt want agents to be doing this because it is seen as an upsell, and they dont want agents doing that
                disabled={!isBookingValid || isDisableSave || isAgentPortal}
                onClick={() => {
                  scheduleBook({
                    agentFee: undefined,
                  });
                  trackEvent({
                    eventName: HOTEL_PRICE_FREEZE_CLICKED_COMPLETE_BUY,
                    properties: {
                      success: true,
                      ...getHotelPriceFreezePurchaseOfferProperties(
                        priceFreezeOffer
                      ),
                    },
                  });
                }}
                onClickWhenDisabled={() => {
                  //if (!isTravelerStepComplete) {
                  //  scrollToCardContent(travelersDivRef);
                  //} else if (!isBookingValid) {
                  //  scrollToCardContent(paymentCardDivRef, "top");
                  //}
                  trackEvent({
                    eventName: HOTEL_PRICE_FREEZE_CLICKED_COMPLETE_BUY,
                    properties: {
                      success: false,
                      ...getHotelPriceFreezePurchaseOfferProperties(
                        priceFreezeOffer
                      ),
                    },
                  });
                }}
                message={t.CONFIRM_AND_FREEZE_PRICE}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
