export const FETCH_HOTEL_PRICE_FREEZE_DETAILS =
  "hotelPriceFreeze/FETCH_HOTEL_PRICE_FREEZE_DETAILS";
export type FETCH_HOTEL_PRICE_FREEZE_DETAILS =
  typeof FETCH_HOTEL_PRICE_FREEZE_DETAILS;

export const SET_HOTEL_PRICE_FREEZE_DETAILS =
  "hotelPriceFreeze/SET_HOTEL_PRICE_FREEZE_DETAILS";
export type SET_HOTEL_PRICE_FREEZE_DETAILS =
  typeof SET_HOTEL_PRICE_FREEZE_DETAILS;

export const SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED =
  "hotelPriceFreeze/SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED";
export type SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED =
  typeof SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED;

export const REFUND_HOTEL_PRICE_FREEZE =
  "hotelPriceFreeze/REFUND_HOTEL_PRICE_FREEZE";
export type REFUND_HOTEL_PRICE_FREEZE = typeof REFUND_HOTEL_PRICE_FREEZE;

export const SET_REFUND_HOTEL_PRICE_FREEZE_RESULT =
  "hotelPriceFreeze/SET_REFUND_HOTEL_PRICE_FREEZE_RESULT";
export type SET_REFUND_HOTEL_PRICE_FREEZE_RESULT =
  typeof SET_REFUND_HOTEL_PRICE_FREEZE_RESULT;

export const SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED =
  "hotelPriceFreeze/SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED";
export type SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED =
  typeof SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED;

export const SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED =
  "hotelPriceFreeze/SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED";
export type SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED =
  typeof SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED;

export const GET_HOTEL_PRICE_FREEZE_CREDITS =
  "hotelPriceFreeze/GET_HOTEL_PRICE_FREEZE_CREDITS";
export type GET_HOTEL_PRICE_FREEZE_CREDITS =
  typeof GET_HOTEL_PRICE_FREEZE_CREDITS;

export const SET_HOTEL_PRICE_FREEZE_CREDITS_RESULT =
  "hotelPriceFreeze/SET_HOTEL_PRICE_FREEZE_CREDITS_RESULT";
export type SET_HOTEL_PRICE_FREEZE_CREDITS_RESULT =
  typeof SET_HOTEL_PRICE_FREEZE_CREDITS_RESULT;

export const SET_GET_HOTEL_PRICE_FREEZE_CREDITS_CALL_STATE_FAILED =
  "hotelPriceFreeze/SET_GET_HOTEL_PRICE_FREEZE_CREDITS_CALL_STATE_FAILED";
export type SET_GET_HOTEL_PRICE_FREEZE_CREDITS_CALL_STATE_FAILED =
  typeof SET_GET_HOTEL_PRICE_FREEZE_CREDITS_CALL_STATE_FAILED;

export const RESET_HOTEL_PRICE_FREEZE_EXERCISE =
  "hotelPriceFreeze/RESET_HOTEL_PRICE_FREEZE_EXERCISE";
export type RESET_HOTEL_PRICE_FREEZE_EXERCISE =
  typeof RESET_HOTEL_PRICE_FREEZE_EXERCISE;

export const SET_PRICE_FREEZE_EXPERIMENTS =
  "hotelPriceFreeze/SET_PRICE_FREEZE_EXPERIMENTS";
export type SET_PRICE_FREEZE_EXPERIMENTS = typeof SET_PRICE_FREEZE_EXPERIMENTS;

export const FETCH_HOTEL_PRICE_FREEZE_OFFERS =
  "hotelPriceFreeze/FETCH_HOTEL_PRICE_FREEZE_OFFERS";
export type FETCH_HOTEL_PRICE_FREEZE_OFFERS =
  typeof FETCH_HOTEL_PRICE_FREEZE_OFFERS;

export const SET_HOTEL_PRICE_FREEZE_OFFERS =
  "hotelPriceFreeze/SET_HOTEL_PRICE_FREEZE_OFFERS";
export type SET_HOTEL_PRICE_FREEZE_OFFERS =
  typeof SET_HOTEL_PRICE_FREEZE_OFFERS;

export const SET_HOTEL_PRICE_FREEZE_PURCHASE_ENTRY =
  "hotelPriceFreeze/SET_HOTEL_PRICE_FREEZE_PURCHASE_ENTRY";
export type SET_HOTEL_PRICE_FREEZE_PURCHASE_ENTRY =
  typeof SET_HOTEL_PRICE_FREEZE_PURCHASE_ENTRY;

export const FETCH_HOTEL_AVAILABILITY_TRACKING =
  "hotelPriceFreeze/FETCH_HOTEL_AVAILABILITY_TRACKING";
export type FETCH_HOTEL_AVAILABILITY_TRACKING =
  typeof FETCH_HOTEL_AVAILABILITY_TRACKING;

export const SET_HOTEL_AVAILABILITY_TRACKING =
  "hotelPriceFreeze/SET_HOTEL_AVAILABILITY_TRACKING";
export type SET_HOTEL_AVAILABILITY_TRACKING =
  typeof SET_HOTEL_AVAILABILITY_TRACKING;
