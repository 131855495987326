import { put } from "redux-saga/effects";
import { IFetchHotelPriceFreezeOffers, setHotelPriceFreezeOffers } from "../actions/actions";
import { LodgingPriceFreezeBatchOfferResponse } from "redmond";
import { fetchHotelPriceFreezeOffers } from "../../../api/v0/priceFreeze/fetchHotelPriceFreezeOffers";
import Logger from "../../../utils/logger";

export function* fetchHotelPriceFreezeOffersSaga(action: IFetchHotelPriceFreezeOffers) { 
    try {
        const { request } = action;
        const response: LodgingPriceFreezeBatchOfferResponse = yield fetchHotelPriceFreezeOffers(request);
        yield put(setHotelPriceFreezeOffers(response)) 
    } catch (e) {
        Logger.debug(e);
    }
}
