export const HEADER_COPY = "Confirm and freeze this price";
export const SUBHEADER_COPY = "Enter all required details";
export const TRAVELERS = "Travelers";
export const REVIEW_ITINERARY_TITLE = (step: number) =>
  `Step ${step}: Review hotel details`;
export const REWARDS_AND_PAYMENT_TITLE = (step: number) =>
  `Step ${step}: Rewards and payment`;
export const CONTACT_INFO_TITLE = (step: number) =>
  `Step ${step}: Contact information`;
export const CONTACT_INFO_SUBTITLE = `Confirmation and updates for your frozen price will be sent to this email address.`;
export const CONFIRM_BUTTON_TEXT = `Freeze this price`;

export const NONREFUNDABLE = "Nonrefundable";
export const NONREFUNDABLE_PAYMENT =
  "Your price freeze payment will be applied to your booking if you use it before it expires. This payment is not refundable.";
export const CHECKOUT_BREAKDOWN_HEADER = "Price freeze checkout breakdown";
export const CONFIRM_AND_FREEZE_PRICE = "Confirm and freeze price";
