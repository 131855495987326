import {
  Cfar,
  LodgingAvailabilityCheckInTime,
  LodgingAvailabilityCheckInTimeEnum,
} from "redmond";
import { getTotalPriceText } from "halifax";
import dayjs from "dayjs";

export const CHECKOUT_BREAKDOWN_HEADER = "Checkout breakdown";
export const BOOK_BUTTON_TEXT = "Confirm and Book";
export const REQUIRED_DETAILS_TEXT = "Enter all required details";
export const CONTACT_INFO_TITLE = "Step 2: Contact Information";
export const CONTACT_INFO_TITLE_NO_STEP = "Your Contact Information";
export const CONTACT_INFO_SUBTITLE =
  "This information is used to notify you about any updates or changes to your trip.";
export const CONTACT_INFO_SAVE = "Save Contact Information";
export const CONTACT_INFO_HELPER_TEXT =
  "You must add one traveler and enter your contact information to continue.";
export const REVIEW_MY_TRIP_TEXT = "Review my Trip";
export const NEED_TO_KNOW_TITLE = "Need to know";
export const NEED_TO_KNOW_DETAILS =
  "All rooms are guaranteed to accommodate 2 guests. Additional guests are at the discretion of the hotel and may be subject to extra fees. At check-in, the hotel may place a temporary hold on your credit card for incidentals.";

export const getCfarSecondaryText = (cfarCancellation: Cfar) =>
  `Refund amount: ${getTotalPriceText({
    price: cfarCancellation.refundAmount.fiat,
  })}`;
export const TRAVELER_INFO_TITLE = "Step 1: Primary Traveler Information";

export const TREES_MODAL_TITLE = "How it works";
export const TREES_MODAL_SUBTITLE =
  "<b>Tree Planting Efforts:</b> Capital One, together with its partners, will donate to GlobalGiving’s fund for EarthLungs’ Reforestation Project enough to plant two (2) trees, $0.25, for every completed flight, hotel booking, car rental, or vacation rental booking made through Capital One Travel.  In the event that a booking is made, but later canceled and refunded to the customer, for any reason, such a payment will not be made. No portion of a customer’s Capital One Travel purchase is tax-deductible. <br>GlobalGiving is a 501(c)(3) nonprofit organization whose mission is to transform aid and philanthropy to accelerate community-led change. To learn more about GlobalGiving, visit <a href='www.globalgiving.org' target='_blank'>www.globalgiving.org</a>; to learn more about GlobalGiving’s nonprofit partner, EarthLungs Reforestation Project, visit <a href='http://www.earthlungsreforestation.org.' target='_blank'>www.earthlungsreforestation.org.";
export const TREES_MODAL_HEADER =
  "When you book, <span class='font-regular'>you plant trees.</span>";

export const TREES_MODAL_CTA_TEXT = "will be planted for this booking! ";
export const TREES_BOLDED_MODAL_CTA_TEXT = "2 trees ";
export const AGENT_DETAILS_MODAL_TEXT = "View Hotel Information";

export const CHECK_IN_INSTRUCTIONS_TITLE =
  "Scheduled check-in and check-out time";

export const formattedCheckIn = (
  date: Date,
  time?: LodgingAvailabilityCheckInTime
) => {
  return `<strong>Check-in:</strong> ${dayjs(date).format(
    "dddd, MMMM D, YYYY"
  )}${
    time && time.CheckInTime == LodgingAvailabilityCheckInTimeEnum.Restricted
      ? " at " + dayjs(time.checkInTimeFrom, "HH:mm").format("h:mm A")
      : ""
  }`;
};

export const formattedCheckOut = (date: Date, time?: string) =>
  `<strong>Check-out:</strong> ${dayjs(date).format("dddd, MMMM D, YYYY")}${
    time ? " at " + dayjs(time, "HH:mm").format("h:mm A") : ""
  }`;

export const CALIFORNIA_BILL_644_CANCELLATION_TEXT = `This hotel is in California, therefore you are entitled to free cancellation without penalty for 24 hours after booking. If you choose to change or cancel this booking after this 24 hour window, you will not be refunded any of the payment.`;
