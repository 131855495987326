import axios from "axios";
import { config } from "../../config";
import { hotelPriceDropEligibilityPath } from "../../v1/paths";
import { PriceDropSmokeEligibilityResponse } from "redmond";

export const fetchPriceDropEligibility =
  (): Promise<PriceDropSmokeEligibilityResponse> =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(hotelPriceDropEligibilityPath, {
          baseURL: config.baseURL,
        });

        resolve(response.data);
      } catch (e) {
        reject(e);
      }
    });
