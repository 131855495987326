import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getLocationCategories,
  getLocation,
  getLocationCategoriesLoading,
  getHasLocationAutocompleteError,
  getAdultsCount,
  getChildren,
  getRoomsCount,
  getPetsCount,
} from "../../../../reducer";
import { MobileLocationSearchModal } from "./component";

const mapDispatchToProps = {
  setLocation: actions.setLocation,
  fetchLocationCategories: actions.fetchLocationCategories,
  setLocationAutocompleteError: actions.setLocationAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => ({
  location: getLocation(state),
  locationCategories: getLocationCategories(state),
  loading: !!getLocationCategoriesLoading(state),
  hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  roomsCount: getRoomsCount(state),
  petsCount: getPetsCount(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileLocationSearchModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileLocationSearchModal = connector(
  MobileLocationSearchModal
);
