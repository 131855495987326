import React from "react";
import { DatePickerButton } from "halifax";
import { DesktopCalendarPicker } from "../DesktopCalendarPicker";
import { ConnectedCalendarPickerButtonProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";

interface CalendarPickerButtonProps extends ConnectedCalendarPickerButtonProps {
  classes?: string[];
  hideSeparator?: boolean;
  saveDatesOnClose?: boolean;
  hasMissingSearchInfoError?: boolean;
  showCalenderPricingColors?: boolean;
  showCalendarPricing?: boolean;
}

export const CalendarPickerButton = ({
  classes = [],
  fromDate,
  hideSeparator,
  openDatesModal,
  setOpenDatesModal,
  untilDate,
  saveDatesOnClose,
  hasMissingSearchInfoError,
  fetchCalendarBuckets,
  showCalenderPricingColors,
  showCalendarPricing,
}: CalendarPickerButtonProps) => {
  const onClick = () => {
    if (showCalenderPricingColors) {
      fetchCalendarBuckets();
    }
    setOpenDatesModal(true);
  };

  return (
    <DatePickerButton
      startDate={fromDate}
      endDate={untilDate}
      startLabel={textConstants.CHECK_IN}
      endLabel={textConstants.CHECK_OUT}
      classes={["hotel-inline-juncture-input", ...classes]}
      hideSeparator={hideSeparator}
      renderCalendarPopup={(props) => (
        <DesktopCalendarPicker
          {...props}
          open={openDatesModal}
          closePopup={() => {
            setOpenDatesModal(false);
            props?.closePopup?.();
          }}
          saveDatesOnClose={saveDatesOnClose}
          showPricing={showCalendarPricing}
        />
      )}
      onClick={onClick}
      dateFormat={"ddd, MMM D"}
      hasMissingSearchInfoError={hasMissingSearchInfoError}
    />
  );
};
