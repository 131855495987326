import { delay, putResolve, select } from "redux-saga/effects";
import { IStoreState } from "../../../../reducers/types";
import {
  getCompleteBuyHotelProperties,
  getPriceFreezePurchaseProperties,
  getSession,
  getTripPurpose,
  hotelBookTypeSelector,
} from "../../reducer";
import {
  COMPLETE_BUY_HOTEL,
  Reservation,
  HOTEL_PRICE_FREEZE_COMPLETE_BUY,
  HotelPriceFreezePurchaseProperties,
  PriceFreezeHotelPriceQuoteFulfilled,
  HotelBookType,
  getHotelPriceFreezePurchaseOfferProperties,
  HotelPriceFreezePurchaseOfferProperties,
} from "redmond";
import {
  IPollConfirmationDetails,
  setConfirmationDetails,
  setPollConfirmationDetailsCallStateFailure,
  setPollConfirmationDetailsCallStateSuccess,
} from "../../actions/actions";
import { getPurchaseRequestMetaProps, getPurchaseApis } from "../../utils";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  ErrorCode,
  FulfillFailure,
  FulfillResponseEnum,
  FulfillResponseV0,
  FulfillResponse,
  FulfillSuccessV0,
  FulfillSuccess,
  PaymentError,
  Product,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import { getHotelShopChosenPriceFreezeOffer } from "../../../shop/reducer";
import dayjs from "dayjs";
import {
  userEligibleHotelPriceDropSelector,
  userPriceDropAmountSelector,
} from "../../../ancillary/reducer";

export const toErrorString = (error: PurchaseError): string => {
  switch (error.Error) {
    case PurchaseErrorEnum.ErrorCode:
      const e = error as ErrorCode;
      return `${e.code}${e.message ? ` - ${e.message}` : ""}`;
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      return JSON.stringify(paymentError.value.value);
    case PurchaseErrorEnum.ProductError:
      const productError = error as ProductError;
      return JSON.stringify(productError.value.value);
    default:
      return PurchaseErrorEnum[error.Error];
  }
};

export function* pollConfirmationDetailsSaga({
  agentFee,
  isRecommended,
}: IPollConfirmationDetails) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const priceFreezePurchaseProperties =
      getPriceFreezePurchaseProperties(state);
    const hotelBookType = hotelBookTypeSelector(state);
    const tripPurpose = getTripPurpose(state);
    const hotelShopChosenPriceFreezeOffer =
      getHotelShopChosenPriceFreezeOffer(state);
    const { version } = getPurchaseRequestMetaProps({ hotelBookType });
    const { pollFinalized } = getPurchaseApis(version);
    const priceDropProtectionIncluded = !!(
      (hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE ||
        hotelBookType === HotelBookType.DEFAULT) &&
      userEligibleHotelPriceDropSelector(state) &&
      userPriceDropAmountSelector(state)
    );

    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [1000];
    let pollFailed = false;
    let index = 0;

    const hotelPriceFreezePurchaseOfferPropertiesWithoutExpirationTime =
      getHotelPriceFreezePurchaseOfferProperties(
        hotelShopChosenPriceFreezeOffer
      );

    while (!pollFailed) {
      yield delay(delayTimes[index]);
      const finalizedCheckedResponse: FulfillResponse | FulfillResponseV0 =
        yield pollFinalized({
          req: sessionToken,
        }).catch((e) => {
          // [CMKT-1150] do not error out on network errors, instead retry
          if (e.response.status !== 200) {
            return Promise.resolve({
              data: { result: FulfillResponseEnum.Pending },
              status: 200,
              statusText: "Gateway timeout",
              headers: [],
              config: {},
            });
          }
          return Promise.reject(e);
        });
      switch (finalizedCheckedResponse.FulfillResponse) {
        case FulfillResponseEnum.Failure:
          pollFailed = true;
          const failedResponse = finalizedCheckedResponse as FulfillFailure;
          const newState: IStoreState = yield select();

          const completeBuyHotelProperties =
            getCompleteBuyHotelProperties(newState);
          handleTrackEvent({
            properties: {
              ...completeBuyHotelProperties.properties,
              success: false,
              agent_booking_fee_amount_usd: agentFee,
              failure_reason:
                failedResponse.errors.length > 0
                  ? failedResponse.errors
                      .map((error) => toErrorString(error))
                      .join(" - ")
                  : "Poll Confirmation Details response returned an error and the given error code is not handleable.",
              is_recommended: isRecommended,
              hotel_price_drop_included: priceDropProtectionIncluded,
              purpose_of_travel: tripPurpose,
            },
            priceFreezePurchaseProperties,
            hotelPriceFreezePurchaseOfferProperties:
              hotelPriceFreezePurchaseOfferPropertiesWithoutExpirationTime,
            encryptedProperties: [
              ...completeBuyHotelProperties.encryptedProperties,
            ],
            hotelBookType,
          });
          if (failedResponse.errors.length > 0) {
            yield putResolve(
              setPollConfirmationDetailsCallStateFailure(failedResponse.errors)
            );
            return;
          } else {
            yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
            throw new Error(
              "Poll Confirmation Details response returned an error and the given error code is not handleable."
            );
          }

        case FulfillResponseEnum.Pending:
          break;
        case FulfillResponseEnum.Success:
          const productType = (() => {
            switch (hotelBookType) {
              case HotelBookType.PRICE_FREEZE_PURCHASE:
                return (finalizedCheckedResponse as FulfillSuccess)
                  ?.products?.[0]?.type;
              case HotelBookType.PRICE_FREEZE_EXERCISE:
              case HotelBookType.DEFAULT:
              default:
                return (finalizedCheckedResponse as FulfillSuccessV0)
                  ?.fulfillment?.type;
            }
          })();

          switch (productType) {
            case Product.LodgingPriceFreeze: {
              const product = (
                finalizedCheckedResponse as FulfillSuccess
              )?.products?.find(
                (product) => product?.type === Product.LodgingPriceFreeze
              );
              const hotelPriceFreezeConfirmationDetails =
                product?.value as PriceFreezeHotelPriceQuoteFulfilled;

              yield putResolve(
                setConfirmationDetails({
                  hotelPriceFreezeConfirmationDetails,
                  type: "pf-purchase",
                })
              );
              yield putResolve(setPollConfirmationDetailsCallStateSuccess());

              const durationDays = hotelShopChosenPriceFreezeOffer
                ? dayjs
                    .duration({
                      hours: hotelShopChosenPriceFreezeOffer.durationHours,
                    })
                    .asDays()
                : null;

              const backupPriceFreezeExpirationDate = durationDays
                ? dayjs().add(dayjs.duration({ days: durationDays }))
                : null;

              const priceFreezeExpirationDate =
                hotelPriceFreezeConfirmationDetails?.expirationUtc
                  ? dayjs(hotelPriceFreezeConfirmationDetails.expirationUtc)
                  : backupPriceFreezeExpirationDate;

              const hotelPriceFreezePurchaseOfferProperties =
                getHotelPriceFreezePurchaseOfferProperties(
                  hotelShopChosenPriceFreezeOffer,
                  priceFreezeExpirationDate
                    ? priceFreezeExpirationDate.unix()
                    : undefined
                );

              handleTrackEvent({
                properties: {
                  success: true,
                  hotel_price_drop_included: priceDropProtectionIncluded,
                },
                priceFreezePurchaseProperties: {
                  ...priceFreezePurchaseProperties,
                  confirmation_id:
                    hotelPriceFreezeConfirmationDetails?.confirmationCode,
                },
                hotelPriceFreezePurchaseOfferProperties,
                encryptedProperties: undefined,
                hotelBookType,
              });
              return;
            }
            case Product.Hotel: {
              const finalizedResponse =
                finalizedCheckedResponse as FulfillSuccessV0;
              const reservation = finalizedResponse.fulfillment
                .value as Reservation;
              yield putResolve(
                setConfirmationDetails({
                  confirmationDetails: reservation,
                  type: "hotel-checkout",
                })
              );
              yield putResolve(setPollConfirmationDetailsCallStateSuccess());

              const refundableAdvance =
                finalizedResponse?.fulfillment?.value?.trackingProperties
                  ?.refundable_advance ?? undefined;
              const cancellationPolicy =
                finalizedResponse?.fulfillment?.value?.trackingProperties
                  ?.cancellation_policy ?? undefined;

              const newState: IStoreState = yield select();

              const completeBuyHotelProperties =
                getCompleteBuyHotelProperties(newState);

              handleTrackEvent({
                properties: {
                  ...completeBuyHotelProperties.properties,
                  success: true,
                  agent_booking_fee_amount_usd: agentFee,
                  reservation_id: reservation.reservationId,
                  is_recommended: isRecommended,
                  refundable_advance: refundableAdvance,
                  cancellation_policy: cancellationPolicy,
                  hotel_price_drop_included: priceDropProtectionIncluded,
                  purpose_of_travel: tripPurpose,
                },
                priceFreezePurchaseProperties: undefined,
                hotelPriceFreezePurchaseOfferProperties: undefined,
                encryptedProperties: [
                  ...completeBuyHotelProperties.encryptedProperties,
                ],
                hotelBookType,
              });
              return;
            }
            default: {
              pollFailed = true;
              const newState: IStoreState = yield select();
              const completeBuyHotelProperties =
                getCompleteBuyHotelProperties(newState);
              handleTrackEvent({
                properties: {
                  ...completeBuyHotelProperties.properties,
                  success: false,
                  agent_booking_fee_amount_usd: agentFee,
                  failure_reason:
                    "Poll Confirmation Details Success Response Failed",
                  is_recommended: isRecommended,
                  hotel_price_drop_included: priceDropProtectionIncluded,
                  purpose_of_travel: tripPurpose,
                },
                priceFreezePurchaseProperties,
                hotelPriceFreezePurchaseOfferProperties: undefined,
                encryptedProperties: [
                  ...completeBuyHotelProperties.encryptedProperties,
                ],
                hotelBookType,
              });
              yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
              throw new Error("Poll Finalized Failed");
            }
          }
      }

      // if we want to give up on polling we should instead direct the user to my trips
      if (index >= delayTimes.length) {
        window.location.pathname = "/trips";
      }

      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    const state: IStoreState = yield select();
    const hotelBookType = hotelBookTypeSelector(state);
    const tripPurpose = getTripPurpose(state);
    const hotelShopChosenPriceFreezeOffer =
      getHotelShopChosenPriceFreezeOffer(state);
    const hotelPriceFreezePurchaseOfferProperties =
      getHotelPriceFreezePurchaseOfferProperties(
        hotelShopChosenPriceFreezeOffer
      );
    const completeBuyHotelProperties = getCompleteBuyHotelProperties(state);
    const priceFreezePurchaseProperties =
      getPriceFreezePurchaseProperties(state);

    const priceDropProtectionIncluded = !!(
      (hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE ||
        hotelBookType === HotelBookType.DEFAULT) &&
      userEligibleHotelPriceDropSelector(state) &&
      userPriceDropAmountSelector(state)
    );

    handleTrackEvent({
      properties: {
        ...completeBuyHotelProperties.properties,
        success: false,
        agent_booking_fee_amount_usd: agentFee,
        failure_reason: "Poll Confirmation Details Network Call Failed",
        is_recommended: isRecommended,
        hotelShopChosenPriceFreezeOffer,
        hotel_price_drop_included: priceDropProtectionIncluded,
        purpose_of_travel: tripPurpose,
      },
      hotelPriceFreezePurchaseOfferProperties,
      priceFreezePurchaseProperties,
      encryptedProperties: [...completeBuyHotelProperties.encryptedProperties],
      hotelBookType,
    });
    yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
  }
}

const handleTrackEvent = ({
  properties,
  priceFreezePurchaseProperties,
  hotelPriceFreezePurchaseOfferProperties,
  encryptedProperties,
  hotelBookType,
}: {
  properties: any;
  priceFreezePurchaseProperties: HotelPriceFreezePurchaseProperties | undefined;
  hotelPriceFreezePurchaseOfferProperties:
    | HotelPriceFreezePurchaseOfferProperties
    | undefined;
  encryptedProperties: string[] | undefined;
  hotelBookType: HotelBookType;
}) => {
  switch (hotelBookType) {
    case HotelBookType.PRICE_FREEZE_PURCHASE:
      trackEvent({
        eventName: HOTEL_PRICE_FREEZE_COMPLETE_BUY,
        properties: {
          ...priceFreezePurchaseProperties,
          ...hotelPriceFreezePurchaseOfferProperties,
          success: properties?.success,
        },
      });
      break;
    case HotelBookType.PRICE_FREEZE_EXERCISE:
    case HotelBookType.DEFAULT:
      trackEvent({
        eventName: COMPLETE_BUY_HOTEL,
        properties,
        encryptedProperties,
      });
      break;
  }
};
