import { put, call, select } from "redux-saga/effects";
import {
  ConnectionResultEnum,
  HotelBookType,
  InvalidEnum,
  PaymentSessionToken,
} from "redmond";
import { actions } from "../../actions";
import { IStoreState } from "../../../../reducers/types";
import {
  getSession,
  priceQuoteParamsSelector,
  hotelBookTypeSelector,
  priceFreezePriceQuoteParamsSelector,
} from "../../reducer";
import { getPurchaseApis, getPurchaseRequestMetaProps } from "../../utils";
import * as H from "history";
import { AncillaryOpaqueValue } from "@b2bportal/purchase-api";

// Wrapper function that first opens session, and then calls schedule price quote.
export function* schedulePriceQuoteSaga({
  agentFee,
  pollQuoteOnly,
  ancillaries,
  history,
}: actions.ISchedulePriceQuote) {
  yield call(
    callSchedulePriceQuote,
    agentFee,
    pollQuoteOnly,
    ancillaries,
    history
  );
}

function* callSchedulePriceQuote(
  agentFee?: number,
  pollQuoteOnly?: boolean,
  ancillaries?: AncillaryOpaqueValue[],
  history?: H.History
) {
  try {
    const state: IStoreState = yield select();
    const hotelBookType = hotelBookTypeSelector(state);
    const session = getSession(state);
    const { version, type } = getPurchaseRequestMetaProps({ hotelBookType });
    const { closeSession, schedulePriceQuote } = getPurchaseApis(version);

    if (session) {
      yield closeSession({
        token: session,
      });
      yield put(actions.clearSession());
    }

    // TODO - Discussed with sshen.
    // We will separate the flows for PF and Hotel Purchase into functions in this file, but keep a single-saga approach.
    // The work should be done with BF-1981.
    let params;
    switch (hotelBookType) {
      case HotelBookType.DEFAULT:
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        params = priceQuoteParamsSelector(state);
        break;
      case HotelBookType.PRICE_FREEZE_PURCHASE:
        params = priceFreezePriceQuoteParamsSelector(state);
        break;
    }

    if (!params) {
      throw new Error("Invalid Request.");
    }

    if ("hotelQuoteRequest" in params) {
      yield put(actions.setPriceQuoteRequest(params));
    }

    const result: String = yield schedulePriceQuote({
      req: params as any,
      type,
    });

    yield put(actions.setSchedulePriceQuoteCallStateSuccess());
    yield put(actions.setSession({ value: result } as PaymentSessionToken));
    yield put(
      actions.pollPriceQuote({ agentFee, pollQuoteOnly, ancillaries, history })
    );
  } catch (e) {
    yield put(
      actions.setSchedulePriceQuoteCallStateFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
