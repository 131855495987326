import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import {
  getHotelShopSelectedLodging,
  getHotelShopShopRequestId,
  getViewedHotelDetailsProperties,
  getViewedHotelDetailsTrackingProps,
  isAddOnOptionAvailableSelector,
  getHotelShopRoomInfoProducts,
  getHotelShopRoomInfoProductsWithTransformedIndexes,
  getRefundableRoomProductWithSelectedCfarId,
  getHotelCfarOfferChoicePropertiesSelector,
  getHotelShopCallState,
  getRefundableRoomProductCorrespondingToChosenRoom,
  getHotelShopDateRange,
  getHotelOpaqueCfarQuotesRequestSelector,
} from "./reducer/selectors";
import { HotelShop } from "./component";
import { actions } from "./actions";
import { getRewardsAccounts } from "../rewards/reducer";
import { actions as rewardsActions } from "../rewards/actions";
import { actions as priceFreezeActions } from "../freeze/actions";
import {
  fetchTravelWalletCreditHistory,
  fetchTravelWalletDetails,
} from "../travel-wallet/actions/actions";
import {
  fetchCfarQuotes,
  setHasSelectedRefundableRoom,
  setSelectedCfarId,
  setHasAddedCfarFromModal,
} from "../ancillary/actions/actions";
import {
  isHotelCfarEnabledSelector,
  hasSelectedRefundableRoomSelector,
  fetchCfarQuotesCallStateSelector,
  isHotelCfarRefundDisplayShowAmountEnabledSelector,
  isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector,
  isFintechHotelUpdatedUxEnabledSelector,
  isHotelCfarRefundDisplayNonRefPartialEnabledSelector,
} from "../ancillary/reducer";
import { getFromDate, getUntilDate } from "../search/reducer";
import {
  hotelPriceFreezeOfferPropertiesSelector,
  isHotelPriceFreezeEnabledSelector,
  isHotelPriceFreezeRefundableRoomsEnabledSelector,
} from "../freeze/reducer";
import { getHotelAvailabilityRoomsCount } from "../availability/reducer";
import { setSelectedRoomId } from "./actions/actions";
import { listPaymentMethods } from "../availability/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    hotelShopCallState: getHotelShopCallState(state),
    selectedLodging: getHotelShopSelectedLodging(state),
    rewardsAccounts: getRewardsAccounts(state),
    viewedHotelDetailsProperties: getViewedHotelDetailsProperties(state),
    trackingProps: getViewedHotelDetailsTrackingProps(state),
    hotelShopShopRequestId: getHotelShopShopRequestId(state),
    isHotelCfarEnabled: isHotelCfarEnabledSelector(state),
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    roomInfoProducts: getHotelShopRoomInfoProducts(state),
    roomInfoProductsWithTransformedIndexes:
      getHotelShopRoomInfoProductsWithTransformedIndexes(state),
    hasSelectedRefundableRoom: hasSelectedRefundableRoomSelector(state),
    refundableRoomProductWithSelectedCfarId:
      getRefundableRoomProductWithSelectedCfarId(state),
    refundableRoomProductCorrespondingToChosenRoom:
      getRefundableRoomProductCorrespondingToChosenRoom(state),
    getHotelCfarOfferChoiceProperties:
      getHotelCfarOfferChoicePropertiesSelector(state),
    fetchCfarQuotesCallState: fetchCfarQuotesCallStateSelector(state),
    isHotelPriceFreezeEnabled: isHotelPriceFreezeEnabledSelector(state),
    isHotelCfarRefundDisplayShowAmount:
      isHotelCfarRefundDisplayShowAmountEnabledSelector(state),
    isHotelCfarRefundDisplayShowAmountAdditionalPlaces:
      isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector(state),
    searchedRoomsCount: getHotelAvailabilityRoomsCount(state),
    hotelPriceFreezeOfferProperties:
      hotelPriceFreezeOfferPropertiesSelector(state),
    isHotelPriceFreezeRefundableRoomsEnabled:
      isHotelPriceFreezeRefundableRoomsEnabledSelector(state),
    isFintechHotelUpdatedUxEnabled:
      isFintechHotelUpdatedUxEnabledSelector(state),
    isHotelCfarRefundDisplayNonRefPartialEnabled:
      isHotelCfarRefundDisplayNonRefPartialEnabledSelector(state),
    hotelShopDateRange: getHotelShopDateRange(state),
    hotelOpaqueCfarQuotesRequest:
      getHotelOpaqueCfarQuotesRequestSelector(state),
  };
};

export const mapDispatchToProps = {
  fetchHotelShop: actions.fetchHotelShop,
  selectLodging: actions.selectLodging,
  resetLodging: actions.resetLodging,
  selectRoomType: actions.selectRoomType,
  setSelectedAccount: rewardsActions.setSelectedAccountReferenceId,
  fetchHotelPriceFreezeOffers: priceFreezeActions.fetchHotelPriceFreezeOffers,
  fetchTravelWalletDetails,
  fetchCfarQuotes,
  setHasSelectedRefundableRoom,
  setSelectedCfarId,
  fetchTravelWalletCreditHistory,
  setSelectedRoomId,
  setHasAddedCfarFromModal,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type HotelShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelShop = withRouter(connector(HotelShop));
