import { PRICE_FREEZE_ENTRY, HotelPriceFreezePurchaseEntry } from "redmond";

import * as H from "history";
import queryStringParser from "query-string";

interface IHotelPriceFreezeParsedQuery {
  [PRICE_FREEZE_ENTRY]: HotelPriceFreezePurchaseEntry;
}

export const parseQueryString = (
  history: H.History
): IHotelPriceFreezeParsedQuery => {
  const queryString = history?.location?.search || "";
  return parseQueryFromString(queryString);
};

export const parseQueryFromString = (
  queryString: string
): IHotelPriceFreezeParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  return {
    [PRICE_FREEZE_ENTRY]: parsedQueryStringPrimitive[
      PRICE_FREEZE_ENTRY
    ] as HotelPriceFreezePurchaseEntry,
  };
};
