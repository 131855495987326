import { Box } from "@material-ui/core";
import * as H from "history";
import React from "react";
import {
  IIdLodgings,
  LAUNCHED_MOBILE_CALENDAR,
  LaunchedMobileCalendarProperties,
} from "redmond";

import clsx from "clsx";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import {
  PATH_AVAILABILITY,
  PATH_HOME_SEARCH,
} from "../../../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../../../shop/utils/queryStringHelpers";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../HotelSearchControl/components/MonthAndDatePicker";
import { HotelSearchButton } from "../../../HotelSearchControl/components/SearchButton";
import { MobileCalendarPickerConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";

export interface IMobileCalendarPickerProps
  extends MobileCalendarPickerConnectorProps {
  focusedMonthIndex?: number;
  onComplete?: (from: Date | null, until: Date | null) => void;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    fromDate,
    untilDate,
    location,
    adultsCount,
    children,
    setFromDate,
    setUntilDate,
    focusedMonthIndex,
    onComplete,
    roomsCount,
  } = props;

  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  React.useEffect(() => {
    if (window.location.pathname === PATH_HOME_SEARCH) {
      const properties: LaunchedMobileCalendarProperties = {
        funnel: "hotels",
        url: window.location.pathname,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_CALENDAR, properties });
    }
  }, []);

  const handleClickDone = (history: H.History) => {
    setFromDate(from);
    setUntilDate(until);

    history.push(
      `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        (location?.id as IIdLodgings).lodgingSelection.searchTerm,
        from,
        until,
        adultsCount,
        children,
        roomsCount
      )}`
    );

    onComplete?.(from, until);
  };

  return (
    <Box className="hotel-mobile-calendar-picker-root">
      <MonthAndDatePicker
        className="b2b"
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setFromDate={setFrom}
        setUntilDate={setUntil}
        from={from}
        until={until}
      />
      {from && until && (
        <HotelSearchButton
          className={clsx("mobile-calendar-picker-search-button", "b2b")}
          message={textConstants.SEARCH_TEXT}
          onClick={(history: H.History) => {
            handleClickDone(history);
          }}
          from={from}
          until={until}
        />
      )}
    </Box>
  );
};
