import React from "react";
import { Icon, IconName, NotificationBanner } from "halifax";
import { Box, Typography } from "@material-ui/core";
import {
  BENEFITS_TITLE,
  BENEFITS_LIST,
  ADDITIONAL_BENEFITS,
} from "./textConstants";
import clsx from "clsx";
import "./styles.scss";

export interface IPremierCollectionCheckoutBenefitsProps {
  className?: string;
}
export const PremierCollectionCheckoutBenefits = ({
  className,
}: IPremierCollectionCheckoutBenefitsProps) => {
  return (
    <Box className={clsx("pc-checkout-benefits-content-wrapper", className)}>
      <Box className="pc-benefits-title">
        <Icon name={IconName.StarOutline} />
        <Typography className="pc-benefits-title-text">
          {BENEFITS_TITLE}
        </Typography>
      </Box>

      <Typography className="pc-benefits-list">{BENEFITS_LIST}</Typography>
      <NotificationBanner
        icon={<Icon name={IconName.GiftOutline} />}
        content={ADDITIONAL_BENEFITS}
      />
    </Box>
  );
};
