import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";

// components
import { Box, Button, Typography, Divider } from "@material-ui/core";
import { DesktopShop, HotelShopProgressBar } from "../../../../shop/components";
import { FrozenPriceSummary } from "../../../../freeze/components";
import { TravelOfferSelection } from "../../TravelOfferSelection";
import {
  IContactInfo,
  TravelerSelectStep,
  HotelNeedToKnowPanel,
  ContactInfoForm,
  DesktopPopupModal,
  RoomDetails,
  CheckoutStepper,
  getCancellationPolicyInfo,
  ButtonWrap,
  emailRegex,
  phoneRegex,
  ActionButton,
  ActionLink,
  IconName,
  Icon,
  TreesConfirmation,
  InformationalModal as TreesModal,
  VoidWindowNotice,
} from "halifax";
import {
  PriceBreakdown,
  HotelBookPassengerSelection,
  PaymentCard,
  HotelBookSummaryPanel,
  AGENT_FEE,
} from "../..";

// types, constants
import { DesktopHotelBookWorkflowConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import {
  ADD_CONTACT_INFO,
  CallState,
  CheckInPolicy,
  HOTEL_SAVE_AND_CONTINUE_CLICKED,
  ITravelerStepErrors,
  HotelBookType,
  getUpdatedHotelPriceFreezeCancellationPolicyInfo,
  PlatformEnum,
  AvailabilityRequestEnum,
  LodgingIds,
  LodgingSelectionEnum,
  LodgingCollectionEnum,
  CancellationReason,
  CancellationPolicyEnum,
} from "redmond";
import {
  CHECKOUT_BREAKDOWN_HEADER,
  BOOK_BUTTON_TEXT,
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_TITLE_NO_STEP,
  CONTACT_INFO_HELPER_TEXT,
  NEED_TO_KNOW_TITLE,
  NEED_TO_KNOW_DETAILS,
  REQUIRED_DETAILS_TEXT,
  getCfarSecondaryText,
  TRAVELER_INFO_TITLE,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
  AGENT_DETAILS_MODAL_TEXT,
  CHECK_IN_INSTRUCTIONS_TITLE,
  formattedCheckIn,
  formattedCheckOut,
  CALIFORNIA_BILL_644_CANCELLATION_TEXT,
} from "./textConstants";

// context
import { ClientContext } from "../../../../../App";
import {
  AVAILABLE,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
} from "../../../../../context/experiments";

// helpers, utils
import { fetchCustomerDetails } from "../../../../../api/v0/customer/fetchCustomerDetails";
import { PATH_BOOK_CONFIRMATION, PATH_HOME } from "../../../../../utils/paths";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import queryStringParser from "query-string";
import clsx from "clsx";

// styles
import "./styles.scss";
import PremierCollectionCheckoutBenefits from "../../PremierCollectionCheckoutBenefits";
import dayjs from "dayjs";
import { CfarCancellationPolicy } from "../../../../ancillary/components/addOnComponents/cfar/CfarCancellationPolicy";
import { PriceDropProtectionBanner } from "../../../../ancillary/components/addOnComponents";
import { PriceDropProtectionPopup } from "../../../../ancillary/components/addOnComponents/PriceDropProtectionBanner/components";

export interface IDesktopHotelBookWorkflowProps
  extends RouteComponentProps,
    DesktopHotelBookWorkflowConnectorProps {}

export const DesktopHotelBookWorkflow = ({
  setContactInfo,
  priceQuote,
  priceQuoteCallState,
  schedulePriceQuote,
  chosenProduct,
  selectedLodging,
  roomInfoProduct,
  chosenProductIndex,
  combinedBookingSteps,
  reservation,
  history,
  isBookingValid,
  email,
  phoneNumber,
  allStepsCompleted,
  priceDifferenceAcknowledged,
  scheduleBook,
  isTravelerStepComplete,
  isContactStepComplete,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  priceQuoteErrors,
  confirmationDetailsErrors,
  setPriceQuote,
  roomsCount,
  isTravelWalletPaymentOnly,
  isCustomizePageEnabled,
  ancillaries,
  hasCfarAttached,
  hotelBookType,
  isReadyToDisplayHotelSummaryDetails,
  priceFreezeOffer,
  checkIn,
  checkOut,
  fetchHotelAvailabilityTracking,
  userPriceDropAmount,
  cancellationSummary,
}: IDesktopHotelBookWorkflowProps) => {
  const isInHotelPriceFreezeExercise =
    hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE;
  const includeCheckoutHeader = isInHotelPriceFreezeExercise;
  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = useMemo(() => {
    return californiaBill644Variant === AVAILABLE;
  }, [californiaBill644Variant]);

  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal } = clientContext;
  const isFirstStepCompletedInCheckout =
    isContactStepComplete && isTravelerStepComplete;

  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
  });
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: phoneNumber || "",
    email: email || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    useState<boolean>(true);
  const [disableEditTravelerInfo, setDisableEditTravelerInfo] = useState(false);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [
    priceDropProtectionBannerModal,
    setOpenPriceDropProtectionBannerModal,
  ] = useState<boolean>(false);

  const showCheckoutBreadcrumbs =
    (hotelBookType === HotelBookType.DEFAULT && isCustomizePageEnabled) ||
    hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE;
  const showCheckoutBreadcrumbsCustomizeStep =
    hotelBookType === HotelBookType.DEFAULT && isCustomizePageEnabled;
  const showFrozenPriceSummary = isInHotelPriceFreezeExercise;

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        chosenProduct?.cancellationPolicy,
        getCfarSecondaryText
      )
    : null;

  const onBookHotel = () => {
    const queryString = queryStringParser.parse(history.location.search);
    priceDifferenceAcknowledged || !!priceQuote
      ? scheduleBook({
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          isRecommended: queryString.recommended === "true",
          ancillaries: ancillaries,
        })
      : schedulePriceQuote({
          history,
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          ancillaries: ancillaries,
        });
  };

  const mergedPolicies = useMemo(
    () =>
      [
        ...(chosenProduct?.policies ?? []),
        ...(priceQuote?.checkInInstructions.policies ?? []),
      ].reduce((uniquePolicies, currentPolicy) => {
        if (
          !uniquePolicies.some(({ title }) => currentPolicy.title === title)
        ) {
          uniquePolicies.push(currentPolicy);
        }
        return uniquePolicies;
      }, [] as CheckInPolicy[]),
    [chosenProduct?.policies, priceQuote?.checkInInstructions.policies]
  );

  useEffect(() => {
    if (reservation) {
      history.push(PATH_BOOK_CONFIRMATION);
    }
  }, [reservation]);

  useEffect(() => {
    if (priceQuoteErrors.length > 0 || confirmationDetailsErrors.length > 0) {
      setDisableEditTravelerInfo(false);
    }
  }, [priceQuoteErrors, confirmationDetailsErrors]);

  useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
  }, []);

  useEffect(() => {
    if (
      isInHotelPriceFreezeExercise &&
      checkIn &&
      checkOut &&
      selectedLodging &&
      roomInfoProduct
    ) {
      const lodgingSelection: LodgingIds = {
        lodgingIds: [selectedLodging.lodging.id ?? ""],
        LodgingSelection: LodgingSelectionEnum.LodgingIds,
      };
      fetchHotelAvailabilityTracking({
        lodgingSelection: lodgingSelection,
        stayDates: {
          from: dayjs(checkIn).format("YYYY-MM-DD"),
          until: dayjs(checkOut).format("YYYY-MM-DD"),
        },
        guests: {
          adults: roomInfoProduct.roomInfo.maxAdults ?? 1,
          children: [],
        },
        platform: PlatformEnum.Desktop,
        progressiveConfig: {},
        AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
        rooms: { numberOfRooms: roomsCount },
      });
    }
  }, [
    isInHotelPriceFreezeExercise,
    checkIn,
    checkOut,
    selectedLodging,
    roomInfoProduct,
  ]);

  const renderLogo = (
    <ButtonWrap
      className="logo"
      onClick={() => {
        history.push(PATH_HOME);
      }}
    >
      {clientContext.logo}
    </ButtonWrap>
  );

  const handleBookingInfoClick = useCallback(() => {
    setDetailsModalOpen(!detailsModalOpen);
  }, [detailsModalOpen]);

  return (
    <Box
      className={clsx("desktop-hotel-book-workflow-root", {
        "with-progress-bar": showCheckoutBreadcrumbs,
      })}
    >
      {detailsModalOpen && (
        <DesktopPopupModal
          open={detailsModalOpen}
          onClose={handleBookingInfoClick}
          className="desktop-hotel-book-details-modal"
        >
          <DesktopShop hideRightColumn hideProgressbar />
        </DesktopPopupModal>
      )}
      <CheckoutStepper
        steps={combinedBookingSteps}
        headerCopy={BOOK_BUTTON_TEXT}
        subHeaderCopy={REQUIRED_DETAILS_TEXT}
        logo={renderLogo}
        className={clsx("b2b", "combined-step")}
      />
      {showCheckoutBreadcrumbs && (
        <Box className="hotel-book-progress-bar-wrapper">
          <HotelShopProgressBar
            useCustomizeStep={showCheckoutBreadcrumbsCustomizeStep}
          />
        </Box>
      )}
      <Box className="desktop-hotel-book-workflow-container">
        <Box className={clsx("checkout-template-column", "left")}>
          {isReadyToDisplayHotelSummaryDetails && (
            <Box
              className={clsx(
                "checkout-template-card-content-container",
                "hotel"
              )}
            >
              <HotelBookSummaryPanel
                className="b2b"
                actions={
                  isAgentPortal ? (
                    <Typography
                      role="button"
                      variant="h6"
                      className="view-hotel-information"
                      onClick={handleBookingInfoClick}
                    >
                      {AGENT_DETAILS_MODAL_TEXT}
                    </Typography>
                  ) : null
                }
              />
            </Box>
          )}

          {isTravelWalletOfferExperiment &&
            !isCreditAndOfferStackingExperimentV1 && <TravelOfferSelection />}

          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "traveler"
            )}
          >
            <>
              <Box className={clsx("checkout-template-description")}>
                <Typography
                  variant="h2"
                  className={clsx("checkout-template-title")}
                >
                  {TRAVELER_INFO_TITLE}
                </Typography>
              </Box>
              <Divider className="traveler-info-container-divider" />
            </>
            <HotelBookPassengerSelection
              disabled={disableEditTravelerInfo}
              progress={travelerWorkflowStep}
              setProgress={setTravelerWorkflowStep}
              combinedStep
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              saveButtonClicked={saveButtonClicked}
              hiddenTravelerIds={[]}
            />
            <Divider className="traveler-info-container-divider" />
            <ContactInfoForm
              disabled={disableEditTravelerInfo}
              title={CONTACT_INFO_TITLE_NO_STEP}
              subtitle={CONTACT_INFO_SUBTITLE}
              contactInfo={contactInfo}
              onContactInfoChange={(info: IContactInfo) => {
                setContact(info);
                setContactInfo(info.email, info.phoneNumber);
                if (
                  info.email &&
                  info.phoneNumber &&
                  emailRegex.test(info.email) &&
                  phoneRegex.test(info.phoneNumber)
                ) {
                  trackEvent({
                    eventName: ADD_CONTACT_INFO,
                    properties: {},
                  });
                }
              }}
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              loading={customerDetailsLoading}
            />
            {!disableEditTravelerInfo && (
              <ActionButton
                className={clsx(
                  "traveler-select-workflow-button",
                  "traveler-step-cta"
                )}
                message="Save & Continue"
                onClick={() => {
                  if (isFirstStepCompletedInCheckout) {
                    schedulePriceQuote({
                      history,
                      agentFee: isAgentPortal ? AGENT_FEE : 0,
                      pollQuoteOnly: true,
                      ancillaries: ancillaries,
                    });
                    setDisableEditTravelerInfo(true);
                    setShowErrors({
                      phone: false,
                      email: false,
                      travelerSelect: false,
                    });
                    trackEvent({
                      eventName: HOTEL_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: false,
                      },
                    });
                  } else {
                    trackEvent({
                      eventName: HOTEL_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: true,
                        traveler_error: !isTravelerStepComplete,
                        email_error:
                          !contactInfo || !emailRegex.test(contactInfo.email),
                        phone_error:
                          !contactInfo ||
                          !phoneRegex.test(contactInfo.phoneNumber),
                      },
                    });
                    setShowErrors({
                      phone:
                        !contactInfo ||
                        !phoneRegex.test(contactInfo.phoneNumber),
                      email:
                        !contactInfo || !emailRegex.test(contactInfo.email),
                      travelerSelect: !isTravelerStepComplete,
                    });
                    if (!saveButtonClicked) {
                      setSaveButtonClicked(true);
                    }
                  }
                }}
                defaultStyle="h4r-primary"
              />
            )}
            {disableEditTravelerInfo && (
              <ActionLink
                className={clsx("traveler-step-cta", "b2b")}
                content={
                  <Box className="edit-traveler-link">
                    <Typography variant="body1">
                      Edit Traveler Information
                    </Typography>
                    <Icon
                      aria-hidden
                      className="icon-end"
                      name={IconName.B2BEditPencil}
                      ariaLabel=""
                    />
                  </Box>
                }
                onClick={() => {
                  setPriceQuote({
                    priceQuote: null,
                    pricingWithAncillaries: null,
                    hotelAncillaryQuotes: null,
                    type: "hotel-checkout",
                  });
                  setDisableEditTravelerInfo(false);
                  setSelectedPaymentMethodId({
                    paymentMethodId: "",
                    accountId: undefined,
                    priceFreezeOffer: priceFreezeOffer,
                  });
                  setSelectedRewardsAccountReferenceId(null);
                }}
              />
            )}
            <Box className={clsx("contact-info-helper-container")}>
              <Typography
                variant="body2"
                className={clsx("contact-info-helper-text")}
              >
                {CONTACT_INFO_HELPER_TEXT}
              </Typography>
            </Box>
          </Box>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "credit"
            )}
          >
            <PaymentCard
              disabled={
                !isFirstStepCompletedInCheckout ||
                !disableEditTravelerInfo ||
                (!isCreditAndOfferStackingExperimentV1 &&
                  isTravelWalletPaymentOnly)
              }
            />
            {isTreesModalExperiment && (
              <TreesModal
                image={TreesConfirmation}
                header={TREES_MODAL_HEADER}
                title={TREES_MODAL_TITLE}
                onClose={() => setTreeModalOpen(false)}
                subtitle={TREES_MODAL_SUBTITLE}
                icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
                openModal={treeModalOpen}
                setOpenModal={setTreeModalOpen}
                modalLinkCopy={TREES_MODAL_CTA_TEXT}
                modalButtonCopyStartIcon={
                  <Icon className="trees-icon" name={IconName.TreesIcon} />
                }
                boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
              />
            )}
          </Box>
          {hasCfarAttached ? (
            <CfarCancellationPolicy />
          ) : cancellationPolicyInfo ? (
            <Box
              className={clsx(
                "checkout-template-card-content-container",
                "cancellation-details"
              )}
            >
              <Typography variant="h2">
                {cancellationPolicyInfo.primaryText}
              </Typography>
              <Typography variant="body2">
                {showFree24HourCancel &&
                chosenProduct?.cancellationPolicy.CancellationPolicy ===
                  CancellationPolicyEnum.NonRefundable ? (
                  CALIFORNIA_BILL_644_CANCELLATION_TEXT
                ) : (
                  <>
                    {isInHotelPriceFreezeExercise
                      ? getUpdatedHotelPriceFreezeCancellationPolicyInfo(
                          cancellationPolicyInfo.secondaryText
                        )
                      : cancellationPolicyInfo.secondaryText}
                  </>
                )}
              </Typography>
            </Box>
          ) : null}
          {selectedLodging?.lodging?.checkInInstructions &&
            checkIn &&
            checkOut && (
              <Box
                className={clsx(
                  "checkout-template-card-content-container",
                  "check-in-instructions"
                )}
              >
                <Typography
                  variant="h2"
                  className="hotel-shop-check-in-instructions-heading"
                >
                  {CHECK_IN_INSTRUCTIONS_TITLE}
                </Typography>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: formattedCheckIn(
                      checkIn,
                      selectedLodging.lodging.checkInInstructions?.checkInTime
                    ),
                  }}
                />
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: formattedCheckOut(
                      checkOut,
                      selectedLodging.lodging.checkInInstructions?.checkOutTime
                    ),
                  }}
                />
              </Box>
            )}
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "need-to-know"
            )}
          >
            <HotelNeedToKnowPanel
              title={NEED_TO_KNOW_TITLE}
              subtitle={NEED_TO_KNOW_DETAILS}
              policies={mergedPolicies}
              fetchingPriceQuote={priceQuoteCallState === CallState.InProcess}
            />
          </Box>
        </Box>
        <Box className={clsx("checkout-template-column", "right")}>
          <Box className="checkout-template-column-right-wrapper">
            {showFrozenPriceSummary && (
              <Box
                className={clsx(
                  "hotel-book-right-content",
                  "price-freeze-details"
                )}
              >
                <FrozenPriceSummary />
              </Box>
            )}
            <Box
              className={clsx("hotel-book-right-content", "checkout-breakdown")}
            >
              {includeCheckoutHeader && (
                <Box
                  className={clsx(
                    "checkout-template-card-content-container",
                    "checkout-header"
                  )}
                >
                  <Typography className="header-copy" variant="h2">
                    {CHECKOUT_BREAKDOWN_HEADER}
                  </Typography>
                </Box>
              )}
              <Box
                className={clsx(
                  "checkout-template-card-content-container",
                  "payment-break-down"
                )}
              >
                {!!roomInfoProduct && chosenProductIndex !== null && (
                  <RoomDetails
                    roomInfoProduct={roomInfoProduct}
                    productIndex={chosenProductIndex}
                    lodging={selectedLodging}
                    hideAmenities
                    hideAllAmenities
                    roomsCount={roomsCount}
                    hideRoomBedDescription
                    hideRoomCapacity
                    showAmenitiesModal
                  />
                )}
                <PriceBreakdown />
              </Box>
              <PriceDropProtectionBanner
                bannerVersion="small"
                setOpenModal={setOpenPriceDropProtectionBannerModal}
              />
              <PriceDropProtectionPopup
                amount={userPriceDropAmount}
                openModal={priceDropProtectionBannerModal}
                onClose={() => setOpenPriceDropProtectionBannerModal(false)}
              />
              <Box className="confirm-book-button-container">
                <Button
                  className="confirm-book-button"
                  disabled={!isBookingValid || !allStepsCompleted}
                  onClick={onBookHotel}
                >
                  {BOOK_BUTTON_TEXT}
                </Button>
              </Box>
            </Box>
            {showFree24HourCancel && <VoidWindowNotice />}
            {selectedLodging?.lodgingCollection ===
              LodgingCollectionEnum.Premier && (
              <PremierCollectionCheckoutBenefits />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
