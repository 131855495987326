import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { HotelCustomize } from "./component";
import {
  getHotelShopChosenProduct,
  getHotelShopSelectedLodging,
  showOfferBasedOnSelectedLodgingSelector,
  hotelCfarQuoteFromChosenRoomProductSelector,
  additionalInfoFromChosenRoomProductSelector,
} from "../../../shop/reducer";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    chosenProduct: getHotelShopChosenProduct(state),
    selectedLodging: getHotelShopSelectedLodging(state),
    showOfferBasedOnSelectedLodging:
      showOfferBasedOnSelectedLodgingSelector(state),
    hotelCfarQuote: hotelCfarQuoteFromChosenRoomProductSelector(state),
    additionalInfo: additionalInfoFromChosenRoomProductSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelCustomizeConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelCustomize = connector(withRouter(HotelCustomize));
