import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PriceFreezeAction } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { baseHotelPriceFreezeOfferSelector } from "../../reducer";
import { getHotelAvailabilityRoomsCount } from "../../../availability/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    baseHotelPriceFreezeOffer: baseHotelPriceFreezeOfferSelector(state),
    searchedRoomsCount: getHotelAvailabilityRoomsCount(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeActionConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceFreezeAction = connector(
  withRouter(PriceFreezeAction)
);
