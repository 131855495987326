import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../reducers/types";
import {
  resetBook,
  closeSession,
  setContactInfo,
  resetPaymentCardSelectedAccounts,
} from "../book/actions/actions";
import { HotelFreeze } from "./component";
import { fetchRewardsAccounts } from "../rewards/actions/actions";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {
  resetBook,
  closeSession,
  setContactInfo,
  fetchRewardsAccounts,
  resetPaymentCardSelectedAccounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelFreezeConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelFreeze = withRouter(connector(HotelFreeze));
