import { Place } from "redmond";
import queryStringParser from "query-string";

const trackPlaceOverrides = [
  "ChIJKcEGZna4lxIRwOzSAv-b67c", // Majorca, Spain
];

export const shouldTrackLodgingLocation = (queryString: string): boolean => {
  try {
    const parsedQueryString = queryStringParser.parse(queryString);

    if (!parsedQueryString.lodgingSelection) return false;

    const lodgingSelection = JSON.parse(
      parsedQueryString.lodgingSelection as string
    ) as Place;

    if (!lodgingSelection.placeTypes) return false;

    const locationIsGeocode = lodgingSelection.placeTypes.includes("geocode"); // only track general locations rather than specific addresses or establishments
    const placeOnOverrideList = trackPlaceOverrides.includes(
      lodgingSelection.placeId
    ); // allow tracking locations that are not geocode

    return locationIsGeocode || placeOnOverrideList;
  } catch (e) {
    console.error(
      `Unable to determine whether or not to track lodging location: ${e}`
    );
    return false;
  }
};
