import * as React from "react";
import { DesktopPopupModal } from "halifax";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../MonthAndDatePicker";
import { ConnectedDesktopCalendarPicker } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";

export interface IDesktopCalendarPickerProps
  extends ConnectedDesktopCalendarPicker {
  open: boolean;
  closePopup: () => void;
  fromProp?: Date | null;
  untilProp?: Date | null;
  onClickDone?: () => void;
  saveDatesOnClose?: boolean;
  showPricing?: boolean;
}

export const DesktopCalendarPicker = (props: IDesktopCalendarPickerProps) => {
  const {
    open,
    closePopup,
    fromDate,
    untilDate,
    setOpenDatesModal,
    setFromDate,
    setUntilDate,
    fromProp,
    untilProp,
    onClickDone,
    saveDatesOnClose,
    showPricing,
  } = props;

  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  React.useEffect(() => {
    fromProp && setFrom(fromProp);
  }, [fromProp]);

  React.useEffect(() => {
    untilProp && setUntil(untilProp);
  }, [untilProp]);

  const handleClickDone = () => {
    closePopup();
    setOpenDatesModal(false);
    setFromDate(from);
    setUntilDate(until);
    onClickDone && onClickDone();
  };

  const handleClose = () => {
    closePopup();
    setOpenDatesModal(false);
    if (!saveDatesOnClose) {
      setFrom(fromDate);
      setUntil(untilDate);
    }
  };

  return (
    <>
      {open && (
        <DesktopPopupModal
          open={open}
          onClose={handleClose}
          className={clsx(
            "desktop-calendar-picker-popup-root",
            "hotels-module"
          )}
          contentClassName="desktop-calendar-picker-wrapper"
          invisibleBackdrop={false}
          headerElement={textConstants.DESKTOP_CALENDAR_TITLE}
        >
          <MonthAndDatePicker
            className="b2b"
            viewType={MonthAndDatePickerType.Horizontal}
            from={from}
            until={until}
            setFromDate={(val: Date | null) => {
              setFrom(val);
            }}
            setUntilDate={(val: Date | null) => {
              setUntil(val);
            }}
            header={textConstants.DESKTOP_CALENDAR_TITLE}
            showPricing={showPricing}
          />
          <Button
            onClick={handleClickDone}
            disabled={!from || !until}
            className="select-dates-button"
            variant="contained"
          >
            {textConstants.DONE_BUTTON_TEXT}
          </Button>
        </DesktopPopupModal>
      )}
    </>
  );
};
