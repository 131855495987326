import { fetchLocations } from "./fetchLocationAutocompleteSaga";
import { actionTypes } from "../actions";
import { takeLatest } from "redux-saga/effects";
import { fetchCalendarBucketsSaga } from "./fetchCalendarSaga";

export function* watchFetchLocationCategories() {
  yield takeLatest(actionTypes.FETCH_LOCATION_CATEGORIES, fetchLocations);
}

export function* watchFetchCalendarBucketsSaga() {
  yield takeLatest(
    actionTypes.FETCH_CALENDAR_BUCKETS,
    fetchCalendarBucketsSaga
  );
}
