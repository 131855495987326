import React, { useEffect } from "react";
import {
  BackButton,
  BackToTopButton,
  FloatingMenuPill,
  IconName,
  TravelSalesEventBanner,
  UserPreferencesAppliedBanner,
} from "halifax";
import { Box, useScrollTrigger } from "@material-ui/core";
import clsx from "clsx";

import { AvailabilityMap } from "../AvailabilityMap";
import { AvailabilityFilter } from "../AvailabilityFilter";
import { MobileLocationSelection } from "../MobileLocationSelection";
import { AvailabilityNoResults } from "../AvailabilityNoResults";
import { MobileAvailabilityMapRollingGallery } from "../MobileAvailabilityMapRollingGallery";
import { MobileHotelAvailabilityConnectorProps } from "./container";
import "./styles.scss";
import { AvailabilityList } from "../AvailabilityList";
import { RewardsAccountSelection } from "../../../rewards/components";
import { HotelAvailabilityCallState } from "../../reducer/state";
import { TravelWalletDrawer } from "../../../travel-wallet/components";
import {
  AVAILABLE,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_SALE,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
  CUSTOMER_PROFILE_EXPERIMENT,
  addTrackingProperties,
  CUSTOMER_PROFILE_VARIANTS,
  CONTROL,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
} from "../../../../context/experiments";
import { RouteComponentProps } from "react-router-dom";
import {
  TRAVEL_SALES_EVENT_ACTIVE_CTA,
  TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
} from "../../textConstants";
import { PATH_TRAVEL_SALE } from "../../../../utils/paths";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { config } from "../../../../api/config";
import { CLEAR_ALL_FILTERS } from "redmond";
import { MobileStayTypeInfoPopper } from "../MobileStayTypeInfoPopper";

export interface IMobileHotelAvailabilityProps
  extends MobileHotelAvailabilityConnectorProps,
    RouteComponentProps {
  setOpenPriceDropProtectionBannerModal: (arg: boolean) => void;
  onShowMap?: (open: boolean) => void;
}

export const MobileHotelAvailability = (
  props: IMobileHotelAvailabilityProps
) => {
  const {
    lodgings,
    lodgingIdInFocus,
    isFilteredHotelAvailabilityLodgingsEmpty,
    hotelAvailabilityCallState,
    history,
    setOpenPriceDropProtectionBannerModal,
    shouldApplyUserHotelPreferences,
    resetFilters,
    appliedFilterCount,
    onShowMap,
    availabilityResultsHasHomesAndHotels,
  } = props;

  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const initialCallInProgress =
    hotelAvailabilityCallState ===
    HotelAvailabilityCallState.InitialSearchCallInProcess;

  const [showMap, setShowMap] = React.useState(false);

  const stayTypeModalPopperSeen =
    localStorage.getItem("stayTypeFilterAwarenessPopperSeen") == null;
  const [openStayTypeModalPopper, setStayTypeModalPopper] =
    React.useState<boolean>(stayTypeModalPopperSeen);
  const closeStayTypeFilterAwarenessPopperHandler = () => {
    setStayTypeModalPopper(false);
    localStorage.setItem("stayTypeFilterAwarenessPopperSeen", "true");
  };

  const [filterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);

  useEffect(() => {
    onShowMap?.(showMap);
    if (showMap) {
      // removes medallia from the fixed bottom that was covering pricing (only on the enlarged map)
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "none";
      }
    }
    return () => {
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    };
  }, [showMap]);

  const renderMap = (isPreview: boolean, onClickPreview?: () => void) => {
    return <AvailabilityMap isPreview={isPreview} onClick={onClickPreview} />;
  };

  const renderFullHeightMap = () => (
    <Box className="mobile-lodging-availability-map-wrapper">
      <Box
        className={clsx("mobile-lodging-availability-map-container", {
          "full-height": lodgingIdInFocus === null,
        })}
      >
        {renderMap(false)}
        <Box className="map-back-button-container">
          <BackButton onClick={() => setShowMap(false)} />
        </Box>
        <Box className="map-filters-button-container">
          <AvailabilityFilter />
        </Box>
      </Box>
      <Box className="mobile-lodging-availability-map-bottom-items">
        <Box className="mobile-lodging-availability-map-rolling-gallery">
          <MobileAvailabilityMapRollingGallery />
        </Box>
      </Box>
    </Box>
  );

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const renderLocationSearch = () => {
    return (
      <Box
        className={clsx("mobile-location-search-contents", {
          "global-mobile-nav": isGlobalMobileNavExperiment,
          scrolled: scrollTrigger,
        })}
      >
        <MobileLocationSelection
          fullScreen
          filterModalOpen={filterModalOpen}
          setFilterModalOpen={setFilterModalOpen}
        />
      </Box>
    );
  };

  const renderRewardsAccount = () => {
    return (
      <Box
        className={clsx("mobile-rewards-account-contents", {
          "global-mobile-nav": isGlobalMobileNavExperiment,
        })}
      >
        <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
      </Box>
    );
  };

  const onClickPreview = () => {
    setShowMap(true);
    window.scrollTo(0, 0);
  };

  const renderHotelListView = () => (
    <Box className="mobile-lodging-availability-contents">
      {isCustomerProfileExperiment && shouldApplyUserHotelPreferences && (
        <UserPreferencesAppliedBanner
          resetFilters={() => {
            resetFilters();

            trackEvent({
              eventName: CLEAR_ALL_FILTERS,
              properties: addTrackingProperties(expState.trackingProperties),
            });
          }}
          type={"hotel"}
        />
      )}
      {travelSalesEventVariant === TRAVEL_SALE_ACTIVE && (
        <TravelSalesEventBanner
          variant="default"
          onClick={() => {
            const path = `${PATH_TRAVEL_SALE}?entryType=hotel_list_component`;
            history.push(path);
          }}
          subtitle={TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE}
          buttonText={TRAVEL_SALES_EVENT_ACTIVE_CTA}
        />
      )}
      {!!lodgings && lodgings.length > 0 && (
        <Box
          className={clsx(
            "mobile-availability-map-preview",
            "enhanced-hotel-filters"
          )}
        >
          {renderMap(true, onClickPreview)}
        </Box>
      )}
      {!isFilteredHotelAvailabilityLodgingsEmpty && !initialCallInProgress && (
        <Box className="mobile-availability-list-wrapper">
          <AvailabilityList
            setOpenPriceDropProtectionBannerModal={
              setOpenPriceDropProtectionBannerModal
            }
          />
        </Box>
      )}
      {isGlobalMobileNavExperiment && !initialCallInProgress && (
        <FloatingMenuPill
          items={[
            {
              label: "Map",
              onClick: () => setShowMap(true),
              icon: IconName.MapOutline,
            },
            {
              label: "Sort & filter",
              onClick: () => setFilterModalOpen(true),
              icon: IconName.Settings,
              badge: appliedFilterCount
                ? String(appliedFilterCount)
                : undefined,
            },
          ]}
        />
      )}
    </Box>
  );

  return (
    <>
      <Box className={clsx("mobile-lodging-availability-page", config.TENANT)}>
        {!showMap && (
          <>
            {renderLocationSearch()}
            {renderRewardsAccount()}
            {!isFilteredHotelAvailabilityLodgingsEmpty && renderHotelListView()}

            {isGlobalMobileNavExperiment ? <BackToTopButton /> : undefined}

            {!isGlobalMobileNavExperiment && isTravelWalletCreditsExperiment ? (
              <TravelWalletDrawer />
            ) : undefined}
          </>
        )}
        {showMap && renderFullHeightMap()}
        {isFilteredHotelAvailabilityLodgingsEmpty && (
          <Box className="mobile-availability-no-results-wrapper">
            <AvailabilityNoResults />
          </Box>
        )}
        {availabilityResultsHasHomesAndHotels && (
          <MobileStayTypeInfoPopper
            isOpen={openStayTypeModalPopper}
            handleClose={closeStayTypeFilterAwarenessPopperHandler}
          />
        )}
      </Box>
    </>
  );
};
