import React from "react";
import {
  GenericDetailsCard,
  GenericDetailsCardComponent,
  GenericDetailsCardComponentEnum,
  GenericDetailsCardTopSectionComponent,
  GenericDetailsCardTopSectionComponentEnum,
  PriceDropProtectionImage,
  MobilePriceDropProtectionImage,
  IconName,
  useDeviceTypes,
  getPriceString,
  getCurrencySymbol,
} from "halifax";
import { HOTEL_PRICE_DROP_PROTECTION_BODY, Amount } from "redmond";
import clsx from "clsx";
import { PATH_TERMS_HOTEL_PRICE_DROP } from "../../../../../../../utils/paths";
import * as constants from "./constants";
import "./styles.scss";

interface IPriceDropProtectionPopupProps {
  className?: string;
  openModal: boolean;
  onClose: () => void;
  amount: Amount | null;
}

export const PriceDropProtectionPopup = (
  props: IPriceDropProtectionPopupProps
) => {
  const { matchesMobile } = useDeviceTypes();
  const { className, openModal, onClose, amount } = props;

  if (amount != null) {
    const getTopContent = (): GenericDetailsCardTopSectionComponent => {
      return {
        image: matchesMobile
          ? PriceDropProtectionImage
          : MobilePriceDropProtectionImage,
        location: constants.LOCATION_TEXT,
        header: constants.PRICE_DROP_PROTECTION_POPUP_TITLE,
        icon: IconName.PriceDrop,
        component:
          GenericDetailsCardTopSectionComponentEnum.HeaderWithImageBackground,
      };
    };

    const getMainContent = (): GenericDetailsCardComponent[] => {
      const handleViewTerms = () => {
        window.open(PATH_TERMS_HOTEL_PRICE_DROP, "_blank")?.focus();
      };

      const mainContent: GenericDetailsCardComponent[] = [];

      mainContent.push({
        className: clsx("price-drop-protection-details-generic-copy", "bold"),
        type: "primary",
        copy: constants.HOW_IT_WORKS_COPY,
        component: GenericDetailsCardComponentEnum.GenericCopy,
      });
      mainContent.push({
        className: clsx("price-drop-protection-details-generic-copy", "fade"),
        type: "secondary",
        copy: constants.PRICE_DROP_PROTECTION_BODY({
          // note: the number of days doesn't currently come from the BE, so it's hardcoded
          days: 5,
          amountString: getPriceString({
            price: amount.amount,
            currencySymbol: getCurrencySymbol(amount.currency),
          }),
        }),
        component: GenericDetailsCardComponentEnum.GenericCopy,
      });

      if (matchesMobile) {
        mainContent.push({
          content: [
            {
              title: constants.READ_TERMS_AND_CONDITIONS_COPY,
              body: HOTEL_PRICE_DROP_PROTECTION_BODY,
            },
          ],
          component: GenericDetailsCardComponentEnum.AccordionGroup,
        });
      } else {
        mainContent.push({
          message: constants.READ_TERMS_AND_CONDITIONS_COPY,
          onClick: handleViewTerms,
          position: "left",
          component: GenericDetailsCardComponentEnum.ClickableLink,
        });
      }

      return mainContent;
    };

    return (
      <GenericDetailsCard
        popupClassName={clsx("price-drop-protection-popup-root", className)}
        topContent={getTopContent()}
        mainContent={getMainContent()}
        openModal={openModal}
        onClose={onClose}
        isMobile={matchesMobile}
        scrollOption="default-scroll"
      />
    );
  }

  return null;
};
