export const PRICE_DROP_PROTECTION_BANNER_TEXT = (amount: string) =>
  `If the price drops after you book, you’ll get up to ${amount} in travel credit with <strong>free price drop protection.</strong>`;

export const LEARN_MORE = "Learn More";

export const PRICE_DROP_PROTECTION_SMALL_BANNER_TEXT = `<strong>Free price drop protection</strong> included.`;

export const PREMIER_COLLECTION_NOT_ELIGIBLE =
  "Premier Collection hotels are not eligible.";

export const PREMIER_AND_LIFESTYLE_COLLECTION_NOT_ELIGIBLE =
  "Premier and Lifestyle Collection hotels are not eligible.";
