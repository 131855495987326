import { createSelector } from "@reduxjs/toolkit";
import {
  CallState,
  ResultEnum,
  RoomProduct,
  RoomInfoProducts,
  FiatPrice,
  CurrentVersusCapEnum,
  LodgingPriceFreezeRefundResponseEnum,
  LodgingPriceFreezeOfferResultEnum,
  LodgingPriceFreezeGetCreditsStatement,
  LodgingPriceFreezeGetCreditsPayments,
  PartialHotelCheckoutPriceFreezeExerciseProperties,
  getPartialHotelCheckoutPriceFreezeExerciseProperties,
  PartialCompleteBuyHotelPriceFreezeExerciseProperties,
  getPartialCompleteBuyHotelPriceFreezeExerciseProperties,
  HotelPriceFreezeRefundChoiceProperties,
  getHotelPriceFreezeRefundChoiceProperties,
  PartialPriceQuoteHotelPriceFreezeExerciseProperties,
  getPartialPriceQuoteHotelPriceFreezeExerciseProperties,
  HotelPriceFreezeRefundOutcomeProperties,
  getHotelPriceFreezeRefundOutcomeProperties,
  HotelCancellationPolicyV2Enum,
  HOTEL_PRICE_FREEZE_PAY_NOTE_PRICE_DROP_SAVINGS_THRESHOLD,
  CorpRoomProduct,
  CorpRoomInfoProducts,
} from "redmond";
import {
  HotelFrozenPriceNotification,
  CurrentPriceVersusCapEnum,
} from "halifax";
import dayjs from "dayjs";
import {
  HOTELS_PRICE_FREEZE,
  AVAILABLE,
  HOTELS_PRICE_FREEZE_ON_REFUNDABLE_ROOMS,
} from "../../../../context/experiments";
import { IStoreState } from "../../../../reducers/types";

export const fetchHotelPriceFreezeDetailsCallStateSelector = (
  state: IStoreState
) => state.hotelPriceFreeze.fetchHotelPriceFreezeDetailsCallState;

export const hotelPriceFreezeDetailsSelector = (state: IStoreState) =>
  state.hotelPriceFreeze.hotelPriceFreezeDetails;

export const getHotelPriceFreezeCreditsCallStateSelector = (
  state: IStoreState
) => state.hotelPriceFreeze.getHotelPriceFreezeCreditsCallState;

export const hotelPriceFreezeCreditsResultSelector = (state: IStoreState) =>
  state.hotelPriceFreeze.hotelPriceFreezeCreditsResult;

export const hotelPriceFreezeGetCreditsResponseSelector = createSelector(
  hotelPriceFreezeCreditsResultSelector,
  (hotelPriceFreezeCreditsResult) =>
    hotelPriceFreezeCreditsResult?.Result === ResultEnum.Ok
      ? hotelPriceFreezeCreditsResult.value
      : undefined
);

export const hotelPriceFreezeGetCreditsStatementSelector = createSelector(
  hotelPriceFreezeGetCreditsResponseSelector,
  (
    hotelPriceFreezeGetCreditsResponse
  ): LodgingPriceFreezeGetCreditsStatement | undefined =>
    hotelPriceFreezeGetCreditsResponse?.statement
);

export const hotelPriceFreezeGetCreditsPaymentsSelector = createSelector(
  hotelPriceFreezeGetCreditsResponseSelector,
  (
    hotelPriceFreezeGetCreditsResponse
  ): LodgingPriceFreezeGetCreditsPayments | undefined =>
    hotelPriceFreezeGetCreditsResponse?.payments
);

export const hotelPriceFreezeGetCreditsCurrentPricingListSelector =
  createSelector(
    hotelPriceFreezeGetCreditsResponseSelector,
    (hotelPriceFreezeGetCreditsResponse) =>
      hotelPriceFreezeGetCreditsResponse?.pricings
  );

export const priceFreezePurchaseEntrySelector = (state: IStoreState) =>
  state.hotelPriceFreeze.priceFreezePurchaseEntry;

export const priceFreezeExperimentsSelector = (state: IStoreState) =>
  state.hotelPriceFreeze.experiments;

export const hotelPriceFreezeVariantSelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => experiments?.[HOTELS_PRICE_FREEZE]
);

export const hotelPriceFreezeRefundableRoomsVariantSelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => experiments?.[HOTELS_PRICE_FREEZE_ON_REFUNDABLE_ROOMS]
);

export const isHotelPriceFreezeEnabledSelector = createSelector(
  hotelPriceFreezeVariantSelector,
  (hotelPriceFreezeVariant) => hotelPriceFreezeVariant === AVAILABLE
);

export const isHotelPriceFreezeRefundableRoomsEnabledSelector = createSelector(
  hotelPriceFreezeRefundableRoomsVariantSelector,
  (hotelPriceFreezeRefundableRoomVariant) =>
    hotelPriceFreezeRefundableRoomVariant === AVAILABLE
);

export const refundHotelPriceFreezeResultSelector = (state: IStoreState) =>
  state.hotelPriceFreeze.refundHotelPriceFreezeResult;

export const refundHotelPriceFreezeCallStateSelector = (state: IStoreState) =>
  state.hotelPriceFreeze.refundHotelPriceFreezeCallState;

export const hasSuccessfullyRefundedHotelPriceFreezeSelector = createSelector(
  refundHotelPriceFreezeResultSelector,
  refundHotelPriceFreezeCallStateSelector,
  (refundResult, refundCallState) =>
    refundCallState === CallState.Success &&
    refundResult?.Result === ResultEnum.Ok &&
    refundResult.value.RefundResponse ===
      LodgingPriceFreezeRefundResponseEnum.Success
);

export const hotelPriceFreezeLodgingResultSelector = createSelector(
  hotelPriceFreezeDetailsSelector,
  (hotelPriceFreezeDetails) => hotelPriceFreezeDetails?.lodging
);

export const hotelPriceFreezeLodgingSelector = createSelector(
  hotelPriceFreezeLodgingResultSelector,
  (hotelPriceFreezeLodgingResult) => {
    return hotelPriceFreezeLodgingResult?.Result == ResultEnum.Ok
      ? hotelPriceFreezeLodgingResult.value
      : null;
  }
);

export const hotelPriceFreezeLodgingErrSelector = createSelector(
  hotelPriceFreezeLodgingResultSelector,
  (hotelPriceFreezeLodgingResult) => {
    return hotelPriceFreezeLodgingResult?.Result == ResultEnum.Err
      ? hotelPriceFreezeLodgingResult.err
      : null;
  }
);

export const hotelPriceFreezeVoucherSelector = createSelector(
  hotelPriceFreezeDetailsSelector,
  (hotelPriceFreezeDetails) => hotelPriceFreezeDetails?.voucher
);

export const hotelPriceFreezeVoucherActionSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  (voucher) => voucher?.action
);

export const hotelPriceFreezeVoucherReservationSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  (voucher) => voucher?.reservation
);

export const hotelPriceFreezeVoucherStateSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  (voucher) => voucher?.state
);

export const nightCountFromPriceFreezeVoucherSelector = createSelector(
  hotelPriceFreezeVoucherReservationSelector,
  (voucherReservation) =>
    voucherReservation
      ? dayjs.duration(voucherReservation.duration).asDays()
      : null
);

export const hotelPriceFreezeFrozenPricingListSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  (voucher) => voucher?.pricings
);

export const hotelPriceFreezeTotalFrozenPricingSelector = createSelector(
  hotelPriceFreezeFrozenPricingListSelector,
  (hotelPriceFreezeFrozenPricingList) =>
    hotelPriceFreezeFrozenPricingList?.total
);

export const hotelPriceFreezeNightlyFrozenPricingSelector = createSelector(
  hotelPriceFreezeFrozenPricingListSelector,
  (hotelPriceFreezeFrozenPricingList) =>
    hotelPriceFreezeFrozenPricingList?.nightly
);

export const hotelPriceFreezeOffersSelector = (state: IStoreState) =>
  state.hotelPriceFreeze.hotelPriceFreezeOffers;

const getHotelShopRoomInfoProducts = (state: IStoreState) =>
  state.hotelShop.roomInfoProducts;
export const baseHotelPriceFreezeOfferSelector = createSelector(
  getHotelShopRoomInfoProducts,
  isHotelPriceFreezeEnabledSelector,
  hotelPriceFreezeOffersSelector,
  isHotelPriceFreezeRefundableRoomsEnabledSelector,
  (
    roomInfoProducts,
    isHotelPriceFreezeEnabled,
    hotelPriceFreezeOffers,
    isHotelPriceFreezeRefundableRoomsEnabled
  ) => {
    if (
      isHotelPriceFreezeEnabled &&
      hotelPriceFreezeOffers &&
      hotelPriceFreezeOffers.length
    ) {
      const firstOfferWithHasOffer =
        hotelPriceFreezeOffers.find(
          (offer) =>
            offer.LodgingPriceFreezeOfferResult ===
            LodgingPriceFreezeOfferResultEnum.HasOffer
        ) || null;
      const allRoomInfoProductRates = roomInfoProducts
        .map((roomInfoProduct) => {
          return roomInfoProduct.products;
        })
        .flat(2);
      let firstOfferWithNonRefundableRoom = null;
      for (let i = 0; i < allRoomInfoProductRates.length; i++) {
        const offer = hotelPriceFreezeOffers[i];
        const product = allRoomInfoProductRates[i];
        if (
          offer.LodgingPriceFreezeOfferResult ===
            LodgingPriceFreezeOfferResultEnum.HasOffer &&
          product.cancellationPolicyV2?.CancellationPolicyV2 ===
            HotelCancellationPolicyV2Enum.NonRefundable
        ) {
          firstOfferWithNonRefundableRoom = offer;
          break;
        }
      }
      return isHotelPriceFreezeRefundableRoomsEnabled
        ? firstOfferWithHasOffer?.offer
        : firstOfferWithNonRefundableRoom?.offer;
    }
    return null;
  }
);

export const hotelPriceFreezeOfferPropertiesSelector = createSelector(
  baseHotelPriceFreezeOfferSelector,
  (baseHotelPriceFreezeOffer) => {
    return {
      hotel_price_freeze_eligible: baseHotelPriceFreezeOffer ? true : false,
      // Calculate deposit percentage
      hotel_price_freeze_deposit_percent: baseHotelPriceFreezeOffer
        ? Math.round(
            (baseHotelPriceFreezeOffer?.pricing.depositPerNight.fiat.value /
              baseHotelPriceFreezeOffer?.pricing.frozenPricePerNight.fiat
                .value) *
              100
          )
        : 0,
    };
  }
);

export const hotelPriceFreezeRoomWithPricingResultSelector = createSelector(
  hotelPriceFreezeDetailsSelector,
  (hotelPriceFreezeDetails) => hotelPriceFreezeDetails?.roomWithPricing
);

export const hotelPriceFreezeRoomWithPricingSelector = createSelector(
  hotelPriceFreezeRoomWithPricingResultSelector,
  (hotelPriceFreezeRoomWithPricingResult) => {
    return hotelPriceFreezeRoomWithPricingResult?.Result == ResultEnum.Ok
      ? hotelPriceFreezeRoomWithPricingResult.value
      : undefined;
  }
);

export const hotelPriceFreezeRoomWithPricingErrSelector = createSelector(
  hotelPriceFreezeRoomWithPricingResultSelector,
  (hotelPriceFreezeRoomWithPricingResult) => {
    return hotelPriceFreezeRoomWithPricingResult?.Result == ResultEnum.Err
      ? hotelPriceFreezeRoomWithPricingResult.err
      : undefined;
  }
);

export const hotelPriceFreezeGetDetailsCurrentPricingListSelector =
  createSelector(
    hotelPriceFreezeRoomWithPricingSelector,
    (hotelPriceFreezeRoomWithPricing) =>
      hotelPriceFreezeRoomWithPricing?.pricings
  );

export const hotelPriceFreezeCurrentPricingListSelector = createSelector(
  hotelPriceFreezeGetCreditsCurrentPricingListSelector,
  hotelPriceFreezeGetDetailsCurrentPricingListSelector,
  (getCreditsCurrentPricingList, getDetailsCurrentPricingList) =>
    getCreditsCurrentPricingList ?? getDetailsCurrentPricingList
);

export const hotelPriceFreezeTotalCurrentPricingSelector = createSelector(
  hotelPriceFreezeCurrentPricingListSelector,
  (hotelPriceFreezeCurrentPricingList) =>
    hotelPriceFreezeCurrentPricingList?.total
);

export const hotelPriceFreezeNightlyCurrentPricingSelector = createSelector(
  hotelPriceFreezeCurrentPricingListSelector,
  (hotelPriceFreezeCurrentPricingList) =>
    hotelPriceFreezeCurrentPricingList?.nightly
);

export const hotelPriceFreezeRoomInfoSelector = createSelector(
  hotelPriceFreezeRoomWithPricingSelector,
  (hotelPriceFreezeRoomWithPricing) => hotelPriceFreezeRoomWithPricing?.roomInfo
);

export const hotelPriceFreezeRoomProductsSelector = createSelector(
  hotelPriceFreezeRoomWithPricingSelector,
  (hotelPriceFreezeRoomWithPricing) =>
    hotelPriceFreezeRoomWithPricing?.roomProducts
);

export const hotelPriceFreezeRoomProductFromCurrentShopSelector =
  createSelector(
    hotelPriceFreezeRoomProductsSelector,
    (hotelPriceFreezeRoomProducts) => hotelPriceFreezeRoomProducts?.standard
  );

export const hotelPriceFreezeRoomProductFromExerciseSelector = createSelector(
  hotelPriceFreezeRoomProductsSelector,
  hotelPriceFreezeGetDetailsCurrentPricingListSelector,
  (hotelPriceFreezeRoomProducts, getDetailCurrentPricing) => {
    if (hotelPriceFreezeRoomProducts?.exercised && getDetailCurrentPricing) {
      return {
        ...hotelPriceFreezeRoomProducts.exercised,
        sellRate: getDetailCurrentPricing.total.baseRateWithSavings,
        perNightSellRate: getDetailCurrentPricing.nightly.baseRateWithSavings,
      };
    }

    return undefined;
  }
);

/*
  note: the RoomProduct included in this selector represents the exercise price,
  which has its prices adjusted to reflect the Price Freeze savings
*/
export const hotelPriceFreezeRoomInfoProductsSelector = createSelector(
  hotelPriceFreezeRoomInfoSelector,
  hotelPriceFreezeRoomProductFromExerciseSelector,
  (
    roomInfo,
    roomProductFromExercise
  ): RoomInfoProducts | CorpRoomInfoProducts | undefined => {
    if (!roomInfo || !roomProductFromExercise) {
      return undefined;
    }

    return {
      roomInfo,
      products: [roomProductFromExercise],
    };
  }
);

export const hotelPriceFreezeChosenProductSelector = createSelector(
  hotelPriceFreezeRoomInfoProductsSelector,
  (hotelPriceFreezeRoomInfoProducts): RoomProduct | CorpRoomProduct | null =>
    // note: in the PF exercise flow, the frozen room product is the one that's being returned
    hotelPriceFreezeRoomInfoProducts?.products[0] ?? null
);

export const hotelFrozenPriceSummaryDetailsSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  hotelPriceFreezeVoucherActionSelector,
  hotelPriceFreezeVoucherReservationSelector,
  hotelPriceFreezeTotalFrozenPricingSelector,
  hotelPriceFreezeTotalCurrentPricingSelector,
  (
    voucher,
    voucherAction,
    voucherReservation,
    frozenPricing,
    currentPricing
  ):
    | {
        nights: number;
        expiresAt: string;
        frozenAmountFiat: FiatPrice;
        priceFreezeFiat: FiatPrice;
        frozenPriceSummaryNotification?: HotelFrozenPriceNotification;
      }
    | undefined => {
    if (
      !voucher ||
      !voucherReservation ||
      !frozenPricing ||
      !voucherAction ||
      !currentPricing
    ) {
      return undefined;
    }

    const nights = dayjs.duration(voucherReservation.duration).asDays();
    const frozenPriceSummaryNotification:
      | HotelFrozenPriceNotification
      | undefined = (() => {
      switch (currentPricing.versusCap.CurrentVersusCap) {
        // Scenario: New price is above the cap
        case CurrentVersusCapEnum.OverCap: {
          return {
            currentPriceFiat: currentPricing.current.fiat,
            capCoverageFiat: frozenPricing.cap.fiat,
            exercisePriceFiat: currentPricing.baseRateWithSavings.fiat,
            type: CurrentPriceVersusCapEnum.NewPriceOverCap,
            priceFreezeSavingsFiat: currentPricing.savings.fiat,
          };
        }
        case CurrentVersusCapEnum.UnderCap: {
          // Scenario: Current price is lower than frozen price
          // It also follows this scenario when frozen price == current price
          if (
            currentPricing.current.fiat.value < frozenPricing.frozen.fiat.value
          ) {
            const savingsAmount =
              frozenPricing.frozen.fiat.value -
              currentPricing.current.fiat.value;

            if (
              savingsAmount <
              HOTEL_PRICE_FREEZE_PAY_NOTE_PRICE_DROP_SAVINGS_THRESHOLD
            ) {
              return undefined;
            }

            return {
              currentPriceFiat: currentPricing.current.fiat,
              savingsAmountFiat: {
                ...currentPricing.savings.fiat,
                value: savingsAmount,
              },
              type: CurrentPriceVersusCapEnum.NewPriceLowerThanFrozenPrice,
            };
          }
          // Scenario: Frozen price is lower than current price, but the difference is still within the price cap
          else if (
            frozenPricing.frozen.fiat.value < currentPricing.current.fiat.value
          ) {
            if (
              currentPricing.savings.fiat.value <
              HOTEL_PRICE_FREEZE_PAY_NOTE_PRICE_DROP_SAVINGS_THRESHOLD
            ) {
              return undefined;
            }

            return {
              currentPriceFiat: currentPricing.current.fiat,
              savingsAmountFiat: currentPricing.savings.fiat,
              type: CurrentPriceVersusCapEnum.FrozenPriceLowerThanNewPriceWithinCap,
              priceFreezeSavingsFiat: currentPricing.savings.fiat,
            };
          } else {
            return undefined;
          }
        }
      }
    })();

    return {
      nights,
      expiresAt: voucherAction.expiration,
      frozenAmountFiat: frozenPricing.frozen.fiat,
      priceFreezeFiat: voucher.deposit.fiat,
      frozenPriceSummaryNotification,
    };
  }
);

export const getHotelCheckoutPriceFreezeExercisePropertiesSelector =
  createSelector(
    hotelPriceFreezeGetCreditsStatementSelector,
    hotelPriceFreezeVoucherSelector,
    hotelPriceFreezeCurrentPricingListSelector,
    (
      statement,
      voucher,
      currentPricingList
    ): ((
      isPriceFreezeExerciseFlow: boolean
    ) => PartialHotelCheckoutPriceFreezeExerciseProperties) => {
      return (isPriceFreezeExerciseFlow: boolean) =>
        getPartialHotelCheckoutPriceFreezeExerciseProperties({
          statement,
          voucher,
          pricingsFromGetDetail: currentPricingList,
          isPriceFreezeExerciseFlow,
        });
    }
  );

export const getCompleteBuyHotelPriceFreezeExercisePropertiesSelector =
  createSelector(
    hotelPriceFreezeGetCreditsStatementSelector,
    hotelPriceFreezeVoucherSelector,
    hotelPriceFreezeCurrentPricingListSelector,
    (
      statement,
      voucher,
      currentPricingList
    ): ((
      isPriceFreezeExerciseFlow: boolean
    ) => PartialCompleteBuyHotelPriceFreezeExerciseProperties) => {
      return (isPriceFreezeExerciseFlow: boolean) =>
        getPartialCompleteBuyHotelPriceFreezeExerciseProperties({
          statement,
          voucher,
          pricingsFromGetDetail: currentPricingList,
          isPriceFreezeExerciseFlow,
        });
    }
  );

export const getPriceQuoteHotelPriceFreezeExercisePropertiesSelector =
  createSelector(
    hotelPriceFreezeVoucherSelector,
    (
      voucher
    ): ((
      isPriceFreezeExerciseFlow: boolean
    ) => PartialPriceQuoteHotelPriceFreezeExerciseProperties) => {
      return (isPriceFreezeExerciseFlow: boolean) =>
        getPartialPriceQuoteHotelPriceFreezeExerciseProperties({
          voucher,
          isPriceFreezeExerciseFlow,
        });
    }
  );

export const hotelPriceFreezeRefundChoicePropertiesSelector = createSelector(
  hotelPriceFreezeGetCreditsStatementSelector,
  hotelPriceFreezeVoucherSelector,
  hotelPriceFreezeCurrentPricingListSelector,
  refundHotelPriceFreezeResultSelector,
  (
    statement,
    voucher,
    currentPricingList,
    refundResult
  ): HotelPriceFreezeRefundChoiceProperties => {
    return getHotelPriceFreezeRefundChoiceProperties({
      statement,
      voucher,
      pricingsFromGetDetail: currentPricingList,
      refundResult: refundResult ?? undefined,
    });
  }
);

export const hotelPriceFreezeRefundOutcomePropertiesSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  refundHotelPriceFreezeResultSelector,
  (voucher, refundResult): HotelPriceFreezeRefundOutcomeProperties => {
    return getHotelPriceFreezeRefundOutcomeProperties({
      voucher,
      refundResult: refundResult ?? undefined,
    });
  }
);

export const hotelAvailabilityTrackingSelector = (state: IStoreState) =>
  state.hotelPriceFreeze.availabilityTrackingProperties;
