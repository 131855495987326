import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import { HotelFreezePriceBreakdown } from "./component";
import {
  getHotelPriceFreezePricingLineItems,
  getHotelRewardsAndTotalLineItems,
} from "../../../../../book/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    pricingLineItems: getHotelPriceFreezePricingLineItems(state),
    rewardsAndTotalLineItems: getHotelRewardsAndTotalLineItems(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelFreezePriceBreakdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelFreezePriceBreakdown = withRouter(
  connector(HotelFreezePriceBreakdown)
);
