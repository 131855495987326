import { connect } from "react-redux";
import { IResult } from "redmond";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getLocation,
  getLocationCategories,
  getLocationCategoriesLoading,
} from "../../../../reducer";
import { actions } from "../../../../actions";
import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";
import {
  getMapBound,
  getViewHotelsNearLocation,
} from "../../../../../availability/reducer";

export const mapDispatchToProps = {
  setValue: actions.setLocation,
  fetchValueCategories: actions.fetchLocationCategories,
};

export const mapStateToProps = (state: IStoreState) => {
  let loading = !!getLocationCategoriesLoading(state);
  let isMapArea = !!getViewHotelsNearLocation(state) || !!getMapBound(state);

  return {
    overrideInputValue:
      isMapArea && !getLocation(state) ? "Map area" : undefined,
    value: getLocation(state),
    valueCategories: getLocationCategories(state),
    loading,
  };
};

export const ConnectedLocationAutocomplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<IResult | null>
  ) => JSX.Element
);
