import { combineReducers } from "@reduxjs/toolkit";
import { reducer as hotelSearch } from "../modules/search/reducer";
import { reducer as hotelAvailability } from "../modules/availability/reducer";
import { reducer as hotelShop } from "../modules/shop/reducer";
import { reducer as hotelBook } from "../modules/book/reducer";
import { reducer as hotelRewards } from "../modules/rewards/reducer";
import { reducer as hotelTravelWallet } from "../modules/travel-wallet/reducer";
import { reducer as hotelAncillary } from "../modules/ancillary/reducer";
import { reducer as hotelPriceFreeze } from "../modules/freeze/reducer";

export const rootReducer = combineReducers({
  hotelSearch,
  hotelAvailability,
  hotelShop,
  hotelBook,
  hotelRewards,
  hotelTravelWallet,
  hotelAncillary,
  hotelPriceFreeze,
});
