import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  setContactInfo,
  schedulePriceQuote,
  scheduleBook,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setPriceQuote,
} from "../../../actions/actions";

import { IStoreState } from "../../../../../reducers/types";
import { DesktopHotelBookWorkflow } from "./component";
import {
  getBookingProgress,
  getIsBookingValid,
  getPollPriceQuoteCallState,
  getPriceQuote,
  getSelectedPaymentMethodId,
  getSession,
  getUserSelectedTravelerId,
  getConfirmationDetails,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  areAllStepsCompletedInCheckout,
  getPriceDifferenceAcknowledged,
  isTravelerStepComplete,
  isContactStepComplete,
  getCombinedBookingSteps,
  getPriceQuoteErrors,
  getConfirmationDetailsError,
  getIsTravelWalletPaymentOnly,
  getHotelBookChosenProduct,
  getHotelBookChosenProductIndex,
  getHotelBookChosenRoomInfo,
  getHotelBookSelectedAvailability,
  getRoomsCountInBook,
  hotelBookTypeSelector,
  isReadyToDisplayHotelSummaryDetailsSelector,
  getFromDateInBook,
  getUntilDateInBook,
} from "../../../reducer";
import {
  getHotelShopCancellationSummary,
  getHotelShopChosenPriceFreezeOffer,
  hotelCfarQuoteFromChosenRoomProductSelector,
} from "../../../../shop/reducer";
import {
  getAllAncillaries,
  hasCfarAttached,
  isCustomizePageEnabledSelector,
  isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector,
  userPriceDropAmountSelector,
} from "../../../../ancillary/reducer";
import { fetchHotelAvailabilityTracking } from "../../../../freeze/actions/actions";
import { Lodging, PriceQuote, Reservation, RoomProduct } from "redmond";

export const mapStateToProps = (state: IStoreState) => {
  return {
    priceQuote: getPriceQuote(state) as PriceQuote,
    priceQuoteCallState: getPollPriceQuoteCallState(state),
    currentSession: getSession(state),
    paymentMethodId: getSelectedPaymentMethodId(state),
    selectedTravelerId: getUserSelectedTravelerId(state),
    chosenProduct: getHotelBookChosenProduct(state) as RoomProduct,
    selectedLodging: getHotelBookSelectedAvailability(state) as Lodging,
    roomInfoProduct: getHotelBookChosenRoomInfo(state),
    chosenProductIndex: getHotelBookChosenProductIndex(state),
    checkoutProgress: getBookingProgress(state),
    combinedBookingSteps: getCombinedBookingSteps(state),
    isBookingValid: getIsBookingValid(state),
    reservation: getConfirmationDetails(state) as Reservation,
    email: getConfirmationEmail(state),
    phoneNumber: getConfirmationPhoneNumber(state),
    isTravelerStepComplete: isTravelerStepComplete(state),
    isContactStepComplete: isContactStepComplete(state),
    allStepsCompleted: areAllStepsCompletedInCheckout(state),
    priceDifferenceAcknowledged: getPriceDifferenceAcknowledged(state),
    priceQuoteErrors: getPriceQuoteErrors(state),
    confirmationDetailsErrors: getConfirmationDetailsError(state),
    roomsCount: getRoomsCountInBook(state),
    isTravelWalletPaymentOnly: getIsTravelWalletPaymentOnly(state),
    isCustomizePageEnabled: isCustomizePageEnabledSelector(state),
    ancillaries: getAllAncillaries(state),
    hasCfarAttached: hasCfarAttached(state),
    hotelCfarQuote: hotelCfarQuoteFromChosenRoomProductSelector(state),
    hotelBookType: hotelBookTypeSelector(state),
    isReadyToDisplayHotelSummaryDetails:
      isReadyToDisplayHotelSummaryDetailsSelector(state),
    priceFreezeOffer: getHotelShopChosenPriceFreezeOffer(state),
    isHotelCfarRefundDisplayShowAmountAdditionalPlaces:
      isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector(state),
    checkIn: getFromDateInBook(state),
    checkOut: getUntilDateInBook(state),
    userPriceDropAmount: userPriceDropAmountSelector(state),
    cancellationSummary: getHotelShopCancellationSummary(state),
  };
};

export const mapDispatchToProps = {
  setContactInfo,
  schedulePriceQuote,
  scheduleBook,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setPriceQuote,
  fetchHotelAvailabilityTracking,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopHotelBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopHotelBookWorkflow = withRouter(
  connector(DesktopHotelBookWorkflow)
);
