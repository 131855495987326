import { connect, ConnectedProps } from "react-redux";

import { HotelsSearch } from "./component";
import { IStoreState } from "../../reducers/types";
import { fetchRewardsAccounts } from "../rewards/actions/actions";
import {
  getFetchRewardsAccountsCallState,
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../rewards/reducer";
import { withRouter } from "react-router";
import {
  fetchUserHotelPreferences,
  listPaymentMethods,
  resetHotelAvailabilityCallState,
} from "../availability/actions/actions";
import {
  getHasUserSetHotelPreferences,
  getUserHotelPreferencesCallState,
} from "../availability/reducer";
import { getApplyUserHotelPreferences } from "./reducer";
import { setApplyUserHotelPreferences } from "./actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  rewardsAccounts: getRewardsAccounts(state),
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  fetchRewardsAccountsCallState: getFetchRewardsAccountsCallState(state),
  userHasSetHotelPreferences: getHasUserSetHotelPreferences(state),
  userHotelPreferencesCallState: getUserHotelPreferencesCallState(state),
  shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
});

const mapDispatchToProps = {
  fetchRewardsAccounts,
  fetchUserHotelPreferences,
  setApplyUserHotelPreferences,
  resetHotelAvailabilityCallState,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelsSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelsSearch = withRouter(connector(HotelsSearch));
