export const SET_ANCILLARY_EXPERIMENTS =
  "hotelAncillary/SET_ANCILLARY_EXPERIMENTS";
export type SET_ANCILLARY_EXPERIMENTS = typeof SET_ANCILLARY_EXPERIMENTS;

export const FETCH_CFAR_QUOTES = "hotelAncillary/FETCH_CFAR_QUOTES";
export type FETCH_CFAR_QUOTES = typeof FETCH_CFAR_QUOTES;

export const SET_CFAR_QUOTES = "hotelAncillary/SET_CFAR_QUOTES";
export type SET_CFAR_QUOTES = typeof SET_CFAR_QUOTES;

export const SET_FETCH_CFAR_QUOTES_CALL_STATE_FAILED =
  "hotelAncillary/SET_FETCH_CFAR_QUOTES_CALL_STATE_FAILED";
export type SET_FETCH_CFAR_QUOTES_CALL_STATE_FAILED =
  typeof SET_FETCH_CFAR_QUOTES_CALL_STATE_FAILED;

export const SET_SELECTED_CFAR_ID = "hotelAncillary/SET_SELECTED_CFAR_ID";
export type SET_SELECTED_CFAR_ID = typeof SET_SELECTED_CFAR_ID;

export const SET_HAS_SELECTED_REFUNDABLE_ROOM =
  "flightShop/SET_HAS_SELECTED_REFUNDABLE_ROOM";
export type SET_HAS_SELECTED_REFUNDABLE_ROOM =
  typeof SET_HAS_SELECTED_REFUNDABLE_ROOM;

export const FETCH_PRICE_DROP_ELIGIBILITY =
  "hotelAncillary/FETCH_PRICE_DROP_ELIGIBILITY";
export type FETCH_PRICE_DROP_ELIGIBILITY = typeof FETCH_PRICE_DROP_ELIGIBILITY;

export const SET_PRICE_DROP_ELIGIBILITY =
  "hotelAncillary/SET_PRICE_DROP_ELIGIBILITY";
export type SET_PRICE_DROP_ELIGIBILITY = typeof SET_PRICE_DROP_ELIGIBILITY;

export const SET_FETCH_PRICE_DROP_ELIGIBILITY_CALL_STATE_FAILED =
  "hotelAncillary/SET_FETCH_PRICE_DROP_ELIGIBILITY_CALL_STATE_FAILED";
export type SET_FETCH_PRICE_DROP_ELIGIBILITY_CALL_STATE_FAILED =
  typeof SET_FETCH_PRICE_DROP_ELIGIBILITY_CALL_STATE_FAILED;

export const SET_HAS_ADDED_CFAR_FROM_MODAL =
  "hotelAncillary/SET_HAS_ADDED_CFAR_FROM_MODAL";
export type SET_HAS_ADDED_CFAR_FROM_MODAL =
  typeof SET_HAS_ADDED_CFAR_FROM_MODAL;
