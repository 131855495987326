import {
  QuoteId,
  GetCfarQuotesRequest,
  GetCfarQuotesSuccess,
  Amount,
} from "redmond";
import { IHotelAncillaryExperiments } from "../reducer";
import * as actionTypes from "./constants";

export interface ISetAncillaryExperiments {
  type: actionTypes.SET_ANCILLARY_EXPERIMENTS;
  experiments: IHotelAncillaryExperiments;
}

export const setAncillaryExperiments = (
  experiments: IHotelAncillaryExperiments
): ISetAncillaryExperiments => ({
  type: actionTypes.SET_ANCILLARY_EXPERIMENTS,
  experiments,
});

export interface IFetchCfarQuotes {
  type: actionTypes.FETCH_CFAR_QUOTES;
  request: GetCfarQuotesRequest;
}

export const fetchCfarQuotes = (
  request: GetCfarQuotesRequest
): IFetchCfarQuotes => ({
  type: actionTypes.FETCH_CFAR_QUOTES,
  request: request,
});

export interface ISetCfarQuotes {
  type: actionTypes.SET_CFAR_QUOTES;
  response: GetCfarQuotesSuccess;
}

export const setCfarQuotes = (args: {
  response: GetCfarQuotesSuccess;
}): ISetCfarQuotes => ({
  type: actionTypes.SET_CFAR_QUOTES,
  ...args,
});

export interface ISetFetchCfarQuotesCallStateFailed {
  type: actionTypes.SET_FETCH_CFAR_QUOTES_CALL_STATE_FAILED;
}

export const setFetchCfarOffersCallStateFailed =
  (): ISetFetchCfarQuotesCallStateFailed => ({
    type: actionTypes.SET_FETCH_CFAR_QUOTES_CALL_STATE_FAILED,
  });

export interface ISetSelectedCfarId {
  type: actionTypes.SET_SELECTED_CFAR_ID;
  cfarId: QuoteId | null;
}

export const setSelectedCfarId = (
  cfarId: QuoteId | null
): ISetSelectedCfarId => ({
  type: actionTypes.SET_SELECTED_CFAR_ID,
  cfarId,
});

export interface ISetHasSelectedRefundableRoom {
  type: actionTypes.SET_HAS_SELECTED_REFUNDABLE_ROOM;
  hasSelectedRefundableRoom: boolean;
}

export const setHasSelectedRefundableRoom = (
  hasSelectedRefundableRoom: boolean
): ISetHasSelectedRefundableRoom => ({
  type: actionTypes.SET_HAS_SELECTED_REFUNDABLE_ROOM,
  hasSelectedRefundableRoom,
});

export interface IFetchPriceDropEligibility {
  type: actionTypes.FETCH_PRICE_DROP_ELIGIBILITY;
}

export const fetchPriceDropEligibility = (): IFetchPriceDropEligibility => ({
  type: actionTypes.FETCH_PRICE_DROP_ELIGIBILITY,
});

export interface ISetPriceDropEligibility {
  type: actionTypes.SET_PRICE_DROP_ELIGIBILITY;
  eligible: boolean;
  amount: Amount;
}

export const setPriceDropEligibility = (args: {
  eligible: boolean;
  amount: Amount;
}): ISetPriceDropEligibility => ({
  ...args,
  type: actionTypes.SET_PRICE_DROP_ELIGIBILITY,
});

export interface ISetFetchPriceDropEligibilityCallStateFailed {
  type: actionTypes.SET_FETCH_PRICE_DROP_ELIGIBILITY_CALL_STATE_FAILED;
}

export const setFetchPriceDropEligibilityCallStateFailed =
  (): ISetFetchPriceDropEligibilityCallStateFailed => ({
    type: actionTypes.SET_FETCH_PRICE_DROP_ELIGIBILITY_CALL_STATE_FAILED,
  });

export interface ISetHasAddedCfarFromModal {
  type: actionTypes.SET_HAS_ADDED_CFAR_FROM_MODAL;
  hasAddedCfarFromModal: boolean;
}

export const setHasAddedCfarFromModal = (hasAddedCfarFromModal: boolean) => ({
  type: actionTypes.SET_HAS_ADDED_CFAR_FROM_MODAL,
  hasAddedCfarFromModal,
});

export type HotelAncillaryActions =
  | ISetAncillaryExperiments
  | IFetchCfarQuotes
  | ISetCfarQuotes
  | ISetFetchCfarQuotesCallStateFailed
  | ISetSelectedCfarId
  | ISetHasSelectedRefundableRoom
  | IFetchPriceDropEligibility
  | ISetPriceDropEligibility
  | ISetFetchPriceDropEligibilityCallStateFailed
  | ISetHasAddedCfarFromModal;
