import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilityMap } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getHotelAvailabilitySearchLocation,
  getHotelAvailabilityLodgingIdInFocus,
  getMapBound as getSearchedMapBound,
  getSearchedNightCount,
  getHotelAvailabilityLodgingIdHovered,
  getFilteredHotelAvailabilityLodgings,
  getViewHotelsNearLocationCategories,
  getViewHotelsNearLocation,
  getViewHotelsNearAvailabilityProperties,
  getMapBound,
} from "../../reducer/selectors";
import { actions } from "../../actions";

const mapStateToProps = (state: IStoreState) => {
  const visibleLodgings = getFilteredHotelAvailabilityLodgings(state);
  const searchLocation = getHotelAvailabilitySearchLocation(state);
  const lodgingIdInFocus = getHotelAvailabilityLodgingIdInFocus(state);
  const lodgingIdHovered = getHotelAvailabilityLodgingIdHovered(state);
  const searchedMapBound = getSearchedMapBound(state);
  const nightCount = getSearchedNightCount(state);
  const viewHotelsNearLocationCategories = getViewHotelsNearLocationCategories(state);
  const viewHotelsNearLocation = getViewHotelsNearLocation(state);
  const viewHotelsNearAvailabilityProperties = getViewHotelsNearAvailabilityProperties(state);
  const mapBound = getMapBound(state);

  return {
    lodgings: visibleLodgings,
    searchLocation,
    lodgingIdInFocus,
    lodgingIdHovered,
    searchedMapBound,
    nightCount,
    viewHotelsNearLocationCategories,
    viewHotelsNearLocation,
    viewHotelsNearAvailabilityProperties,
    mapBound
  };
};

const mapDispatchToProps = {
  setLodgingIdInFocus: actions.setLodgingIdInFocus,
  setSearchedMapBound: actions.setMapBound,
  fetchInitialHotelAvailability: actions.fetchInitialHotelAvailability,
  fetchViewHotelsNearLocationCategories: actions.fetchViewHotelsNearLocationCategories,
  setViewHotelsNearLocation: actions.setViewHotelsNearLocation,
  
  // resetFilters: resetFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityMapConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityMap = connector(withRouter(AvailabilityMap));
