import { all } from "redux-saga/effects";
import { sagas as hotelSearch } from "../modules/search/";
import { sagas as hotelAvailability } from "../modules/availability";
import { sagas as hotelShop } from "../modules/shop";
import { sagas as hotelBook } from "../modules/book";
import { sagas as hotelRewards } from "../modules/rewards";
import { sagas as hotelTravelWallet } from "../modules/travel-wallet";
import { sagas as hotelAncillary } from "../modules/ancillary";
import { sagas as hotelPriceFreeze } from "../modules/freeze";

export function* rootSaga() {
  yield all([
    hotelSearch.watchFetchLocationCategories(),
    hotelSearch.watchFetchCalendarBucketsSaga(),

    hotelAvailability.watchFetchHotelAvailability(),
    hotelAvailability.watchFetchViewHotelsNearLocations(),
    hotelAvailability.watchFetchUserHotelPreferences(),
    hotelAvailability.watchListHotelAvailabilityPaymentMethods(),

    hotelShop.watchFetchHotelShopSaga(),
    hotelShop.watchFetchDistanceFromLocationCategories(),
    hotelShop.watchFetchNearbyLodgingsSaga(),

    hotelBook.watchFetchUserPassengers(),
    hotelBook.watchDeletePassengerSaga(),
    hotelBook.watchUpdateUserPassenger(),
    hotelBook.watchFetchCorpUserPassengers(),
    hotelBook.watchUpdateUserPassengers(),

    hotelBook.watchFetchPaymentMethodSaga(),
    hotelBook.watchDeletePaymentMethodSaga(),
    hotelBook.watchListPaymentMethodsSaga(),
    hotelBook.watchVerifyPaymentMethodSaga(),

    hotelBook.watchProductToEarnSaga(),
    hotelBook.watchFetchAllEarnForProductSaga(),
    hotelBook.watchSubmitForApprovalSaga(),

    hotelBook.watchSchedulePriceQuoteSaga(),
    hotelBook.watchPollPriceQuoteSaga(),
    hotelBook.watchscheduleBookSaga(),
    hotelBook.watchPollConfirmationDetailsSaga(),
    hotelBook.watchFetchApplicableTravelWalletItemsSaga(),
    hotelBook.watchCloseSessionSaga(),

    hotelRewards.watchFetchRewardsAccounts(),

    hotelTravelWallet.watchFetchTravelWalletDetails(),
    hotelTravelWallet.watchFetchTravelWalletCreditHistorySaga(),

    hotelAncillary.watchFetchCfarOffers(),
    hotelAncillary.watchFetchPriceDropEligibility(),

    hotelPriceFreeze.watchFetchHotelPriceFreezeDetailsSaga(),
    hotelPriceFreeze.watchFetchHotelPriceFreezeOffersSaga(),
    hotelPriceFreeze.watchRefundHotelPriceFreezeSaga(),
    hotelPriceFreeze.watchGetHotelPriceFreezeCreditsSaga(),
    hotelPriceFreeze.watchFetchHotelAvailabilityTrackingSaga(),
  ]);
}
