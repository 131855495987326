export interface ExperimentDef<
  Name extends string,
  Variants extends readonly [string, ...string[]]
> {
  name: Name;
  variants: Variants;
}

export type AnyExperimentDef = ExperimentDef<
  string,
  readonly [string, ...string[]]
>;

export type ExperimentVariants<Def extends AnyExperimentDef> =
  Def["variants"][number];

export type ExperimentVariantMap<Def extends AnyExperimentDef, Value> = {
  [k in Def["variants"][number]]: Value;
};

export function experimentDef<
  Name extends string,
  Variants extends readonly [string, ...string[]]
>(name: Name, ...variants: Variants): ExperimentDef<Name, Variants> {
  return { name, variants };
}

export function mapExperimentVariants<Def extends AnyExperimentDef, T>(
  def: Def,
  mapfn: (variant: Def["variants"][number]) => T
): ExperimentVariantMap<Def, T> {
  return Object.fromEntries(
    def.variants.map((v) => [v, mapfn(v)] as const)
  ) as ExperimentVariantMap<Def, T>;
}
