import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  Header,
  BackButton,
  MobileFloatingButton,
  MobilePopoverCard,
  isValidEmail,
  RoomDetails,
} from "halifax";
import clsx from "clsx";

import { MobileHotelFreezeWorkflowConnectorProps } from "./container";
import * as t from "./textConstants";
import "./styles.scss";
import { PaymentCard } from "../../../../../book/components";
import { HotelFreezeContactInfoForm } from "../HotelFreezeContactInfoForm";
import { HotelFreezePriceBreakdown } from "../HotelFreezePriceBreakdown";
import { HotelFreezeReviewItinerary } from "../HotelFreezeReviewItinerary";
import { HotelFreezePriceBreakdownDropdown } from "../HotelFreezePriceBreakdownDropdown";
import { HotelFreezeSummary } from "../HotelFreezeSummary";

enum MobileHotelFreezeWorkflowStep {
  Start,
  RewardsAndPayment,
  Review,
}

export interface IMobileHotelFreezeWorkflowProps
  extends RouteComponentProps,
    MobileHotelFreezeWorkflowConnectorProps {}

export const MobileHotelFreezeWorkflow = ({
  isBookingValid,
  history,
  confirmationEmail,
  roomInfoProduct,
  chosenProductIndex,
  selectedLodging,
  roomsCount,
  rewardsPaymentAccountReferenceId,
  scheduleBook,
}: IMobileHotelFreezeWorkflowProps) => {
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const isConfirmationEmailValid = isValidEmail(confirmationEmail);
  const [isDisableSave, setIsDisableSave] = useState<boolean>(
    !isConfirmationEmailValid
  );

  const [purchaseStep, setPurchaseStep] =
    useState<MobileHotelFreezeWorkflowStep>(
      MobileHotelFreezeWorkflowStep.Start
    );

  const openPaymentCardSection = () => {
    setOpenPaymentCard(true);
  };

  const handleCloseModal = () => {
    setOpenPaymentCard(false);
  };

  const handlePurchase = () => {
    scheduleBook({
      agentFee: undefined,
    });
  };

  const incrementPurchaseStep = () => {
    setPurchaseStep((step) => {
      switch (step) {
        case MobileHotelFreezeWorkflowStep.Review:
          handlePurchase();
          return step;
        default:
          return step + 1;
      }
    });
  };

  const handleGoBack = () => {
    switch (purchaseStep) {
      case MobileHotelFreezeWorkflowStep.Start:
        history.goBack();
        break;
      default:
        setPurchaseStep((step) => step - 1);
        break;
    }
  };

  const getFloatingButtonText = () => {
    switch (purchaseStep) {
      case MobileHotelFreezeWorkflowStep.Review:
        return t.HOTEL_FREEZE_COMPLETE_BUTTON_TEXT;
      default:
        return t.HOTEL_FREEZE_CONTINUE_BUTTON_TEXT;
    }
  };

  const isFloatingButtonDisabled = () => {
    switch (purchaseStep) {
      case MobileHotelFreezeWorkflowStep.Review:
        return !isConfirmationEmailValid;
      default:
        return false;
    }
  };

  const closeAllPopovers = () => {
    handleCloseModal();
  };

  useEffect(() => {
    switch (purchaseStep) {
      case MobileHotelFreezeWorkflowStep.RewardsAndPayment:
        openPaymentCardSection();
        break;
      default:
        closeAllPopovers();
        break;
    }
  }, [purchaseStep]);

  // note: when the user is at Review step, but rewardsPaymentAccountReferenceId is null / undefined,
  // let the user go to the previous step
  useEffect(() => {
    if (
      purchaseStep === MobileHotelFreezeWorkflowStep.Review &&
      !rewardsPaymentAccountReferenceId
    ) {
      handleGoBack();
    }
  }, [purchaseStep, rewardsPaymentAccountReferenceId]);

  return (
    <>
      <Box className="mobile-hotel-freeze-workflow-root">
        <Header
          className="mobile-hotel-freeze-workflow-header"
          left={
            <BackButton
              className="mobile-hotel-freeze-header-go-back"
              onClick={handleGoBack}
            />
          }
          center={
            purchaseStep === MobileHotelFreezeWorkflowStep.Start ? (
              <Typography variant="body1" className="freeze-price-header">
                {t.HOTEL_FREEZE_HEADER}
              </Typography>
            ) : undefined
          }
          right={
            purchaseStep === MobileHotelFreezeWorkflowStep.Review ? (
              <HotelFreezePriceBreakdownDropdown />
            ) : undefined
          }
          isMobile={true}
          fullWidth={true}
        />
        <HotelFreezeSummary
          isMobile
          middleSection={
            <Box className="mobile-contact-info-form-container">
              <HotelFreezeContactInfoForm
                title={t.CONTACT_INFORMATION}
                isDisableSave={isDisableSave}
                setIsDisableSave={setIsDisableSave}
              />
            </Box>
          }
        ></HotelFreezeSummary>
        <Box
          className={clsx(
            "mobile-hotel-freeze-content-section",
            "review-itinerary"
          )}
        >
          <HotelFreezeReviewItinerary
            title={t.REVIEW_ITINERARY_TITLE}
            isMobile={true}
          />
          {!!roomInfoProduct && chosenProductIndex !== null && (
            <Box
              className={clsx("hotel-shop-room-details-container", "mobile")}
            >
              <RoomDetails
                roomInfoProduct={roomInfoProduct}
                productIndex={chosenProductIndex}
                lodging={selectedLodging}
                isMobile
                hideAmenities
                roomsCount={roomsCount}
                hideRoomBedDescription
                hideRoomCapacity
                showAmenitiesModal
              />
            </Box>
          )}
        </Box>
        {purchaseStep === MobileHotelFreezeWorkflowStep.Review && (
          <>
            <Box
              className={clsx(
                "mobile-hotel-freeze-content-section",
                "price-breakdown"
              )}
            >
              <Typography className="header-text" variant="h2">
                {t.DEPOSIT_AMOUNT}
              </Typography>
              <HotelFreezePriceBreakdown />
            </Box>
            <Box
              className={clsx(
                "mobile-hotel-freeze-content-section",
                "nonrefundable"
              )}
            >
              <Box className="nonrefundable-section-container">
                <Typography variant="h6" className="title">
                  {t.NONREFUNDABLE}
                </Typography>
                <Typography
                  variant="body2"
                  className="nonrefundable-payment-text"
                >
                  {t.NONREFUNDABLE_PAYMENT}
                </Typography>
              </Box>
            </Box>
          </>
        )}
        {!isDisableSave && (
          <Box className="hotel-freeze-button-section">
            <MobileFloatingButton
              className="hotel-freeze-button"
              wrapperClassName="b2b"
              disabled={isFloatingButtonDisabled()}
              onClick={incrementPurchaseStep}
            >
              {getFloatingButtonText()}
            </MobileFloatingButton>
          </Box>
        )}
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={handleCloseModal}
        onConfirm={incrementPurchaseStep}
        onGoBack={handleGoBack}
        mobileHeaderElement={<HotelFreezePriceBreakdownDropdown />}
      />
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("price-freeze-hotel-payment-card-popup", "b2b")}
      fullScreen={true}
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="price-freeze-hotel-payment-card-popup-back-button"
          onClick={onGoBack}
        />
      }
      headerElement={mobileHeaderElement}
      fullScreenWithBanner={true}
    >
      <PaymentCard />
      <MobileFloatingButton
        className="price-freeze-hotel-book-review-trip-button"
        onClick={onConfirm}
        wrapperClassName="b2b"
        disabled={disabled}
      >
        {t.REVIEW_MY_FROZEN_PRICE_TEXT}
      </MobileFloatingButton>
    </MobilePopoverCard>
  );
};
