import {
  GetHotelDetailRequest,
  HotelPriceFreezeDetail,
  LodgingPriceFreezeBatchOfferRequest,
  LodgingPriceFreezeOfferResult,
  LodgingPriceFreezeRefundResult,
  LodgingPriceFreezeGetCreditsRequest,
  LodgingPriceFreezeGetCreditsResult,
  HotelPriceFreezePurchaseEntry,
  AvailabilityRequest,
  TrackingProperties,
} from "redmond";
import { IHotelPriceFreezeExperiments } from "../reducer";
import * as actionTypes from "./constants";

export interface IFetchHotelPriceFreezeDetails {
  type: actionTypes.FETCH_HOTEL_PRICE_FREEZE_DETAILS;
  request: GetHotelDetailRequest;
}

export const fetchHotelPriceFreezeDetails = (
  request: GetHotelDetailRequest
): IFetchHotelPriceFreezeDetails => {
  return {
    type: actionTypes.FETCH_HOTEL_PRICE_FREEZE_DETAILS,
    request,
  };
};

export interface ISetHotelPriceFreezeDetails {
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_DETAILS;
  detail: HotelPriceFreezeDetail;
}

export const setHotelPriceFreezeDetails = (args: {
  detail: HotelPriceFreezeDetail;
}): ISetHotelPriceFreezeDetails => ({
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_DETAILS,
  ...args,
});

export interface ISetFetchHotelPriceFreezeDetailsCallStateFailed {
  type: actionTypes.SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED;
}

export const setFetchHotelPriceFreezeDetailsCallStateFailed =
  (): ISetFetchHotelPriceFreezeDetailsCallStateFailed => ({
    type: actionTypes.SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED,
  });

export interface IRefundHotelPriceFreeze {
  type: actionTypes.REFUND_HOTEL_PRICE_FREEZE;
  id: string;
}

export const refundHotelPriceFreeze = (args: {
  id: string;
}): IRefundHotelPriceFreeze => ({
  type: actionTypes.REFUND_HOTEL_PRICE_FREEZE,
  ...args,
});

export interface ISetRefundHotelPriceFreezeResult {
  type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_RESULT;
  result: LodgingPriceFreezeRefundResult;
}

export const setRefundHotelPriceFreezeResult = (args: {
  result: LodgingPriceFreezeRefundResult;
}): ISetRefundHotelPriceFreezeResult => ({
  type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_RESULT,
  ...args,
});

export interface ISetRefundHotelPriceFreezeCallStateFailed {
  type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED;
}

export const setRefundHotelPriceFreezeCallStateFailed =
  (): ISetRefundHotelPriceFreezeCallStateFailed => ({
    type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED,
  });

export interface ISetRefundHotelPriceFreezeCallStateNotCalled {
  type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED;
}

export const setRefundHotelPriceFreezeCallStateNotCalled =
  (): ISetRefundHotelPriceFreezeCallStateNotCalled => ({
    type: actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED,
  });

export interface IGetHotelPriceFreezeCredits {
  type: actionTypes.GET_HOTEL_PRICE_FREEZE_CREDITS;
  request: LodgingPriceFreezeGetCreditsRequest;
}

export const getHotelPriceFreezeCredits = (args: {
  request: LodgingPriceFreezeGetCreditsRequest;
}): IGetHotelPriceFreezeCredits => ({
  type: actionTypes.GET_HOTEL_PRICE_FREEZE_CREDITS,
  ...args,
});

export interface ISetHotelPriceFreezeCreditsResult {
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_CREDITS_RESULT;
  result: LodgingPriceFreezeGetCreditsResult;
}

export const setHotelPriceFreezeCreditsResult = (args: {
  result: LodgingPriceFreezeGetCreditsResult;
}): ISetHotelPriceFreezeCreditsResult => ({
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_CREDITS_RESULT,
  ...args,
});

export interface ISetGetHotelPriceFreezeCreditsCallStateFailed {
  type: actionTypes.SET_GET_HOTEL_PRICE_FREEZE_CREDITS_CALL_STATE_FAILED;
}

export const setGetHotelPriceFreezeCreditsCallStateFailed =
  (): ISetGetHotelPriceFreezeCreditsCallStateFailed => ({
    type: actionTypes.SET_GET_HOTEL_PRICE_FREEZE_CREDITS_CALL_STATE_FAILED,
  });

export interface IResetHotelPriceFreezeExercise {
  type: actionTypes.RESET_HOTEL_PRICE_FREEZE_EXERCISE;
}

export const resetHotelPriceFreezeExercise =
  (): IResetHotelPriceFreezeExercise => ({
    type: actionTypes.RESET_HOTEL_PRICE_FREEZE_EXERCISE,
  });

export interface ISetPriceFreezeExperiments {
  type: actionTypes.SET_PRICE_FREEZE_EXPERIMENTS;
  experiments: IHotelPriceFreezeExperiments;
}

export const setPriceFreezeExperiments = (
  experiments: IHotelPriceFreezeExperiments
): ISetPriceFreezeExperiments => ({
  type: actionTypes.SET_PRICE_FREEZE_EXPERIMENTS,
  experiments,
});

export interface IFetchHotelPriceFreezeOffers {
  type: actionTypes.FETCH_HOTEL_PRICE_FREEZE_OFFERS;
  request: LodgingPriceFreezeBatchOfferRequest;
}
export const fetchHotelPriceFreezeOffers = (
  request: LodgingPriceFreezeBatchOfferRequest
): IFetchHotelPriceFreezeOffers => ({
  type: actionTypes.FETCH_HOTEL_PRICE_FREEZE_OFFERS,
  request,
});

export interface ISetHotelPriceFreezeOffers {
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_OFFERS;
  offers: LodgingPriceFreezeOfferResult[];
}

export const setHotelPriceFreezeOffers = (args: {
  offers: LodgingPriceFreezeOfferResult[];
}): ISetHotelPriceFreezeOffers => ({
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_OFFERS,
  ...args,
});

export interface ISetHotelPriceFreezePurchaseEntry {
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_PURCHASE_ENTRY;
  priceFreezeEntry: HotelPriceFreezePurchaseEntry;
}

export const setHotelPriceFreezePurchaseEntry = (
  priceFreezeEntry: HotelPriceFreezePurchaseEntry
): ISetHotelPriceFreezePurchaseEntry => ({
  type: actionTypes.SET_HOTEL_PRICE_FREEZE_PURCHASE_ENTRY,
  priceFreezeEntry,
});

export interface IFetchHotelAvailabilityTracking {
  type: actionTypes.FETCH_HOTEL_AVAILABILITY_TRACKING;
  request: AvailabilityRequest;
}

export const fetchHotelAvailabilityTracking = (
  request: AvailabilityRequest
): IFetchHotelAvailabilityTracking => ({
  type: actionTypes.FETCH_HOTEL_AVAILABILITY_TRACKING,
  request,
});

export interface ISetHotelAvailabilityTracking {
  type: actionTypes.SET_HOTEL_AVAILABILITY_TRACKING;
  trackingProperties: TrackingProperties | null;
}

export const setHotelAvailabilityTracking = (
  trackingProperties: TrackingProperties | null
): ISetHotelAvailabilityTracking => ({
  type: actionTypes.SET_HOTEL_AVAILABILITY_TRACKING,
  trackingProperties,
});

export type HotelPriceFreezeActions =
  | IFetchHotelPriceFreezeDetails
  | ISetHotelPriceFreezeDetails
  | ISetFetchHotelPriceFreezeDetailsCallStateFailed
  | IRefundHotelPriceFreeze
  | ISetRefundHotelPriceFreezeResult
  | ISetRefundHotelPriceFreezeCallStateFailed
  | ISetRefundHotelPriceFreezeCallStateNotCalled
  | IGetHotelPriceFreezeCredits
  | ISetHotelPriceFreezeCreditsResult
  | ISetGetHotelPriceFreezeCreditsCallStateFailed
  | IResetHotelPriceFreezeExercise
  | ISetPriceFreezeExperiments
  | IFetchHotelPriceFreezeOffers
  | ISetHotelPriceFreezeOffers
  | ISetHotelPriceFreezePurchaseEntry
  | IFetchHotelAvailabilityTracking
  | ISetHotelAvailabilityTracking;
