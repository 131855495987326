import { connect, ConnectedProps } from "react-redux";

import { HotelSearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getLocation,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getRoomsCount,
  getChildren,
  getApplyUserHotelPreferences,
  getPetsCount,
} from "../../reducer/index";
import { CallState, IResult } from "redmond";
import { Dispatch } from "@reduxjs/toolkit";
import {
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHotelNameFilter,
  setHotelsOnSaleFilter,
  setMaxPriceFilter,
  setStarRatingsFilter,
} from "../../../availability/actions/actions";
import {
  getHasUserSetHotelPreferences,
  getMapBound,
  getUserHotelPreferencesCallState,
  getViewHotelsNearLocation,
  initialFilterState,
} from "../../../availability/reducer";
import H from "history";
import { setApplyUserHotelPreferences } from "../../actions/actions";

interface IStateProps {
  location: IResult | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  roomsCount: number;
  petsCount: number;
  isSearchMap: boolean;
  isViewHotelsNear: boolean;
  shouldApplyUserHotelPreferences: boolean;
  userHasSetHotelPreferences: boolean;
  userHotelPreferencesCallState: CallState;
}

const mapStateToProps = (state: IStoreState) => {
  return {
    location: getLocation(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    roomsCount: getRoomsCount(state),
    petsCount: getPetsCount(state),
    isViewHotelsNear: !!getViewHotelsNearLocation(state),
    isSearchMap: !!getMapBound(state),
    shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
    userHasSetHotelPreferences: getHasUserSetHotelPreferences(state),
    userHotelPreferencesCallState: getUserHotelPreferencesCallState(state),
  };
};

interface OwnProps {
  className?: string;
  onSearch?: (
    history: H.History,
    searchFromMap?: boolean,
    searchHotelsNear?: boolean
  ) => void;
  displaySearchOnChange?: boolean;
  showTravelerSelection?: boolean;
  showSearchButton?: boolean;
  saveDatesOnClose?: boolean;
  hideCalendarSeparator?: boolean;
  showTotalTravelers?: boolean;
}
interface IDispatchProps {
  resetFilters: () => void;
  setApplyUserHotelPreferences: (shouldApply: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetFilters: () => {
      dispatch(setAmenitiesFilter(initialFilterState.amenities));
      dispatch(setStarRatingsFilter(initialFilterState.starRatings));
      dispatch(setHotelNameFilter(initialFilterState.hotelName));
      dispatch(setMaxPriceFilter(initialFilterState.maxPrice));
      dispatch(setFreeCancelFilter(initialFilterState.freeCancel));
      dispatch(setHotelsOnSaleFilter(initialFilterState.hotelsOnSaleOnly));
    },
    setApplyUserHotelPreferences: (shouldApply: boolean) =>
      dispatch(setApplyUserHotelPreferences(shouldApply)),
  };
};
const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: OwnProps
) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type HotelSearchControlConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelSearchControl = connector(HotelSearchControl);
