import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import {
  MobileAutocompleteOptions,
  ActionButton,
  Icon,
  IconName,
  MobilePopoverCard,
  ActionLink,
  CloseButtonIcon,
} from "halifax";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { MobileViewHotelsNearSearchConnectorProps } from "./container";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IResult } from "redmond";
export interface IMobileViewHotelsNearSearchProps
  extends MobileViewHotelsNearSearchConnectorProps {
  onComplete: (value: IResult | null) => void;
  onClose: () => void;
  open: boolean;
}
export const MobileViewHotelsNearSearch = (
  props: IMobileViewHotelsNearSearchProps
) => {
  const {
    viewHotelsNearLocation,
    viewHotelsNearLoading,
    onComplete,
    onClose,
    fetchViewHotelsNearLocationCategories,
    viewHotelsNearLocationCategories,
    open,
  } = props;

  const [modalOpen, setIsOpen] = useState(open);

  const [locationSearchString, setLocationSearchString] = useState<
    string | null
  >(viewHotelsNearLocation?.label || null);

  const [localLocation, setLocalLocation] = useState<IResult | null>(
    viewHotelsNearLocation
  );

  const onMobileViewHotelsNearSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocationSearchString(event.target.value);
    if (event.target.value) {
      fetchViewHotelsNearLocationCategories(event.target.value);
    }
  };

  const onLocationSelected = (value: IResult | null) => {
    setLocationSearchString(value ? value.label : "");
    setLocalLocation(value);
  };

  const onClearLocationField = () => {
    setLocationSearchString("");
    setLocalLocation(null);
    fetchViewHotelsNearLocationCategories("");
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    setLocalLocation(viewHotelsNearLocation);
    setLocationSearchString(
      viewHotelsNearLocation ? viewHotelsNearLocation.label : ""
    );
  }, [viewHotelsNearLocation]);

  const closeModal = () => {
    onClose();
    setIsOpen(false);
  };

  return (
    <MobilePopoverCard
      open={modalOpen}
      className="mobile-view-hotels-near-root"
      headerElement={
        <Box className="mobile-view-hotels-near-modal-header-container">
          <Typography className="header-title">
            Enter name, address, landmark
          </Typography>
          <ActionLink
            className="modal-close-button"
            onClick={() => closeModal()}
            content={<CloseButtonIcon className="close-button-icon" />}
            label="Close"
            showTappableArea
          />
        </Box>
      }
    >
      <Box className="mobile-view-hotels-near-modal">
        <Box className="mobile-view-hotels-near-input-container">
          <Box className="mobile-view-hotels-near-input-field">
            <Icon name={IconName.MagnifyingGlass} />
            <TextField
              className={clsx("mobile-view-hotels-near-auto-complete")}
              onChange={(value) => onMobileViewHotelsNearSearch(value)}
              value={locationSearchString}
              label="View hotels near..."
              InputProps={{
                disableUnderline: true,
              }}
            />
            {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
            <FontAwesomeIcon
              className={clsx("icon", {
                hidden: !locationSearchString,
              })}
              icon={faTimesCircle}
              onClick={onClearLocationField}
            />
          </Box>
        </Box>

        <MobileAutocompleteOptions
          value={null}
          valueCategories={viewHotelsNearLocationCategories}
          setValue={(value: IResult | null) => onLocationSelected(value)}
          loading={viewHotelsNearLoading}
          loadingText={textConstants.LOADING_TEXT}
        />
        {localLocation !== viewHotelsNearLocation ? (
          <ActionButton
            className="mobile-autocomplete-continue-button"
            message={textConstants.CONTINUE}
            onClick={() => {
              onComplete(localLocation);
            }}
          />
        ) : null}
      </Box>
    </MobilePopoverCard>
  );
};
