export const SEARCH_HOTELS = "Search Hotels";
export const SEARCH_STAYS = "Search Stays";
export const MISSING_INFO_SEARCH_ERROR =
  "To continue, please complete all required fields.";

export const WHERE_TO = "Where to?";
export const WHERE_ARE_YOU_STAYING = "Where are you staying?";

export const CHOOSE_DATES = "Choose Dates";

export const CHECK_IN = "Check-in";
export const CHECKOUT = "Check-out";
