import dayjs from "dayjs";

export const HEADER =
  "<strong>Freeze the price,</strong> <span>book later.</span>";
export const TITLE = (durationHours: number, basePricePerNight: string) => {
  const daysDuration = dayjs.duration({ hours: durationHours }).asDays();
  return `<strong>Interested in this room but not ready to book?</strong> Choose a room and freeze the price for ${daysDuration} ${
    daysDuration > 1 ? "days" : "day"
  }, starting at <strong>${basePricePerNight} per night.</strong>`;
};
export const INFO_TEXT =
  "When you book with your price freeze, <strong>the amount you pay today will be applied toward your final booking price.</strong>";
export const INCREASE_TEXT = (cap: string) =>
  `<strong>If the price goes up after you freeze,</strong> we cover the difference up to ${cap} when you book through Capital One Travel.`;
export const DECREASE_TEXT =
  "<strong>If the price goes down after you freeze,</strong> you pay the lower price when you book through Capital One Travel.";
export const REFUND_TEXT =
  "Freezing the price doesn't reserve this room, but <strong> we'll give you a full refund </strong> if it isn't available when you try to book.";
export const CTA_TEXT = "Read terms and conditions";
