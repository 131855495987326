import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  B2BSpinner,
  BackToTopButton,
  BannerSeverity,
  HotelAmenities,
  HotelCarousel,
  HotelDescription,
  HotelName,
  HotelOverview,
  HotelPriceCompareChart,
  HotelShopRoomTypePickerVariant,
  HotelShopSimilarHotels,
  HotelTripAdvisorReviews,
  Icon,
  IconName,
  MobileHotelShopRoomTypePickerPanelSkeleton,
  NotificationBanner,
  TrackingEventControlType,
  VoidWindowNotice,
  getCancellationPolicyInfo,
  savingsAmountBasedOnCheapestProduct,
} from "halifax";
import queryStringParser from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  CallState,
  CancellationPolicyEnum,
  CancellationReason,
  HOTEL_CLICKED_PRICE_FREEZE,
  HOTEL_PRICE_FREEZE_CLICK_INFO_MODAL_CONTINUE,
  HOTEL_PRICE_FREEZE_VIEW_INFO_MODAL,
  HotelPriceFreezeViewInfoModalProperties,
  Lodging,
  LodgingCollectionEnum,
  LodgingPriceFreezeOffer,
  SELECTED_RECOMMENDED_HOTEL,
  SelectedTravelOfferScreen,
} from "redmond";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  HOTELS_SHOP_IMAGE_MODAL,
  PREMIER_COLLECTION_EXPERIMENT,
  SIMILAR_HOTELS_1A,
  SIMILAR_HOTELS_1B,
  SIMILAR_HOTELS_EXPERIMENT,
  SIMILAR_HOTELS_VARIANTS,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import {
  PATH_SHOP,
  PREMIER_COLLECTION_PATH_SHOP,
} from "../../../../utils/paths";
import { PriceDropProtectionBanner } from "../../../ancillary/components/addOnComponents";
import { PriceDropProtectionPopup } from "../../../ancillary/components/addOnComponents/PriceDropProtectionBanner/components";
import { PriceFreezeAction } from "../../../freeze/components/PriceFreezeAction/index";
import { RewardsAccountSelection } from "../../../rewards/components";
import { TravelWalletDrawer } from "../../../travel-wallet/components";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components/TravelWalletDetailsBanner";
import { HotelShopCallState, HotelShopStep } from "../../reducer/state";
import { goToAvailability } from "../../utils/queryStringHelpers";
import { HotelShopExpandedMap } from "../HotelShopExpandedMap";
import { HotelShopSmallMap } from "../HotelShopSmallMap/component";
import { MobileHotelPhotoModal } from "../MobileHotelPhotoModal";
import { MobileHotelShopRoomTypePickerPanelRedesign } from "../MobileHotelShopRoomTypePickerPanelRedesign";
import { MobileRoomPickerRedesign } from "../MobileRoomPickerRedesign";
import { MobileShopHeader } from "../MobileShopHeader";
import { ShopErrorModal } from "../ShopErrorModal";
import { MobileShopConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import { CALIFORNIA_BILL_644_CANCELLATION_TEXT } from "../DesktopShop/textConstants";
import { resetLodging } from "../../actions/actions";
import { useDispatch } from "react-redux";

export interface IMobileShopProps
  extends MobileShopConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
}

export const MobileShop = (props: IMobileShopProps) => {
  const {
    lodging,
    hotelShopCallInProgress,
    cheapestProduct,
    chosenProduct,
    hasFailed,
    hotelShopStep,
    similarHotels,
    similarHotelsAvailabilityCallState,
    selectedAccountReferenceId,
    fromDate,
    untilDate,
    history,
    similarHotelsAvailabilityTrackingProperties,
    largestValueAccount,
    viewedHotelDetailsProperties,
    searchDistanceFromShopProperties,
    adultsCount,
    children,
    roomsCount,
    petsCount,
    hotelShopCallState,
    nightCount,
    showOfferBasedOnSelectedLodging,
    roomInfoProductsType,
    handleReadyToRedirect,
    setHotelShopProgress,
    baseHotelPriceFreezeOffer,
    userPriceDropAmount,
    rewardsKey,
    cancellationSummary,
  } = props;

  const dispatch = useDispatch();
  const [showExpandedHotelMap, setShowExpandedHotelMap] = useState(false);
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const [successShopSearchFromDate, setSuccessShopSearchFromDate] =
    useState<Date | null>(null);
  const [successShopSearchUntilDate, setSuccessShopSearchUntilDate] =
    useState<Date | null>(null);
  const [basePriceFreezeOffer, setBasePriceFreezeOffer] = useState<
    null | undefined | LodgingPriceFreezeOffer
  >(undefined);
  const [
    openPriceDropProtectionBannerModal,
    setOpenPriceDropProtectionBannerModal,
  ] = useState<boolean>(false);

  const expState = useExperiments();

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const hotelShopImageModal = getExperimentVariant(
    expState.experiments,
    HOTELS_SHOP_IMAGE_MODAL
  );
  const isHotelShopImageModal = useMemo(
    () => hotelShopImageModal === AVAILABLE,
    [hotelShopImageModal]
  );

  const similarHotelsVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    SIMILAR_HOTELS_EXPERIMENT,
    SIMILAR_HOTELS_VARIANTS
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const premierCollectionExperiment = getExperimentVariant(
    expState.experiments,
    PREMIER_COLLECTION_EXPERIMENT
  );
  const isPremierCollectionEnabled = useMemo(
    () => premierCollectionExperiment === AVAILABLE,
    [premierCollectionExperiment]
  );

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = useMemo(() => {
    return californiaBill644Variant === AVAILABLE;
  }, [californiaBill644Variant]);

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.hotelsMultiplier;

  const [showOfferBanner, setShowOfferBanner] = useState(false);

  useEffect(() => {
    setShowOfferBanner(
      isTravelWalletOfferExperiment && showOfferBasedOnSelectedLodging
    );
  }, [showOfferBasedOnSelectedLodging]);

  useEffect(() => {
    if (hotelShopCallState === HotelShopCallState.Success) {
      setSuccessShopSearchFromDate(fromDate);
      setSuccessShopSearchUntilDate(untilDate);
    }
  }, [hotelShopCallState]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, [hotelShopStep]);

  useEffect(() => {
    dispatch(resetLodging());
  }, []);

  const cancellationPolicyInfo = cheapestProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        cheapestProduct?.cancellationPolicy,
        textConstants.getCfarSecondaryText
      )
    : null;

  const returnToAvailability = (
    prevSuccessHotelFromDate?: Date | null,
    prevSuccessHotelUntilDate?: Date | null
  ) => {
    goToAvailability({
      history,
      lodging,
      fromDate,
      untilDate,
      adultsCount,
      children,
      roomsCount,
      petsCount,
      prevSuccessHotelFromDate,
      prevSuccessHotelUntilDate,
    });
  };

  const renderSkeleton = () => (
    <Box className={"mobile-hotel-shop-skeleton"}>
      <Box>
        <HotelName isSkeleton />
      </Box>
      <HotelCarousel isSkeleton />
      <Box className={"section-wrapper"}>
        <HotelOverview isSkeleton />
        <HotelAmenities isSkeleton />
        <HotelOverview isSkeleton />
      </Box>
      <Box className={"section-wrapper"}>
        <HotelAmenities isSkeleton />
        <HotelAmenities isSkeleton />
        <HotelOverview isSkeleton />
      </Box>
      <Box className={"section-wrapper"}>
        <HotelName isSkeleton />
        <HotelCarousel isSkeleton />
      </Box>
      <MobileHotelShopRoomTypePickerPanelSkeleton />
    </Box>
  );

  const renderRewardsAccount = () => {
    return (
      <Box className="mobile-hotel-shop-rewards-account-contents">
        <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
      </Box>
    );
  };

  const renderPriceFreeze = () => {
    const onClickHandler = () => {
      trackEvent({
        eventName: HOTEL_CLICKED_PRICE_FREEZE,
        properties: {
          price_freeze_entry: "hotel_details",
          success: true,
          position: "inline",
          lob: "hotel",
        },
      });
      setHotelShopProgress(HotelShopStep.ChooseRoom);
      setBasePriceFreezeOffer(baseHotelPriceFreezeOffer);
    };
    const onInfoClickHandler = () => {
      trackEvent({
        eventName: HOTEL_PRICE_FREEZE_VIEW_INFO_MODAL,
        properties: {
          price_freeze_entry: "hotel_details",
          info_modal_entry: "choose_room_cta",
        } as HotelPriceFreezeViewInfoModalProperties,
      });
    };
    const onInfoContinueClickHandler = () => {
      trackEvent({
        eventName: HOTEL_PRICE_FREEZE_CLICK_INFO_MODAL_CONTINUE,
        properties: {
          price_freeze_entry: "hotel_details",
          info_modal_entry: "choose_room_cta",
        } as HotelPriceFreezeViewInfoModalProperties,
      });
      setHotelShopProgress(HotelShopStep.ChooseRoom);
    };
    return (
      <PriceFreezeAction
        onClickHandler={onClickHandler}
        onInfoClickHandler={onInfoClickHandler}
        onInfoContinueClickHandler={onInfoContinueClickHandler}
        size="large"
      />
    );
  };

  useEffect(() => {
    // removes medallia from the fixed bottom that was covering pricing
    if (document && document.getElementById("nebula_div_btn")) {
      document!.getElementById("nebula_div_btn")!.style.display = "none";
    }
    return () => {
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    };
  }, [document.getElementById("nebula_div_btn")]);

  const showSimilarHotels =
    similarHotelsAvailabilityCallState === CallState.InProcess ||
    similarHotels.length > 1;

  const renderSimilarHotels = (lodging: Lodging) =>
    showSimilarHotels ? (
      <Box
        className={clsx("similar-hotels", {
          "variant-1a": similarHotelsVariant === SIMILAR_HOTELS_1A,
          "variant-1b": similarHotelsVariant === SIMILAR_HOTELS_1B,
          loading: similarHotelsAvailabilityCallState === CallState.InProcess,
        })}
      >
        {similarHotelsAvailabilityCallState === CallState.InProcess ? (
          <B2BSpinner />
        ) : (
          <>
            <Typography className="similar-hotels-header" variant="h2">
              Other similar Hotels in <strong>{lodging.lodging.city}</strong>
            </Typography>
            <HotelShopSimilarHotels
              similarHotels={
                isPremierCollectionEnabled
                  ? similarHotels
                  : similarHotels.filter(
                      (s) =>
                        s.lodgingCollection !== LodgingCollectionEnum.Premier
                    )
              }
              nightCount={dayjs(untilDate).diff(fromDate, "days")}
              rewardsKey={selectedAccountReferenceId ?? ""}
              onCTAClick={(lodgingId: string) => {
                const lodging = similarHotels.find(
                  (similarHotel) => similarHotel.lodging.id === lodgingId
                );

                trackEvent({
                  eventName: SELECTED_RECOMMENDED_HOTEL,
                  properties: {
                    ...lodging?.trackingPropertiesV2?.properties,
                    ...similarHotelsAvailabilityTrackingProperties?.properties,
                  },
                  encryptedProperties: [
                    lodging?.trackingPropertiesV2?.encryptedProperties ?? "",
                    similarHotelsAvailabilityTrackingProperties?.encryptedProperties ??
                      "",
                  ],
                });

                const queryString = history.location.search;
                const parsedQueryStringPrimitive =
                  queryStringParser.parse(queryString);

                const childrenArray = Array.isArray(
                  parsedQueryStringPrimitive.children
                )
                  ? parsedQueryStringPrimitive.children.map((age) =>
                      parseInt(age, 10)
                    )
                  : parsedQueryStringPrimitive.children
                  ? [parseInt(parsedQueryStringPrimitive.children, 10)]
                  : [];

                const hotelQueryParams = {
                  fromDate: parsedQueryStringPrimitive.fromDate as string,
                  untilDate: parsedQueryStringPrimitive.untilDate as string,
                  adultsCount: Number(parsedQueryStringPrimitive.adultsCount),
                  children: childrenArray,
                  selectedLodgingIndex: Number(
                    parsedQueryStringPrimitive.selectedLodgingIndex
                  ),
                  selectedAccountIndex: Number(
                    parsedQueryStringPrimitive.selectedAccountIndex
                  ),
                  lodgingSelection:
                    parsedQueryStringPrimitive.lodgingSelection as string,
                  recommended: true,
                  fromHotelAvailability:
                    lodging?.lodgingCollection ===
                      LodgingCollectionEnum.Premier ||
                    lodging?.lodgingCollection ===
                      LodgingCollectionEnum.Lifestyle
                      ? true
                      : undefined,
                };

                const path =
                  lodging?.lodgingCollection === LodgingCollectionEnum.Premier
                    ? PREMIER_COLLECTION_PATH_SHOP
                    : PATH_SHOP;
                history.push(
                  `${path}?${queryStringParser.stringify({
                    lodgingId,
                    ...hotelQueryParams,
                  })}`,
                  { fromPage: location.pathname }
                );
                window.scroll(0, 0);
              }}
              isMobile
              isGlobalMobileNavExperiment={isGlobalMobileNavExperiment}
            />
          </>
        )}
      </Box>
    ) : null;

  const renderAvailability = (hotelShopStep: HotelShopStep) => {
    const { lodging: lodgingData, price } = lodging || {};
    const savings =
      price && cheapestProduct
        ? savingsAmountBasedOnCheapestProduct(price, cheapestProduct)
        : null;

    return (
      <Box className={"mobile-hotel-shop-container"}>
        {lodging && hotelShopStep === HotelShopStep.HotelInfo ? (
          <>
            <MobileShopHeader />

            {renderRewardsAccount()}

            {isHotelShopImageModal && (
              <MobileHotelPhotoModal
                openModal={openPhotoModal}
                setOpenModal={setOpenPhotoModal}
              />
            )}
            <div
              onClick={() => {
                if (isHotelShopImageModal) {
                  trackEvent({
                    eventName: "hotel_shop_image_modal",
                    properties: { ...viewedHotelDetailsProperties },
                  });
                  setOpenPhotoModal(true);
                }
              }}
            >
              <HotelCarousel media={lodgingData?.media} />
            </div>
            {isPremierCollectionEnabled &&
              lodging.minNightOfStay &&
              nightCount &&
              lodging.minNightOfStay > nightCount && (
                <NotificationBanner
                  severity={BannerSeverity.WARNING}
                  content={
                    <Box className="pc-minimum-night-stay-warning-banner-content">
                      <Icon name={IconName.WarningAlert} />
                      <Typography
                        variant="body1"
                        className="warning-banner-text"
                        dangerouslySetInnerHTML={{
                          __html: textConstants.getMinNightStayBannerText(
                            lodging.minNightOfStay
                          ),
                        }}
                      />
                    </Box>
                  }
                  className="pc-minimum-stay-night-warning-banner"
                />
              )}
            {showOfferBanner && (
              <TravelWalletDetailsBanner
                onDismiss={() => setShowOfferBanner(false)}
                offer={lodging.bestOfferThisLodging!}
                className="mobile-hotel-shop-offer"
                screen={SelectedTravelOfferScreen.HOTEL_DETAILS}
                bannerTextType="shop"
              />
            )}
            <PriceDropProtectionBanner
              bannerVersion="large"
              showPremierCollectionText={false}
              setOpenModal={setOpenPriceDropProtectionBannerModal}
            />
            <PriceDropProtectionPopup
              amount={userPriceDropAmount}
              openModal={openPriceDropProtectionBannerModal}
              onClose={() => setOpenPriceDropProtectionBannerModal(false)}
            />
            {showFree24HourCancel && <VoidWindowNotice />}
            <Box className={clsx("section-wrapper", "overview")}>
              <HotelOverview
                lodging={lodging}
                includePricing={lodging?.price?.displayTaxesAndFeesIncluded}
                chosenProduct={chosenProduct ?? cheapestProduct}
                nightCount={nightCount ?? 0}
                rewardsKey={rewardsKey ?? undefined}
                roomsCount={roomsCount}
              />
            </Box>

            <Box className={clsx("section-wrapper", "amenities")}>
              <Typography variant="h2">Amenities</Typography>
              <HotelAmenities
                amenities={lodgingData?.amenities}
                displayCount={4}
                showAmenitiesText={`${textConstants.SHOW_ALL_AMENITIES_TEXT} (${
                  lodgingData?.amenities && lodgingData?.amenities?.length - 4
                })`}
                hideAmenityIconsInTooltip
              />
            </Box>
            <Box className="price-freeze-section">{renderPriceFreeze()}</Box>

            {price &&
              price.comparePrices.length > 0 &&
              !!price.comparePrices.find(
                (price) => price.source !== "Expedia"
              ) &&
              cheapestProduct &&
              savings && (
                <Box className={clsx("section-wrapper", "compare")}>
                  <Typography variant="h2">Compare Prices</Typography>
                  <HotelPriceCompareChart
                    lodgingPrice={price}
                    siteName="Capital One"
                    cheapestProduct={cheapestProduct}
                    earnText={
                      showEarnEnhancement
                        ? textConstants.getEarnText(
                            largestValueAccount.earn.hotelsMultiplier,
                            largestValueAccount.productDisplayName,
                            largestValueAccount.rewardsBalance
                              .currencyDescription ??
                              largestValueAccount.rewardsBalance.currency
                          )
                        : undefined
                    }
                  />
                </Box>
              )}

            <Box className={clsx("section-wrapper", "property")}>
              <Typography variant="h2">About the Property</Typography>
              <HotelDescription
                description={lodging?.lodging?.description}
                onMapClick={() => {
                  setShowExpandedHotelMap(true);
                }}
                map={
                  <HotelShopSmallMap
                    lodging={lodging}
                    setShowExpandedHotelMap={setShowExpandedHotelMap}
                    searchDistanceFromShopProperties={
                      searchDistanceFromShopProperties
                    }
                  />
                }
              />
              <HotelShopExpandedMap
                isMobile
                lodging={lodging}
                showExpandedHotelMap={showExpandedHotelMap}
                setShowExpandedHotelMap={setShowExpandedHotelMap}
              />
            </Box>

            {lodgingData?.tripAdvisorReviews &&
              lodgingData?.tripAdvisorReviews?.comments?.length > 0 && (
                <Box className={clsx("section-wrapper", "customer")}>
                  <Typography variant="h2">Customer Reviews</Typography>
                  <HotelTripAdvisorReviews
                    lodgingData={lodgingData}
                    trim={500}
                    isMobile={true}
                  />
                </Box>
              )}

            {cancellationPolicyInfo && (
              <Box className={clsx("section-wrapper", "cancellation")}>
                <Typography variant="h2">
                  {cancellationPolicyInfo.primaryText}
                </Typography>
                <Typography variant="subtitle2">
                  {showFree24HourCancel &&
                  cheapestProduct?.cancellationPolicy.CancellationPolicy ===
                    CancellationPolicyEnum.NonRefundable
                    ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                    : cancellationPolicyInfo.secondaryText}
                </Typography>
              </Box>
            )}

            {lodgingData?.checkInInstructions && fromDate && untilDate && (
              <Box className={clsx("section-wrapper", "check-in-instructions")}>
                <Typography variant="h2">
                  {textConstants.CHECK_IN_INSTRUCTIONS_TITLE}
                </Typography>
                <Typography
                  variant="subtitle2"
                  dangerouslySetInnerHTML={{
                    __html: textConstants.formattedCheckIn(
                      fromDate,
                      lodgingData.checkInInstructions?.checkInTime
                    ),
                  }}
                />
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: textConstants.formattedCheckOut(
                      untilDate,
                      lodgingData.checkInInstructions?.checkOutTime
                    ),
                  }}
                />
              </Box>
            )}

            {isGlobalMobileNavExperiment ? (
              <BackToTopButton bottom={110} />
            ) : undefined}

            {!isGlobalMobileNavExperiment && isTravelWalletCreditsExperiment ? (
              <TravelWalletDrawer />
            ) : undefined}

            <MobileHotelShopRoomTypePickerPanelRedesign />
          </>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const renderShop = (hotelShopStep: HotelShopStep) => {
    return (
      <Box className={"mobile-hotel-shop-container"}>
        {lodging && hotelShopStep === HotelShopStep.HotelInfo ? (
          <>
            {[SIMILAR_HOTELS_1A, SIMILAR_HOTELS_1B].includes(
              similarHotelsVariant
            ) && renderSimilarHotels(lodging)}

            {isGlobalMobileNavExperiment ? (
              <BackToTopButton bottom={110} />
            ) : undefined}
          </>
        ) : (
          lodging && (
            <MobileRoomPickerRedesign
              handleReadyToRedirect={handleReadyToRedirect}
              roomInfoProductsType={roomInfoProductsType}
              baseHotelPriceFreezeOffer={basePriceFreezeOffer}
              nonRefundablePolicyOverrideText={
                CALIFORNIA_BILL_644_CANCELLATION_TEXT
              }
            />
          )
        )}
      </Box>
    );
  };

  return !hasFailed ? (
    <Box
      className={clsx("mobile-hotel-shop-wrapper", config.TENANT, {
        "global-mobile-nav": isGlobalMobileNavExperiment,
      })}
    >
      {renderAvailability(hotelShopStep)}
      {hotelShopCallInProgress ? renderSkeleton() : renderShop(hotelShopStep)}
    </Box>
  ) : (
    <ShopErrorModal
      returnToAvailability={() =>
        returnToAvailability(
          successShopSearchFromDate,
          successShopSearchUntilDate
        )
      }
    />
  );
};
