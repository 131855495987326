import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { isCorpTenant } from "@capone/common";
import { IStoreState } from "../../../../reducers/types";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import {
  getFromDate,
  getNightCount,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import {
  getHotelShopChosenProduct,
  getHotelShopChosenProductIndex,
  getHotelShopChosenRoomInfo,
  getHotelShopChosenRoomInfoIndex,
  getHotelShopRoomInfoProducts,
  getHotelShopSelectedLodging,
  showOfferBasedOnSelectedLodgingSelector,
} from "../../reducer";
import { MobileRoomPickerRedesign } from "./component";
import { actions } from "../../actions";
import { getHotelAvailabilityRoomsCount } from "../../../availability/reducer";
import {
  isFintechHotelUpdatedUxEnabledSelector,
  isHotelDisplayRoomPricingUpdateEnabledSelector,
  isHotelCfarModelV1EnabledSelector,
  isHotelCfarModelV1RefundableRoomUpdatedUXSelector,
  isHotelCfarModelV1RefundableRoomUpdatedCopySelector,
} from "../../../ancillary/reducer";
import { config } from "../../../../api/config";

const mapStateToProps = (state: IStoreState) => {
  return {
    checkinDate: getFromDate(state),
    checkoutDate: getUntilDate(state),
    roomInfoProducts: getHotelShopRoomInfoProducts(state),
    chosenRoomInfoIndex: getHotelShopChosenRoomInfoIndex(state),
    chosenRoomInfo: getHotelShopChosenRoomInfo(state),
    chosenProductIndex: getHotelShopChosenProductIndex(state),
    chosenProduct: getHotelShopChosenProduct(state),
    selectedLodging: getHotelShopSelectedLodging(state),
    nightCount: getNightCount(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) ?? undefined,
    roomsCount: getRoomsCount(state),
    searchedRoomsCount: getHotelAvailabilityRoomsCount(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    showOfferBasedOnSelectedLodging:
      showOfferBasedOnSelectedLodgingSelector(state),
    isFintechHotelUpdatedUxEnabled:
      isFintechHotelUpdatedUxEnabledSelector(state),
    isHotelDisplayRoomPricingUpdateEnabled:
      isHotelDisplayRoomPricingUpdateEnabledSelector(state),
    isHotelCfarModelV1Enabled: isHotelCfarModelV1EnabledSelector(state),
    isHotelCfarModelV1RefundableRoomUpdatedUX:
      isHotelCfarModelV1RefundableRoomUpdatedUXSelector(state),
    isHotelCfarModelV1RefundableRoomUpdatedCopy:
      isHotelCfarModelV1RefundableRoomUpdatedCopySelector(state),
    canEarnRewards:
      !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state),
  };
};

export const mapDispatchToProps = {
  selectRoomType: actions.selectRoomType,
  fetchHotelShop: actions.fetchHotelShop,
  setSelectedRoomId: actions.setSelectedRoomId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileRoomPickerRedesignConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileRoomPickerRedesign = withRouter(
  connector(MobileRoomPickerRedesign)
);
