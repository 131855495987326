import {
  Amount,
  CallState,
  LodgingId,
  QuoteId,
  RoomInfoCfarQuote,
} from "redmond";
import {
  DefaultVariantType,
  HOTELS_CFAR,
  HOTEL_CFAR_REFUND_DISPLAY,
  HOTELS_LFAR,
  FINTECH_HOTEL_UX_UPDATED,
  HotelCfarRefundDisplayVariantType,
  HOTELS_CFAR_ELIGIBLE_POLICY,
  HotelsCfarEligiblePolicyPropVariantType,
  HOTEL_CFAR_RR_MULTIPLE_CANCELLATION_POLICIES,
  HOTEL_PD_BANNER_COPY,
  HotelPDBannerCopyVariantType,
  HOTEL_DISPLAY_ROOM_PRICING_UPDATE,
  HOTEL_CFAR_MODEL_V1,
  LDJSONConfig,
  HOTEL_CFAR_ROOM_ID_MATCH,
  HOTEL_CFAR_PASS_OPAQUE_PARAM,
  HOTELS_TAXES_AND_FEES_EXPERIMENT,
  HotelsTaxesAndFeesVariantType,
} from "../../../context/experiments";
import { actions, actionTypes } from "../actions";

export const DO_NOT_APPLY_OPTION_KEY = "do-not-apply-option";

export interface IHotelAncillaryExperiments {
  [HOTELS_CFAR]: DefaultVariantType;
  [HOTEL_CFAR_REFUND_DISPLAY]: HotelCfarRefundDisplayVariantType;
  [HOTELS_CFAR_ELIGIBLE_POLICY]: HotelsCfarEligiblePolicyPropVariantType;
  [HOTELS_LFAR]: DefaultVariantType;
  [FINTECH_HOTEL_UX_UPDATED]: DefaultVariantType;
  [HOTEL_PD_BANNER_COPY]: HotelPDBannerCopyVariantType;
  [HOTEL_CFAR_RR_MULTIPLE_CANCELLATION_POLICIES]: DefaultVariantType;
  [HOTEL_DISPLAY_ROOM_PRICING_UPDATE]: DefaultVariantType;
  [HOTEL_CFAR_MODEL_V1]: LDJSONConfig | null;
  [HOTEL_CFAR_ROOM_ID_MATCH]: DefaultVariantType;
  [HOTEL_CFAR_PASS_OPAQUE_PARAM]: DefaultVariantType;
  [HOTELS_TAXES_AND_FEES_EXPERIMENT]: HotelsTaxesAndFeesVariantType;
}
export interface IHotelAncillaryExperimentState {
  experiments: IHotelAncillaryExperiments | null;
}

export interface IHotelAncillaryState extends IHotelAncillaryExperimentState {
  lodgingId: LodgingId | null;
  roomInfoCfarQuotes: RoomInfoCfarQuote[];
  fetchCfarQuoteCallState: CallState;
  selectedCfarId: QuoteId | null;
  hasSelectedRefundableRoom: boolean;
  priceDropEligible: boolean;
  priceDropAmount: Amount | null;
  fetchPriceDropEligibilityCallState: CallState;
  priceDropBannerModalOpen: boolean;
  shouldDisableRefundableRooms?: boolean;
  hasAddedCfarFromModal?: boolean;
}

export const initialState: IHotelAncillaryState = {
  experiments: null,
  lodgingId: null,
  roomInfoCfarQuotes: [],
  fetchCfarQuoteCallState: CallState.NotCalled,
  selectedCfarId: null,
  hasSelectedRefundableRoom: false,
  priceDropEligible: false,
  priceDropAmount: null,
  fetchPriceDropEligibilityCallState: CallState.NotCalled,
  priceDropBannerModalOpen: false,
};

export const getExperimentState = (
  hotelAncillaryState: IHotelAncillaryState | undefined
): IHotelAncillaryExperimentState => {
  return {
    experiments: hotelAncillaryState?.experiments ?? initialState.experiments,
  };
};

export function reducer(
  state: IHotelAncillaryState = initialState,
  action: actions.HotelAncillaryActions
): IHotelAncillaryState {
  switch (action.type) {
    case actionTypes.SET_ANCILLARY_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
      };
    case actionTypes.FETCH_CFAR_QUOTES:
      return {
        ...state,
        lodgingId: null,
        selectedCfarId: null,
        hasSelectedRefundableRoom: false,
        roomInfoCfarQuotes: [],
        fetchCfarQuoteCallState: CallState.InProcess,
      };
    case actionTypes.SET_FETCH_CFAR_QUOTES_CALL_STATE_FAILED:
      return {
        ...state,
        fetchCfarQuoteCallState: CallState.Failed,
      };
    case actionTypes.SET_CFAR_QUOTES:
      return {
        ...state,
        fetchCfarQuoteCallState: CallState.Success,
        lodgingId: action.response.lodgingId,
        roomInfoCfarQuotes: action.response.roomInfoCfarQuotes,
        shouldDisableRefundableRooms:
          action.response.shouldDisableRefundableRooms,
      };
    case actionTypes.SET_SELECTED_CFAR_ID:
      return {
        ...state,
        selectedCfarId: action.cfarId,
      };
    case actionTypes.SET_HAS_SELECTED_REFUNDABLE_ROOM:
      return {
        ...state,
        hasSelectedRefundableRoom: action.hasSelectedRefundableRoom,
      };
    case actionTypes.FETCH_PRICE_DROP_ELIGIBILITY:
      return {
        ...state,
        fetchPriceDropEligibilityCallState: CallState.InProcess,
      };
    case actionTypes.SET_PRICE_DROP_ELIGIBILITY:
      return {
        ...state,
        fetchCfarQuoteCallState: CallState.Success,
        priceDropEligible: action.eligible,
        priceDropAmount: action.amount,
      };
    case actionTypes.SET_FETCH_PRICE_DROP_ELIGIBILITY_CALL_STATE_FAILED:
      return {
        ...state,
        fetchPriceDropEligibilityCallState: CallState.Failed,
        priceDropEligible: false,
        priceDropAmount: null,
      };
    case actionTypes.SET_HAS_ADDED_CFAR_FROM_MODAL:
      return {
        ...state,
        hasAddedCfarFromModal: action.hasAddedCfarFromModal,
      };
    default:
      return state;
  }
}

export * from "./selectors";
