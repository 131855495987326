import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";

import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getAdultsCount,
  getChildren,
  getPetsCount,
  getRoomsCount,
} from "../../../../reducer/selectors";
import { OccupancySelection } from "./component";

const mapDispatchToProps = {
  setOccupancyCounts: actions.setOccupancyCounts,
  setRoomsCount: actions.setRoomsCount,
  setPetsCount: actions.setPetsCount,
};
const mapStateToProps = (state: IStoreState) => ({
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  roomsCount: getRoomsCount(state),
  petsCount: getPetsCount(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type OccupancySelectionConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedOccupancySelection = withRouter(
  connector(OccupancySelection)
);
