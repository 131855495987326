import * as H from "history";
import {
  AvailabilityResponse,
  BoundingBox,
  LodgingId,
  AvailabilityRequestEnum,
  AmenityEnum,
  HotelStarRatingEnum,
  IResult,
  APPLIED_HOTEL_FILTER,
  AppliedHotelFilterProperties,
  GuestsSelection,
  HotelEntryTypeEnum,
  ICategorizedResponse,
  UserHotelPreferencesPayload,
  AppliedPremiumStaysToggleProperties,
  Payment,
} from "redmond";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";

import { HotelAvailabilitySortOption } from "../reducer/state";
import * as actionTypes from "./constants";
import { StayType } from "redmond/hotels-module/interfaces";

export interface IFetchHotelAvailability {
  type:
    | actionTypes.FETCH_INITIAL_HOTEL_AVAILABILITY
    | actionTypes.FETCH_MORE_HOTEL_AVAILABILITY;
  requestType: AvailabilityRequestEnum;
  history: H.History;
  searchFromMap?: boolean;
  searchHotelsNear?: boolean;
  includeHomes?: boolean;
}

export const fetchInitialHotelAvailability = ({
    history,
    searchFromMap,
    searchHotelsNear,
    includeHomes,
  }:
  {
    history: H.History,
    searchFromMap?: boolean,
    searchHotelsNear?: boolean,
    includeHomes?: boolean,
  }): IFetchHotelAvailability => ({
  type: actionTypes.FETCH_INITIAL_HOTEL_AVAILABILITY,
  requestType: AvailabilityRequestEnum.InitialSearch,
  history,
  searchFromMap,
  searchHotelsNear,
  includeHomes,
});

export const fetchMoreHotelAvailability = (
  history: H.History,
  includeHomes?: boolean,
): IFetchHotelAvailability => ({
  type: actionTypes.FETCH_MORE_HOTEL_AVAILABILITY,
  requestType: AvailabilityRequestEnum.FollowUpSearch,
  history,
  includeHomes: includeHomes
});

export interface ISetHotelAvailabilityResults {
  type: actionTypes.SET_HOTEL_AVAILABILITY_RESULTS;
  payload: ISetHotelAvailabilityResultsPayload;
}

export type ISetHotelAvailabilityResultsPayload = AvailabilityResponse;

export const setHotelAvailabilityResults = (args: {
  payload: ISetHotelAvailabilityResultsPayload;
}): ISetHotelAvailabilityResults => ({
  type: actionTypes.SET_HOTEL_AVAILABILITY_RESULTS,
  ...args,
});

export const setHotelAvailabilityCallStateFailed =
  (): ISetHotelAvailabilityCallStateFailed => ({
    type: actionTypes.SET_HOTEL_AVAILABILITY_CALL_STATE_FAILED,
  });

export interface ISetHotelAvailabilityCallStateFailed {
  type: actionTypes.SET_HOTEL_AVAILABILITY_CALL_STATE_FAILED;
}

export interface ISetAmenitiesFilter {
  type: actionTypes.SET_AMENITIES_FILTER;
  amenities: AmenityEnum[];
}

export const setAmenitiesFilter = (
  amenities: AmenityEnum[]
): ISetAmenitiesFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "amenities",
    amenities: amenities,
  };
  if (amenities.length > 0) {
    trackEvent({
      eventName: APPLIED_HOTEL_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_AMENITIES_FILTER,
    amenities,
  };
};

export interface ISetStarRatingsFilter {
  type: actionTypes.SET_STAR_RATINGS_FILTER;
  starRatings: HotelStarRatingEnum[];
}

export const setStarRatingsFilter = (
  starRatings: HotelStarRatingEnum[]
): ISetStarRatingsFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "stars",
  };
  if (starRatings.length > 0) {
    trackEvent({
      eventName: APPLIED_HOTEL_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_STAR_RATINGS_FILTER,
    starRatings,
  };
};

export interface ISetMaxPriceFilter {
  type: actionTypes.SET_MAX_PRICE_FILTER;
  maxPrice: number;
}

export const setMaxPriceFilter = (maxPrice: number): ISetMaxPriceFilter => {
  return {
    type: actionTypes.SET_MAX_PRICE_FILTER,
    maxPrice,
  };
};

export interface ISetHotelNameFilter {
  type: actionTypes.SET_HOTEL_NAME_FILTER;
  hotelName: string;
}

export const setHotelNameFilter = (hotelName: string): ISetHotelNameFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "hotel_name",
  };
  if (!!hotelName) {
    trackEvent({
      eventName: APPLIED_HOTEL_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_HOTEL_NAME_FILTER,
    hotelName,
  };
};

export interface ISetFreeCancelFilter {
  type: actionTypes.SET_FREE_CANCEL_FILTER;
  freeCancel: boolean;
}

export const setFreeCancelFilter = (
  freeCancel: boolean
): ISetFreeCancelFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "free_cancel",
  };
  if (freeCancel) {
    trackEvent({
      eventName: APPLIED_HOTEL_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_FREE_CANCEL_FILTER,
    freeCancel,
  };
};

export interface ISetHotelsOnSaleFilter {
  type: actionTypes.SET_HOTELS_ON_SALE_FILTER;
  saleOnly: boolean;
}

export const setHotelsOnSaleFilter = (
  saleOnly: boolean
): ISetHotelsOnSaleFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "on_sale",
  };
  if (saleOnly) {
    trackEvent({
      eventName: APPLIED_HOTEL_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_HOTELS_ON_SALE_FILTER,
    saleOnly,
  };
};

export interface ISetHotelAvailabilitySortOption {
  type: actionTypes.SET_HOTEL_SORT_OPTION;
  sortOption: HotelAvailabilitySortOption;
}

export const setHotelAvailabilitySortOption = (
  sortOption: HotelAvailabilitySortOption
): ISetHotelAvailabilitySortOption => ({
  type: actionTypes.SET_HOTEL_SORT_OPTION,
  sortOption,
});

export interface ISetMapBound {
  type: actionTypes.SET_MAP_BOUND;
  mapBound: BoundingBox | null;
}

export const setMapBound = (mapBound: BoundingBox | null): ISetMapBound => ({
  type: actionTypes.SET_MAP_BOUND,
  mapBound,
});

export interface ISetLodgingIdInFocus {
  type: actionTypes.SET_LODGING_ID_IN_FOCUS;
  lodgingId: LodgingId | null;
}

export const setLodgingIdInFocus = (
  lodgingId: LodgingId | null
): ISetLodgingIdInFocus => ({
  type: actionTypes.SET_LODGING_ID_IN_FOCUS,
  lodgingId,
});

export interface ISetLodgingIdHovered {
  type: actionTypes.SET_LODGING_ID_HOVERED;
  lodgingId: LodgingId | null;
}

export const setLodgingIdHovered = (
  lodgingId: LodgingId | null
): ISetLodgingIdHovered => ({
  type: actionTypes.SET_LODGING_ID_HOVERED,
  lodgingId,
});

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetSearchedDates {
  type: actionTypes.SET_SEARCHED_DATES;
  searchedFromDate: Date;
  searchedUntilDate: Date;
}

export const setSearchedDates = (
  searchedFromDate: Date,
  searchedUntilDate: Date
): ISetSearchedDates => ({
  type: actionTypes.SET_SEARCHED_DATES,
  searchedFromDate,
  searchedUntilDate,
});

export interface ISetSearchedLocationResult {
  type: actionTypes.SET_SEARCHED_LOCATION_RESULT;
  searchedLocationResult: IResult | null;
}

export const setSearchedLocationResult = (
  searchedLocationResult: IResult | null
): ISetSearchedLocationResult => ({
  type: actionTypes.SET_SEARCHED_LOCATION_RESULT,
  searchedLocationResult,
});

export interface ISetHasViewedUnavailableHotel {
  type: actionTypes.SET_HAS_VIEWED_UNAVAILABLE_HOTEL;
}

export const setHasViewedUnavailableHotel = () => ({
  type: actionTypes.SET_HAS_VIEWED_UNAVAILABLE_HOTEL,
});

export interface ISetSelectedLodgingIndex {
  type: actionTypes.SET_SELECTED_LODGING_INDEX;
  index: number;
}

export const setSelectedLodgingIndex = (index: number) => ({
  type: actionTypes.SET_SELECTED_LODGING_INDEX,
  index,
});

export interface ISetSearchedOccupancyCounts {
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS;
  counts: Omit<GuestsSelection, "rooms">;
}

export const setSearchedOccupancyCounts = (
  counts: Omit<GuestsSelection, "rooms">
): ISetSearchedOccupancyCounts => ({
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS,
  counts,
});

export interface ISetSearchedRoomsCount {
  type: actionTypes.SET_SEARCHED_ROOMS_COUNT;
  rooms: number;
}

export const setSearchedRoomsCount = (
  rooms: number
): ISetSearchedRoomsCount => ({
  type: actionTypes.SET_SEARCHED_ROOMS_COUNT,
  rooms,
});

export interface ISetSearchedPetsCount {
  type: actionTypes.SET_SEARCHED_PETS_COUNT;
  pets: number;
}

export const setSearchedPetsCount = (
  pets: number
): ISetSearchedPetsCount => ({
  type: actionTypes.SET_SEARCHED_PETS_COUNT,
  pets,
});

export interface ISetHotelAvailEntryPoint {
  type: actionTypes.SET_HOTEL_AVAIL_ENTRY_POINT;
  entryPoint?: HotelEntryTypeEnum;
}

export const setHotelAvailEntryPoint = (
  entryPoint?: HotelEntryTypeEnum
): ISetHotelAvailEntryPoint => ({
  type: actionTypes.SET_HOTEL_AVAIL_ENTRY_POINT,
  entryPoint,
});

export interface IFetchViewHotelsNearLocationCategories {
  type: actionTypes.FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES;
  queryString: string;
}

export const fetchViewHotelsNearLocationCategories = (
  queryString: string
): IFetchViewHotelsNearLocationCategories => ({
  type: actionTypes.FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES,
  queryString,
});

export interface ISetViewHotelsNearLocationCategories {
  type: actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES;
  categories: ICategorizedResponse[];
}

export const setViewHotelsNearLocationCategories = (
  categories: ICategorizedResponse[]
): ISetViewHotelsNearLocationCategories => ({
  type: actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES,
  categories,
});

export interface ISetViewHotelsNearLocation {
  type: actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION;
  location: IResult | null;
}

export const setViewHotelsNearLocation = (
  location: IResult | null
): ISetViewHotelsNearLocation => ({
  type: actionTypes.SET_VIEW_HOTELS_NEAR_LOCATION,
  location,
});

// Corporate Travel
export interface ISetLoyaltyProgramsFilter {
  type: actionTypes.SET_LOYALTY_PROGRAMS_FILTER;
  loyaltyPrograms: string[];
}

export const setLoyaltyProgramsFilter = (
  loyaltyPrograms: string[]
): ISetLoyaltyProgramsFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "loyalty_programs",
  };
  trackEvent({
    eventName: APPLIED_HOTEL_FILTER,
    properties,
  });

  return {
    type: actionTypes.SET_LOYALTY_PROGRAMS_FILTER,
    loyaltyPrograms,
  };
};

export interface ISetPolicyFilter {
  type: actionTypes.SET_POLICY_FILTER;
  isInPolicy: boolean;
}

export const setPolicyFilter = (isInPolicy: boolean) => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "policy_status",
  };
  trackEvent({
    eventName: APPLIED_HOTEL_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_POLICY_FILTER,
    isInPolicy,
  };
};

export const fetchUserHotelPreferences = (): IFetchUserHotelPreferences => ({
  type: actionTypes.FETCH_USER_HOTEL_PREFERENCES,
});

export interface IFetchUserHotelPreferences {
  type: actionTypes.FETCH_USER_HOTEL_PREFERENCES;
}

export const setUserHotelPreferences = (args: {
  hotelPreferences: UserHotelPreferencesPayload;
}): ISetUserHotelPreferences => ({
  type: actionTypes.SET_USER_HOTEL_PREFERENCES,
  hotelPreferences: args.hotelPreferences,
});

export interface ISetUserHotelPreferences {
  type: actionTypes.SET_USER_HOTEL_PREFERENCES;
  hotelPreferences: UserHotelPreferencesPayload;
}

export const setUserHotelPreferencesCallStateFailed =
  (): ISetUserHotelPreferencesCallStateFailed => ({
    type: actionTypes.SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED,
  });

export interface ISetUserHotelPreferencesCallStateFailed {
  type: actionTypes.SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED;
}

export interface ISetIsPremierCollectionEnabled {
  type: actionTypes.SET_IS_PREMIER_COLLECTION_ENABLED;
  isEnabled: boolean;
}

export const setIsPremierCollectionEnabled = (
  isEnabled: boolean
): ISetIsPremierCollectionEnabled => ({
  type: actionTypes.SET_IS_PREMIER_COLLECTION_ENABLED,
  isEnabled,
});

export interface ISetPremiumStaysFilter {
  type: actionTypes.SET_PREMIUM_STAYS_FILTER;
  showPremiumStaysOnly: boolean;
}

export const setPremiumStaysOnly = (
  showPremiumStaysOnly: boolean,
  properties?: Omit<AppliedPremiumStaysToggleProperties, "filter_type">
): ISetPremiumStaysFilter => {
  if (showPremiumStaysOnly && properties != null) {
    trackEvent({
      eventName: APPLIED_HOTEL_FILTER,
      properties: { filter_type: "premium_stays", ...properties },
    });
  }
  return {
    type: actionTypes.SET_PREMIUM_STAYS_FILTER,
    showPremiumStaysOnly,
  };
};

export interface ISetStayTypesFilter {
  type: actionTypes.SET_STAY_TYPES_FILTER;
  stayTypes: StayType[];
}

export const setStayTypeFilter = (
  stayTypes: StayType[]
): ISetStayTypesFilter => {
  const properties: AppliedHotelFilterProperties = {
    filter_type: "stay_types",
  };
  if (stayTypes.length > 0) {
    trackEvent({
      eventName: APPLIED_HOTEL_FILTER,
      properties,
    });
  }
  return {
    type: actionTypes.SET_STAY_TYPES_FILTER,
    stayTypes: stayTypes,
  };
}

export const resetHotelAvailabilityCallState =
  (): IResetHotelAvailabilityCallState => ({
    type: actionTypes.RESET_HOTEL_AVAILABILITY_CALL_STATE,
  });

export interface IResetHotelAvailabilityCallState {
  type: actionTypes.RESET_HOTEL_AVAILABILITY_CALL_STATE;
}

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

export type HotelAvailabilityActions =
  | IFetchHotelAvailability
  | ISetHotelAvailabilityResults
  | ISetHotelAvailabilityCallStateFailed
  | ISetAmenitiesFilter
  | ISetLoyaltyProgramsFilter
  | ISetStarRatingsFilter
  | ISetStayTypesFilter
  | ISetMaxPriceFilter
  | ISetHotelNameFilter
  | ISetHotelAvailabilitySortOption
  | ISetMapBound
  | ISetOpenDatesModal
  | ISetLodgingIdInFocus
  | ISetLodgingIdHovered
  | ISetSearchedDates
  | ISetSearchedLocationResult
  | ISetHasViewedUnavailableHotel
  | ISetSelectedLodgingIndex
  | ISetSearchedOccupancyCounts
  | ISetSearchedRoomsCount
  | ISetSearchedPetsCount
  | ISetHotelAvailEntryPoint
  | ISetFreeCancelFilter
  | ISetHotelsOnSaleFilter
  | IFetchViewHotelsNearLocationCategories
  | ISetViewHotelsNearLocation
  | ISetPolicyFilter
  | ISetViewHotelsNearLocationCategories
  | IFetchUserHotelPreferences
  | ISetUserHotelPreferences
  | ISetUserHotelPreferencesCallStateFailed
  | ISetIsPremierCollectionEnabled
  | ISetPremiumStaysFilter
  | IResetHotelAvailabilityCallState
  | IListPaymentMethods
  | ISetPaymentMethods
  | ISetPaymentMethodsCallStateFailed;
