import { FiatPrice, RewardsPrice } from "redmond";
import { getRewardText, getTotalPriceText, twoDecimalFormatter } from "halifax";

export const TOTAL_TEXT = (fiat: FiatPrice, reward: RewardsPrice | undefined) =>
  `Freeze total: <strong>${getTotalPriceText({
    price: fiat,
    priceFormatter: twoDecimalFormatter,
  })}</strong>${
    reward
      ? ` / ${getRewardText({
          reward,
        })}`
      : ""
  }`;
