import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingInProgressModal } from "./component";
import {
  bookingInProgressSelector,
  getConfirmationDetails,
  getPollPriceQuoteCallState,
  getSchedulePriceQuoteCallState,
  hotelBookTypeSelector,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { CallState } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  bookingInProgress: bookingInProgressSelector(state),
  priceQuoteInProgress:
    getSchedulePriceQuoteCallState(state) === CallState.InProcess ||
    getPollPriceQuoteCallState(state) === CallState.InProcess,
  reservation: getConfirmationDetails(state),
  hotelBookType: hotelBookTypeSelector(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingInProgressModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingInProgressModal = withRouter(
  connector(BookingInProgressModal)
);
