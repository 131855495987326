import { put } from "redux-saga/effects";
import {
  IFetchPriceDropEligibility,
  setPriceDropEligibility,
  setFetchPriceDropEligibilityCallStateFailed,
} from "../actions/actions";
import Logger from "../../../utils/logger";
import {
  PriceDropSmokeEligibilityEnum,
  PriceDropSmokeEligibilityResponse,
} from "redmond";
import { fetchPriceDropEligibility } from "../../../api/v1/ancillary/fetchPriceDropEligibility";

export function* fetchPriceDropEligibilitySaga(_: IFetchPriceDropEligibility) {
  try {
    const response: PriceDropSmokeEligibilityResponse =
      yield fetchPriceDropEligibility();

    if (
      response.eligibility &&
      response.eligibility.PriceDropSmokeEligibility ===
        PriceDropSmokeEligibilityEnum.PriceDropEligible
    ) {
      yield put(
        setPriceDropEligibility({
          eligible: true,
          amount: response.eligibility.amount,
        })
      );
    } else {
      yield put(
        setPriceDropEligibility({
          eligible: false,
          amount: { amount: 0, currency: "USD" },
        })
      );
    }
  } catch (e) {
    yield put(setFetchPriceDropEligibilityCallStateFailed());
    Logger.debug(e);
  }
}
