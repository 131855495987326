import {
  HotelBookType,
  HOTEL_BOOK_TYPE,
  PRICE_FREEZE_ID_QUERY_PARAM,
} from "redmond";

import * as H from "history";
import queryStringParser from "query-string";

interface IHotelBookParsedQuery {
  [HOTEL_BOOK_TYPE]: HotelBookType;
}

interface IHotelBookDefaultParsedQuery extends IHotelBookParsedQuery {
  [HOTEL_BOOK_TYPE]: HotelBookType.DEFAULT;
}

interface IHotelBookPriceFreezeExerciseParsedQuery
  extends IHotelBookParsedQuery {
  [PRICE_FREEZE_ID_QUERY_PARAM]: string;
  [HOTEL_BOOK_TYPE]: HotelBookType.PRICE_FREEZE_EXERCISE;
}

interface IHotelBookPriceFreezePurchaseParsedQuery
  extends IHotelBookParsedQuery {
  [HOTEL_BOOK_TYPE]: HotelBookType.PRICE_FREEZE_PURCHASE;
}

export type HotelBookParsedQuery =
  | IHotelBookDefaultParsedQuery
  | IHotelBookPriceFreezeExerciseParsedQuery
  | IHotelBookPriceFreezePurchaseParsedQuery;

export const parseQueryString = (history: H.History): HotelBookParsedQuery => {
  const queryString = history?.location?.search || "";
  return parseQueryFromString(queryString);
};

export const parseQueryFromString = (
  queryString: string
): HotelBookParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  return {
    [PRICE_FREEZE_ID_QUERY_PARAM]: parsedQueryStringPrimitive[
      PRICE_FREEZE_ID_QUERY_PARAM
    ] as string,
    [HOTEL_BOOK_TYPE]: parsedQueryStringPrimitive[
      HOTEL_BOOK_TYPE
    ] as HotelBookType,
  };
};
