import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { isCorpTenant } from "@capone/common";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import {
  getUntilDate,
  getFromDate,
  getNightCount,
  getRoomsCount,
  getAdultsCount,
  getChildrenCount,
} from "../../../search/reducer";
import {
  getHotelShopRoomInfoProducts,
  getHotelShopCallState,
  getHotelShopSelectedLodging,
  getHotelShopChosenProduct,
  getHotelShopChosenRoomInfoIndex,
  getHotelShopChosenProductIndex,
  getHotelShopChosenRoomInfo,
} from "../../reducer";
import { HotelShopCallState } from "../../reducer/state";
import { DesktopRoomPickerRedesign } from "./component";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import {
  isFintechHotelUpdatedUxEnabledSelector,
  isHotelDisplayRoomPricingUpdateEnabledSelector,
  isHotelCfarModelV1EnabledSelector,
  isHotelCfarModelV1RefundableRoomUpdatedUXSelector,
  isHotelCfarModelV1RefundableRoomUpdatedCopySelector,
} from "../../../ancillary/reducer";
import { config } from "../../../../api/config";

const mapStateToProps = (state: IStoreState) => {
  const checkinDate = getFromDate(state);
  const checkoutDate = getUntilDate(state);
  const chosenRoomInfoIndex = getHotelShopChosenRoomInfoIndex(state);
  const chosenProductIndex = getHotelShopChosenProductIndex(state);
  const chosenRoomInfo = getHotelShopChosenRoomInfo(state);
  const chosenProduct = getHotelShopChosenProduct(state);
  const selectedLodging = getHotelShopSelectedLodging(state);
  const nightCount = getNightCount(state);
  const rewardsKey =
    getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined;
  const roomInfoProducts = getHotelShopRoomInfoProducts(state);
  const isHotelShopLoading =
    getHotelShopCallState(state) === HotelShopCallState.InProcess;
  const roomsCount = getRoomsCount(state);
  const adultsCount = getAdultsCount(state);
  const children = getChildrenCount(state);
  const rooms = getRoomsCount(state);
  const largestValueAccount = getRewardsAccountWithLargestEarnForBanner(state);
  const isHotelDisplayRoomPricingUpdateEnabled =
    isHotelDisplayRoomPricingUpdateEnabledSelector(state);
  const isHotelCfarModelV1Enabled = isHotelCfarModelV1EnabledSelector(state);
  const isHotelCfarModelV1RefundableRoomUpdatedUX =
    isHotelCfarModelV1RefundableRoomUpdatedUXSelector(state);
  const isHotelCfarModelV1RefundableRoomUpdatedCopy =
    isHotelCfarModelV1RefundableRoomUpdatedCopySelector(state);
  const canEarnRewards =
    !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state);
  return {
    checkinDate,
    checkoutDate,
    chosenRoomInfoIndex,
    chosenProductIndex,
    chosenRoomInfo,
    chosenProduct,
    selectedLodging,
    nightCount,
    rewardsKey,
    roomInfoProducts,
    isHotelShopLoading,
    roomsCount,
    adultsCount,
    children,
    rooms,
    largestValueAccount,
    isFintechHotelUpdatedUxEnabled:
      isFintechHotelUpdatedUxEnabledSelector(state),
    isHotelDisplayRoomPricingUpdateEnabled,
    isHotelCfarModelV1Enabled,
    isHotelCfarModelV1RefundableRoomUpdatedUX,
    isHotelCfarModelV1RefundableRoomUpdatedCopy,
    canEarnRewards,
  };
};

const mapDispatchToProps = {
  // TODO: Implement navigation to summary
  // seeHotelSummary: seeHotelSummary,
  selectRoomType: actions.selectRoomType,
  fetchHotelShop: actions.fetchHotelShop,
  setSelectedRoomId: actions.setSelectedRoomId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopRoomPickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDesktopRoomPickerPickerRedesign = withRouter(
  connector(DesktopRoomPickerRedesign)
);
