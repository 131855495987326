import React from "react";
import {
  ActionButton,
  useDeviceTypes,
  MobilePopoverCard,
  RadioDropdown,
  IconName,
  Icon,
} from "halifax";
import { Box, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

import { AvailabilitySortConnectorProps } from "./container";
import { HotelAvailabilitySortOption } from "../../reducer/state";
import * as textConstants from "./textConstants";
import "./styles.scss";

export interface IAvailabilitySortProps
  extends AvailabilitySortConnectorProps,
    RouteComponentProps {}

const sortOptions = [
  HotelAvailabilitySortOption.Recommended,
  HotelAvailabilitySortOption.Pricing,
  HotelAvailabilitySortOption.StarRating,
];

export const AvailabilitySort = (props: IAvailabilitySortProps) => {
  const { sortOption, setHotelAvailabilitySortOption } = props;

  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const renderDesktopView = () => {
    return (
      <RadioDropdown
        buttonClassName={clsx(
          "desktop-availability-sort-button",
          "b2b-shop-filter",
          "enhanced-hotel-filters"
        )}
        dropdownLabel={
          <>
            <strong>Sort:</strong> {sortOptionLabelMobile[sortOption]}
          </>
        }
        options={sortOptions.map((option) => {
          return {
            value: option,
            label: sortOptionLabelMobile[option],
          };
        })}
        setOption={(option) =>
          setHotelAvailabilitySortOption(HotelAvailabilitySortOption[option])
        }
        selected={sortOption}
        groupAriaLabel={textConstants.CHOOSE_SORT_OPTION_TEXT}
        buttonAriaLabel={textConstants.CHANGE_SORT_OPTION_TEXT}
        popoverClassName={clsx(
          "b2b",
          "hotel-availability-sort-popover",
          "enhanced-hotel-filters"
        )}
        dropdownIcon={<Icon name={IconName.Sort} />}
      />
    );
  };

  const onClose = () => setOpenModal(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHotelAvailabilitySortOption(
      (event.target as HTMLInputElement).value as HotelAvailabilitySortOption
    );
    onClose();
  };

  const renderMobileView = () => {
    return (
      <>
        <ActionButton
          className="mobile-availability-sort-button"
          defaultStyle="h4r-secondary"
          message={textConstants.SORT_TEXT}
          onClick={() => setOpenModal(true)}
        />
        <MobilePopoverCard
          open={openModal}
          className="mobile-availability-sort-popup"
          contentClassName="mobile-availability-sort-popup-container"
          onClose={onClose}
          centered={true}
        >
          <Box className="sort-option-selection-container">
            <Box className="header-container">{textConstants.SORT_TEXT}</Box>
            <Box className="selection-container">
              <RadioGroup
                className="sort-option-selection"
                value={sortOption}
                onChange={handleChange}
              >
                {renderRadios(sortOptions)}
              </RadioGroup>
            </Box>
          </Box>
        </MobilePopoverCard>
      </>
    );
  };

  return (
    <Box
      className={clsx("availability-sort-root", "enhanced-hotel-filters", {
        mobile: matchesMobile,
      })}
    >
      <Box className="availability-sort-container" tabIndex={0}>
        {matchesDesktop && renderDesktopView()}
        {matchesMobile && renderMobileView()}
      </Box>
    </Box>
  );
};

const sortOptionLabelMobile: {
  [key in HotelAvailabilitySortOption]: string;
} = {
  Recommended: textConstants.RECOMMENDED_TEXT,
  StarRating: textConstants.RATING_TEXT,
  Pricing: textConstants.PRICE_PER_NIGHT_TEXT,
};

const renderRadios = (options: HotelAvailabilitySortOption[]) =>
  options.map((option) => (
    <FormControlLabel
      className="sort-option"
      key={option}
      value={option}
      label={
        <span className="sort-option-label">
          {sortOptionLabelMobile[option]}
        </span>
      }
      labelPlacement="start"
      control={<Radio className="sort-option-radio" />}
    />
  ));
