import { Reservation } from "redmond";
import { hotelLoyaltyApiPrefix } from "../../paths";
import axios from "axios";
import { config } from "../../../config";

interface LoyaltyKustomerTicketRequest {
  loyaltyNumber: string;
  reservation: Reservation;
}

const END_POINT = `${hotelLoyaltyApiPrefix}/send`;

export const submitLoyaltyKustomerTicket = async (
  req: LoyaltyKustomerTicketRequest
): Promise<any> => {
  try {
    const res = await axios.post(END_POINT, req, {
      baseURL: config.baseURL,
    });

    return res.data;
  } catch (e) {
    throw e;
  }
};
