import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  closeSession,
  resetBook,
  setHotelBookType,
  fetchApplicableTravelWalletItems,
} from "./actions/actions";
import {
  fetchHotelPriceFreezeDetails,
  resetHotelPriceFreezeExercise,
} from "../freeze/actions/actions";
import { fetchTravelWalletCreditHistory } from "../travel-wallet/actions/actions";

import { IStoreState } from "../../reducers/types";
import { HotelBook } from "./component";
import {
  getReviewHotelDetailsCheckoutProperties,
  getHotelBookChosenProduct,
  getHotelBookSelectedAvailability,
  hotelBookTypeSelector,
  getPriceQuote,
  getFromDateInBook,
  getUntilDateInBook,
  getHotelTripTotalInPrices,
} from "./reducer";
import {
  hotelCfarQuoteFromChosenRoomProductSelector,
  additionalInfoFromChosenRoomProductSelector,
  getHotelCfarOfferChoicePropertiesSelector,
} from "../shop/reducer/selectors";
import {
  fetchHotelPriceFreezeDetailsCallStateSelector,
  getHotelCheckoutPriceFreezeExercisePropertiesSelector,
  hotelPriceFreezeVoucherStateSelector,
} from "../freeze/reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  return {
    chosenProduct: getHotelBookChosenProduct(state),
    selectedLodging: getHotelBookSelectedAvailability(state),
    reviewHotelDetailsProperties:
      getReviewHotelDetailsCheckoutProperties(state),
    hotelCfarQuote: hotelCfarQuoteFromChosenRoomProductSelector(state),
    additionalInfo: additionalInfoFromChosenRoomProductSelector(state),
    getHotelCfarOfferChoiceProperties:
      getHotelCfarOfferChoicePropertiesSelector(state),
    hotelBookType: hotelBookTypeSelector(state),
    fetchHotelPriceFreezeDetailsCallState:
      fetchHotelPriceFreezeDetailsCallStateSelector(state),
    voucherState: hotelPriceFreezeVoucherStateSelector(state),
    getHotelCheckoutPriceFreezeExerciseProperties:
      getHotelCheckoutPriceFreezeExercisePropertiesSelector(state),
    priceQuote: getPriceQuote(state),
    checkInDate: getFromDateInBook(state),
    checkOutDate: getUntilDateInBook(state),
    tripTotal: getHotelTripTotalInPrices(state),
  };
};

const mapDispatchToProps = {
  closeSession,
  resetBook,
  setHotelBookType,
  fetchHotelPriceFreezeDetails,
  resetHotelPriceFreezeExercise,
  fetchApplicableTravelWalletItems,
  fetchTravelWalletCreditHistory,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelBookConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelBook = withRouter(connector(HotelBook));
