import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { getHotelQueryParams } from "../../../availability/reducer";
import {
  getRewardsAccounts,
  getSelectedAccountReferenceId,
  getRewardsAccountWithLargestEarnForBanner,
} from "../../../rewards/reducer";
import { getNightCount, getPetsCount, getRoomsCount } from "../../../search/reducer";
import {
  fetchDistanceFromLocationCategories,
  fetchNearbyLodgings,
  setDistanceFromLocation,
  setNearbyLodgings,
} from "../../actions/actions";

import {
  getDistanceFromLocation,
  getNearbyLodgings,
  getNearbyLodgingsAvailabilityCallState,
  getSearchDistanceFromShopProperties,
} from "../../reducer/selectors";
import { HotelShopExpandedMap } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    rewardsAccountId: getSelectedAccountReferenceId(state) || "",
    distanceFromLocation: getDistanceFromLocation(state),
    searchDistanceFromShopProperties:
      getSearchDistanceFromShopProperties(state),
    nearbyLodgings: getNearbyLodgings(state),
    nightCount: getNightCount(state),
    roomsCount: getRoomsCount(state),
    petsCount: getPetsCount(state),
    hotelQueryParams: getHotelQueryParams(state),
    fetchNearbyLodgingsCallState: getNearbyLodgingsAvailabilityCallState(state),
    isVentureX: !!getRewardsAccounts(state).find((acc) =>
      acc.productDisplayName.includes("Venture X")
    ),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  };
};

export const mapDispatchToProps = {
  setDistanceFromLocation,
  fetchDistanceFromLocationCategories,
  fetchNearbyLodgings,
  setNearbyLodgings,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelShopExpandedMapConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelShopExpandedMap = connector(HotelShopExpandedMap);
