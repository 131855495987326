import { connect, ConnectedProps } from "react-redux";
import { HotelSummaryPanel } from "halifax";
import {
  getHotelBookSelectedAvailability,
  getFromDateInBook,
  getUntilDateInBook,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedLodging: getHotelBookSelectedAvailability(state),
    checkIn: getFromDateInBook(state),
    checkOut: getUntilDateInBook(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelBookSummaryPanelConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelBookSummaryPanel = connector(HotelSummaryPanel);
