import React from "react";
import { Box, Dialog } from "@material-ui/core";
import {
  PriceFreeze,
  useDeviceTypes,
  MobilePopoverCard,
  PriceFreezeImage,
  PriceFreezeImageMobile,
  Icon,
  IconName,
} from "halifax";
import { PATH_HOTEL_PRICE_FREEZE_TERMS } from "../../../../utils/paths";
import { HOTEL_PRICE_FREEZE_BODY } from "redmond";
import * as constants from "./constants";
import "./styles.scss";

export interface IPriceFreezeExplanationModalProps {
  priceFreezeExplanationModalOpen: boolean;
  setPriceFreezeExplanationModalOpen: (bool: boolean) => void;
  priceFreezeExplanationModalTitles: {
    header: string;
    title: string;
    increaseText: string;
    decreaseText: string;
    refundText: string;
    ctaText: string;
  };
  onContinue: () => void;
}

export const PriceFreezeExplanationModal = (
  props: IPriceFreezeExplanationModalProps
) => {
  const {
    priceFreezeExplanationModalOpen,
    setPriceFreezeExplanationModalOpen,
    priceFreezeExplanationModalTitles,
    onContinue,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const handleContinue = () => {
    onContinue();
    setPriceFreezeExplanationModalOpen(false);
  };
  const priceFreezeInfo = (
    <Dialog
      open={priceFreezeExplanationModalOpen}
      /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
      TransitionProps={{ role: "none" } as never}
      PaperProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
      }}
      onClose={() => setPriceFreezeExplanationModalOpen(false)}
      className="price-freeze-explanation-modal"
    >
      <Box className="price-freeze-wrapper">
        <PriceFreeze
          {...priceFreezeExplanationModalTitles}
          onContinue={handleContinue}
          image={PriceFreezeImage}
          onClose={() => setPriceFreezeExplanationModalOpen(false)}
          onClick={() =>
            window.open(`${PATH_HOTEL_PRICE_FREEZE_TERMS}`, "_blank")
          }
          continueCTAText={constants.CONTINUE_CTA_TEXT}
          continueCTAIcon={<Icon name={IconName.B2bPriceFreezeMedium} />}
          continueCTAIconPosition="left"
          className="price-freeze-card"
        />
      </Box>
    </Dialog>
  );
  const priceFreezeInfoMobile = (
    <MobilePopoverCard open={priceFreezeExplanationModalOpen}>
      <Box>
        <PriceFreeze
          {...priceFreezeExplanationModalTitles}
          isMobile={true}
          image={PriceFreezeImageMobile}
          onClose={() => setPriceFreezeExplanationModalOpen(false)}
          onContinue={onContinue}
          continueCTAText={constants.CONTINUE_CTA_TEXT_MOBILE}
          mobileTermsConditionCopy={HOTEL_PRICE_FREEZE_BODY}
          className="price-freeze-card"
        />
      </Box>
    </MobilePopoverCard>
  );

  return matchesMobile ? priceFreezeInfoMobile : priceFreezeInfo;
};
