import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import submitForApproval from "../../../../api/v0/book/book-flow/submitForApproval";
import {
  ConnectionResultEnum,
  InvalidEnum,
  TRIP_REQUESTED_HOTEL,
} from "redmond";
import { getCompleteBuyHotelProperties, getSession } from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export function* submitForApprovalSaga({
  payload,
}: actions.ISetSubmitForApproval) {
  try {
    const state: IStoreState = yield select();
    const completeBuyHotelProperties = getCompleteBuyHotelProperties(state);
    const session = getSession(state);
    if (!session) {
      throw Error("Session is undefined");
    }

    yield submitForApproval(payload);
    trackEvent({
      eventName: TRIP_REQUESTED_HOTEL,
      properties: {
        ...completeBuyHotelProperties.properties,
        success: true,
        added_hotel_loyalty: Boolean(payload.loyaltyNumber),
      },
      encryptedProperties: [...completeBuyHotelProperties.encryptedProperties],
    });

    yield put(actions.setSubmitForApprovalSuccess());
  } catch (e) {
    const state: IStoreState = yield select();
    const completeBuyHotelProperties = getCompleteBuyHotelProperties(state);

    trackEvent({
      eventName: TRIP_REQUESTED_HOTEL,
      properties: {
        ...completeBuyHotelProperties.properties,
        success: false,
      },
      encryptedProperties: [...completeBuyHotelProperties.encryptedProperties],
    });
    yield put(
      actions.setSubmitForApprovalFailure({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
