import axios from "axios";
import { config } from "../../config";

import {
  AvailabilityRequest,
  AvailabilityResponse,
} from "redmond";
import { staysAvailabilityApiPrefix } from "../paths";

export const fetchStaysAvailability = (
  body: AvailabilityRequest
): Promise<AvailabilityResponse> =>
  new Promise(async (resolve, reject) => {
    const payload = 
      {...body, ...{
        "searchRefinementOptions": {
          "sort": "MostRecommended",
          "stayTypes": ["Hotels", "Homes"]
        }
      }
    }

    try {
      const res = await axios.post(staysAvailabilityApiPrefix, payload, {
        baseURL: config.baseURL,
      });
      
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
