export const LOCATION_AUTOCOMPLETE_LABEL = "Where are you staying?";
export const LOCATION_AUTOCOMPLETE_PLACEHOLDER = "Where are you staying?";
export const SEARCH = "Search Hotels";
export const SEARCH_STAYS = "Search Stays";
export const MISSING_INFO_SEARCH_ERROR =
  "To continue, please complete all required fields.";

export const HOTEL_PREFERENCES_INFO_PROFILE_CTA_TEXT =
  "Add hotel preferences to your travel profile";
export const HOTEL_PREFERENCES_WITH_SAVED_PROFILE_CTA_TEXT =
  "Manage hotel preferences";
