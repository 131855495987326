import React, { useContext, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router";

import { PriceFreezePurchaseConnectorProps } from "./container";
import { DesktopHotelFreezeWorkflow } from "./components";
import { parseQueryString } from "../../utils/parseQueryString";
import { goToShop } from "../../../shop/utils/queryStringHelpers";
import { ClientContext } from "../../../../App";
import { PORTAL_TITLE } from "../../../../lang/textConstants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  HotelBookType,
  HOTEL_PRICE_FREEZE_REVIEW_DETAILS,
  PRICE_FREEZE_ENTRY,
  getHotelPriceFreezePurchaseOfferProperties,
} from "redmond";
import { MobileHotelFreezeWorkflow } from "./components/MobileHotelFreezeWorkflow";
import {
  BookingErrorModal,
  BookingInProgressModal,
} from "../../../book/components";
import { PATH_FREEZE_CONFIRMATION } from "../../../../utils/paths";

export interface IPriceFreezePurchaseProps
  extends RouteComponentProps,
    PriceFreezePurchaseConnectorProps {}

export const PriceFreezePurchase = (props: IPriceFreezePurchaseProps) => {
  const {
    history,
    location,
    chosenProduct,
    selectedLodging,
    selectedPriceFreezeOffer,
    confirmationEmail,
    // lodgingId,
    setContactInfo,
    // resetPaymentCardSelectedAccounts,
    schedulePriceQuote,
    setHotelBookType,
    setHotelPriceFreezePurchaseEntry,
    hotelPriceFreezeConfirmationDetails,
  } = props;
  const clientContext = useContext(ClientContext);
  const { sessionInfo } = clientContext;
  const { matchesDesktop, matchesMobile } = useDeviceTypes();

  useEffect(() => {
    setHotelBookType(HotelBookType.PRICE_FREEZE_PURCHASE);
  }, []);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [location.pathname]);

  useEffect(() => {
    const hotelBookParsedQuery = parseQueryString(history);
    const priceFreezeEntry = hotelBookParsedQuery[PRICE_FREEZE_ENTRY];

    if (priceFreezeEntry) {
      setHotelPriceFreezePurchaseEntry(priceFreezeEntry);
    }

    trackEvent({
      eventName: HOTEL_PRICE_FREEZE_REVIEW_DETAILS,
      properties: {
        price_freeze_entry: priceFreezeEntry,
        ...getHotelPriceFreezePurchaseOfferProperties(selectedPriceFreezeOffer),
      },
    });
  }, []);

  useEffect(() => {
    if (!chosenProduct || !selectedLodging || !selectedPriceFreezeOffer) {
      goToShop({ history });
    }
  }, []);

  useEffect(() => {
    setContactInfo(sessionInfo?.userInfo?.email || "", "");
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, [history]);

  useEffect(() => {
    if (!!selectedPriceFreezeOffer?.id && confirmationEmail) {
      schedulePriceQuote({
        history,
        pollQuoteOnly: true,
      });
    }
  }, [selectedPriceFreezeOffer?.id, confirmationEmail]);

  useEffect(() => {
    if (hotelPriceFreezeConfirmationDetails) {
      history.push(PATH_FREEZE_CONFIRMATION);
    }
  }, [hotelPriceFreezeConfirmationDetails]);

  return (
    <Box className="price-freeze-purchase-root">
      <Box className="price-freeze-purchase-container">
        {matchesDesktop && <DesktopHotelFreezeWorkflow />}
        {matchesMobile && <MobileHotelFreezeWorkflow />}
        <BookingErrorModal />
        <BookingInProgressModal />
      </Box>
    </Box>
  );
};
