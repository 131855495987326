import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import { setContactInfo } from "../../../../../book/actions/actions";
import { HotelFreezeContactInfoForm } from "./component";
import {
  getConfirmationEmail,
  getConfirmationPhoneNumber,
} from "../../../../../book/reducer";
import { getHotelShopChosenPriceFreezeOffer } from "../../../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    contactInfo: {
      email: getConfirmationEmail(state) ?? "",
      phone: getConfirmationPhoneNumber(state) ?? "",
    },
    priceFreezeOffer: getHotelShopChosenPriceFreezeOffer(state),
  };
};

const mapDispatchToProps = {
  setContactInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelFreezeContactInfoFormConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelFreezeContactInfoForm = withRouter(
  connector(HotelFreezeContactInfoForm)
);
