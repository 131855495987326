import {
  HotelPricing,
  LodgingPriceFreezeGetCreditsStatement,
  PaymentAmountEnum,
} from "redmond";

// note: in the future, the BE is expected to return an updated price quote (once shopping cart integration is ready)
export const getHotelPricingWithCreditsStatementApplied = ({
  hotelPricing,
  getCreditsStatement,
}: {
  hotelPricing: HotelPricing;
  getCreditsStatement: LodgingPriceFreezeGetCreditsStatement;
}): HotelPricing => {
  const {
    taxesCharge,
    subtotalWithSavingsCreditCharge,
    payNowWithTotalCreditCharge,
    tripTotalWithTotalCreditCharge,
  } = getCreditsStatement;

  return {
    ...hotelPricing,
    rooms: subtotalWithSavingsCreditCharge,
    taxes: taxesCharge,
    taxBreakdown: {
      salesTax: taxesCharge,
    },
    subtotal: subtotalWithSavingsCreditCharge,
    grandTotal: {
      currency: payNowWithTotalCreditCharge.fiat.currencyCode,
      amount: payNowWithTotalCreditCharge.fiat.value,
      PaymentAmount: PaymentAmountEnum.FiatAmount,
    },
    payNowTotal: payNowWithTotalCreditCharge,
    tripTotal: tripTotalWithTotalCreditCharge,
  };
};
