import { Box, Typography } from "@material-ui/core";
import * as H from "history";
import React from "react";
import {
  IIdLodgings,
  LAUNCHED_MOBILE_CALENDAR,
  LaunchedMobileCalendarProperties,
} from "redmond";

import clsx from "clsx";
import dayjs from "dayjs";
import { Icon, IconName } from "halifax";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import {
  PATH_AVAILABILITY,
  PATH_HOME_SEARCH,
  PATH_STAYS_AVAILABILITY,
} from "../../../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../../../shop/utils/queryStringHelpers";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../HotelSearchControl/components/MonthAndDatePicker";
import { HotelSearchButton } from "../../../HotelSearchControl/components/SearchButton";
import { MobileCalendarPickerConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  AVAILABLE,
  getExperimentVariant,
  STAYS_SEARCH,
  useExperiments,
} from "../../../../../../context/experiments";

export interface IMobileCalendarPickerProps
  extends MobileCalendarPickerConnectorProps {
  focusedMonthIndex?: number;
  onComplete?: (from: Date | null, until: Date | null) => void;
  showPricing?: boolean;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    fromDate,
    untilDate,
    location,
    adultsCount,
    children,
    setFromDate,
    setUntilDate,
    focusedMonthIndex,
    onComplete,
    roomsCount,
    showPricing,
  } = props;

  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  const expState = useExperiments();

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  React.useEffect(() => {
    if (window.location.pathname === PATH_HOME_SEARCH) {
      const properties: LaunchedMobileCalendarProperties = {
        funnel: "hotels",
        url: window.location.pathname,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_CALENDAR, properties });
    }
  }, []);

  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const handleClickDone = (history: H.History) => {
    setFromDate(from);
    setUntilDate(until);

    const path = staysSearchEnabled
      ? PATH_STAYS_AVAILABILITY
      : PATH_AVAILABILITY;

    history.push(
      `${path}${transformToStringifiedAvailabilityQuery(
        (location?.id as IIdLodgings).lodgingSelection.searchTerm,
        from,
        until,
        adultsCount,
        children,
        roomsCount,
        undefined
      )}`
    );

    onComplete?.(from, until);
  };

  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="hotel-mobile-calendar-picker-root redesign-v3">
      <MonthAndDatePicker
        className="b2b"
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setFromDate={setFrom}
        setUntilDate={setUntil}
        from={from}
        until={until}
        header={
          <Box className="hotel-mobile-calendar-header">
            <DatePickerHeader
              label={textConstants.CHECK_IN}
              value={from ? dayjs(from).format("ddd D, MMM") : undefined}
            />
            <DatePickerHeader
              label={textConstants.CHECK_OUT}
              value={until ? dayjs(until).format("ddd D, MMM") : undefined}
            />
          </Box>
        }
        showPricing={showPricing}
      />
      {from && until && (
        <HotelSearchButton
          className={clsx("mobile-calendar-picker-search-button", "b2b")}
          message={
            staysSearchEnabled
              ? textConstants.SEARCH_STAYS_TEXT
              : textConstants.SEARCH_TEXT
          }
          onClick={(history: H.History) => {
            handleClickDone(history);
          }}
          from={from}
          until={until}
        />
      )}
    </Box>
  );
};
