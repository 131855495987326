import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";
import { RouteComponentProps } from "react-router-dom";
import { PriceDropProtectionBannerConnectorProps } from "./container";
import {
  ActionLink,
  B2BButton,
  Icon,
  IconName,
  getPriceString,
  useDeviceTypes,
} from "halifax";
import {
  LEARN_MORE,
  PREMIER_AND_LIFESTYLE_COLLECTION_NOT_ELIGIBLE,
  PREMIER_COLLECTION_NOT_ELIGIBLE,
  PRICE_DROP_PROTECTION_BANNER_TEXT,
  PRICE_DROP_PROTECTION_SMALL_BANNER_TEXT,
} from "./constants";
import "./styles.scss";
import { CallState } from "redmond";

export interface IPriceDropProtectionBannerProps
  extends RouteComponentProps,
    PriceDropProtectionBannerConnectorProps {
  bannerVersion: "large" | "small";
  className?: string;
  showPremierCollectionText?: boolean;
  setOpenModal: (arg0: boolean) => void;
}

export const PriceDropProtectionBanner = (
  props: IPriceDropProtectionBannerProps
) => {
  const { matchesMobile } = useDeviceTypes();

  const {
    bannerVersion,
    className,
    userEligibleHotelPriceDrop,
    userPriceDropAmount,
    fetchPriceDropEligibilityCallState,
    fetchPriceDropEligibility,
    hotelPriceDropBannerPremierAndLifestyleEnabled,
    showPremierCollectionText = true,
    setOpenModal,
  } = props;

  useEffect(() => {
    if (fetchPriceDropEligibilityCallState === CallState.NotCalled) {
      fetchPriceDropEligibility();
    }
  }, [fetchPriceDropEligibilityCallState]);

  if (userEligibleHotelPriceDrop && userPriceDropAmount) {
    switch (bannerVersion) {
      case "large":
        return (
          <>
            <Box
              className={clsx("price-drop-protection-banner-root", className, {
                mobile: matchesMobile,
              })}
            >
              <Box className="price-drop-protection-banner-container">
                <Box className="price-drop-protection-banner-content">
                  <span className="pdp-banner-icon">
                    <Icon
                      name={
                        matchesMobile
                          ? IconName.PriceDropGreenWithoutCircle
                          : IconName.PriceDropGreen
                      }
                    />
                  </span>
                  <Box>
                    <Typography
                      className="pdp-banner-subtitle"
                      variant="subtitle2"
                      dangerouslySetInnerHTML={{
                        __html: PRICE_DROP_PROTECTION_BANNER_TEXT(
                          getPriceString({ price: userPriceDropAmount.amount })
                        ),
                      }}
                    ></Typography>
                    {showPremierCollectionText ? (
                      <Typography
                        className="pdp-subtitle-note"
                        variant="subtitle2"
                      >
                        {hotelPriceDropBannerPremierAndLifestyleEnabled
                          ? PREMIER_AND_LIFESTYLE_COLLECTION_NOT_ELIGIBLE
                          : PREMIER_COLLECTION_NOT_ELIGIBLE}
                      </Typography>
                    ) : null}
                    {matchesMobile && (
                      <ActionLink
                        content={
                          <Typography
                            className="pdp-cta-link-text"
                            variant="body1"
                          >
                            {LEARN_MORE}
                          </Typography>
                        }
                        onClick={() => {
                          setOpenModal(true);
                        }}
                        className="pdp-cta-link"
                      />
                    )}
                  </Box>
                </Box>
                <Box className="pdp-cta-container">
                  {!matchesMobile && (
                    <B2BButton
                      onClick={() => {
                        setOpenModal(true);
                      }}
                      className="pdp-cta"
                      variant="b2b"
                    >
                      <Typography className="pdp-cta-text" variant="button">
                        {LEARN_MORE}
                      </Typography>
                    </B2BButton>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        );

      case "small":
        return (
          <>
            <Box
              className={clsx(
                "price-drop-protection-small-banner-root",
                className,
                {
                  mobile: matchesMobile,
                }
              )}
            >
              <Box className="price-drop-protection-small-banner-container">
                <Box className="price-drop-protection-small-banner-content">
                  <Box>
                    <Typography
                      className="price-drop-protection-small-banner-banner-subtitle"
                      variant="subtitle2"
                      dangerouslySetInnerHTML={{
                        __html: PRICE_DROP_PROTECTION_SMALL_BANNER_TEXT,
                      }}
                    ></Typography>
                  </Box>
                </Box>
                <Box className="price-drop-protection-small-banner-cta-container">
                  <ActionLink
                    content={
                      <span className="price-drop-protection-small-banner-banner-icon">
                        <Icon name={IconName.InfoCircle} />
                      </span>
                    }
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    className="price-drop-protection-small-banner-cta-link"
                  />
                </Box>
              </Box>
            </Box>
          </>
        );
    }
  }

  return null;
};
