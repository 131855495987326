import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  fetchCalendarBuckets,
  setApplyUserHotelPreferences,
  setLocationAutocompleteError,
} from "../../actions/actions";
import {
  getApplyUserHotelPreferences,
  getHasLocationAutocompleteError,
  getLocation,
} from "../../reducer";
import { MobileHotelSearchControlV3 } from "./component";
import {
  getHasUserSetHotelPreferences,
  getUserHotelPreferencesCallState,
} from "../../../availability/reducer";

const mapDispatchToProps = {
  setLocationAutocompleteError,
  fetchCalendarBuckets,
  setApplyUserHotelPreferences,
};
const mapStateToProps = (state: IStoreState) => ({
  hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
  userHasSetHotelPreferences: getHasUserSetHotelPreferences(state),
  userHotelPreferencesCallState: getUserHotelPreferencesCallState(state),
  shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
  hasLocation: !!getLocation(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelSearchV3ConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelSearchControlV3 = withRouter(
  connector(MobileHotelSearchControlV3)
);
