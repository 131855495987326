import { Lodging, HotelStarRatingEnum } from "redmond";

const priceSortComparer = (l1: Lodging, l2: Lodging) => {
  if (!l1.price || !l2.price) {
    if (!l1.price && !l2.price) {
      return 0;
    } else if (!l1.price) {
      return -1;
    }

    return 1;
  }

  return l1.price.nightlyPrice.fiat.value - l2.price.nightlyPrice.fiat.value;
};

export const orderByPriceLowToHigh = (lodgings: Lodging[]) => {
  return lodgings.sort(priceSortComparer);
};

const starRatingIntValue: { [key in HotelStarRatingEnum]: number | null } = {
  NotAvailable: null,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
};

const starRatingSortComparer = (l1: Lodging, l2: Lodging) => {
  if (
    l1.lodging.starRating === HotelStarRatingEnum.NotAvailable ||
    l2.lodging.starRating === HotelStarRatingEnum.NotAvailable
  ) {
    if (
      l1.lodging.starRating === HotelStarRatingEnum.NotAvailable &&
      l2.lodging.starRating === HotelStarRatingEnum.NotAvailable
    ) {
      return 0;
    } else if (l1.lodging.starRating === HotelStarRatingEnum.NotAvailable) {
      return -1;
    }

    return 1;
  }

  return (
    (starRatingIntValue[l1.lodging.starRating] as number) -
    (starRatingIntValue[l2.lodging.starRating] as number)
  );
};

export const orderByStarRatingHighToLow = (lodgings: Lodging[]) => {
  return lodgings.sort((l1, l2) => -starRatingSortComparer(l1, l2));
};
