import clsx from "clsx";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { B2BTextField, Icon, IconName } from "halifax";
import { getHotelLoyaltyProgram } from "@capone/common";
import { IPerson } from "redmond";
import { getHotelShopChosenProductHotelLoyaltyProgramCode } from "../../../../shop/reducer/selectors";
import "./styles.scss";

export interface LoyaltyInformationFormProps {
  isMobile?: boolean;
  onChange: (value: string) => void;
  hasSaved?: boolean;
  disabled?: boolean;
  traveler: IPerson | undefined;
}

export const getLoyaltyNumberForChosenProduct = (
  loyaltyProgram: string | undefined,
  traveler: IPerson | undefined
) => {
  if (!loyaltyProgram || !traveler) {
    return null;
  }

  return traveler?.hotelLoyalty[loyaltyProgram]?.value ?? null;
};

export const LoyaltyInformationForm = ({
  isMobile,
  onChange,
  hasSaved,
  disabled,
  traveler,
}: LoyaltyInformationFormProps) => {
  const [value, setValue] = React.useState("");

  const loyaltyProgramCode = useSelector(
    getHotelShopChosenProductHotelLoyaltyProgramCode
  );
  const selectedTravelerLoyaltyNumber = getLoyaltyNumberForChosenProduct(
    loyaltyProgramCode,
    traveler
  );

  const loyaltyProgram = getHotelLoyaltyProgram(loyaltyProgramCode);

  useEffect(() => {
    setValue(selectedTravelerLoyaltyNumber || "");
  }, [selectedTravelerLoyaltyNumber]);

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return (
    <Box
      className={clsx("loyalty-info-form-container", {
        mobile: isMobile,
      })}
    >
      <Box className="loyalty-info-form-description">
        <label htmlFor="loyalty-email-input-field">
          <Typography className="title" component="span">
            Hotel loyalty information
            <Icon className="icon" name={IconName.StarCircledIcon} />
          </Typography>
        </label>
        <Typography className="subtitle" variant="body2">
          Please enter your loyalty program number carefully.
        </Typography>
      </Box>
      <div className="loyalty-info-form">
        <B2BTextField
          value={value}
          disabled={disabled}
          width="100%"
          className="loyalty-input-field"
          label={
            <Typography variant="body2">
              <strong>{loyaltyProgram?.loyaltyProgramName}</strong> Loyalty
              program number (Optional)
            </Typography>
          }
          onChange={handleChange}
          type="text"
          id="loyalty-email-input-field"
          endAdornment={
            hasSaved && (
              <Typography
                variant="inherit"
                className="loyalty-input-field-saved"
              >
                <Icon name={IconName.CheckMarkGreen} />
                Saved
              </Typography>
            )
          }
        />
      </div>
    </Box>
  );
};
