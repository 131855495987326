import axios from "axios";
import { config } from "../../config";

import { HotelPriceCalendarRequest, IResponse } from "redmond";
import { calendarHotelsPriceApi } from "../paths";

export const fetchCalendarBuckets = (
  body: HotelPriceCalendarRequest
): Promise<IResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(calendarHotelsPriceApi, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
