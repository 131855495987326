import clsx from "clsx";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import React, { useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  MODAL_ALERT,
  ModalButtonType,
  ModalCategoryType,
  ModalScreens,
} from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CONTROL,
  DESKTOP_RECENTLY_VIEWED_HOTELS,
  DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS,
  MOBILE_RECENTLY_VIEWED_HOTELS,
  MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS,
  RECENTLY_VIEWED_MASTER_V1,
  SIMILAR_HOTELS_EXPERIMENT,
  SIMILAR_HOTELS_VARIANTS,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { PATH_HOME } from "../../../../utils/paths";
import { HotelShopCallError } from "../../reducer/state";
import { shouldTrackLodgingLocation } from "../../utils/shouldTrackLodgingLocation";
import { ShopErrorModalConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";

export interface IAvailabilityErrorModalProps
  extends ShopErrorModalConnectorProps,
    RouteComponentProps {
  returnToAvailability: () => void;
}

export const ShopErrorModal = (props: IAvailabilityErrorModalProps) => {
  const {
    hasFailed,
    history,
    fetchHotelShop,
    missingRoomProductInfo,
    shopError,
    returnToAvailability,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();

  const similarHotelsVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    SIMILAR_HOTELS_EXPERIMENT,
    SIMILAR_HOTELS_VARIANTS
  );

  const recentlyViewedDesktopP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    DESKTOP_RECENTLY_VIEWED_HOTELS,
    DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS
  );

  const recentlyViewedMobileP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_RECENTLY_VIEWED_HOTELS,
    MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS
  );

  const recentlyViewedExperiment = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_MASTER_V1
  );
  const isRecentlyViewedV1Experiment = useMemo(() => {
    return recentlyViewedExperiment === AVAILABLE;
  }, [recentlyViewedExperiment]);

  const modalEventProperties = {
    type: "hotels_shop_error",
    primary_button: textConstants.SEARCH_AGAIN_BUTTON,
    secondary_button: textConstants.GO_BACK_BUTTON,
    screen: ModalScreens.HOTELS_SHOP,
    category: ModalCategoryType.TROUBLE,
  };

  React.useEffect(() => {
    if (hasFailed) {
      setIsOpen(true);
      trackEvent({
        eventName: MODAL_ALERT,
        properties: { ...modalEventProperties },
      });
    }
  }, [hasFailed]);

  const hotelUnavailable = shopError === HotelShopCallError.NoAvailability;

  const buttons = hotelUnavailable
    ? [
        {
          buttonText: textConstants.BACK_TO_RESULTS_BUTTON,
          buttonWrapperClassName: clsx(
            "availability-error-modal-button",
            "primary"
          ),
          onClick: () => {
            setIsOpen(false);
            trackEvent({
              eventName: MODAL_ALERT,
              properties: {
                ...modalEventProperties,
                button_choice: ModalButtonType.SECONDARY,
              },
            });
            returnToAvailability();
          },
        },
      ]
    : [
        {
          buttonText: textConstants.GO_BACK_BUTTON,
          buttonWrapperClassName: clsx(
            "availability-error-modal-button",
            "secondary"
          ),
          onClick: () => {
            setIsOpen(false);
            trackEvent({
              eventName: MODAL_ALERT,
              properties: {
                ...modalEventProperties,
                button_choice: ModalButtonType.SECONDARY,
              },
            });
            history.push(PATH_HOME);
          },
        },
        {
          buttonText: textConstants.SEARCH_AGAIN_BUTTON,
          buttonWrapperClassName: clsx(
            "availability-error-modal-button",
            "primary"
          ),
          onClick: () => {
            setIsOpen(false);
            trackEvent({
              eventName: MODAL_ALERT,
              properties: {
                ...modalEventProperties,
                button_choice: ModalButtonType.SECONDARY,
              },
            });
            fetchHotelShop(history, {
              fetchSimilarHotels: similarHotelsVariant !== CONTROL,
              includeLocationSearchTerm:
                isRecentlyViewedV1Experiment &&
                (matchesMobile
                  ? recentlyViewedMobileP0Variant !== CONTROL
                  : recentlyViewedDesktopP0Variant !== CONTROL) &&
                shouldTrackLodgingLocation(history.location.search),
            });
          },
        },
      ];

  return (
    <GenericInfoPopup
      className="hotel-shop-error-modal-root"
      open={isOpen}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      title={
        hotelUnavailable
          ? textConstants.UNAVAILABLE_DATES_ERROR_TITLE
          : missingRoomProductInfo
          ? textConstants.SHOP_MISSING_ROOMS_ERROR
          : textConstants.SHOP_ERROR_TITLE
      }
      subtitle={
        missingRoomProductInfo || hotelUnavailable
          ? ""
          : textConstants.SHOP_ERROR_SUBTITLE
      }
      buttons={buttons}
      isMobile={matchesMobile}
    />
  );
};
