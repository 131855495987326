import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
  IIdLodgings,
  LAUNCHED_MOBILE_CALENDAR,
  LaunchedMobileCalendarProperties,
} from "redmond";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import {
  PATH_AVAILABILITY,
  PATH_HOME_SEARCH,
} from "../../../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../../../shop/utils/queryStringHelpers";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../HotelSearchControl/components/MonthAndDatePicker";
import { HotelSearchButton } from "../../../HotelSearchControl/components/SearchButton";
import { MobileCalendarPickerModalConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
export interface IMobileCalendarPickerModalProps
  extends MobileCalendarPickerModalConnectorProps,
    RouteComponentProps {
  focusedMonthIndex?: number;
  openCalendarModal: boolean;
  setOpenCalendarModal: (val: boolean) => void;
  handleIncompleteSearch?: (val: boolean) => void;
  searchOnContinue?: boolean;
  headerText?: string;
  fullScreen?: boolean;
  onComplete?: (from: Date | null, until: Date | null) => void;
  showPricing?: boolean;
}

export const MobileCalendarPickerModal = (
  props: IMobileCalendarPickerModalProps
) => {
  const {
    fromDate,
    untilDate,
    searchLocation,
    adultsCount,
    children,
    setFromDate,
    setUntilDate,
    focusedMonthIndex,
    roomsCount,
    openCalendarModal,
    setOpenCalendarModal,
    handleIncompleteSearch,
    searchOnContinue = false,
    history,
    headerText,
    fullScreen = false,
    onComplete,
    showPricing,
  } = props;

  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  React.useEffect(() => {
    if (window.location.pathname === PATH_HOME_SEARCH) {
      const properties: LaunchedMobileCalendarProperties = {
        funnel: "hotels",
        url: window.location.pathname,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_CALENDAR, properties });
    }
  }, []);

  const isReadyToSearch =
    !!searchLocation && !!from && !!until && !!adultsCount;

  const handleContinue = () => {
    setFromDate(from);
    setUntilDate(until);
    setOpenCalendarModal(false);
    isReadyToSearch
      ? history.push(
          `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
            (searchLocation?.id as IIdLodgings).lodgingSelection.searchTerm,
            from,
            until,
            adultsCount,
            children,
            roomsCount
          )}`
        )
      : handleIncompleteSearch && handleIncompleteSearch(true);
    onComplete?.(from, until);
  };

  const handleClose = () => {
    !searchOnContinue && setFromDate(from);
    !searchOnContinue && setUntilDate(until);
    searchOnContinue && setFrom(fromDate);
    searchOnContinue && setUntil(untilDate);
    setOpenCalendarModal(false);
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {headerText || textConstants.CHOOSE_DATES}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };
  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <MobilePopoverCard
      open={openCalendarModal}
      className={"mobile-hotel-search-calendar-popup"}
      onClose={handleClose}
      headerElement={<MobileSearchPopoverHeader />}
      fullScreen={!!fullScreen}
    >
      <Box className="hotel-mobile-calendar-picker-root redesign-v2">
        <MonthAndDatePicker
          className="b2b"
          viewType={MonthAndDatePickerType.Column}
          focusedMonthIndex={focusedMonthIndex}
          setFromDate={setFrom}
          setUntilDate={setUntil}
          from={from}
          until={until}
          header={
            <Box className="hotel-mobile-calendar-header">
              <DatePickerHeader
                label={textConstants.CHECK_IN}
                value={from ? dayjs(from).format("ddd D, MMM") : undefined}
              />
              <DatePickerHeader
                label={textConstants.CHECK_OUT}
                value={until ? dayjs(until).format("ddd D, MMM") : undefined}
              />
            </Box>
          }
          showPricing={showPricing}
        />
        {from && until && (
          <HotelSearchButton
            className={clsx("mobile-calendar-picker-search-button", "b2b")}
            message={textConstants.SEARCH_HOTELS}
            onClick={handleContinue}
            from={from}
            until={until}
            enabled={from && until ? true : false}
          />
        )}
      </Box>
    </MobilePopoverCard>
  );
};
