import React from "react";
import { RouteComponentProps } from "react-router";
import { HotelFrozenPriceSummary, useDeviceTypes } from "halifax";
import { FrozenPriceSummaryConnectorProps } from "./container";

export interface IFrozenPriceSummaryProps
  extends FrozenPriceSummaryConnectorProps,
    RouteComponentProps {}

export const FrozenPriceSummary = (props: IFrozenPriceSummaryProps) => {
  const { frozenPriceSummaryDetails } = props;
  const { matchesMobile } = useDeviceTypes();

  return (
    <HotelFrozenPriceSummary
      summaryProps={frozenPriceSummaryDetails}
      isMobile={matchesMobile}
    />
  );
};
