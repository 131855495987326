import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getPetsCount,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import { fetchTravelWalletDetails } from "../../../travel-wallet/actions/actions";
import { getTravelWalletOffers } from "../../../travel-wallet/reducer";
import { HotelShopCallState } from "../../../shop/reducer/state";
import {
  getHotelShopCallState,
  getHotelShopChosenProduct,
  getHotelShopSelectedLodging,
} from "../../../shop/reducer";
import { DesktopRewardsHeader } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  lodging: getHotelShopSelectedLodging(state),
  hotelShopCallInProgress:
    getHotelShopCallState(state) === HotelShopCallState.InProcess,
  chosenProduct: getHotelShopChosenProduct(state),
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  roomsCount: getRoomsCount(state),
  petsCount: getPetsCount(state),
  travelOffers: getTravelWalletOffers(state),
});

export const mapDispatchToProps = {
  fetchTravelWalletDetails: fetchTravelWalletDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type DesktopRewardsHeaderConnectorProps = ConnectedProps<typeof connector>;

export interface IDesktopRewardsHeaderProps
  extends DesktopRewardsHeaderConnectorProps,
    RouteComponentProps {
  variant?: "shop" | "customize";
  title?: string;
  subtitle?: string;
}

export const ConnectedDesktopRewardsHeader = withRouter(
  connector(DesktopRewardsHeader)
);
