import { mapAmenitiesToTopAmenities } from "halifax";
import { intersection, isEqual } from "lodash-es";
import { Lodging, UserHotelPreferencesPayload } from "redmond";

export const isLodgingRecommendedBasedOnPreferences = (
  lodging: Lodging,
  hotelPreferences: UserHotelPreferencesPayload
) => {
  const ratingMatches = hotelPreferences.starRatings.includes(
    lodging.lodging.starRating
  );

  const amenitiesMatch = isEqual(
    intersection(
      hotelPreferences.amenities,
      mapAmenitiesToTopAmenities(
        lodging.lodging.amenities.map(({ amenity }) => amenity)
      ).map((topAmenity) => topAmenity.value)
    ),
    hotelPreferences.amenities
  );

  const cancellationMatches =
    hotelPreferences.freeCancellationOnly === lodging.isFreeCancel;

  return ratingMatches && amenitiesMatch && cancellationMatches;
};
