export const PRICE_DROP_PROTECTION_POPUP_TITLE =
  "Book now with <strong>free price drop protection.</strong>";

export const LOCATION_TEXT = "Thailand";

export const HOW_IT_WORKS_COPY = "How it works";
export const PRICE_DROP_PROTECTION_BODY = ({
  days,
  amountString,
}: {
  days: number;
  amountString: string;
}) =>
  `We'll monitor the hotel price for ${days} ${
    days > 1 ? "days" : "day"
  }. If we detect a price drop, you'll get up to ${amountString} in travel credit to cover the difference. Premier and Lifestyle Collection hotels are not eligible for price drop protection.`;

export const READ_TERMS_AND_CONDITIONS_COPY = "Read terms and conditions";
