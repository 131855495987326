import { connect, ConnectedProps } from "react-redux";
import {
  updateUserPassenger,
  setUserSelectedPassengerIds,
  deleteUserPassenger,
  fetchUserPassengers,
  fetchCorpUserPassengers,
} from "../../actions/actions";
import {
  getUserPassengerCallState,
  getUserPassengers,
  getUserSelectedTravelerId,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { HotelBookPassengerSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    travelers: getUserPassengers(state),
    selectedTravelerId: getUserSelectedTravelerId(state),
    userPassengerCallState: getUserPassengerCallState(state),
  };
};

const mapDispatchToProps = {
  updateUserPassenger,
  setUserSelectedPassengerIds,
  deleteUserPassenger,
  fetchUserPassengers,
  fetchCorpUserPassengers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelBookPassengerSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelBookPassengerSelection = connector(
  HotelBookPassengerSelection
);
