import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { isCustomizePageEnabledSelector } from "./reducer";
import { IStoreState } from "../../reducers/types";
import { HotelAncillary } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    isCustomizePageEnabled: isCustomizePageEnabledSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelAncillaryConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelAncillary = connector(withRouter(HotelAncillary));
