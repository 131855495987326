import React from "react";

export const BENEFITS_TITLE = "Your Premier Collection Benefits:";

export const BENEFITS_LIST = (
  <ul>
    <li>
      Earn <strong>10X miles</strong> on any Premier Collection booking
    </li>
    <li>
      Enjoy a <strong>$100 experience credit</strong>
    </li>
    <li>
      Daily <strong>breakfast for 2</strong> and complimentary{" "}
      <strong>WIFI</strong>
    </li>
  </ul>
);

export const ADDITIONAL_BENEFITS = (
  <>
    Plus, enjoy other premium benefits like room upgrades, early check-in and
    late checkout when available
  </>
);
