import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ContactInfoWorkflow,
  IContactInfo,
  TravelerSelectStep,
  ContactInfoStep,
  Header,
  HotelNeedToKnowPanel,
  RoomDetails,
  getCancellationPolicyInfo,
  MobilePopoverCard,
  BackButton,
  MobileFloatingButton,
  LoadingPopup,
  B2BSpinner,
  emailRegex,
  phoneRegex,
  Icon,
  IconName,
  TreesConfirmation,
  InformationalModal as TreesModal,
  TravelWalletSingleSelectionStep,
  VoidWindowNotice,
} from "halifax";
import {
  ADD_CONTACT_INFO,
  CheckInPolicy,
  HotelBookType,
  getUpdatedHotelPriceFreezeCancellationPolicyInfo,
  LodgingIds,
  LodgingSelectionEnum,
  PlatformEnum,
  AvailabilityRequestEnum,
  LodgingCollectionEnum,
  CancellationReason,
  CancellationPolicyEnum,
} from "redmond";
import { RouteComponentProps } from "react-router-dom";

import "./styles.scss";
import { MobileHotelBookWorkflowConnectorProps } from "./container";
import {
  PriceBreakdown,
  HotelBookPassengerSelection,
  PaymentCard,
  TitleSummaryCard,
  HotelBookMobileButton,
  HotelBookSummaryPanel,
  PriceBreakdownDropdown,
  AGENT_FEE,
} from "../../../components";
import {
  CONTACT_INFO_SAVE,
  CONTINUE,
  CONTACT_INFO_TITLE,
  CONTACT_INFO_SUBTITLE,
  NEED_TO_KNOW_TITLE,
  NEED_TO_KNOW_DETAILS,
  getCfarSecondaryText,
  REVIEW_MY_TRIP_TEXT,
  PRICE_QUOTE_MESSAGE,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
  CHECKOUT_PRICE_BREAKDOWN_HEADER,
  CHECK_IN_INSTRUCTIONS_TITLE,
  formattedCheckOut,
  formattedCheckIn,
} from "./textConstants";
import { PATH_HOME, PATH_BOOK_CONFIRMATION } from "../../../../../utils/paths";
import { ClientContext } from "../../../../../App";
import clsx from "clsx";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import {
  goToShop,
  goToAncillaryCustomize,
} from "../../../../shop/utils/queryStringHelpers";
import { goToPriceFreezeOverviewPage } from "../../../../freeze/utils/queryStringHelpers";
import { fetchCustomerDetails } from "../../../../../api/v0/customer/fetchCustomerDetails";
import {
  AVAILABLE,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
} from "../../../../../context/experiments";
import { TravelOfferSelection } from "../../TravelOfferSelection";
import queryStringParser from "query-string";
import PremierCollectionCheckoutBenefits from "../../PremierCollectionCheckoutBenefits";
import dayjs from "dayjs";
import { CfarCancellationPolicy } from "../../../../ancillary/components/addOnComponents/cfar/CfarCancellationPolicy";
import { PriceDropProtectionBanner } from "../../../../ancillary/components/addOnComponents";
import { PriceDropProtectionPopup } from "../../../../ancillary/components/addOnComponents/PriceDropProtectionBanner/components";
import { CALIFORNIA_BILL_644_CANCELLATION_TEXT } from "../DesktopHotelBookWorkflow/textConstants";

export interface IMobileHotelBookWorkflowProps
  extends MobileHotelBookWorkflowConnectorProps,
    RouteComponentProps {}

enum MobileHotelBookWorkflowStep {
  TravelerInformation,
  ContactInformation,
  TravelOfferSelection,
  RewardsAndPayment,
  Review,
}

export const MobileHotelBookWorkflow = ({
  priceQuote,
  hasNoUserPassengers,
  schedulePriceQuote,
  history,
  chosenProduct,
  selectedLodging,
  roomInfoProduct,
  chosenProductIndex,
  reservation,
  isBookingValid,
  setContactInfo,
  confirmationEmail,
  confirmationPhoneNumber,
  priceDifferenceAcknowledged,
  scheduleBook,
  priceQuoteInProgress,
  priceQuoteErrors,
  confirmationDetailsErrors,
  offers,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  roomsCount,
  credit,
  isTravelWalletPaymentOnly,
  creditToApply,
  isAddOnOptionAvailable,
  ancillaries,
  hasCfarAttached,
  hotelBookType,
  checkIn,
  checkOut,
  fetchHotelAvailabilityTracking,
  userPriceDropAmount,
  cancellationSummary,
}: IMobileHotelBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal } = clientContext;
  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    React.useState<boolean>(true);
  const [checkoutStep, setCheckoutStep] =
    useState<MobileHotelBookWorkflowStep>(0);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [contactInfoStep, setContactInfoStep] = useState<ContactInfoStep>(
    ContactInfoStep.Main
  );
  const [travelOfferSelectStep, setTravelOfferSelectStep] =
    useState<TravelWalletSingleSelectionStep>(
      TravelWalletSingleSelectionStep.Main
    );
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [updatedInfoOnReview, setUpdatedInfoOnReview] = useState(false);

  const [
    openPriceDropProtectionBannerModal,
    setOpenPriceDropProtectionBannerModal,
  ] = useState<boolean>(false);

  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = useMemo(() => {
    return californiaBill644Variant === AVAILABLE;
  }, [californiaBill644Variant]);

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const isInHotelPriceFreezeExercise =
    hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE;

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        chosenProduct?.cancellationPolicy,
        getCfarSecondaryText
      )
    : null;

  const onBookHotel = () => {
    const queryString = queryStringParser.parse(history.location.search);
    priceDifferenceAcknowledged || !!priceQuote
      ? scheduleBook({
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          isRecommended: queryString.recommended === "true",
          ancillaries: ancillaries,
        })
      : schedulePriceQuote({
          history,
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          ancillaries,
        });
  };

  const mergedPolicies = React.useMemo(
    () =>
      [
        ...(chosenProduct?.policies ?? []),
        ...(priceQuote?.checkInInstructions.policies ?? []),
      ].reduce((uniquePolicies, currentPolicy) => {
        if (
          !uniquePolicies.some(({ title }) => currentPolicy.title === title)
        ) {
          uniquePolicies.push(currentPolicy);
        }
        return uniquePolicies;
      }, [] as CheckInPolicy[]),
    [chosenProduct?.policies, priceQuote?.checkInInstructions.policies]
  );

  const openHotelBookPassengerSelection = () =>
    setTravelerWorkflowStep(TravelerSelectStep.TravelerSelect);

  const openContactInfoWorkflow = () => {
    setContactInfoStep(ContactInfoStep.ContactInfoForm);
  };

  const openTravelOffersSelection = () => {
    setTravelOfferSelectStep(
      TravelWalletSingleSelectionStep.TravelWalletSelection
    );
  };

  const openPaymentSelection = () => setOpenPaymentCard(true);

  const incrementCheckoutStep = () => {
    setCheckoutStep((step) => {
      if (
        (step === MobileHotelBookWorkflowStep.ContactInformation &&
          (isTravelWalletCreditsExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !credit
            : true) &&
          (isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !offers?.length
            : true)) ||
        (step === MobileHotelBookWorkflowStep.TravelOfferSelection &&
          isTravelWalletPaymentOnly)
      ) {
        return step + 2;
      }
      return step === MobileHotelBookWorkflowStep.Review ? step : step + 1;
    });
  };

  const handleGoBack = () => {
    switch (checkoutStep) {
      case MobileHotelBookWorkflowStep.TravelerInformation:
        switch (hotelBookType) {
          case HotelBookType.PRICE_FREEZE_EXERCISE:
            goToPriceFreezeOverviewPage({ history });
            break;
          case HotelBookType.DEFAULT:
          default:
            if (selectedLodging) {
              if (isAddOnOptionAvailable) {
                goToAncillaryCustomize({ history });
              } else {
                goToShop({ history });
              }
            } else {
              history.push(PATH_HOME);
            }
            break;
        }
        break;
      case MobileHotelBookWorkflowStep.RewardsAndPayment:
        if (
          (isTravelWalletCreditsExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !credit
            : true) &&
          (isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1
            ? !offers?.length
            : true)
        ) {
          setCheckoutStep((step) => step - 2);
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      default:
        setCheckoutStep((step) => step - 1);
        break;
    }
  };

  const closeAllPopovers = () => {
    setTravelerWorkflowStep(TravelerSelectStep.Main);
    setContactInfoStep(ContactInfoStep.Main);
    setTravelOfferSelectStep(TravelWalletSingleSelectionStep.Main);
    setOpenPaymentCard(false);
  };

  const startSchedulePriceQuoteAndResetPayment = () => {
    schedulePriceQuote({
      history,
      agentFee: isAgentPortal ? AGENT_FEE : 0,
      pollQuoteOnly: true,
      ancillaries,
    });
    resetPaymentCardSelectedAccounts();
    setUpdatedInfoOnReview(true);
  };

  useEffect(() => {
    if (
      isInHotelPriceFreezeExercise &&
      checkIn &&
      checkOut &&
      selectedLodging &&
      roomInfoProduct
    ) {
      const lodgingSelection: LodgingIds = {
        lodgingIds: [selectedLodging.lodging.id ?? ""],
        LodgingSelection: LodgingSelectionEnum.LodgingIds,
      };
      fetchHotelAvailabilityTracking({
        lodgingSelection: lodgingSelection,
        stayDates: {
          from: dayjs(checkIn).format("YYYY-MM-DD"),
          until: dayjs(checkOut).format("YYYY-MM-DD"),
        },
        guests: {
          adults: roomInfoProduct.roomInfo.maxAdults ?? 1,
          children: [],
        },
        platform: PlatformEnum.Mobile,
        progressiveConfig: {},
        AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
        rooms: { numberOfRooms: roomsCount },
      });
    }
  }, [
    isInHotelPriceFreezeExercise,
    checkIn,
    checkOut,
    selectedLodging,
    roomInfoProduct,
  ]);

  useEffect(() => {
    if (priceQuoteErrors.length > 0 || confirmationDetailsErrors.length > 0) {
      setCheckoutStep(MobileHotelBookWorkflowStep.TravelerInformation);
    }
  }, [priceQuoteErrors.length, confirmationDetailsErrors.length]);

  useEffect(() => {
    switch (checkoutStep) {
      case MobileHotelBookWorkflowStep.TravelerInformation:
        closeAllPopovers();
        openHotelBookPassengerSelection();
        break;
      case MobileHotelBookWorkflowStep.ContactInformation:
        closeAllPopovers();
        openContactInfoWorkflow();
        break;
      case MobileHotelBookWorkflowStep.TravelOfferSelection:
        closeAllPopovers();
        openTravelOffersSelection();
        break;
      case MobileHotelBookWorkflowStep.RewardsAndPayment:
        closeAllPopovers();
        openPaymentSelection();
        break;
      default:
        break;
    }
  }, [checkoutStep]);

  // note: it handles the PassengerSelection step logic separately from the previous useEffect;
  // when travelerWorkflowStep is being changed too rapidly (e.g.: changing from Main -> TravelerSelect -> TravelerInfoForm because of hasUserPassengers value)
  // it seems to cause some unexpected behaviours on TravelerSelectWorkflow, which in turn causes the Review screen to be unscrollable
  // https://hopper-jira.atlassian.net/browse/BP-1090
  useEffect(() => {
    if (
      checkoutStep === MobileHotelBookWorkflowStep.TravelerInformation &&
      hasNoUserPassengers
    ) {
      setTravelerWorkflowStep(TravelerSelectStep.TravelerInfoForm);
    }
  }, [checkoutStep, hasNoUserPassengers]);

  useEffect(() => {
    if (reservation) {
      history.push(PATH_BOOK_CONFIRMATION);
    }
  }, [reservation]);

  useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
  }, []);

  useEffect(() => {
    if (
      checkoutStep === MobileHotelBookWorkflowStep.Review &&
      !priceQuoteInProgress &&
      !!priceQuote &&
      updatedInfoOnReview
    ) {
      if (creditToApply) {
        setCheckoutStep(MobileHotelBookWorkflowStep.TravelOfferSelection); // if user has selected a travel wallet item before PQ is run again on pax/contact info update, bring them back to that step
      } else {
        handleGoBack();
      }
      setUpdatedInfoOnReview(false);
    }
  }, [checkoutStep, priceQuoteInProgress, priceQuote, updatedInfoOnReview]);

  useEffect(() => {
    if (priceQuoteInProgress && !!priceQuote) {
      setPriceQuote({
        priceQuote: null,
        pricingWithAncillaries: null,
        hotelAncillaryQuotes: null,
        type: "hotel-checkout",
      });
    }
  }, [priceQuoteInProgress]);

  return (
    <>
      <Box
        className={clsx(
          "mobile-hotel-book-workflow-root",
          "display-room-details-modal",
          {
            "mobile-review-hotel-book":
              checkoutStep === MobileHotelBookWorkflowStep.Review,
          }
        )}
      >
        {/* TODO: Header should have pricing details */}
        <Header
          className={clsx("mobile-hotel-book-header", "checkout")}
          left={
            <BackButton
              className="mobile-hotel-book-header-go-back"
              onClick={handleGoBack}
            />
          }
          right={
            <PriceBreakdownDropdown
              popoverClassName={
                checkoutStep === MobileHotelBookWorkflowStep.Review
                  ? "mobile-review-hotel-book-price-breakdown"
                  : undefined
              }
            />
          }
          isMobile={true}
          fullWidth={true}
        />
        <TitleSummaryCard isMobile />
        {showFree24HourCancel && <VoidWindowNotice />}
        <HotelBookSummaryPanel isMobile className="b2b" />
        {!!roomInfoProduct && chosenProductIndex !== null && (
          <RoomDetails
            roomInfoProduct={roomInfoProduct}
            productIndex={chosenProductIndex}
            lodging={selectedLodging}
            isMobile
            hideAmenities
            roomsCount={roomsCount}
            hideRoomBedDescription
            hideRoomCapacity
            showAmenitiesModal
          />
        )}
        <HotelBookPassengerSelection
          progress={travelerWorkflowStep}
          setProgress={setTravelerWorkflowStep}
          onGoBack={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileHotelBookWorkflowStep.TravelerInformation:
                handleGoBack();
                break;
              case MobileHotelBookWorkflowStep.Review:
                // note: when the user changes passengers on the final step and then clicks the go-back button,
                // handle it as if the user clicked continue;
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                break;
            }
          }}
          onContinue={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileHotelBookWorkflowStep.Review:
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                incrementCheckoutStep();
                break;
            }
          }}
          selectionScreenHeaderElement={<PriceBreakdownDropdown />}
          className="b2b"
          onTravelersStep={
            checkoutStep === MobileHotelBookWorkflowStep.TravelerInformation
          }
          isMobile={true}
          useLocalId={true}
          hiddenTravelerIds={[]}
          showVoidWindowNotice={showFree24HourCancel}
        />
        <ContactInfoWorkflow
          titles={{
            contactInfoTitle: CONTACT_INFO_TITLE,
            contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
            buttonTitle:
              checkoutStep === MobileHotelBookWorkflowStep.Review
                ? CONTACT_INFO_SAVE
                : CONTINUE,
          }}
          progressStep={contactInfoStep}
          setProgressStep={setContactInfoStep}
          isMobile={true}
          contactInfo={contactInfo}
          onGoBack={() => {
            if (
              checkoutStep === MobileHotelBookWorkflowStep.ContactInformation
            ) {
              handleGoBack();
            }
          }}
          onContactInfoChange={(contactInfo) => {
            setContact(contactInfo);
            setContactInfo(contactInfo.email, contactInfo.phoneNumber);
            if (
              contactInfo.email &&
              contactInfo.phoneNumber &&
              emailRegex.test(contactInfo.email) &&
              phoneRegex.test(contactInfo.phoneNumber)
            ) {
              trackEvent({
                eventName: ADD_CONTACT_INFO,
                properties: {},
              });
            }
            incrementCheckoutStep();
          }}
          mobileHeaderElement={<PriceBreakdownDropdown />}
          className="b2b"
          onContinueClick={() => {
            switch (checkoutStep) {
              case MobileHotelBookWorkflowStep.Review:
                startSchedulePriceQuoteAndResetPayment();
                break;
              default:
                schedulePriceQuote({
                  history,
                  agentFee: isAgentPortal ? AGENT_FEE : 0,
                  pollQuoteOnly: true,
                  ancillaries,
                });
                break;
            }
          }}
          loading={customerDetailsLoading}
          bottomContent={
            showFree24HourCancel ? <VoidWindowNotice /> : undefined
          }
        />
        {selectedLodging?.lodgingCollection ===
          LodgingCollectionEnum.Premier && (
          <PremierCollectionCheckoutBenefits />
        )}
        {isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1 && (
            <TravelOfferSelection
              progressStep={travelOfferSelectStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              isMobile
              onGoBack={() => {
                if (
                  checkoutStep ===
                  MobileHotelBookWorkflowStep.TravelOfferSelection
                ) {
                  handleGoBack();
                }
              }}
              onContinueClick={() => {
                incrementCheckoutStep();
                isTravelWalletPaymentOnly &&
                  setTravelOfferSelectStep(
                    TravelWalletSingleSelectionStep.Main
                  );
              }}
            />
          )}
        <Box className="mobile-hotels-price-breakdown">
          <Typography className="price-breakdown-header" variant="h2">
            {CHECKOUT_PRICE_BREAKDOWN_HEADER}
          </Typography>
          <PriceBreakdown />
        </Box>
        <PriceDropProtectionBanner
          bannerVersion="small"
          setOpenModal={setOpenPriceDropProtectionBannerModal}
        />
        <PriceDropProtectionPopup
          amount={userPriceDropAmount}
          openModal={openPriceDropProtectionBannerModal}
          onClose={() => setOpenPriceDropProtectionBannerModal(false)}
        />
        {hasCfarAttached ? (
          <CfarCancellationPolicy />
        ) : cancellationPolicyInfo ? (
          <Box className={"cancellation-details"}>
            <Typography variant="h2">
              {cancellationPolicyInfo.primaryText}
            </Typography>
            <Typography variant="body2">
              {showFree24HourCancel &&
              chosenProduct?.cancellationPolicy.CancellationPolicy ===
                CancellationPolicyEnum.NonRefundable ? (
                CALIFORNIA_BILL_644_CANCELLATION_TEXT
              ) : (
                <>
                  {isInHotelPriceFreezeExercise
                    ? getUpdatedHotelPriceFreezeCancellationPolicyInfo(
                        cancellationPolicyInfo.secondaryText
                      )
                    : cancellationPolicyInfo.secondaryText}
                </>
              )}
            </Typography>
          </Box>
        ) : null}
        {selectedLodging?.lodging?.checkInInstructions &&
          checkIn &&
          checkOut && (
            <Box className={clsx("check-in-instructions")}>
              <Typography
                variant="h2"
                className="hotel-shop-check-in-instructions-heading"
              >
                {CHECK_IN_INSTRUCTIONS_TITLE}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: formattedCheckIn(
                    checkIn,
                    selectedLodging.lodging.checkInInstructions?.checkInTime
                  ),
                }}
              />
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: formattedCheckOut(
                    checkOut,
                    selectedLodging.lodging.checkInInstructions?.checkOutTime
                  ),
                }}
              />
            </Box>
          )}
        <HotelNeedToKnowPanel
          title={NEED_TO_KNOW_TITLE}
          subtitle={NEED_TO_KNOW_DETAILS}
          policies={mergedPolicies}
          fetchingPriceQuote={priceQuoteInProgress}
          className={clsx({ "less-padding": isTreesModalExperiment })}
        />
        {isTreesModalExperiment && (
          <TreesModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            modalButtonCopyStartIcon={
              <Icon className="trees-icon" name={IconName.TreesIcon} />
            }
            isMobile
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
          />
        )}

        <HotelBookMobileButton
          hasContactInfo={!!contactInfo}
          openContactInfo={() => {
            setContactInfoStep(ContactInfoStep.ContactInfoForm);
          }}
          isBookingValid={isBookingValid}
          onConfirmAndBook={() => onBookHotel()}
          // TODO: simplify/trim HotelBookMobileButton logic
          showPaymentStep={true}
          onApplyRewards={() => {}}
          paymentCardOpen={openPaymentCard}
        />
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={() => setOpenPaymentCard(false)}
        onConfirm={() => {
          incrementCheckoutStep();
          setOpenPaymentCard(false);
        }}
        onGoBack={() => {
          if (checkoutStep === MobileHotelBookWorkflowStep.RewardsAndPayment) {
            handleGoBack();
          }
        }}
        mobileHeaderElement={<PriceBreakdownDropdown />}
      />
      <LoadingPopup
        classes={["mobile-price-quote-loading-popup"]}
        open={priceQuoteInProgress}
        message={PRICE_QUOTE_MESSAGE}
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen={true}
      />
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("hotel-payment-card-popup", "b2b")}
      fullScreen={true}
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="hotel-payment-card-popup-back-button"
          onClick={onGoBack}
        />
      }
      headerElement={mobileHeaderElement}
    >
      <PaymentCard />
      {!disabled && (
        <MobileFloatingButton
          className="hotel-book-review-trip-button"
          onClick={onConfirm}
          wrapperClassName="b2b"
        >
          {REVIEW_MY_TRIP_TEXT}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  );
};
