import {
  CallState,
  HotelPriceFreezeDetail,
  LodgingPriceFreezeOfferResult,
  LodgingPriceFreezeRefundResult,
  LodgingPriceFreezeGetCreditsResult,
  HotelPriceFreezePurchaseEntry,
  TrackingProperties,
} from "redmond";
import {
  DefaultVariantType,
  HOTELS_PRICE_FREEZE,
  HOTELS_PRICE_FREEZE_ON_REFUNDABLE_ROOMS,
} from "../../../context/experiments";
import { actions, actionTypes } from "../actions";

export const DO_NOT_APPLY_OPTION_KEY = "do-not-apply-option";

export interface IHotelPriceFreezeExperiments {
  [HOTELS_PRICE_FREEZE]: DefaultVariantType;
  [HOTELS_PRICE_FREEZE_ON_REFUNDABLE_ROOMS]: DefaultVariantType;
}

export interface IHotelPriceFreezeExperimentState {
  experiments: IHotelPriceFreezeExperiments | null;
}

export interface IHotelPriceFreezeState
  extends IHotelPriceFreezeExperimentState {
  fetchHotelPriceFreezeDetailsCallState: CallState;
  hotelPriceFreezeDetails: HotelPriceFreezeDetail | null;
  refundHotelPriceFreezeCallState: CallState;
  refundHotelPriceFreezeResult: LodgingPriceFreezeRefundResult | null;
  getHotelPriceFreezeCreditsCallState: CallState;
  hotelPriceFreezeCreditsResult: LodgingPriceFreezeGetCreditsResult | null;
  hotelPriceFreezeOffers: LodgingPriceFreezeOfferResult[] | null;
  priceFreezePurchaseEntry: HotelPriceFreezePurchaseEntry | null;
  availabilityTrackingProperties: TrackingProperties | null;
}

export const initialState: IHotelPriceFreezeState = {
  fetchHotelPriceFreezeDetailsCallState: CallState.NotCalled,
  hotelPriceFreezeDetails: null,
  refundHotelPriceFreezeCallState: CallState.NotCalled,
  refundHotelPriceFreezeResult: null,
  getHotelPriceFreezeCreditsCallState: CallState.NotCalled,
  hotelPriceFreezeCreditsResult: null,
  hotelPriceFreezeOffers: null,
  priceFreezePurchaseEntry: null,
  experiments: null,
  availabilityTrackingProperties: null,
};

export const getExperimentState = (
  hotelPriceFreezeState: IHotelPriceFreezeState | undefined
): IHotelPriceFreezeExperimentState => {
  return {
    experiments: hotelPriceFreezeState?.experiments ?? initialState.experiments,
  };
};

export function reducer(
  state: IHotelPriceFreezeState = initialState,
  action: actions.HotelPriceFreezeActions
): IHotelPriceFreezeState {
  switch (action.type) {
    case actionTypes.FETCH_HOTEL_PRICE_FREEZE_DETAILS:
      return {
        ...state,
        fetchHotelPriceFreezeDetailsCallState: CallState.InProcess,
      };
    case actionTypes.SET_FETCH_HOTEL_PRICE_FREEZE_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        fetchHotelPriceFreezeDetailsCallState: CallState.Failed,
      };
    case actionTypes.SET_HOTEL_PRICE_FREEZE_DETAILS:
      return {
        ...state,
        fetchHotelPriceFreezeDetailsCallState: CallState.Success,
        hotelPriceFreezeDetails: action.detail,
      };
    case actionTypes.REFUND_HOTEL_PRICE_FREEZE:
      return {
        ...state,
        refundHotelPriceFreezeCallState: CallState.InProcess,
        refundHotelPriceFreezeResult: null,
      };
    case actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_RESULT:
      return {
        ...state,
        refundHotelPriceFreezeCallState: CallState.Success,
        refundHotelPriceFreezeResult: action.result,
      };
    case actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_FAILED:
      return {
        ...state,
        refundHotelPriceFreezeCallState: CallState.Failed,
      };
    case actionTypes.SET_REFUND_HOTEL_PRICE_FREEZE_CALL_STATE_NOT_CALLED:
      return {
        ...state,
        refundHotelPriceFreezeCallState: CallState.NotCalled,
        refundHotelPriceFreezeResult: null,
      };
    case actionTypes.GET_HOTEL_PRICE_FREEZE_CREDITS:
      return {
        ...state,
        getHotelPriceFreezeCreditsCallState: CallState.InProcess,
        hotelPriceFreezeCreditsResult: null,
      };
    case actionTypes.SET_HOTEL_PRICE_FREEZE_CREDITS_RESULT:
      return {
        ...state,
        getHotelPriceFreezeCreditsCallState: CallState.Success,
        hotelPriceFreezeCreditsResult: action.result,
      };
    case actionTypes.SET_GET_HOTEL_PRICE_FREEZE_CREDITS_CALL_STATE_FAILED:
      return {
        ...state,
        getHotelPriceFreezeCreditsCallState: CallState.Failed,
      };
    case actionTypes.RESET_HOTEL_PRICE_FREEZE_EXERCISE:
      return {
        ...state,
        fetchHotelPriceFreezeDetailsCallState:
          initialState.fetchHotelPriceFreezeDetailsCallState,
        hotelPriceFreezeDetails: initialState.hotelPriceFreezeDetails,
        refundHotelPriceFreezeCallState:
          initialState.refundHotelPriceFreezeCallState,
        refundHotelPriceFreezeResult: initialState.refundHotelPriceFreezeResult,
        getHotelPriceFreezeCreditsCallState:
          initialState.getHotelPriceFreezeCreditsCallState,
        hotelPriceFreezeCreditsResult:
          initialState.hotelPriceFreezeCreditsResult,
      };
    case actionTypes.SET_PRICE_FREEZE_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
      };
    case actionTypes.SET_HOTEL_PRICE_FREEZE_OFFERS:
      return {
        ...state,
        hotelPriceFreezeOffers: action.offers,
      };
    case actionTypes.SET_HOTEL_PRICE_FREEZE_PURCHASE_ENTRY:
      return {
        ...state,
        priceFreezePurchaseEntry: action.priceFreezeEntry,
      };
    case actionTypes.SET_HOTEL_AVAILABILITY_TRACKING:
      return {
        ...state,
        availabilityTrackingProperties: action.trackingProperties,
      };
    default:
      return state;
  }
}

export * from "./selectors";
