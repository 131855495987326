import { select, put } from "redux-saga/effects";
import dayjs from "dayjs";
import {
  IIdLodgings,
  AvailabilityRequestEnum,
  AvailabilityRequest,
  AvailabilityResponse,
  PlatformEnum,
  CallState,
} from "redmond";

import { fetchHotelAvailability as fetchAvailability } from "../../../api/v0/availability/fetchHotelAvailability";
import { isMobile } from "../../../utils/userAgent";
import { actions } from "../actions";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getLocation,
  getRoomsCount,
  getUntilDate,
} from "../../search/reducer";
import Logger from "../../../utils/logger";
import { IStoreState } from "../../../reducers/types";
import { getDistanceFromLocation } from "../reducer";

export function* fetchNearbyLodgingsSaga(
  _: actions.IFetchNearbyLodgings
) {
  try {
    let requestBody: AvailabilityRequest;
    const state: IStoreState = yield select();

        let { fromDate, untilDate, adultsCount, children, roomsCount, distanceFromLocation } =
        getExistingStateVariables(state);


        // If we do not have data to make request we should return
        if (!fromDate || !untilDate || !adultsCount || !children || !distanceFromLocation) return;
          requestBody = {
            lodgingSelection: (distanceFromLocation.id as IIdLodgings).lodgingSelection,
            stayDates: {
              from: dayjs(fromDate).format("YYYY-MM-DD"),
              until: dayjs(untilDate).format("YYYY-MM-DD"),
            },
            guests: {
              adults: adultsCount,
              children,
            },
            platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
            progressiveConfig: {},
            AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
            rooms: { numberOfRooms: roomsCount },
          };
 
    const availabilityResponse: AvailabilityResponse = yield fetchAvailability(
      requestBody
    );

    yield put(
      actions.setNearbyLodgings(
        availabilityResponse.lodgings,
      )
    );
    yield put(actions.setNearbyLodgingsAvailabilityCallState(CallState.Success));
  } catch (e) {
    yield put(actions.setNearbyLodgingsAvailabilityCallState(CallState.Failed));
    Logger.debug(e);
  }
}

const getExistingStateVariables = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  location: getLocation(state),
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  roomsCount: getRoomsCount(state),
  distanceFromLocation: getDistanceFromLocation(state),
});