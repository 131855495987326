import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { CfarDetails } from "./component";
import { IStoreState } from "../../../../../../reducers/types";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer";
import {
  getHotelShopDateRange,
  getHotelShopSelectedLodging,
} from "../../../../../shop/reducer";
import {
  selectedCfarIdSelector,
  isRefundableRoomsWithPartiallyRefundablePolicyEnabledSelector,
  isHotelCfarRefundDisplayNonRefPartialEnabledSelector,
  isFintechHotelUpdatedUxEnabledSelector,
  isHotelCfarModelV1EnabledSelector,
} from "../../../../../ancillary/reducer";
import { setSelectedCfarId } from "../../../../../ancillary/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedAccountReferenceId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    selectedCfarId: selectedCfarIdSelector(state),
    isRefundableRoomsWithPartiallyRefundablePolicyEnabled:
      isRefundableRoomsWithPartiallyRefundablePolicyEnabledSelector(state),
    isHotelCfarRefundDisplayNonRefPartialEnabled:
      isHotelCfarRefundDisplayNonRefPartialEnabledSelector(state),
    hotelShopDateRange: getHotelShopDateRange(state),
    isFintechHotelUpdatedUxEnabled:
      isFintechHotelUpdatedUxEnabledSelector(state),
    isHotelCfarModelV1Enabled: isHotelCfarModelV1EnabledSelector(state),
    selectedLodging: getHotelShopSelectedLodging(state),
  };
};

const mapDispatchToProps = {
  setSelectedCfarId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CfarDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCfarDetails = connector(withRouter(CfarDetails));
