import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router";
import {
  HotelShopRoomTypePickerVariant,
  TrackingEventControlType,
} from "halifax";
import { IStoreState } from "../../../../reducers/types";
import { getSearchedNightCount } from "../../../availability/reducer";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getPetsCount,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import {
  isCustomizePageEnabledSelector,
  userPriceDropAmountSelector,
} from "../../../ancillary/reducer";
import { fetchTravelWalletDetails } from "../../../travel-wallet/actions/actions";
import { getTravelWalletOffers } from "../../../travel-wallet/reducer";
import { HotelShopCallState } from "../../reducer/state";
import {
  getCheapestProduct,
  getHotelShopCallState,
  getHotelShopCancellationSummary,
  getHotelShopChosenProduct,
  getHotelShopSelectedLodging,
  getSearchDistanceFromShopProperties,
  getSimilarHotels,
  getSimilarHotelsAvailabilityCallState,
  getSimilarHotelsAvailabilityTrackingProperties,
  hasHotelShopFailed,
} from "../../reducer";
import { DesktopShop } from "./component";
import { RoomInfoProductsWithTransformedIndexes } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  lodging: getHotelShopSelectedLodging(state),
  cheapestProduct: getCheapestProduct(state),
  hotelShopCallInProgress:
    getHotelShopCallState(state) === HotelShopCallState.InProcess,
  chosenProduct: getHotelShopChosenProduct(state),
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  hasFailed: hasHotelShopFailed(state),
  similarHotels: getSimilarHotels(state),
  similarHotelsAvailabilityCallState:
    getSimilarHotelsAvailabilityCallState(state),
  selectedAccountReferenceId:
    getSelectedAccountReferenceIdIfRedemptionEnabled(state),
  roomsCount: getRoomsCount(state),
  petsCount: getPetsCount(state),
  similarHotelsAvailabilityTrackingProperties:
    getSimilarHotelsAvailabilityTrackingProperties(state),
  travelOffers: getTravelWalletOffers(state),
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  searchDistanceFromShopProperties: getSearchDistanceFromShopProperties(state),
  hotelShopCallState: getHotelShopCallState(state),
  isCustomizePageEnabled: isCustomizePageEnabledSelector(state),
  nightCount: getSearchedNightCount(state),
  userPriceDropAmount: userPriceDropAmountSelector(state),
  cancellationSummary: getHotelShopCancellationSummary(state),
});

export const mapDispatchToProps = {
  fetchTravelWalletDetails: fetchTravelWalletDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type DesktopShopConnectorProps = ConnectedProps<typeof connector>;

export interface IDesktopShopProps
  extends DesktopShopConnectorProps,
    RouteComponentProps {
  hideRightColumn?: boolean;
  hideProgressbar?: boolean;
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
  setRoomInfoProduct?: (
    roomInfoProduct: RoomInfoProductsWithTransformedIndexes
  ) => void;
  setRoomProductIndex?: (roomProductIndex: number) => void;
}

export const ConnectedDesktopShop = withRouter(connector(DesktopShop));
