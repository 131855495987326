import { connect, ConnectedProps } from "react-redux";
import {
  updateUserPassenger,
  deleteUserPassenger,
  fetchUserPassengers,
  setUserSelectedPassengerIds,
  fetchCorpUserPassengers,
} from "../../../actions/actions";
import {
  getHotelBookChosenProduct,
  getRoomsCountInBook,
  getUserPassengerCallState,
  getUserPassengers,
  getUserSelectedTravelerIds,
} from "../../../reducer";
import { IStoreState } from "../../../../../reducers/types";
import { MobileHotelBookPassengerSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    travelers: getUserPassengers(state),
    userPassengerCallState: getUserPassengerCallState(state),
    selectedTravelerIds: getUserSelectedTravelerIds(state),
    roomsCount: getRoomsCountInBook(state),
    chosenProduct: getHotelBookChosenProduct(state),
  };
};

const mapDispatchToProps = {
  updateUserPassenger,
  setUserSelectedPassengerIds,
  deleteUserPassenger,
  fetchUserPassengers,
  fetchCorpUserPassengers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelBookPassengerSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelBookPassengerSelection = connector(
  MobileHotelBookPassengerSelection
);
