import { IPerson } from "redmond";

export const BANNER_COPY = `Confirmed, your hotel is booked!`;
export const TITLE = (
  isMultiroomAmadeus: boolean,
  travelers: IPerson[],
  primaryContactEmail: string
) => {
  if (!isMultiroomAmadeus) {
    return `An email confirmation will be sent to ${primaryContactEmail}.`;
  }

  const travelerEmails = travelers.reduce((acc, traveler) => {
    if (traveler.emailAddress) {
      return [...acc, traveler.emailAddress];
    }
    return acc;
  }, [] as string[]);

  const emails = [...travelerEmails, primaryContactEmail];

  // note: dedupe emails if contact email is same as one of the travelers
  const uniqueEmails = [...new Set(emails)];
  const lastIndex = uniqueEmails.length - 1;
  return `An email confirmation will be sent to ${uniqueEmails
    .slice(0, lastIndex)
    .join(", ")} and ${uniqueEmails[lastIndex]}.`;
};
// note: for Capital One, an “H-” is hard coded in front of the code string
export const SUBTITLE = (reservationId: string) =>
  `Your booking confirmation is #H-${reservationId}`;
export const WHATS_NEXT_HEADER = "What's next?";
export const FLIGHTS_TITLE = "Book a Flight";
export const FLIGHTS_DESCRIPTION =
  "We offer members the lowest prices on flights, guaranteed.";
export const CARS_TITLE = "Rent a Car";
export const CARS_DESCRIPTION = "Search now and get the best deals on wheels.";
export const TREES_MODAL_TITLE = "How it works";
export const TREES_MODAL_SUBTITLE =
  "<b>Tree Planting Efforts:</b> Capital One, together with its partners, will donate to GlobalGiving’s fund for EarthLungs’ Reforestation Project enough to plant two (2) trees, $0.25, for every completed flight, hotel booking, car rental, or vacation rental booking made through Capital One Travel.  In the event that a booking is made, but later canceled and refunded to the customer, for any reason, such a payment will not be made. No portion of a customer’s Capital One Travel purchase is tax-deductible. <br>GlobalGiving is a 501(c)(3) nonprofit organization whose mission is to transform aid and philanthropy to accelerate community-led change. To learn more about GlobalGiving, visit <a href='www.globalgiving.org' target='_blank'>www.globalgiving.org</a>; to learn more about GlobalGiving’s nonprofit partner, EarthLungs Reforestation Project, visit <a href='http://www.earthlungsreforestation.org.' target='_blank'>www.earthlungsreforestation.org.";
export const TREES_MODAL_HEADER =
  "When you book, <span class='font-regular'>you plant trees.</span>";
export const TREES_MODAL_IMG_LOCATION = "Banff, Alberta";
export const TREES_MODAL_CTA_TEXT = "will be planted for this booking! ";
export const TREES_BOLDED_MODAL_CTA_TEXT = "2 trees ";

// Add-ons
export const ADD_ONS_TITLE = "You also purchased:";
export const CFAR_TITLE = "Cancel for any reason";
export const CFAR_DESCRIPTION_ONE = `Cancel for any reason up until your scheduled check-in time from the `;
export const CFAR_DESCRIPTION_MY_TRIPS_LINK = "My trips";
export const CFAR_DESCRIPTION_MY_TRIPS_LINK_ARIA_LABEL = "Open my trips";
export const CFAR_DESCRIPTION_TWO = ` page.`;

export const PRICE_FREEZE_SAVINGS = (price: string) =>
  `You saved <b>${price}</b> by freezing this price!`;

export const TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE =
  "Save up to $200 on select hotels with our first-ever travel sale.";
export const TRAVEL_SALES_EVENT_ACTIVE_CTA = "Explore destinations on sale";
