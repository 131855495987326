import { takeLeading } from "redux-saga/effects";

import { actionTypes } from "../actions";
import {
  deleteUserPassengerSaga,
  updateUserPassengerSaga,
  fetchUserPassengersSaga,
  fetchCorpUserPassengersSaga,
} from "./user-passenger";
import {
  deletePaymentMethodSaga,
  fetchPaymentMethodSaga,
  listPaymentMethodsSaga,
  verifyPaymentMethodSaga,
} from "./payment-method";
import {
  schedulePriceQuoteSaga,
  pollPriceQuoteSaga,
  scheduleBookSaga,
  pollConfirmationDetailsSaga,
  fetchApplicableTravelWalletItemsSaga,
  closeSessionSaga,
  submitForApprovalSaga,
} from "./hotel-book";
import { fetchProductToEarnSaga } from "./fetchProductToEarnSaga";
import { fetchAllEarnForProductSaga } from "./fetchAllEarnForProductSaga";
import { updateUserPassengersMultipleSaga } from "./user-passenger/capone-corporate/updateUserPassengersMultipleSaga";

export function* watchUpdateUserPassenger() {
  yield takeLeading(actionTypes.UPDATE_USER_PASSENGER, updateUserPassengerSaga);
}

export function* watchUpdateUserPassengers() {
  yield takeLeading(
    actionTypes.UPDATE_USER_PASSENGERS_MULTIPLE,
    updateUserPassengersMultipleSaga
  );
}

export function* watchDeletePassengerSaga() {
  yield takeLeading(actionTypes.DELETE_USER_PASSENGER, deleteUserPassengerSaga);
}

export function* watchFetchUserPassengers() {
  yield takeLeading(actionTypes.FETCH_USER_PASSENGERS, fetchUserPassengersSaga);
}

export function* watchDeletePaymentMethodSaga() {
  yield takeLeading(actionTypes.DELETE_PAYMENT_METHOD, deletePaymentMethodSaga);
}

export function* watchFetchPaymentMethodSaga() {
  yield takeLeading(actionTypes.FETCH_PAYMENT_METHOD, fetchPaymentMethodSaga);
}

export function* watchListPaymentMethodsSaga() {
  yield takeLeading(actionTypes.LIST_PAYMENT_METHODS, listPaymentMethodsSaga);
}

export function* watchVerifyPaymentMethodSaga() {
  yield takeLeading(actionTypes.VERIFY_PAYMENT_METHOD, verifyPaymentMethodSaga);
}

export function* watchSchedulePriceQuoteSaga() {
  yield takeLeading(actionTypes.SCHEDULE_PRICE_QUOTE, schedulePriceQuoteSaga);
}

export function* watchPollPriceQuoteSaga() {
  yield takeLeading(actionTypes.POLL_PRICE_QUOTE, pollPriceQuoteSaga);
}

export function* watchscheduleBookSaga() {
  yield takeLeading(actionTypes.SCHEDULE_BOOK, scheduleBookSaga);
}

export function* watchPollConfirmationDetailsSaga() {
  yield takeLeading(
    actionTypes.POLL_CONFIRMATION_DETAILS,
    pollConfirmationDetailsSaga
  );
}

export function* watchProductToEarnSaga() {
  yield takeLeading(actionTypes.FETCH_PRODUCT_TO_EARN, fetchProductToEarnSaga);
}

export function* watchFetchApplicableTravelWalletItemsSaga() {
  yield takeLeading(
    actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS,
    fetchApplicableTravelWalletItemsSaga
  );
}

export function* watchCloseSessionSaga() {
  yield takeLeading(actionTypes.CLOSE_SESSION, closeSessionSaga);
}

export function* watchFetchAllEarnForProductSaga() {
  yield takeLeading(
    actionTypes.FETCH_ALL_EARN_FOR_PRODUCT,
    fetchAllEarnForProductSaga
  );
}

export function* watchSubmitForApprovalSaga() {
  yield takeLeading(actionTypes.SET_SUBMIT_FOR_APPROVAL, submitForApprovalSaga);
}

export function* watchFetchCorpUserPassengers() {
  yield takeLeading(
    actionTypes.FETCH_CORP_USER_PASSENGERS,
    fetchCorpUserPassengersSaga
  );
}
