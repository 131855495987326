import React, { useState } from "react";
import {
  Box,
  Typography,
  Popover,
  Link,
  InputAdornment,
} from "@material-ui/core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { HotelFreezePriceBreakdown } from "../HotelFreezePriceBreakdown";
import { HotelFreezePriceBreakdownDropdownConnectorProps } from "./container";
import { TOTAL_TEXT } from "./textConstants";
import "./styles.scss";

export interface IHotelFreezePriceBreakdownDropdownProps
  extends HotelFreezePriceBreakdownDropdownConnectorProps {}

export const HotelFreezePriceBreakdownDropdown = ({
  totalToPay,
}: IHotelFreezePriceBreakdownDropdownProps) => {
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const togglePopover = () => {
    setOpenPopover((open) => !open);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  if (!totalToPay) {
    return null;
  }

  return (
    <>
      <Link
        className="hotel-freeze-price-breakdown-dropdown-root"
        onClick={togglePopover}
        component={"button"}
      >
        <Box className="total-price-label-container">
          <Typography
            className="trip-total"
            variant="subtitle2"
            dangerouslySetInnerHTML={{
              __html: TOTAL_TEXT(totalToPay.fiat, totalToPay.reward),
            }}
          />
        </Box>
        <InputAdornment
          className="total-price-adornment-icon-container"
          position="end"
        >
          <Box>
            <FontAwesomeIcon
              className={clsx("caret-icon", { open: openPopover })}
              icon={faCaretDown}
            />
          </Box>
        </InputAdornment>
      </Link>
      <Popover
        className="hotel-freeze-price-breakdown-popover-root"
        open={openPopover}
        PaperProps={{
          className: "hotel-freeze-price-breakdown-popover-paper",
        }}
        BackdropProps={{
          className: "hotel-freeze-price-breakdown-popover-backdrop",
          invisible: false,
        }}
        hideBackdrop={false}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableEnforceFocus
      >
        <HotelFreezePriceBreakdown />
      </Popover>
    </>
  );
};
