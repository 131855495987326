export const HOTEL_FREEZE_HEADER = "Freeze this price";
export const HOTEL_FREEZE_CONTINUE_BUTTON_TEXT = "Continue";
export const HOTEL_FREEZE_COMPLETE_BUTTON_TEXT = "Freeze this price";
export const REVIEW_ITINERARY_TITLE = "Review itinerary";
export const CONTACT_INFORMATION = "Contact information:";
export const REVIEW_MY_FROZEN_PRICE_TEXT = "Review my frozen price";
export const DEPOSIT_AMOUNT = "Deposit amount";
export const NONREFUNDABLE = "Nonrefundable";
export const NONREFUNDABLE_PAYMENT =
  "Your price freeze payment will be applied to your booking if you use it before it expires. This payment is not refundable.";
