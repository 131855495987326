import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { MobileAddOnCustomize } from "./component";
import {
  isCfarAvailableInChosenRoomProductSelector,
  hotelCfarQuoteFromChosenRoomProductSelector,
  cancellationPolicyFromChosenRoomProductSelector,
  additionalInfoFromChosenRoomProductSelector,
} from "../../../../shop/reducer";
import { IStoreState } from "../../../../../reducers/types";
import { isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector } from "../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    isCfarAvailable: isCfarAvailableInChosenRoomProductSelector(state),
    cfarQuote: hotelCfarQuoteFromChosenRoomProductSelector(state),
    cancellationPolicy: cancellationPolicyFromChosenRoomProductSelector(state),
    additionalInfo: additionalInfoFromChosenRoomProductSelector(state),
    isHotelCfarRefundDisplayShowAmountAdditionalPlaces:
      isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileAddOnCustomizeConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileAddOnCustomize = withRouter(
  connector(MobileAddOnCustomize)
);
