import {
  CallState,
  HotelEntryTypeEnum,
  ICategorizedResponse,
  IResult,
  Lodging,
  OpaqueValue,
  RoomInfoProducts,
  TrackingProperties,
  DateRange,
  CorpLodging,
  CorpRoomInfoProducts,
  CancellationSummary,
  HotelDetailsEntrySourceEnum,
} from "redmond";

export interface IHotelShopState {
  selectedLodging: Lodging | CorpLodging | null;
  dateRange: DateRange | null;
  shopRequestId: OpaqueValue | null;
  roomInfoProducts: RoomInfoProducts[] | CorpRoomInfoProducts[];
  hotelShopTrackingPropertiesV2: TrackingProperties | null;
  chosenRoomInfoIndex: number | null;
  chosenProductIndex: number | null;
  hotelShopCallState: HotelShopCallState;
  hotelShopCallError: HotelShopCallError | null;
  progress: HotelShopStep;
  similarHotels: Lodging[] | CorpLodging[];
  similarHotelsAvailabilityCallState: CallState;
  similarHotelsAvailabilityTrackingProperties: TrackingProperties | null;
  nearbyLodgings: Lodging[] | CorpLodging[];
  nearbyLodgingsAvailabilityCallState: CallState;
  distanceFromLocation: IResult | null;
  distanceFromLocationCategories: ICategorizedResponse[];
  distanceFromLocationCategoriesLoading: boolean | null;
  entryPoint?: HotelEntryTypeEnum;
  selectedRoomId?: string;
  chosenRateId?: string;
  hotelOpaqueCfarQuotesRequest?: string | null;
  recommendedBasedOnPreferences?: boolean;
  cancellationSummary: CancellationSummary | null;
  hotelDetailsEntrySource?: HotelDetailsEntrySourceEnum;
}

export enum HotelShopStep {
  HotelInfo = "hotel-info-step",
  ChooseRoom = "choose-room-step",
}

export enum HotelShopCallState {
  NotCalled = "NotCalled",
  InProcess = "InProcess",
  Success = "Success",
  Failed = "Failed",
  FailureAcknowledged = "FailureAcknowledged",
}

export enum HotelShopCallError {
  NoAvailability = "NoAvailability",
  Unknown = "Unknown",
}
