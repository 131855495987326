import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingConfirmationPage } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getHotelShopChosenPriceFreezeOffer,
  getHotelShopSelectedLodging,
} from "../../../shop/reducer";
import {
  getConfirmationEmail,
  getHotelBookChosenRoomInfo,
  getHotelPriceFreezeConfirmationDetails,
} from "../../../book/reducer";

const mapStateToProps = (state: IStoreState) => ({
  lodging: getHotelShopSelectedLodging(state),
  hotelPriceFreezeConfirmationDetails:
    getHotelPriceFreezeConfirmationDetails(state),
  confirmationEmail: getConfirmationEmail(state),
  chosenPriceFreezeOffer: getHotelShopChosenPriceFreezeOffer(state),
  chosenRoomInfo: getHotelBookChosenRoomInfo(state),
  priceFreezeOffer: getHotelShopChosenPriceFreezeOffer(state),
});
const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingConfirmationPageConnectorProps = ConnectedProps<
  typeof connector
>;
export const ConnectedBookingConfirmationPage = withRouter(
  connector(BookingConfirmationPage)
);
