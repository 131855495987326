export const apiVersionPrefix = "/api/v1";

// Ancillaries
export const hotelAncillariesApiPrefix = `${apiVersionPrefix}/ancillaries/hotel`;

// CFAR
export const hotelCfarPrefix = `${hotelAncillariesApiPrefix}/cfar`;
export const cfarQuoteForHotelPath = `${hotelCfarPrefix}/quotes/get`;

// Price Drop
export const hotelPriceDropPrefix = `${hotelAncillariesApiPrefix}/pricedrop/smoke`;
export const hotelPriceDropEligibilityPath = `${hotelPriceDropPrefix}/eligibility`;
