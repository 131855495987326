import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import { getNightCount, getRoomsCount } from "../../../search/reducer";
import { showOfferBasedOnSelectedLodgingSelector } from "../../reducer";

import {
  getCheapestProduct,
  getHotelShopRoomInfoProducts,
  getHotelShopSelectedLodging,
} from "../../reducer/selectors";
import { DesktopHotelShopChooseRoom } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    roomInfoProducts: getHotelShopRoomInfoProducts(state),
    selectedLodging: getHotelShopSelectedLodging(state),
    cheapestProduct: getCheapestProduct(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    roomsCount: getRoomsCount(state),
    showOfferBasedOnSelectedLodging:
      showOfferBasedOnSelectedLodgingSelector(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopHotelShopChooseRoomConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopHotelShopChooseRoom = withRouter(
  connector(DesktopHotelShopChooseRoom)
);
