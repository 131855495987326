import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { setLocationAutocompleteError } from "../../actions/actions";
import { getHasLocationAutocompleteError } from "../../reducer";
import { MobileHotelSearchControl } from "./component";

const mapDispatchToProps = {
  setLocationAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => ({
  hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileHotelSearchControl = withRouter(
  connector(MobileHotelSearchControl)
);
