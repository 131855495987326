import React, { useState } from "react";
import { Box } from "@material-ui/core";
import {
  MobileOutlinedTextInput,
  MobileAutocompleteOptions,
  ActionButton,
  InfoIcon,
  RecentlySearchedList,
} from "halifax";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { LocationSearchConnectorProps } from "./container";
import * as H from "history";
import { PATH_HOME } from "../../../../../../utils/paths";
import { IResult, RecentHotelSearch } from "redmond";
export interface ILocationSearchProps extends LocationSearchConnectorProps {
  onComplete?: (value?: any) => void;
  onEditDates?: boolean;
  history: H.History;
  continueOnLocationSelection?: boolean;
  localLocation?: IResult | null;
  onSetLocalLocation?: (location: IResult | null) => void;
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
}
export const LocationSearch = (props: ILocationSearchProps) => {
  const {
    location,
    loading,
    onComplete,
    setLocation,
    fetchLocationCategories,
    locationCategories,
    history,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    continueOnLocationSelection = true,
    onSetLocalLocation,
    localLocation,
    recentSearches,
    onRecentSearchClick,
  } = props;

  const [locationSearchString, setLocationSearchString] = useState<string>(
    (onSetLocalLocation ? localLocation?.label : location?.label) || ""
  );

  const onLocationSearch = (searchVal: string) => {
    hasLocationAutocompleteError && setLocationAutocompleteError(false);
    if (onSetLocalLocation) {
      onSetLocalLocation(null);
    } else {
      setLocation(null);
    }

    setLocationSearchString(searchVal);
    if (searchVal) {
      fetchLocationCategories(searchVal);
    }
  };

  const onLocationSelected = (value: any) => {
    setLocationSearchString(value.label);
    if (onSetLocalLocation) {
      onSetLocalLocation(value);
    } else {
      setLocation(value);
    }
    continueOnLocationSelection && onContinue(value);
  };

  const onContinue = (value?: any) => {
    if (onComplete) {
      onComplete(value);
    }
  };

  const getPrefixString = () => {
    if (onSetLocalLocation) {
      return localLocation
        ? localLocation.label
        : textConstants.LOCATION_SEARCH_PREFIX;
    } else {
      return location ? location.label : textConstants.LOCATION_SEARCH_PREFIX;
    }
  };

  return (
    <Box className="mobile-hotel-search-location">
      <Box className="mobile-location-pickers">
        <MobileOutlinedTextInput
          className={clsx("origin-auto-complete", "mobile")}
          prefixString={getPrefixString()}
          icon={faBed}
          autoFocus={true}
          onChange={(value: string) => onLocationSearch(value)}
          value={locationSearchString}
        />
        {hasLocationAutocompleteError && (
          <Box className="autocomplete-error-container">
            <InfoIcon />
            <span className="autocomplete-error-text">
              Error fetching results.{" "}
            </span>
            <span
              onClick={() => {
                history.push(PATH_HOME);
                hasLocationAutocompleteError &&
                  setLocationAutocompleteError(false);
              }}
              className="try-again-text"
            >
              Try again.
            </span>
          </Box>
        )}
      </Box>
      <MobileAutocompleteOptions
        value={null}
        valueCategories={locationCategories}
        setValue={(value: any) => onLocationSelected(value)}
        loading={loading}
        loadingText={textConstants.LOADING_TEXT}
      />
      {!loading &&
        locationSearchString.length === 0 &&
        (locationCategories.length === 0 ||
          locationCategories[0]?.results.length === 0) &&
        !!recentSearches &&
        recentSearches.length > 0 &&
        !!onRecentSearchClick && (
          <div
            className={clsx("mobile-recently-searched-hotels-container", {
              "has-results": locationCategories.length > 0,
            })}
          >
            <RecentlySearchedList
              recentSearches={recentSearches}
              onRecentSearchClick={(recentSearch) =>
                onRecentSearchClick(recentSearch as RecentHotelSearch)
              }
            />
          </div>
        )}
      {(onSetLocalLocation ? !!localLocation : !!location) && (
        <ActionButton
          className="mobile-autocomplete-continue-button"
          message={textConstants.CONTINUE}
          onClick={() => {
            localLocation ? onContinue(localLocation) : onContinue(location);
          }}
        />
      )}
    </Box>
  );
};
