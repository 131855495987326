import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getViewHotelsNearLocationCategories,
  getViewHotelsNearLocation,
  getViewHotelsNearLocationCategoriesLoading,
} from "../../../../reducer";
import { MobileViewHotelsNearSearch } from "./component";

const mapDispatchToProps = {
  fetchViewHotelsNearLocationCategories: actions.fetchViewHotelsNearLocationCategories,
};
const mapStateToProps = (state: IStoreState) => ({
  viewHotelsNearLocation: getViewHotelsNearLocation(state),
  viewHotelsNearLocationCategories: getViewHotelsNearLocationCategories(state),
  viewHotelsNearLoading: !!getViewHotelsNearLocationCategoriesLoading(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileViewHotelsNearSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileViewHotelsNearSearch = connector(MobileViewHotelsNearSearch);
