import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";
import { Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import "./styles.scss";
import { HotelFreezeConnectorProps } from "./container";
import {
  HOTEL_PRICE_FREEZE_PURCHASE_TITLE,
  PORTAL_TITLE,
} from "../../lang/textConstants";
import { PATH_FREEZE, PATH_FREEZE_CONFIRMATION } from "../../utils/paths";
import { PriceFreezePurchase, BookingConfirmationPage } from "./components";

export interface IHotelFreezeProps
  extends RouteComponentProps,
    HotelFreezeConnectorProps {}

export const HotelFreeze = (props: IHotelFreezeProps) => {
  const {
    history,
    resetBook,
    closeSession,
    setContactInfo,
    fetchRewardsAccounts,
    resetPaymentCardSelectedAccounts,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [history.location.pathname]);

  useEffect(() => {
    document.title = HOTEL_PRICE_FREEZE_PURCHASE_TITLE;

    fetchRewardsAccounts();

    return () => {
      document.title = PORTAL_TITLE;
      // note: reset contact info on destroy
      setContactInfo("", "");
      // note: reset selected rewards account and payment method ids
      resetPaymentCardSelectedAccounts();
      // note: close off any opening session upon leaving the book page
      closeSession();
      // note: calling resetBook (on dismount) erases any checkout states upon leaving the book page
      resetBook();
    };
  }, []);

  return (
    <Box
      className={clsx("hotel-freeze-root", {
        confirm: history.location.pathname === PATH_FREEZE_CONFIRMATION,
        mobile: matchesMobile,
      })}
    >
      <Route path={PATH_FREEZE} exact>
        <PriceFreezePurchase />
      </Route>
      <Route path={PATH_FREEZE_CONFIRMATION}>
        <BookingConfirmationPage />
      </Route>
    </Box>
  );
};
