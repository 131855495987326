import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { CallState } from "redmond";
import { BookingErrorModal } from "./component";
import {
  getCombinedBookingSteps,
  getErrorTitles,
  getHasError,
  getIsBookingValid,
  getModalType,
  getPollPriceQuoteCallState,
  getSelectedPaymentMethodId,
  getSession,
  getUserSelectedTravelerId,
  hotelBookTypeSelector,
  Progress,
  getHotelCfarDetailsAtPriceQuoteSelector,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import {
  scheduleBook,
  acknowledgePriceDifference,
  resetBookErrors,
} from "../../actions/actions";
import { refundHotelPriceFreeze } from "../../../freeze/actions/actions";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getPetsCount,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import { getHotelShopSelectedLodging } from "../../../shop/reducer/selectors";
import { getHotelQueryParams } from "../../../availability/reducer";
import { getAllAncillaries } from "../../../ancillary/reducer";
import {
  hotelPriceFreezeVoucherSelector,
  hotelPriceFreezeRefundChoicePropertiesSelector,
} from "../../../freeze/reducer";

const mapStateToProps = (state: IStoreState) => ({
  hasError: getHasError(state),
  errorTitles: getErrorTitles(state),
  paymentMethodId: getSelectedPaymentMethodId(state),
  currentSession: getSession(state),
  selectedTravelerId: getUserSelectedTravelerId(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  lodging: getHotelShopSelectedLodging(state),
  children: getChildren(state),
  adultsCount: getAdultsCount(state),
  isBookingValid: getIsBookingValid(state),
  priceQuoteSuccessful: getPollPriceQuoteCallState(state) === CallState.Success,
  hotelQueryParams: getHotelQueryParams(state),
  modalType: getModalType(state),
  roomsCount: getRoomsCount(state),
  petsCount: getPetsCount(state),
  ancillaries: getAllAncillaries(state),
  hotelPriceFreezeVoucher: hotelPriceFreezeVoucherSelector(state),
  step: getCombinedBookingSteps(state).find(
    (s) => s.status === Progress.IN_PROGRESS
  )?.name,
  hotelBookType: hotelBookTypeSelector(state),
  hotelPriceFreezeRefundChoiceProperties:
    hotelPriceFreezeRefundChoicePropertiesSelector(state),
  hotelCfarDetailsAtPriceQuote: getHotelCfarDetailsAtPriceQuoteSelector(state),
});

const mapDispatchToProps = {
  scheduleBook,
  resetBookErrors,
  refundHotelPriceFreeze,
  acknowledgePriceDifference,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingErrorModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedBookingErrorModal = withRouter(
  connector(BookingErrorModal)
);
