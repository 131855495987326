import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import { getNightCount, getRoomsCount } from "../../../search/reducer";

import {
  getCheapestProduct,
  getHotelShopRoomInfoProducts,
  getHotelShopSelectedLodging,
  getViewedHotelDetailsProperties,
} from "../../reducer/selectors";
import { DesktopHotelPhotoModal } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    roomInfoProducts: getHotelShopRoomInfoProducts(state),
    lodging: getHotelShopSelectedLodging(state) || undefined,
    roomProduct: getCheapestProduct(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    roomsCount: getRoomsCount(state),
    viewedHotelDetailsProperties: getViewedHotelDetailsProperties(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopHotelPhotoModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopHotelPhotoModal = connector(
  DesktopHotelPhotoModal
);
