import { put } from "redux-saga/effects";
import { LodgingPriceFreezeGetCreditsResult } from "redmond";
import {
  IGetHotelPriceFreezeCredits,
  setGetHotelPriceFreezeCreditsCallStateFailed,
  setHotelPriceFreezeCreditsResult,
} from "../actions/actions";
import { getHotelPriceFreezeCredits } from "../../../api/v0/priceFreeze/getHotelPriceFreezeCredits";
import Logger from "../../../utils/logger";

export function* getHotelPriceFreezeCreditsSaga(
  action: IGetHotelPriceFreezeCredits
) {
  try {
    const { request } = action;
    const result: LodgingPriceFreezeGetCreditsResult =
      yield getHotelPriceFreezeCredits(request);

    yield put(setHotelPriceFreezeCreditsResult({ result }));
  } catch (e) {
    yield put(setGetHotelPriceFreezeCreditsCallStateFailed());
    Logger.debug(e);
  }
}
