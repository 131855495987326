import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../reducers/types";
import { PriceDropProtectionBanner } from "./component";
import {
  fetchPriceDropEligibilityCallStateSelector,
  hotelPriceDropBannerPremierAndLifestyleEnabledSelector,
  userEligibleHotelPriceDropSelector,
  userPriceDropAmountSelector,
} from "../../../reducer";
import { fetchPriceDropEligibility } from "../../../actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    userEligibleHotelPriceDrop: userEligibleHotelPriceDropSelector(state),
    userPriceDropAmount: userPriceDropAmountSelector(state),
    fetchPriceDropEligibilityCallState:
      fetchPriceDropEligibilityCallStateSelector(state),
    hotelPriceDropBannerPremierAndLifestyleEnabled:
      hotelPriceDropBannerPremierAndLifestyleEnabledSelector(state),
  };
};

const mapDispatchToProps = {
  fetchPriceDropEligibility: fetchPriceDropEligibility,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceDropProtectionBannerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceDropProtectionBanner = withRouter(
  connector(PriceDropProtectionBanner)
);
