import React, { useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes, useTrackingEventControl } from "halifax";
import { Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

import { HotelBookConnectorProps } from "./container";
import {
  BookingErrorModal,
  BookingInProgressModal,
  BookingSuccessModal,
} from "./components";
import { RefundPriceFreezeModal } from "../freeze/components";
import { parseQueryString } from "./utils/parseQueryString";
import {
  PATH_HOME,
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_FROZEN_PRICES_LIST,
} from "../../utils/paths";
import "./styles.scss";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../context/experiments";
import {
  CallState,
  HotelBookType,
  HOTEL_BOOK_TYPE,
  PRICE_FREEZE_ID_QUERY_PARAM,
  GetHotelDetailRequestEnum,
  REVIEW_DETAILS_HOTEL_CHECKOUT,
  HotelPriceFreezeStateEnum,
  getReviewDetailsHotelCheckoutHotelCfarOfferFacts,
  Place,
} from "redmond";
import { HOTEL_CHECKOUT_TITLE, PORTAL_TITLE } from "../../lang/textConstants";
import { goToShop } from "../shop/utils/queryStringHelpers";
import { config } from "../../api/config";
import { BookingSubmitApprovalErrorModal } from "./components/capone-corporate/BookingSubmitApprovalErrorModal";
import { BookingSubmitApprovalModal } from "./components/capone-corporate/BookingSubmitApprovalModal";
import { trackEngagementEvent } from "../../api/v0/engagement-data/trackEngagementEvent";
import dayjs from "dayjs";
import queryStringParser from "query-string";

const {
  DesktopHotelBookWorkflow,
} = require(`./components/${config.TENANT}/DesktopHotelBookWorkflow`);
const {
  MobileHotelBookWorkflow,
} = require(`./components/${config.TENANT}/MobileHotelBookWorkflow`);

export interface IHotelBookProps
  extends RouteComponentProps,
    HotelBookConnectorProps {}

export const HotelBook = (props: IHotelBookProps) => {
  const {
    history,
    location,
    chosenProduct,
    selectedLodging,
    reviewHotelDetailsProperties,
    hotelCfarQuote,
    additionalInfo,
    hotelBookType,
    fetchHotelPriceFreezeDetailsCallState,
    voucherState,
    closeSession,
    resetBook,
    setHotelBookType,
    fetchHotelPriceFreezeDetails,
    resetHotelPriceFreezeExercise,
    getHotelCfarOfferChoiceProperties,
    getHotelCheckoutPriceFreezeExerciseProperties,
    fetchApplicableTravelWalletItems,
    fetchTravelWalletCreditHistory,
    priceQuote,
    checkInDate,
    checkOutDate,
    tripTotal,
  } = props;

  const [EDSEventSent, setEDSEventSent] = React.useState<boolean>(false);

  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const {
    trackingEventControl,
    updateTrackingEventControl,
    removeEventFromTrackingEventControl,
  } = useTrackingEventControl();
  const includeRefundPriceFreezeModal =
    hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE;
  const expState = useExperiments();

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  useEffect(() => {
    const hotelBookParsedQuery = parseQueryString(history);
    const priceFreezeId = hotelBookParsedQuery[PRICE_FREEZE_ID_QUERY_PARAM];
    const hotelBookType = hotelBookParsedQuery[HOTEL_BOOK_TYPE];

    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE: {
        if (priceFreezeId) {
          setHotelBookType(HotelBookType.PRICE_FREEZE_EXERCISE);
          fetchHotelPriceFreezeDetails({
            id: priceFreezeId,
            GetDetailRequest: GetHotelDetailRequestEnum.ByVoucherId,
          });
        } else {
          history.push(PATH_HOME);
        }
        break;
      }
      case HotelBookType.DEFAULT:
      default: {
        if (!!chosenProduct && !!selectedLodging) {
          setHotelBookType(HotelBookType.DEFAULT);
        } else {
          goToShop({ history });
        }
        break;
      }
    }
  }, []);

  // note: experiments will always return `control` until `/api/v0/experiments/active` has returned
  useEffect(() => {
    if (!!chosenProduct && !!selectedLodging) {
      isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
      isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
    }
  }, [
    chosenProduct,
    selectedLodging,
    isTravelWalletOfferExperiment,
    isTravelCreditHistoryExperiment,
  ]);

  useEffect(() => {
    if (!!chosenProduct && !!priceQuote) {
      if (
        chosenProduct.tripTotal.fiat.value !==
        priceQuote.pricing.tripTotal.fiat.value
      ) {
        isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
      }
    }
  }, [chosenProduct, priceQuote]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [location.pathname]);

  useEffect(() => {
    const offerProperties = {
      viewed_travel_offer: !!selectedLodging?.bestOfferThisLodging,
    };
    updateTrackingEventControl({
      [REVIEW_DETAILS_HOTEL_CHECKOUT]: {
        properties: {
          ...reviewHotelDetailsProperties.properties,
          ...getReviewDetailsHotelCheckoutHotelCfarOfferFacts({
            roomProduct: chosenProduct,
            hotelCfarQuote,
            additionalInfo,
            ...getHotelCfarOfferChoiceProperties("book"),
          }),
          ...offerProperties,
        },
        encryptedProperties: [
          ...reviewHotelDetailsProperties.encryptedProperties,
        ],
      },
    });
  }, []);

  useEffect(() => {
    document.title = HOTEL_CHECKOUT_TITLE;
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    const hotelBookParsedQuery = parseQueryString(history);
    const hotelBookType = hotelBookParsedQuery[HOTEL_BOOK_TYPE];

    return () => {
      const resetCheckoutStates = () => {
        // note: close off any opening session upon leaving the book page
        closeSession();
        // note: calling resetBook (on dismount) erases any checkout states upon leaving the book page
        resetBook();
      };

      switch (hotelBookType) {
        case HotelBookType.PRICE_FREEZE_EXERCISE: {
          resetCheckoutStates();
          // note: clean up getDetails and getCredits redux states
          resetHotelPriceFreezeExercise();
          break;
        }
        case HotelBookType.DEFAULT:
        default: {
          resetCheckoutStates();
          break;
        }
      }
    };
  }, []);

  useEffect(() => {
    if (
      hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE &&
      fetchHotelPriceFreezeDetailsCallState === CallState.Success &&
      voucherState?.State !== HotelPriceFreezeStateEnum.Valid
    ) {
      history.push(PATH_FROZEN_PRICES_LIST);
    }
  }, [hotelBookType, fetchHotelPriceFreezeDetailsCallState, voucherState]);

  useEffect(() => {
    if (trackingEventControl[REVIEW_DETAILS_HOTEL_CHECKOUT]) {
      const offerProperties = {
        viewed_travel_offer: !!selectedLodging?.bestOfferThisLodging,
      };
      switch (hotelBookType) {
        case HotelBookType.PRICE_FREEZE_EXERCISE: {
          if (fetchHotelPriceFreezeDetailsCallState === CallState.Success) {
            trackEvent({
              eventName: REVIEW_DETAILS_HOTEL_CHECKOUT,
              properties: {
                ...trackingEventControl[REVIEW_DETAILS_HOTEL_CHECKOUT]
                  .properties,
                ...getHotelCheckoutPriceFreezeExerciseProperties(
                  hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE
                ),
                ...offerProperties,
              },
              encryptedProperties:
                trackingEventControl[REVIEW_DETAILS_HOTEL_CHECKOUT]
                  .encryptedProperties,
            });
            removeEventFromTrackingEventControl(REVIEW_DETAILS_HOTEL_CHECKOUT);
          }
          break;
        }
        case HotelBookType.DEFAULT:
        default: {
          trackEvent({
            eventName: REVIEW_DETAILS_HOTEL_CHECKOUT,
            properties: {
              ...trackingEventControl[REVIEW_DETAILS_HOTEL_CHECKOUT].properties,
              ...offerProperties,
            },
            encryptedProperties:
              trackingEventControl[REVIEW_DETAILS_HOTEL_CHECKOUT]
                .encryptedProperties,
          });
          removeEventFromTrackingEventControl(REVIEW_DETAILS_HOTEL_CHECKOUT);
          break;
        }
      }
    }
  }, [
    hotelBookType,
    fetchHotelPriceFreezeDetailsCallState,
    getHotelCheckoutPriceFreezeExerciseProperties,
    trackingEventControl,
  ]);

  useEffect(() => {
    if (
      !EDSEventSent &&
      selectedLodging &&
      location.pathname.startsWith(PATH_BOOK) &&
      !!tripTotal
    ) {
      const { lodgingSelection }: { lodgingSelection?: string } =
        queryStringParser.parse(location.search);

      let parsedLodgingSelection: Place | undefined;

      try {
        if (lodgingSelection) {
          parsedLodgingSelection = JSON.parse(decodeURIComponent(lodgingSelection));
        }
      } catch (error) {
        console.error(error);
      }

      trackEngagementEvent({
        event: {
          event_type: "hotel_checkout",
          lodging_id: selectedLodging?.lodging.id,
          lodging_name: selectedLodging?.lodging.name,
          lodging_type: selectedLodging?.lodgingCollection,
          advance: dayjs(checkInDate).diff(dayjs(), "day"),
          lodging_address:
            selectedLodging?.lodging?.address &&
            "line1" in selectedLodging?.lodging?.address
              ? selectedLodging?.lodging.address.line1
              : undefined,
          lodging_city: selectedLodging?.lodging.city,
          lodging_country: selectedLodging?.lodging.country,
          coordinates: selectedLodging?.lodging.location.coordinates,
          length_of_stay: dayjs(checkOutDate).diff(checkInDate, "day"),
          market_name_full: parsedLodgingSelection?.searchTerm, // TODO: Use real market name in place of search term when available: https://hopper-jira.atlassian.net/browse/CMKT-3678
          owed_overall_including_taxes_fees: tripTotal?.fiat,
          check_in_date_timestamp: checkInDate?.getTime(),
          check_out_date_timestamp: checkOutDate?.getTime(),
        },
      });

      setEDSEventSent(true);
    }
  }, [selectedLodging, tripTotal]);

  return (
    <Box
      className={clsx(
        "hotel-book-root",
        { confirm: location.pathname === PATH_BOOK_CONFIRMATION },
        { mobile: matchesMobile }
      )}
    >
      <Route path={PATH_BOOK} exact>
        <>
          <Box className="hotel-book-container">
            {matchesDesktop && <DesktopHotelBookWorkflow />}
            {matchesMobile && <MobileHotelBookWorkflow />}
          </Box>
          <BookingSubmitApprovalModal />
          <BookingSubmitApprovalErrorModal />
          <BookingErrorModal />
          <BookingInProgressModal />
          {includeRefundPriceFreezeModal && <RefundPriceFreezeModal />}
        </>
      </Route>
      <Route path={PATH_BOOK_CONFIRMATION}>
        <BookingSuccessModal />
      </Route>
    </Box>
  );
};
