import { put, select } from "redux-saga/effects";
import {
  WalletOffersResponse,
  OffersForProductsRequest,
  WalletSummaryResponse,
} from "redmond";
import {
  getOffersRequest,
  getPricingEstimateTotal,
  getPurchasePriceQuotePricing,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import {
  setBestOfferOverall,
  setFetchApplicableTravelWalletItemsCallStateFailure,
  setFetchApplicableTravelWalletItemsCallStateSuccess,
  setTravelWalletCredit,
  setTravelWalletOffers,
} from "../../actions/actions";
import { fetchTravelWalletOffers } from "../../../../api/v0/travel-wallet/fetchTravelWalletOffers";
import { fetchTravelWalletSummary } from "../../../../api/v0/travel-wallet/fetchTravelWalletSummary";

export function* fetchApplicableTravelWalletItemsSaga() {
  try {
    const state: IStoreState = yield select();
    const request: OffersForProductsRequest | null = getOffersRequest(state);
    const estimatePricing = getPricingEstimateTotal(state);
    const getQuotePricing = getPurchasePriceQuotePricing(state);

    const totalFiatValue =
      getQuotePricing?.payNowTotal.fiat.value ||
      estimatePricing?.fiat.value ||
      1;

    if (!request) {
      throw new Error("Must have selected lodging");
    }
    const offersResponse: WalletOffersResponse = yield fetchTravelWalletOffers(
      request
    );

    const sortedOffers = offersResponse.rankedApplicableOffers.sort(
      (prev, current) => {
        const prevAmount = prev.percentage
          ? Math.floor(
              (prev.percentage * totalFiatValue + Number.EPSILON) * 100
            ) / 100
          : prev.amount.amount * -1;
        const currAmount = current.percentage
          ? Math.floor(
              (current.percentage * totalFiatValue + Number.EPSILON) * 100
            ) / 100
          : current.amount.amount * -1;
        return currAmount - prevAmount;
      }
    );

    const summaryResponse: WalletSummaryResponse =
      yield fetchTravelWalletSummary();

    yield put(setTravelWalletOffers(sortedOffers));
    yield put(setBestOfferOverall(sortedOffers[0]));
    yield put(setTravelWalletCredit(summaryResponse.credit));
    yield put(setFetchApplicableTravelWalletItemsCallStateSuccess());
  } catch (e) {
    yield put(setFetchApplicableTravelWalletItemsCallStateFailure());
  }
}
