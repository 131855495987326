import { Product } from "@b2bportal/purchase-api";
import { HotelBookType } from "redmond";
import { schedulePriceQuoteV0 } from "../../../api/v0/book/book-flow/schedulePriceQuote";
import { schedulePriceQuoteV1 } from "../../../api/v1/book/book-flow/schedulePriceQuote";
import { pollPriceQuoteV0 } from "../../../api/v0/book/book-flow/pollPriceQuote";
import { pollPriceQuoteV1 } from "../../../api/v1/book/book-flow/pollPriceQuote";
import { schedulePaymentV0 } from "../../../api/v0/book/book-flow/schedulePayment";
import { schedulePaymentV1 } from "../../../api/v1/book/book-flow/schedulePayment";
import { pollFinalizedV0 } from "../../../api/v0/book/book-flow/pollFinalized";
import { pollFinalizedV1 } from "../../../api/v1/book/book-flow/pollFinalized";
import { closeSessionV0 } from "../../../api/v0/book/session/closeSession";
import { closeSessionV1 } from "../../../api/v1/book/session/closeSession";

type Version = "v0" | "v1";

export const getPurchaseRequestMetaProps = ({
  hotelBookType,
}: {
  hotelBookType: HotelBookType;
}): {
  version: Version;
  type?: Product;
} => {
  switch (hotelBookType) {
    // note: v1 purchase endpoints are only used in the PF purchase workflow
    case HotelBookType.PRICE_FREEZE_PURCHASE:
      return {
        version: "v1",
        type: Product.LodgingPriceFreeze,
      };
    case HotelBookType.PRICE_FREEZE_EXERCISE:
    case HotelBookType.DEFAULT:
    default:
      return {
        version: "v0",
      };
  }
};

export const getPurchaseApis = (version: Version) => {
  switch (version) {
    case "v1":
      return {
        closeSession: closeSessionV1,
        schedulePriceQuote: schedulePriceQuoteV1,
        pollPriceQuote: pollPriceQuoteV1,
        schedulePayment: schedulePaymentV1,
        pollFinalized: pollFinalizedV1,
      };
    case "v0":
    default:
      return {
        closeSession: closeSessionV0,
        schedulePriceQuote: schedulePriceQuoteV0,
        pollPriceQuote: pollPriceQuoteV0,
        schedulePayment: schedulePaymentV0,
        pollFinalized: pollFinalizedV0,
      };
  }
};
