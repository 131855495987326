import { delay, put, putResolve, select, take } from "redux-saga/effects";
import { IStoreState } from "../../../../reducers/types";
import {
  getOpaquePayments,
  getPriceQuoteHotelProperties,
  getPricingEstimateTotal,
  hotelBookTypeSelector,
  getSession,
} from "../../reducer";
import {
  hotelPriceFreezeVoucherSelector,
  hotelPriceFreezeGetCreditsStatementSelector,
} from "../../../freeze/reducer";
import { getHotelPricingWithCreditsStatementApplied } from "../../../freeze/utils/getCreditsHelpers";
import {
  HotelBookType,
  PRICE_QUOTE_HOTEL,
  HotelPriceQuoteWithAncillaries,
  LodgingPriceFreezeGetCreditsStatement,
} from "redmond";
import { isCorpTenant, DO_NOT_APPLY_REWARDS_KEY } from "@capone/common";
import {
  setPollPriceQuoteCallStateFailure,
  setPollPriceQuoteCallStateSuccess,
  setPriceQuote,
} from "../../actions/actions";
import { actions } from "../../actions";
import { getPurchaseRequestMetaProps, getPurchaseApis } from "../../utils";
import {
  SET_HOTEL_PRICE_FREEZE_CREDITS_RESULT,
  SET_GET_HOTEL_PRICE_FREEZE_CREDITS_CALL_STATE_FAILED,
} from "../../../freeze/actions/constants";
import { actions as freezeActions } from "../../../freeze/actions";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import dayjs from "dayjs";
import {
  ErrorCode,
  PaymentError,
  ProductError,
  Product,
  PurchaseError,
  PurchaseErrorEnum,
  QuoteFailure,
  QuoteResponseEnum,
  QuoteSuccessV0,
  QuoteSuccess,
  QuoteResponseV0,
  QuoteResponse,
} from "@b2bportal/purchase-api";
import queryStringParser from "query-string";
import { config } from "../../../../api/config";


const toErrorString = (error: PurchaseError): string => {
  switch (error.Error) {
    case PurchaseErrorEnum.ErrorCode:
      return (error as ErrorCode).code;
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      return JSON.stringify(paymentError.value.value);
    case PurchaseErrorEnum.ProductError:
      const productError = error as ProductError;
      return JSON.stringify(productError.value.value);
    default:
      return PurchaseErrorEnum[error.Error];
  }
};

const defaultRewardsAccountRefId = isCorpTenant(config.TENANT)
  ? DO_NOT_APPLY_REWARDS_KEY
  : null;

export function* pollPriceQuoteSaga({
  agentFee,
  pollQuoteOnly,
  ancillaries,
  history,
}: actions.IPollPriceQuote) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const hotelBookType = hotelBookTypeSelector(state);
    const { version } = getPurchaseRequestMetaProps({ hotelBookType });
    const { pollPriceQuote } = getPurchaseApis(version);
    const pollQuoteHotelProperties = getPriceQuoteHotelProperties(state);
    const startTime = dayjs();

    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [1000];

    let pollFailed = false;
    let index = 0;
    while (!pollFailed) {
      yield delay(delayTimes[index]);
      const priceQuoteCheckedResponse: QuoteResponse | QuoteResponseV0 =
        yield pollPriceQuote({
          req: sessionToken,
        });

      switch (priceQuoteCheckedResponse.QuoteResponse) {
        case QuoteResponseEnum.Failure:
          pollFailed = true;
          const failedResponse = priceQuoteCheckedResponse as QuoteFailure;
          if (failedResponse.errors.length > 0) {
            yield putResolve(
              setPollPriceQuoteCallStateFailure(failedResponse.errors)
            );
            trackEvent({
              eventName: PRICE_QUOTE_HOTEL,
              properties: {
                ...pollQuoteHotelProperties.properties,
                failure_reason:
                  failedResponse.errors.length > 0
                    ? failedResponse.errors
                        .map((error) => toErrorString(error))
                        .join(" - ")
                    : "Poll quote checked response returned an error and the given error code is not handleable.",
              },
              encryptedProperties: [
                ...pollQuoteHotelProperties.encryptedProperties,
              ],
            });
            return;
          } else {
            trackEvent({
              eventName: PRICE_QUOTE_HOTEL,
              properties: {
                ...pollQuoteHotelProperties.properties,
                failure_reason:
                  "Poll quote checked response returned an error and the given error code is not handleable.",
              },
              encryptedProperties: [
                ...pollQuoteHotelProperties.encryptedProperties,
              ],
            });
            yield putResolve(setPollPriceQuoteCallStateFailure([]));
            throw new Error(
              "Price quote checked response returned an error and the given error code is not handleable."
            );
          }

        case QuoteResponseEnum.Pending:
          break;
        case QuoteResponseEnum.Success:
          const productType = (() => {
            switch (hotelBookType) {
              case HotelBookType.PRICE_FREEZE_PURCHASE:
                return (priceQuoteCheckedResponse as QuoteSuccess)
                  ?.quoteBreakdown?.products?.[0]?.product?.type;
              case HotelBookType.PRICE_FREEZE_EXERCISE:
              case HotelBookType.DEFAULT:
              default:
                return (priceQuoteCheckedResponse as QuoteSuccessV0)?.quote
                  ?.type;
            }
          })();

          switch (productType) {
            case Product.LodgingPriceFreeze: {
              const product = (
                priceQuoteCheckedResponse as QuoteSuccess
              ).quoteBreakdown.products.find(
                (product) => product.product.type === Product.LodgingPriceFreeze
              );

              yield putResolve(setPollPriceQuoteCallStateSuccess());
              yield putResolve(
                setPriceQuote({
                  hotelPriceFreezePriceQuote: product?.product.value,
                  type: "pf-purchase",
                })
              );

              return;
            }
            case Product.Hotel: {
              const priceQuoteResponse =
                priceQuoteCheckedResponse as QuoteSuccessV0;
              // BE returns a HotelPriceQuoteWithAncillaries object
              // If hotels cfar is not enabled, the hotelAncillaryQuotes and combinedPricing fields will be empty
              const hotelPriceQuoteWithAncillaries = priceQuoteResponse.quote
                .value as HotelPriceQuoteWithAncillaries;

              if (hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE) {
                const voucher = hotelPriceFreezeVoucherSelector(state);

                if (voucher) {
                  yield putResolve(
                    freezeActions.getHotelPriceFreezeCredits({
                      request: {
                        voucherId: voucher.id,
                        token: sessionToken,
                      },
                    })
                  );

                  // note: wait for getHotelPriceFreezeCredits to finish
                  yield take([
                    SET_HOTEL_PRICE_FREEZE_CREDITS_RESULT,
                    SET_GET_HOTEL_PRICE_FREEZE_CREDITS_CALL_STATE_FAILED,
                  ]);
                }

                const hotelPriceFreezeGetCreditsStatement:
                  | LodgingPriceFreezeGetCreditsStatement
                  | undefined = yield select(
                  hotelPriceFreezeGetCreditsStatementSelector
                );

                if (!hotelPriceFreezeGetCreditsStatement) {
                  yield putResolve(setPollPriceQuoteCallStateFailure([]));
                  throw new Error("PF hotel credits result is not populated");
                }

                hotelPriceQuoteWithAncillaries.hotelQuoteData.pricing =
                  getHotelPricingWithCreditsStatementApplied({
                    hotelPricing:
                      hotelPriceQuoteWithAncillaries.hotelQuoteData.pricing,
                    getCreditsStatement: hotelPriceFreezeGetCreditsStatement,
                  });
                hotelPriceQuoteWithAncillaries.combinedPricing =
                  getHotelPricingWithCreditsStatementApplied({
                    hotelPricing:
                      hotelPriceQuoteWithAncillaries.combinedPricing,
                    getCreditsStatement: hotelPriceFreezeGetCreditsStatement,
                  });
              }

              yield putResolve(setPollPriceQuoteCallStateSuccess());
              yield putResolve(
                setPriceQuote({
                  priceQuote: hotelPriceQuoteWithAncillaries.hotelQuoteData,
                  pricingWithAncillaries:
                    hotelPriceQuoteWithAncillaries.combinedPricing,
                  hotelAncillaryQuotes:
                    hotelPriceQuoteWithAncillaries.hotelAncillaryQuotes ?? null,
                  type: "hotel-checkout",
                })
              );

              const estimate = getPricingEstimateTotal(state);

              trackEvent({
                eventName: PRICE_QUOTE_HOTEL,
                properties: {
                  ...pollQuoteHotelProperties.properties,
                  success: true,
                  load_time: dayjs().diff(startTime, "seconds", true),
                },
                encryptedProperties: [
                  ...pollQuoteHotelProperties.encryptedProperties,
                ],
              });
              if (pollQuoteOnly) {
                return;
              }

              // TODO: When the BE fulfill endpoint is ready, this should be refactored
              // to use the getPriceQuotePricing selector to instead of
              // hotelPriceQuoteWithAncillaries.hotelQuoteData.pricing
              if (
                estimate?.fiat.value ===
                hotelPriceQuoteWithAncillaries.hotelQuoteData.pricing.grandTotal
                  .amount
              ) {
                const paymentRequest = getOpaquePayments(state);
                if (paymentRequest) {
                  const queryString = history
                    ? queryStringParser.parse(history.location.search)
                    : {};
                  yield put(
                    actions.scheduleBook({
                      agentFee: agentFee,
                      isRecommended: queryString.recommended === "true",
                      ancillaries: ancillaries,
                    })
                  );
                } else {
                  yield putResolve(setPollPriceQuoteCallStateFailure([]));
                  throw new Error("Payment amount is undefined");
                }
              } else {
                yield put(
                  actions.setSelectedPaymentMethodId({
                    paymentMethodId: "",
                    accountId: undefined,
                  })
                );
                yield put(
                  actions.setSelectedRewardsAccountReferenceId(
                    defaultRewardsAccountRefId
                  )
                );
              }
              return;
            }
            default:
              trackEvent({
                eventName: PRICE_QUOTE_HOTEL,
                properties: {
                  ...pollQuoteHotelProperties.properties,
                  failure_reason: "Poll quote failed",
                },
                encryptedProperties: [
                  ...pollQuoteHotelProperties.encryptedProperties,
                ],
              });
              pollFailed = true;
              yield putResolve(setPollPriceQuoteCallStateFailure([]));
              throw new Error("Price Quote Failed");
          }
      }
      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    const state: IStoreState = yield select();
    const pollQuoteHotelProperties = getPriceQuoteHotelProperties(state);
    trackEvent({
      eventName: PRICE_QUOTE_HOTEL,
      properties: {
        ...pollQuoteHotelProperties.properties,
        failure_reason: "Poll quote request failed",
      },
      encryptedProperties: [...pollQuoteHotelProperties.encryptedProperties],
    });
    yield putResolve(setPollPriceQuoteCallStateFailure([]));
  }
}
