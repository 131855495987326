import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../../../rewards/reducer";
import {
  getIsCreditCardPaymentRequired,
  getTotalToPay,
  getRewardsPaymentAccountReferenceId,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentInRewardsCurrency,
  getTotalCreditCardPaymentRequiredNumber,
  rewardsAccountMinimumRequirementStateSelector,
  getOfferToApply,
  getCreditToApply,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { RewardsSelection } from "./component";
import { actions } from "../../actions";

const mapStateToProps = (state: IStoreState) => ({
  rewardsAccounts: getRewardsAccounts(state),
  selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
  rewardsPaymentInFiatCurrency: getRewardsPaymentInFiatCurrency(state),
  rewardsPaymentInRewardsCurrency: getRewardsPaymentInRewardsCurrency(state),
  isCreditCardPaymentRequired: getIsCreditCardPaymentRequired(state),
  rewardsAccountMinimumRequirementState:
    rewardsAccountMinimumRequirementStateSelector(state),
  totalCreditCardPaymentRequired:
    getTotalCreditCardPaymentRequiredNumber(state),
  tripTotal: getTotalToPay(state),
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  offerToApply: getOfferToApply(state),
  creditToApply: getCreditToApply(state),
});

const mapDispatchToProps = {
  setSelectedRewardsAccountId: actions.setSelectedRewardsAccountReferenceId,
  setRewardsPaymentAmount: actions.setSelectedRewardsPaymentTotal,
  fetchProductToEarn: actions.fetchProductToEarn,
  setRewardsConversionFailed: actions.setRewardsConversionFailed,
  setPaymentMethod: actions.setPaymentMethod,
  setEarnValue: actions.setEarnValue,
  fetchAllEarnForProduct: actions.fetchAllEarnForProduct,
  setAllEarnValues: actions.setAllEarnValues,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RewardsSelectionConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRewardsSelection = withRouter(
  connector(RewardsSelection)
);
