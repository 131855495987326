import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionButton, Header, HotelName, HotelStarRating } from "halifax";
import React, { useContext } from "react";

import { ClientContext } from "../../../../App";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { PATH_HOME } from "../../../../utils/paths";
import { goToAvailability } from "../../../shop/utils/queryStringHelpers";
import { TravelWalletDrawer } from "../../../travel-wallet/components";
import { RewardsAccountSelection } from "../../components";
import { IDesktopRewardsHeaderProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";

export const DesktopRewardsHeader = (props: IDesktopRewardsHeaderProps) => {
  const {
    history,
    lodging,
    fromDate,
    untilDate,
    adultsCount,
    children,
    hotelShopCallInProgress,
    roomsCount,
    petsCount,
    variant = "shop",
    title,
    subtitle,
  } = props;
  const clientContext = useContext(ClientContext);
  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const returnToAvailability = (
    prevSuccessHotelFromDate?: Date | null,
    prevSuccessHotelUntilDate?: Date | null
  ) => {
    goToAvailability({
      history,
      lodging,
      fromDate,
      untilDate,
      adultsCount,
      children,
      roomsCount,
      petsCount,
      prevSuccessHotelFromDate,
      prevSuccessHotelUntilDate,
    });
  };

  const LeftContent = () => {
    switch (variant) {
      case "customize":
        return (
          <Box className="desktop-hotel-customize-basic-info-contents">
            <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
              {clientContext.logo}
            </Box>
            <Box className={"desktop-hotel-customize-header-details"}>
              <Typography variant={"body1"} tabIndex={0}>
                {title ?? textConstants.CUSTOMIZE}
              </Typography>
              <Typography variant={"body2"}>
                {subtitle ?? textConstants.CUSTOMIZE_SUBTITLE}
              </Typography>
            </Box>
          </Box>
        );
      case "shop":
      default:
        return (
          <Box className="desktop-hotel-shop-basic-info-contents">
            <ActionButton
              className="back-to-results"
              message={textConstants.BACK_TO_RESULTS}
              onClick={() => {
                returnToAvailability();
              }}
              defaultStyle="h4r-secondary"
            />
            <Box className="desktop-hotel-shop-header-details" tabIndex={0}>
              <HotelName
                name={lodging?.lodging?.name}
                isSkeleton={hotelShopCallInProgress}
              />
              <HotelStarRating
                starRating={lodging?.lodging?.starRating}
                city={lodging?.lodging?.city}
                isSkeleton={hotelShopCallInProgress}
              />
            </Box>
          </Box>
        );
    }
  };

  return (
    <Header
      className="desktop-hotel-rewards-header-root"
      left={<LeftContent />}
      right={
        <Box className="desktop-hotel-shop-rewards-account-contents">
          <RewardsAccountSelection
            className={clsx("b2b", {
              "hide-balance-border": isTravelWalletCreditsExperiment,
            })}
            popoverClassName="b2b"
          />
          {isTravelWalletCreditsExperiment && <TravelWalletDrawer />}
        </Box>
      }
    />
  );
};
