import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { HotelFreezePriceBreakdownDropdown } from "./component";
import { getTotalToPayWithoutTravelWalletItems } from "../../../../../book/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    totalToPay: getTotalToPayWithoutTravelWalletItems(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelFreezePriceBreakdownDropdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelFreezePriceBreakdownDropdown = connector(
  HotelFreezePriceBreakdownDropdown
);
