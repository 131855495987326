import React, { useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import {
  IconName,
  MobileAutocompleteOptions,
  Icon,
  ActionLink,
  CloseButtonIcon,
  MobilePopoverCard,
  InfoIcon,
  ActionButton,
  RecentlySearchedList,
} from "halifax";
import clsx from "clsx";

import * as textConstants from "./textConstants";
import { MobileLocationSearchModalConnectorProps } from "./container";
import { IResult, RecentHotelSearch } from "redmond";
import "./styles.scss";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import H from "history";
import { PATH_HOME } from "../../../../../../utils/paths";

export interface IMobileLocationSearchModalProps
  extends MobileLocationSearchModalConnectorProps {
  onContinue?: () => void;
  history: H.History;
  openLocationModal: boolean;
  setOpenLocationModal: (val: boolean) => void;
  searchOnContinue?: boolean;
  setLocalLocation?: (arg: IResult | null) => void;
  occupancySelectionComponent?: JSX.Element;
  onSetLocalPassengerCount?: (adults: number, children: number[]) => void;
  setLocalRoomsCount?: (arg: number) => void;
  setLocalPetsCount?: (arg: number) => void;
  className?: string;
  headerText?: string;
  fullScreen?: boolean;
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
  onSelectLocation?: (value: any) => void;
}
export const MobileLocationSearchModal = (
  props: IMobileLocationSearchModalProps
) => {
  const {
    location,
    loading,
    setLocation,
    fetchLocationCategories,
    locationCategories,
    history,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    openLocationModal,
    setOpenLocationModal,
    onContinue,
    searchOnContinue,
    setLocalLocation,
    occupancySelectionComponent,
    onSetLocalPassengerCount,
    setLocalRoomsCount,
    adultsCount,
    children,
    roomsCount,
    className,
    headerText,
    fullScreen = false,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
  } = props;

  const [locationSearchString, setLocationSearchString] = useState<string>("");
  const [labelToDisplay, setLabelToDisplay] = useState<string>(
    textConstants.LOCATION_SEARCH_PREFIX
  );

  React.useEffect(() => {
    location && setLocationSearchString(location?.label);
  }, [location]);

  const onLocationSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasLocationAutocompleteError && setLocationAutocompleteError(false);

    !searchOnContinue && setLocation(null);

    setLocationSearchString(event.target.value);
    if (event.target.value) {
      fetchLocationCategories(event.target.value);
    }
  };

  const onLocationSelected = (value: any) => {
    setLocationSearchString(value.label);

    !searchOnContinue && setLocation(value);
    setLocalLocation && setLocalLocation(value);
    !searchOnContinue && handleClose();
    onSelectLocation?.(value);
  };

  const handleFocus = () => {
    setLabelToDisplay(textConstants.WHERE_TO);
  };

  const handleBlur = () => {
    setLabelToDisplay(textConstants.LOCATION_SEARCH_PREFIX);
  };

  const onClearLocationField = () => {
    searchOnContinue
      ? setLocalLocation && setLocalLocation(null)
      : setLocation(null);
    setLocationSearchString("");
    fetchLocationCategories("");
  };

  const handleClose = () => {
    if (searchOnContinue) {
      if (location) {
        setLocationSearchString(location?.label);
        setLocalLocation && setLocalLocation(location);
      }
      onSetLocalPassengerCount &&
        onSetLocalPassengerCount(adultsCount, children);
      setLocalRoomsCount && setLocalRoomsCount(roomsCount);
    }
    setOpenLocationModal(false);
  };
  const handleContinue = () => {
    onContinue && onContinue();
    handleClose();
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {headerText || textConstants.HOTELS}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };
  return (
    <MobilePopoverCard
      open={openLocationModal}
      className={clsx("mobile-hotel-search-location-popup", className)}
      onClose={handleClose}
      headerElement={<MobileSearchPopoverHeader />}
      fullScreen={!!fullScreen}
    >
      <Box className="mobile-hotel-search-location-modal-content">
        {occupancySelectionComponent ? occupancySelectionComponent : undefined}
        <Box className="mobile-hotel-search-location-input-field">
          <Icon name={IconName.B2BMapPin} />
          <TextField
            className={clsx("origin-auto-complete", "mobile")}
            onChange={(value) => onLocationSearch(value)}
            value={locationSearchString}
            label={labelToDisplay}
            InputProps={{
              disableUnderline: true,
            }}
            autoFocus
            onFocus={handleFocus}
            onBlur={!locationSearchString ? handleBlur : undefined}
          />
          {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
          <FontAwesomeIcon
            className={clsx("icon", { hidden: !locationSearchString })}
            icon={faTimesCircle}
            onClick={onClearLocationField}
          />
        </Box>
        {hasLocationAutocompleteError && (
          <Box className="autocomplete-error-container">
            <InfoIcon />
            <span className="autocomplete-error-text">
              Error fetching results.{" "}
            </span>
            <span
              onClick={() => {
                history.push(PATH_HOME);
                hasLocationAutocompleteError &&
                  setLocationAutocompleteError(false);
              }}
              className="try-again-text"
            >
              Try again.
            </span>
          </Box>
        )}
        <MobileAutocompleteOptions
          value={null}
          valueCategories={locationCategories}
          setValue={(value: any) => {
            onLocationSelected(value);
          }}
          loading={loading}
          loadingText={textConstants.LOADING_TEXT}
          hideSidePadding
        />
        {!loading &&
          locationSearchString.length === 0 &&
          (locationCategories.length === 0 ||
            locationCategories[0]?.results.length === 0) &&
          !!recentSearches &&
          recentSearches.length > 0 &&
          !!onRecentSearchClick && (
            <div
              className={clsx("mobile-recently-searched-hotels-container", {
                "has-results": locationCategories.length > 0,
              })}
            >
              <RecentlySearchedList
                recentSearches={recentSearches}
                onRecentSearchClick={(recentSearch) =>
                  onRecentSearchClick(recentSearch as RecentHotelSearch)
                }
              />
            </div>
          )}
        {searchOnContinue && onContinue && (
          <ActionButton
            className={clsx("mobile-autocomplete-continue-button", "b2b")}
            message={"Continue"}
            onClick={handleContinue}
          />
        )}
      </Box>
    </MobilePopoverCard>
  );
};
