import React from "react";
import { CloseButtonIcon, Header, ActionLink } from "halifax";
import { Box, Typography } from "@material-ui/core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

import { MobileHotelSearchConnectorProps } from "./container";
import "./styles.scss";
import {
  LocationSearch,
  MobileCalendarPicker,
  OccupancySelection,
} from "./components";
import { PATH_HOME } from "../../../../utils/paths";
import * as textConstants from "./textConstants";
import { RecentHotelSearch } from "redmond";

enum MobileHotelSearchStep {
  LocationSearch,
  CalendarPicker,
  Complete,
}

export interface IMobileHotelSearchControlProps
  extends MobileHotelSearchConnectorProps,
    RouteComponentProps {
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (from: Date | null, until: Date | null) => void;
}

export const MobileHotelSearchControl = (
  props: IMobileHotelSearchControlProps
) => {
  const {
    history,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
    onSelectDates,
  } = props;

  const [currentStep, setCurrentStep] = React.useState<MobileHotelSearchStep>(
    MobileHotelSearchStep.LocationSearch
  );

  const handleGoBack = () => {
    setCurrentStep((step) => {
      return step > MobileHotelSearchStep.LocationSearch ? step - 1 : step;
    });
  };

  React.useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [currentStep]);

  return (
    <Box
      className={clsx({
        "date-range-picker":
          currentStep === MobileHotelSearchStep.CalendarPicker,
      })}
    >
      <Header
        center={<HeaderCenterSection currentStep={currentStep} />}
        left={
          <ActionLink
            className={clsx("mobile-hotel-search-header-go-back", {
              hidden: currentStep === MobileHotelSearchStep.LocationSearch,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className="mobile-hotel-search-header-close"
            onClick={() => {
              history.push(PATH_HOME);
              hasLocationAutocompleteError &&
                setLocationAutocompleteError(false);
            }}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep === MobileHotelSearchStep.LocationSearch && (
        <>
          <OccupancySelection showTotalTravelers />
          <LocationSearch
            onComplete={(value) => {
              setCurrentStep(MobileHotelSearchStep.CalendarPicker);
              onSelectLocation?.(value);
            }}
            history={history}
            recentSearches={recentSearches}
            onRecentSearchClick={onRecentSearchClick}
          />
        </>
      )}
      {currentStep === MobileHotelSearchStep.CalendarPicker && (
        <MobileCalendarPicker onComplete={onSelectDates} />
      )}
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  currentStep: number;
}

const HeaderCenterSection = ({ currentStep }: IHeaderCenterSectionProps) => {
  const headerText =
    currentStep === MobileHotelSearchStep.CalendarPicker
      ? textConstants.UPDATE_CALENDAR_TEXT
      : textConstants.HOTELS_HEADER;
  return (
    <Box className="header-center-section">
      {headerText === textConstants.UPDATE_CALENDAR_TEXT ? (
        <Box className="calendar-header">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      ) : (
        <Box className="mobile-hotel-search-location-label">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      )}
    </Box>
  );
};
