import React from "react";
import { LoadingPopup, B2BSpinner, PoweredByHopper } from "halifax";
import { BookingInProgressModalConnectorProps } from "./container";
import "./styles.scss";
import { useEffect } from "react";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { VIEWED_HOTEL_BOOK_PROCESSING, HotelBookType } from "redmond";
import dayjs from "dayjs";
import * as t from "./textConstants";

export interface IBookingInProgressModalProps
  extends BookingInProgressModalConnectorProps {}

export const BookingInProgressModal = ({
  bookingInProgress,
  priceQuoteInProgress,
  reservation,
  hotelBookType,
}: IBookingInProgressModalProps) => {
  const startTime = dayjs();
  useEffect(() => {
    if (!bookingInProgress && reservation) {
      trackEvent({
        eventName: VIEWED_HOTEL_BOOK_PROCESSING,
        properties: {
          runtime: dayjs().diff(startTime, "seconds", true),
        },
      });
    }
  }, [bookingInProgress, reservation]);

  const { open, message }: { open: boolean; message: string } = (() => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_PURCHASE:
        return {
          // TODO - pf purchase: hide BookingInProgressModal when the price quote is in progress
          open: bookingInProgress || priceQuoteInProgress,
          message: priceQuoteInProgress
            ? t.SAVING_CONTACT_INFO_AND_CHECKING_AVAILABILITY
            : t.FREEZING_PRICE,
        };
      case HotelBookType.PRICE_FREEZE_EXERCISE:
      case HotelBookType.DEFAULT:
      default:
        return {
          open: bookingInProgress || priceQuoteInProgress,
          message: priceQuoteInProgress
            ? t.SAVING_TRAVELERS_AND_CHECKING_AVAILABILITY
            : t.BOOKING_HOTEL,
        };
    }
  })();

  return (
    <LoadingPopup
      indicatorSize={"small"}
      indicator={B2BSpinner}
      open={open}
      popupSize={"small"}
      message={message}
      footer={PoweredByHopper}
    />
  );
};
