import { PRICE_FREEZE_ID_QUERY_PARAM } from "redmond";
import { History } from "history";
import queryStringParser from "query-string";
import { PATH_HOTELS_PRICE_FREEZE_OVERVIEW } from "../../../utils/paths";

interface PriceFreezeOverviewPageQuery {
  [PRICE_FREEZE_ID_QUERY_PARAM]?: string;
}

export const goToPriceFreezeOverviewPage = ({
  history,
}: {
  history: History;
}) => {
  const queryString = history?.location?.search || "";
  const { priceFreezeId } = queryStringParser.parse(
    queryString
  ) as PriceFreezeOverviewPageQuery;

  history.push({
    pathname: PATH_HOTELS_PRICE_FREEZE_OVERVIEW,
    search: queryStringParser.stringify({
      [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreezeId,
    }),
  });
};
