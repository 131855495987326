import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getConfirmationEmail,
  getHotelPriceFreezeConfirmationDetails,
} from "../../../book/reducer";
import { setHotelPriceFreezePurchaseEntry } from "../../actions/actions";
import {
  schedulePriceQuote,
  setContactInfo,
  // resetPaymentCardSelectedAccounts,
  setHotelBookType,
} from "../../../book/actions/actions";
import { PriceFreezePurchase } from "./component";
import {
  getHotelShopChosenPriceFreezeOffer,
  getHotelShopChosenProduct,
  getHotelShopSelectedLodging,
} from "../../../shop/reducer";
// import { getHotelShopSelectedLodgingId } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    chosenProduct: getHotelShopChosenProduct(state),
    selectedPriceFreezeOffer: getHotelShopChosenPriceFreezeOffer(state),
    selectedLodging: getHotelShopSelectedLodging(state),
    confirmationEmail: getConfirmationEmail(state),
    hotelPriceFreezeConfirmationDetails:
      getHotelPriceFreezeConfirmationDetails(state),
    // lodgingId: getHotelShopSelectedLodgingId(state),
  };
};

const mapDispatchToProps = {
  setContactInfo,
  // resetPaymentCardSelectedAccounts,
  schedulePriceQuote,
  setHotelBookType,
  setHotelPriceFreezePurchaseEntry,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezePurchaseConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezePurchase = withRouter(
  connector(PriceFreezePurchase)
);
