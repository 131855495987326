import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import queryStringParser from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  CloseButtonActionEnum,
  ErrorModalType,
  HOTEL_PRICE_FREEZE_REFUND_CHOICE,
  HotelBookType,
  MODAL_ALERT_CHOICE,
  ModalAlertProperties,
  ModalButtonType,
  ModalCategoryType,
  ModalScreens,
} from "redmond";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  CONTACT_SUPPORT_URL,
  PATH_AVAILABILITY,
  PATH_SHOP,
} from "../../../../utils/paths";
import { goToPriceFreezeOverviewPage } from "../../../freeze/utils/queryStringHelpers";
import {
  transformToStringifiedAvailabilityQuery,
  transformToStringifiedQuery,
} from "../../../shop/utils/queryStringHelpers";
import {
  ADD_LOYALTY_NUMBER,
  CHANGE_HOTEL,
  CHANGE_SELECTION,
  CHOOSE_ANOTHER_HOTEL,
  CHOOSE_ANOTHER_RATE,
  CONFIRM_AND_BOOK,
  CONTACT_SUPPORT,
  CONTINUE,
  CORP_ERROR_ICON,
  EDIT_PAYMENT_INFO,
  ERROR_ICON,
  MODIFY_TRAVELERS,
  PRICE_DECREASE_ICON,
  PRICE_INCREASE_ICON,
  SEARCH_AGAIN,
  TRY_AGAIN,
  UNABLED_ICON,
  UPDATE_PAYMENT_INFO,
} from "../../reducer/selectors/textConstants";
import { AGENT_FEE } from "../index";
import { BookingErrorModalConnectorProps } from "./container";
import "./styles.scss";

export interface IBookingErrorModalProps
  extends BookingErrorModalConnectorProps,
    RouteComponentProps {}

export const BookingErrorModal = ({
  hasError,
  errorTitles,
  history,
  scheduleBook,
  lodging,
  fromDate,
  untilDate,
  adultsCount,
  children,
  hotelQueryParams,
  isBookingValid,
  acknowledgePriceDifference,
  resetBookErrors,
  modalType,
  priceQuoteSuccessful,
  roomsCount,
  petsCount,
  ancillaries,
  step,
  hotelPriceFreezeVoucher,
  hotelBookType,
  hotelPriceFreezeRefundChoiceProperties,
  refundHotelPriceFreeze,
  hotelCfarDetailsAtPriceQuote,
}: IBookingErrorModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  const { primaryButtonText, secondaryButtonText } = errorTitles;

  const modalEventProperties: ModalAlertProperties = {
    lob: "hotel",
    type: modalType,
    screen:
      hotelBookType === HotelBookType.PRICE_FREEZE_PURCHASE
        ? ModalScreens.HOTEL_PRICE_FREEZE_PURCHASE
        : ModalScreens.HOTELS_CHECKOUT,
    primary_button: primaryButtonText!,
    secondary_button: secondaryButtonText!,
    category: ModalCategoryType.TROUBLE,
    modal_subtitle: errorTitles.subtitle!,
    modal_title: errorTitles.title,
    agent_title: errorTitles.agentTitle!,
    agent_subtitle: errorTitles.agentSubtitle!,
    funnel: "hotels",
    step: step!,
    price_freeze_flow: hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE,
    ...([
      "hotel_with_add_on_price_difference",
      "hotel_price_difference",
    ].includes(modalType)
      ? hotelCfarDetailsAtPriceQuote
      : {}),
  };

  useEffect(() => {
    setIsOpen(hasError);
  }, [hasError]);

  const redoBook = () => {
    const queryString = queryStringParser.parse(history.location.search);
    scheduleBook({
      agentFee: isAgentPortal ? AGENT_FEE : 0,
      isRecommended: queryString.recommended === "true",
      ancillaries: ancillaries,
    });
  };

  const redirectToShop = () => {
    history.push(
      `${PATH_SHOP}${transformToStringifiedQuery({
        lodgingId: lodging!.lodging.id,
        ...hotelQueryParams,
        roomsCount,
      })}`,
      { fromPage: location.pathname }
    );
  };

  const redirectToAvailability = () => {
    const search = transformToStringifiedAvailabilityQuery(
      lodging!.lodging.city,
      fromDate,
      untilDate,
      adultsCount,
      children,
      roomsCount,
      petsCount
    );
    history.push(`${PATH_AVAILABILITY}${search}`);
  };

  const contactSupport = () =>
    window.open(CONTACT_SUPPORT_URL, "_blank", "noopener")?.focus();

  const scrollToTravelers = () => {
    const travelerSectionElement = document.querySelector(
      ".traveler-select-workflow-container"
    );

    const BANNER_HEIGHT =
      document
        .getElementById("b2bportal-banner-container")
        ?.getBoundingClientRect().height ?? 0;
    const HEADER_HEIGHT = matchesMobile
      ? 0
      : document.querySelector(".app-header")?.getBoundingClientRect().height ??
        0;
    const MARGIN = matchesMobile ? 18 : 20;

    const yOffset =
      window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);

    const y =
      (travelerSectionElement?.getBoundingClientRect().top ?? 0) + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const handleButtonClick = (actionKey: string, isPrimary = false) => {
    setIsOpen(false);
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...modalEventProperties,
        button_choice: isPrimary
          ? ModalButtonType.PRIMARY
          : ModalButtonType.SECONDARY,
      },
    });

    switch (actionKey) {
      case SEARCH_AGAIN:
        resetBookErrors();
        redirectToShop();
        break;
      case TRY_AGAIN:
        acknowledgePriceDifference();
        if (isBookingValid && priceQuoteSuccessful) {
          resetBookErrors();
          redoBook();
        } else {
          redirectToShop();
        }
        break;
      case CHOOSE_ANOTHER_RATE:
        redirectToShop();
        break;
      case CHANGE_HOTEL:
      case CHOOSE_ANOTHER_HOTEL:
      case CHANGE_SELECTION:
        redirectToAvailability();
        break;
      case UPDATE_PAYMENT_INFO:
      case CONTINUE:
        acknowledgePriceDifference();
        break;
      case CONFIRM_AND_BOOK:
        acknowledgePriceDifference();
        redoBook();
        break;
      case MODIFY_TRAVELERS:
        resetBookErrors();
        scrollToTravelers();
        break;
      case EDIT_PAYMENT_INFO:
        resetBookErrors(true);
        break;
      case CONTACT_SUPPORT:
        contactSupport();
        break;
      case ADD_LOYALTY_NUMBER:
        setIsOpen(false);
        setTimeout(() => resetBookErrors(), 500);
        break;
      default:
        break;
    }
  };

  const contextfulButtons = (() => {
    const disableFallback =
      hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE;
    // buttons intentionally saved in reverse order for rendering
    const defaultButtons = [
      !disableFallback || secondaryButtonText
        ? {
            buttonText: secondaryButtonText || SEARCH_AGAIN,
            defaultStyle: "h4r-secondary",
            onClick: () =>
              handleButtonClick(secondaryButtonText || SEARCH_AGAIN),
          }
        : [],
      !disableFallback || primaryButtonText
        ? {
            buttonText: primaryButtonText || CHOOSE_ANOTHER_HOTEL,
            defaultStyle: "h4r-primary",
            onClick: () =>
              handleButtonClick(
                primaryButtonText || CHOOSE_ANOTHER_HOTEL,
                true
              ),
          }
        : [],
    ].flat();

    const closeErrorModal = (isPrimary: boolean) => {
      setIsOpen(false);
      trackEvent({
        eventName: MODAL_ALERT_CHOICE,
        properties: {
          ...modalEventProperties,
          button_choice: isPrimary
            ? ModalButtonType.PRIMARY
            : ModalButtonType.SECONDARY,
        },
      });
      resetBookErrors();
    };

    switch (errorTitles.type) {
      case ErrorModalType.HOTEL_PRICE_FREEZE_EXERCISE_REFUND:
        return [
          {
            buttonText: primaryButtonText ?? "",
            defaultStyle: "h4r-primary",
            onClick: () => {
              if (hotelPriceFreezeVoucher) {
                refundHotelPriceFreeze({ id: hotelPriceFreezeVoucher.id });
                trackEvent({
                  eventName: HOTEL_PRICE_FREEZE_REFUND_CHOICE,
                  properties: hotelPriceFreezeRefundChoiceProperties,
                });
              }
              closeErrorModal(true);
            },
          },
        ];
      case ErrorModalType.HOTEL_PRICE_FREEZE_EXERCISE_GENERIC_FAILED:
        return [
          {
            buttonText: secondaryButtonText ?? "",
            defaultStyle: "h4r-secondary",
            onClick: () => {
              contactSupport();
              goToPriceFreezeOverviewPage({ history });
              closeErrorModal(false);
            },
          },
          {
            buttonText: primaryButtonText ?? "",
            defaultStyle: "h4r-primary",
            onClick: () => {
              goToPriceFreezeOverviewPage({ history });
              closeErrorModal(true);
            },
          },
        ];
      case ErrorModalType.HOTEL_PRICE_FREEZE_EXERCISE_LIKELY_FRAUD:
        return [
          {
            buttonText: primaryButtonText ?? "",
            defaultStyle: "h4r-primary",
            onClick: () => {
              contactSupport();
              goToPriceFreezeOverviewPage({ history });
              closeErrorModal(true);
            },
          },
        ];
      default:
        return defaultButtons;
    }
  })();

  const getIconImage = (type: string) => {
    const iconMap: { [key: string]: JSX.Element } = {
      [ERROR_ICON]: <Icon className="error-icon" name={IconName.ErrorState} />,
      [UNABLED_ICON]: (
        <Icon className="error-icon" name={IconName.UnableToProcess} />
      ),
      [PRICE_DECREASE_ICON]: (
        <Icon className="error-icon" name={IconName.ModalPriceDrop} />
      ),
      [PRICE_INCREASE_ICON]: (
        <Icon className="error-icon" name={IconName.PriceIncrease} />
      ),
      [CORP_ERROR_ICON]: (
        <Icon className="error-icon" name={IconName.ErrorTriangle} />
      ),
    };

    return iconMap[type] || iconMap[ERROR_ICON];
  };

  const closeButtonAction = (closeButtonAction?: CloseButtonActionEnum) => {
    switch (closeButtonAction) {
      case CloseButtonActionEnum.GoToHotelPriceFreezeOverview:
        return () => {
          setIsOpen(false);
          goToPriceFreezeOverviewPage({ history });
        };
      case CloseButtonActionEnum.HoteLoyaltyClose:
        return () => {
          setIsOpen(false);
          setTimeout(() => resetBookErrors(), 500);
        };
        break;
      default:
        return () => {
          setIsOpen(false);
        };
    }
  };

  return (
    <GenericInfoPopup
      agentSubtitle={errorTitles.agentSubtitle}
      agentTitle={errorTitles.agentTitle}
      buttons={contextfulButtons}
      className={clsx("booking-error-modal", errorTitles.type, {
        mobile: matchesMobile,
      })}
      image={getIconImage(errorTitles.icon as string)}
      isAgent={isAgentPortal}
      isMobile={matchesMobile}
      open={isOpen}
      subtitle={
        errorTitles.useHtml && errorTitles.subtitle ? (
          <Typography
            variant="inherit"
            dangerouslySetInnerHTML={{ __html: errorTitles.subtitle }}
          />
        ) : (
          errorTitles.subtitle
        )
      }
      title={errorTitles.title}
      // note: mobile popovers in the checkout workflow have z-index: 1300
      zIndex={matchesMobile ? 1301 : 1300}
      showCloseButton={errorTitles.showCloseButton}
      onClose={closeButtonAction(errorTitles.closeButtonAction)}
    />
  );
};
