import { put } from "redux-saga/effects";
import { LodgingPriceFreezeRefundResult } from "redmond";
import {
  IRefundHotelPriceFreeze,
  setRefundHotelPriceFreezeCallStateFailed,
  setRefundHotelPriceFreezeResult,
} from "../actions/actions";
import { refundHotelPriceFreeze } from "../../../api/v0/priceFreeze/refundHotelPriceFreeze";
import Logger from "../../../utils/logger";

export function* refundHotelPriceFreezeSaga(action: IRefundHotelPriceFreeze) {
  try {
    const { id } = action;
    const response: LodgingPriceFreezeRefundResult =
      yield refundHotelPriceFreeze({
        id,
      });

    yield put(setRefundHotelPriceFreezeResult({ result: response }));
  } catch (e) {
    yield put(setRefundHotelPriceFreezeCallStateFailed());
    Logger.debug(e);
  }
}
