import { HotelStarRatingEnum } from "redmond";

export const hotelStarRatingToNumber = (
  starRating: HotelStarRatingEnum = HotelStarRatingEnum.NotAvailable
) => {
  switch (starRating) {
    default:
    case HotelStarRatingEnum.NotAvailable:
      return 0;
    case HotelStarRatingEnum.One:
      return 1;
    case HotelStarRatingEnum.Two:
      return 2;
    case HotelStarRatingEnum.Three:
      return 3;
    case HotelStarRatingEnum.Four:
      return 4;
    case HotelStarRatingEnum.Five:
      return 5;
  }
};
