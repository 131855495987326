import { connect, ConnectedProps } from "react-redux";
import { getHotelLoyaltyProgram } from "@capone/common";
import {
  updateUserPassenger,
  fetchCorpUserPassengers,
  fetchUserPassengers,
} from "../../../actions/actions";
import {
  getHotelBookChosenProduct,
  getRoomsCountInBook,
  getUserPassengerCallState,
  getUserPassengers,
} from "../../../reducer";
import { IStoreState } from "../../../../../reducers/types";
import { HotelTravelerSelectDropdownForm } from "./component";
import { getHotelShopChosenProductHotelLoyaltyProgramCode } from "../../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  const loyaltyProgramCode =
    getHotelShopChosenProductHotelLoyaltyProgramCode(state);
  const loyaltyProgram = getHotelLoyaltyProgram(loyaltyProgramCode);

  return {
    travelers: getUserPassengers(state),
    roomsCount: getRoomsCountInBook(state),
    chosenProduct: getHotelBookChosenProduct(state),
    userPassengerCallState: getUserPassengerCallState(state),
    loyaltyProgram,
  };
};

const mapDispatchToProps = {
  updateUserPassenger,
  fetchUserPassengers,
  fetchCorpUserPassengers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelTravelerSelectDropdownFormConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelTravelerSelectDropdownForm = connector(
  HotelTravelerSelectDropdownForm
);
