import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import { HotelFreezeSummary } from "./component";
import { getHotelShopChosenPriceFreezeOffer } from "../../../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    priceFreezeOffer: getHotelShopChosenPriceFreezeOffer(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelFreezeSummaryConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelFreezeSummary = withRouter(
  connector(HotelFreezeSummary)
);
