import { all, call, put, select } from "redux-saga/effects";
import {
  CallState,
  CorpSessionInfo,
  ICorpPerson,
  IPerson,
  SessionInfo,
} from "redmond";
import { actions } from "../../actions";
import { deleteUserPassenger } from "../../../../api/v0/book/user-passengers/deleteUserPassenger";
import { getUserSelectedTravelerId } from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import {
  IListUserPassengersResponse,
  fetchUserPassengers,
} from "../../../../api/v0/book/user-passengers/fetchUserPassengers";
import { isCaponeTenant } from "@capone/common";
import { config } from "../../../../api/config";
import fetchCorpUsersList, {
  CorpUsersListResponse,
} from "../../../../../../../cap1-application/b2b-base/src/api/v1/user/fetchCorpUsersList";
import fetchUserInfo from "../../../../api/v0/user/fetchUserInfo";
import { buildCorpTravelers } from "../../../../../../../cap1-application/b2b-base/src/utils/capone-corporate/travelersMappingUtils";

export function* deleteUserPassengerSaga(action: actions.IDeleteUserPassenger) {
  try {
    const state: IStoreState = yield select();
    const selectedPassengerId = getUserSelectedTravelerId(state);
    // Note: we only want a single passenger selected at once so we reset entire array if the deleted passenger is the current selected.
    const newSelectedIds =
      selectedPassengerId === action.payload.personId || !selectedPassengerId
        ? []
        : [selectedPassengerId];

    yield deleteUserPassenger(action.payload);

    yield put(
      actions.setUserSelectedPassengerIds({
        userSelectedPassengerIds: newSelectedIds,
      })
    );

    const sessionInfo: SessionInfo = yield call(fetchUserInfo);
    const canViewUsers =
      "corporateInfo" in sessionInfo
        ? (sessionInfo as CorpSessionInfo).corporateInfo.permissions
            .canViewUsers
        : false;

    const travelerDefaultTravelerId =
      ("corporateInfo" in sessionInfo &&
        (sessionInfo as CorpSessionInfo).corporateInfo.defaultTravelerId) ??
      "";

    let userPassengers: IPerson[] | ICorpPerson[];
    if (isCaponeTenant(config.TENANT)) {
      userPassengers = yield fetchUserPassengers({});
    } else {
      const [corpUsersList, caponeUserPassengers]: [
        CorpUsersListResponse,
        IListUserPassengersResponse
      ] = yield all([
        canViewUsers
          ? call(fetchCorpUsersList)
          : {
              users: [
                {
                  id: sessionInfo.userId,
                  firstName: sessionInfo.userInfo.firstName,
                  lastName: sessionInfo.userInfo.lastName,
                  defaultTravelerId: travelerDefaultTravelerId,
                },
              ],
            },
        call(fetchUserPassengers, {}),
      ]);

      const hiddenTravelerIds =
        "corporateInfo" in sessionInfo
          ? (sessionInfo as CorpSessionInfo).corporateInfo.hiddenTravelerIds
          : [];

      const loggedInUserId = sessionInfo.userId;

      userPassengers = buildCorpTravelers(
        caponeUserPassengers,
        corpUsersList.users,
        hiddenTravelerIds,
        loggedInUserId
      );
    }

    yield put(
      actions.setUserPassengers({
        userPassengerCallState: CallState.Success,
        userPassengers: userPassengers,
      })
    );
  } catch (e) {
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
