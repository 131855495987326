import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { PriceBreakdownDropdown } from "./component";
import {
  getHotelPricingLineItems,
  getHotelRewardsAndTotalLineItems,
  getHotelTripTotalInPrices,
  hotelBookTypeSelector,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    pricingLineItems: getHotelPricingLineItems(state),
    rewardsAndTotalLineItems: getHotelRewardsAndTotalLineItems(state),
    tripTotal: getHotelTripTotalInPrices(state),
    hotelBookType: hotelBookTypeSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceBreakdownDropdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceBreakdownDropdown = withRouter(
  connector(PriceBreakdownDropdown)
);
