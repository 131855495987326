import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { TitleSummaryCard } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getHotelShopSelectedLodging } from "../../../shop/reducer/selectors";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodging: getHotelShopSelectedLodging(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    childrenAges: getChildren(state),
    roomsCount: getRoomsCount(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TitleSummaryCardConnectorProps = ConnectedProps<typeof connector> &
  RouteComponentProps<any>;

export const ConnectedTitleSummaryCard = withRouter(
  connector(TitleSummaryCard)
);
