import React from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@material-ui/core";

import { HotelFreezePriceBreakdownConnectorProps } from "./container";
import { HotelPriceBreakdown, IHotelPriceLineItem } from "halifax";
import "./styles.scss";

export interface IHotelFreezePriceBreakdownProps
  extends HotelFreezePriceBreakdownConnectorProps,
    RouteComponentProps {}

export const HotelFreezePriceBreakdown = ({
  pricingLineItems,
  rewardsAndTotalLineItems,
}: IHotelFreezePriceBreakdownProps) => {
  const pricingItems = pricingLineItems[0] as IHotelPriceLineItem[];
  const totalItems = pricingLineItems[1] as IHotelPriceLineItem[];

  return (
    <Box className="hotel-freeze-price-breakdown">
      <HotelPriceBreakdown
        className="pricing-line-items-breakdown"
        pricingItems={pricingItems}
        totalItems={totalItems}
        summaryItems={rewardsAndTotalLineItems}
      />
    </Box>
  );
};
