import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getLocationCategories,
  getLocation,
  getLocationCategoriesLoading,
  getHasLocationAutocompleteError,
} from "../../../../reducer";
import { LocationSearch } from "./component";

const mapDispatchToProps = {
  setLocation: actions.setLocation,
  fetchLocationCategories: actions.fetchLocationCategories,
  setLocationAutocompleteError: actions.setLocationAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => ({
  location: getLocation(state),
  locationCategories: getLocationCategories(state),
  loading: !!getLocationCategoriesLoading(state),
  hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LocationSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedLocationSearch = connector(LocationSearch);
