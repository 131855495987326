import React, { useState } from "react";
import {
  B2BSpinner,
  TravelerSelectWorkflow,
  LoadingIndicator,
  TravelerSelectStep,
  ToggleActions,
  VoidWindowNotice,
} from "halifax";
import {
  IPerson,
  PersonId,
  CallState,
  SELECT_TRAVELERS,
  ITravelerStepErrors,
  ClickedAddTravelerProperties,
  CLICKED_ADD_TRAVELER,
} from "redmond";
import clsx from "clsx";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useExperimentsById } from "@capone/experiments";
import { AMADEUS } from "@capone/common";

import { MobileHotelBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../../context/experiments";
import { config } from "../../../../../api/config";
import {
  MULTIROOM_TOOLTIP_TEXT,
  MULTIROOM_TOOLTIP_TITLE,
  TRAVELER_SELECT_DROPDOWN_DESCRIPTION,
  TRAVELER_SELECT_DROPDOWN_TITLE,
} from "../HotelTravelerSelectDropdownForm/constants";
import "./styles.scss";

export interface IMobileHotelBookPassengerSelectionProps
  extends MobileHotelBookPassengerSelectionConnectorProps {
  progress: TravelerSelectStep;
  setProgress: (progress: TravelerSelectStep) => void;
  onProgressChange?: (step: TravelerSelectStep) => void;
  onContinue?: (travelersChanged: string[]) => void;
  onGoBack?: (travelersChanged: string[]) => void;
  className?: string;
  selectionScreenHeaderElement?: JSX.Element;
  onReviewStep?: boolean;
  disabled?: boolean;
  combinedStep?: boolean;
  showErrors?: ITravelerStepErrors;
  setShowErrors?: (showErrors: ITravelerStepErrors) => void;
  saveButtonClicked?: boolean;
  useLocalId?: boolean;
  showHotelLoyaltySection?: boolean;
  showVoidWindowNotice: boolean;
}

const Header = ({
  roomsCount,
  isAmadeusProvider,
  isMultiroomAmadeus,
}: {
  roomsCount: number;
  isAmadeusProvider: boolean;
  isMultiroomAmadeus: boolean;
}) => (
  <>
    <Box className="mobile-hotel-book-passenger-selection-title-container">
      <Typography component="h1" className="title">
        {TRAVELER_SELECT_DROPDOWN_TITLE(
          roomsCount,
          isAmadeusProvider,
          isMultiroomAmadeus
        )}
      </Typography>
      <Typography variant="body2" className="subtitle">
        {TRAVELER_SELECT_DROPDOWN_DESCRIPTION(
          roomsCount,
          isAmadeusProvider,
          isMultiroomAmadeus
        )}
      </Typography>
    </Box>
    {!isAmadeusProvider && isMultiroomAmadeus && (
      <Accordion square className="multiroom-accordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Typography className="accordian-title" variant="subtitle2">
            {MULTIROOM_TOOLTIP_TITLE}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordian-subtitle" variant="subtitle2">
            {MULTIROOM_TOOLTIP_TEXT}
          </Typography>
        </AccordionDetails>
      </Accordion>
    )}
  </>
);
export const MobileHotelBookPassengerSelection = (
  props: IMobileHotelBookPassengerSelectionProps
) => {
  const {
    className,
    travelers,
    progress,
    selectionScreenHeaderElement,
    setProgress,
    onProgressChange,
    onContinue,
    onGoBack,
    setUserSelectedPassengerIds,
    updateUserPassenger,
    deleteUserPassenger,
    userPassengerCallState,
    onReviewStep,
    disabled,
    combinedStep,
    showErrors,
    setShowErrors,
    saveButtonClicked,
    selectedTravelerIds,
    roomsCount,
    chosenProduct,
    fetchCorpUserPassengers,
    showHotelLoyaltySection,
    showVoidWindowNotice,
  } = props;

  const [localTravelerIds, setLocalTravelerIds] =
    useState<string[]>(selectedTravelerIds);
  const [travelersChanged, setTravelersChanged] = useState<string[]>([]);

  const isAmadeusProvider = chosenProduct?.providerName === AMADEUS;

  const expState = useExperiments();

  const isMultiroomAmadeus =
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";

  React.useEffect(() => {
    fetchCorpUserPassengers();
  }, []);

  React.useEffect(() => {
    if (travelers.length === 1) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: [travelers[0].id],
      });
    }
  }, [travelers]);

  const handleContinue = () => {
    onContinue && onContinue(travelersChanged);
    setLocalTravelerIds(selectedTravelerIds);
    setTravelersChanged([]);
  };

  const handleGoBack = () => {
    onGoBack && onGoBack(travelersChanged);
    setLocalTravelerIds(selectedTravelerIds);
  };

  return (
    <div>
      {userPassengerCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize="small"
          indicator={B2BSpinner}
          message={
            userPassengerCallState === CallState.InProcess
              ? constants.UPDATE_TEXT
              : constants.VALIDATE_TEXT
          }
        />
      ) : (
        <TravelerSelectWorkflow
          displayModalSubtitle
          showGenderField
          showNationalityField
          hideDelete={isMultiroomAmadeus ? true : false}
          singleTravelerWorkflow={
            isMultiroomAmadeus && isAmadeusProvider ? false : true
          }
          buttonClassName="b2b"
          className={clsx(
            "mobile-hotel-book-passenger-selection-root",
            className,
            {
              "combined-step": combinedStep,
            }
          )}
          disabled={disabled}
          errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
          handleDeletePassenger={(travelerId: PersonId) => {
            const newTravelerIdList = localTravelerIds.filter(
              (id) => id !== travelerId
            );
            setLocalTravelerIds(newTravelerIdList);
            deleteUserPassenger({ personId: travelerId });
          }}
          handleUpdatePassenger={(
            traveler: IPerson,
            hideProfileAction: ToggleActions,
            _,
            isNewTraveler
          ) => {
            if (isNewTraveler)
              setLocalTravelerIds([...localTravelerIds, traveler.id]);

            updateUserPassenger(
              {
                person: traveler,
                hideProfileAction: hideProfileAction,
              },
              traveler.driverLicense !== undefined,
              isNewTraveler,
              undefined
            );
          }}
          isMobile
          onContinue={handleContinue}
          onGoBack={handleGoBack}
          onProgressChange={onProgressChange}
          progress={progress}
          saveButtonClicked={saveButtonClicked}
          selectedTravelerIds={selectedTravelerIds}
          selectionScreenHeaderElement={selectionScreenHeaderElement}
          setProgress={setProgress}
          setSelectedTravelerIds={(travelerIds: string[]) => {
            trackEvent({
              eventName: SELECT_TRAVELERS,
              properties: {},
            });

            const changedTravelersList = travelerIds.filter(
              (id) => !localTravelerIds.includes(id)
            );

            setTravelersChanged(changedTravelersList);
            setUserSelectedPassengerIds({
              userSelectedPassengerIds: travelerIds,
            });
          }}
          setShowErrors={setShowErrors}
          showErrors={showErrors}
          titles={{
            travelerInfoTitle: onReviewStep
              ? constants.TRAVELER_INFORMATION_TITLE(isMultiroomAmadeus)
              : "",
            travelerInfoSubtitle: onReviewStep
              ? TRAVELER_SELECT_DROPDOWN_DESCRIPTION(
                  roomsCount,
                  isAmadeusProvider,
                  isMultiroomAmadeus
                )
              : "",
            frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
            additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
            adultTitle: constants.ADULT_TITLE,
            childTitle: constants.CHILD_TITLE,
            infantSeatTitle: constants.INFANT_SEAT_TITLE,
            infantLapTitle: constants.INFANT_LAP_TITLE,
            addTravelers: onReviewStep
              ? TRAVELER_SELECT_DROPDOWN_TITLE(roomsCount, isAmadeusProvider)
              : constants.ADD_TRAVELER_INFO_TEXT,

            completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
            completeTravelerProfileSubtitle:
              constants.COMPLETE_PROFILE_SUBTITLE,
            editTravelerTitle: constants.EDIT_TRAVELER_TITLE,
            editTravelerSubtitle: constants.EDIT_TRAVELER_SUBTITLE,
          }}
          travelers={travelers}
          wrapButton={!onReviewStep}
          onClickAddNewTraveler={() => {
            trackEvent({
              eventName: CLICKED_ADD_TRAVELER,
              properties: addTrackingProperties(expState.trackingProperties, {
                entry_type: "checkout",
              } as ClickedAddTravelerProperties),
            });
          }}
          tenant={config.TENANT}
          trackEvent={trackEvent}
          maxTravelerCount={
            isMultiroomAmadeus && isAmadeusProvider ? roomsCount : undefined
          }
          customHeader={
            <Header
              roomsCount={roomsCount}
              isAmadeusProvider={isAmadeusProvider}
              isMultiroomAmadeus={isMultiroomAmadeus}
            />
          }
          showHotelLoyaltySection={showHotelLoyaltySection}
          bottomContent={
            showVoidWindowNotice ? <VoidWindowNotice /> : undefined
          }
        />
      )}
    </div>
  );
};
