import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useDeviceTypes } from "halifax";
import {
  SelectedTravelOfferScreen,
  CAP1_HOTEL_VIEWED_ADD_ON,
  getHotelViewedAddOnHotelCfarOfferFacts,
} from "redmond";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  HotelShopProgressBar,
  MobileShopHeader,
} from "../../../shop/components";
import { DesktopRewardsHeader } from "../../../rewards/components";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { goToShop } from "../../../shop/utils/queryStringHelpers";
import {
  AddOnHeader,
  AddOnCustomize,
  MobileAddOnCustomize,
  AddOnCustomizeStepAlias,
} from "../index";
import { HotelCustomizeConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import {
  AVAILABLE,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

export interface IHotelCustomizeProps
  extends HotelCustomizeConnectorProps,
    RouteComponentProps {}

export const HotelCustomize = (props: IHotelCustomizeProps) => {
  const {
    history,
    chosenProduct,
    selectedLodging,
    showOfferBasedOnSelectedLodging,
    hotelCfarQuote,
    additionalInfo,
  } = props;
  const [showOfferBanner, setShowOfferBanner] = useState(false);
  const [currentCustomizeStep, setCurrentCustomizeStep] =
    useState<AddOnCustomizeStepAlias>(AddOnCustomizeStepAlias.AddOn1);
  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  // note: same hook logic as what is added in hotel/book; it redirects the user to hotel/shop on page refresh.
  useEffect(() => {
    if (!chosenProduct || !selectedLodging) {
      goToShop({ history });
    }
  }, [chosenProduct, selectedLodging]);

  useEffect(() => {
    setShowOfferBanner(showOfferBasedOnSelectedLodging);
  }, [showOfferBasedOnSelectedLodging]);

  useEffect(() => {
    trackEvent({
      eventName: CAP1_HOTEL_VIEWED_ADD_ON,
      properties: {
        ...getHotelViewedAddOnHotelCfarOfferFacts(
          chosenProduct,
          hotelCfarQuote,
          additionalInfo
        ),
      },
    });

    setTimeout(() => window.scrollTo(0, 0), 100);
  }, []);

  const Content = () => {
    if (matchesMobile) {
      return (
        <>
          <MobileShopHeader
            onClickBackButton={() =>
              setCurrentCustomizeStep((step) => Math.max(step - 1, 0))
            }
            title={constants.CUSTOMIZE_HEADER_TITLE}
            variant="custom"
          />
          <MobileAddOnCustomize
            currentStep={currentCustomizeStep}
            setCurrentStep={setCurrentCustomizeStep}
          />
        </>
      );
    } else {
      return (
        <>
          <DesktopRewardsHeader variant="customize" />
          {showOfferBanner && selectedLodging?.bestOfferThisLodging && (
            <TravelWalletDetailsBanner
              onDismiss={() => setShowOfferBanner(false)}
              className="hotel-customize-travel-wallet-details-banner"
              offer={selectedLodging.bestOfferThisLodging}
              screen={SelectedTravelOfferScreen.HOTEL_CUSTOMIZE}
              bannerTextType="shop"
              showButton
            />
          )}
          <AddOnHeader />
          <Box className="main-section">
            <Box className="progress-bar-wrapper">
              <HotelShopProgressBar useCustomizeStep={true} />
            </Box>
            <AddOnCustomize />
          </Box>
        </>
      );
    }
  };

  return (
    <Box
      className={clsx("hotel-shop-customize-root", {
        mobile: matchesMobile,
        "global-mobile-nav": isGlobalMobileNavExperiment,
      })}
    >
      <Box className="hotel-shop-customize-container">
        <Content />
      </Box>
    </Box>
  );
};
