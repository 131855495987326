import React, { useEffect } from "react";
import {
  PriceFreezeConfirmation,
  useDeviceTypes,
  getTotalPriceText,
} from "halifax";
import {
  HOTEL_PRICE_FREEZE_VIEWED_CONFIRMATION,
  getHotelPriceFreezePurchaseOfferProperties,
} from "redmond";
import { Box } from "@material-ui/core";
import * as constants from "./constants";
import { BookingConfirmationPageConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import clsx from "clsx";
import "./styles.scss";
import dayjs from "dayjs";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IBookingConfirmationPage
  extends BookingConfirmationPageConnectorProps,
    RouteComponentProps {}

export const BookingConfirmationPage = ({
  lodging,
  history,
  hotelPriceFreezeConfirmationDetails,
  confirmationEmail,
  chosenPriceFreezeOffer,
  chosenRoomInfo,
  priceFreezeOffer,
}: IBookingConfirmationPage) => {
  const { matchesMobile } = useDeviceTypes();
  const durationDays = dayjs
    .duration({ hours: chosenPriceFreezeOffer!.durationHours })
    .asDays();
  const backupPriceFreezeExpirationDate = dayjs().add(
    dayjs.duration({ days: durationDays })
  );
  const priceFreezeExpirationDate =
    hotelPriceFreezeConfirmationDetails?.expirationUtc
      ? dayjs(hotelPriceFreezeConfirmationDetails.expirationUtc)
      : backupPriceFreezeExpirationDate;

  const summarySections = [
    {
      title: constants.CURRENT_PRICE,
      subtitle: `${getTotalPriceText({
        price: chosenPriceFreezeOffer!.pricing.frozenPricePerNight.fiat,
      })} per night`,
      footer: constants.CURRENT_FROZEN_PRICE_FOOTER,
    },
    {
      title: constants.COST_TO_FREEZE,
      subtitle: `${getTotalPriceText({
        price: chosenPriceFreezeOffer!.pricing.depositPerNight.fiat,
      })} per night`,
    },
    {
      title: constants.FROZEN_DURATION,
      subtitle: `${durationDays} ${durationDays > 1 ? "days" : "day"}`,
    },
    {
      title: constants.ROOM_TYPE,
      subtitle: chosenRoomInfo?.roomInfo.name || "",
    },
  ];
  const bannerCopy = constants.BANNER_COPY;
  const title = constants.TITLE(confirmationEmail || "");
  const subtitle = constants.SUBTITLE(
    hotelPriceFreezeConfirmationDetails?.confirmationCode ?? ""
  );
  const currentTimezoneAbbreviation = new Date()
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")
    .pop();
  const nextContent = constants.WHATS_NEXT_CONTENT(
    `${priceFreezeExpirationDate.format(
      constants.formats.VOID_BY_DATE
    )} ${currentTimezoneAbbreviation}`
  );

  useEffect(() => {
    trackEvent({
      eventName: HOTEL_PRICE_FREEZE_VIEWED_CONFIRMATION,
      properties: {
        success: true,
        ...getHotelPriceFreezePurchaseOfferProperties(
          priceFreezeOffer,
          priceFreezeExpirationDate.unix()
        ),
      },
    });
  }, []);

  return (
    <Box
      className={clsx("hotel-price-freeze-booking-success-container", {
        mobile: matchesMobile,
      })}
    >
      <PriceFreezeConfirmation
        isMobile={matchesMobile}
        bannerCopy={bannerCopy}
        title={title}
        subtitle={subtitle}
        nextHeader={constants.WHATS_NEXT_HEADER}
        nextContent={nextContent}
        lodging={lodging!}
        onClick={() => history.push(constants.FROZEN_PRICES_LIST_LINK)}
        CTAText={constants.CTA_TEXT}
        startDate={dayjs(chosenPriceFreezeOffer!.hotelDetails.checkIn).toDate()}
        endDate={dayjs(chosenPriceFreezeOffer!.hotelDetails.checkOut).toDate()}
        summarySections={summarySections}
      />
    </Box>
  );
};
