import React from "react";
import {
  B2BSpinner,
  TravelerSelectWorkflow,
  LoadingIndicator,
  TravelerSelectStep,
  useDeviceTypes,
  ToggleActions,
  VoidWindowNotice,
} from "halifax";
import {
  IPerson,
  PersonId,
  CallState,
  SELECT_TRAVELERS,
  ITravelerStepErrors,
  ClickedAddTravelerProperties,
  CLICKED_ADD_TRAVELER,
} from "redmond";
import clsx from "clsx";

import "./styles.scss";
import { HotelBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import { isCorpTenant } from "@capone/common";

export interface IHotelBookPassengerSelectionProps
  extends HotelBookPassengerSelectionConnectorProps {
  progress: TravelerSelectStep;
  setProgress: (progress: TravelerSelectStep) => void;
  onProgressChange?: (step: TravelerSelectStep) => void;
  onContinue?: (travelersChanged?: boolean) => void;
  onGoBack?: (travelersChanged?: boolean) => void;
  className?: string;
  selectionScreenHeaderElement?: JSX.Element;
  onTravelersStep?: boolean;
  isMobile?: boolean;
  disabled?: boolean;
  combinedStep?: boolean;
  showErrors?: ITravelerStepErrors;
  setShowErrors?: (showErrors: ITravelerStepErrors) => void;
  saveButtonClicked?: boolean;
  useLocalId?: boolean;
  hiddenTravelerIds: string[];
  showVoidWindowNotice?: boolean;
}

export const HotelBookPassengerSelection = (
  props: IHotelBookPassengerSelectionProps
) => {
  const {
    className,
    travelers,
    progress,
    selectionScreenHeaderElement,
    setProgress,
    onProgressChange,
    onContinue,
    onGoBack,
    selectedTravelerId,
    setUserSelectedPassengerIds,
    updateUserPassenger,
    deleteUserPassenger,
    fetchUserPassengers,
    fetchCorpUserPassengers,
    userPassengerCallState,
    onTravelersStep,
    isMobile,
    disabled,
    combinedStep,
    showErrors,
    setShowErrors,
    saveButtonClicked,
    useLocalId,
    showVoidWindowNotice,
  } = props;

  const [localTravelerId, setLocalTravelerId] = React.useState<string[]>([]);
  const currentTravelerId = useLocalId
    ? localTravelerId[0]
    : selectedTravelerId;
  const travelersChanged = selectedTravelerId !== localTravelerId[0];
  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  React.useEffect(() => {
    isCorpTenant(config.TENANT)
      ? fetchCorpUserPassengers()
      : fetchUserPassengers();
  }, []);

  React.useEffect(() => {
    if (travelers.length === 1) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: [travelers[0].id],
      });
    }
  }, [travelers]);

  const handleContinue = () => {
    if (useLocalId && travelersChanged) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: localTravelerId,
      });
    }
    onContinue && onContinue(travelersChanged);
  };

  const handleGoBack = () => {
    if (useLocalId && travelersChanged) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: localTravelerId,
      });
    }
    onGoBack && onGoBack(travelersChanged);
  };

  return userPassengerCallState === CallState.InProcess ? (
    <LoadingIndicator
      indicatorSize="small"
      indicator={B2BSpinner}
      message={
        userPassengerCallState === CallState.InProcess
          ? constants.UPDATE_TEXT
          : constants.VALIDATE_TEXT
      }
    />
  ) : (
    <TravelerSelectWorkflow
      displayModalSubtitle
      showGenderField
      showNationalityField
      singleTravelerWorkflow
      buttonClassName="b2b"
      className={clsx("hotel-book-passenger-selection-root", className, {
        "combined-step": combinedStep,
      })}
      disabled={disabled}
      errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
      handleDeletePassenger={(travelerId: PersonId) => {
        if (useLocalId) {
          if (localTravelerId[0] === travelerId) {
            setLocalTravelerId([]);
          }
        }
        deleteUserPassenger({ personId: travelerId });
      }}
      handleUpdatePassenger={(
        traveler: IPerson,
        hideProfileAction: ToggleActions,
        entryPoint,
        _,
        isNewTraveler
      ) => {
        if (useLocalId) {
          setLocalTravelerId([traveler.id]);
        }
        updateUserPassenger(
          {
            person: traveler,
            hideProfileAction: hideProfileAction,
          },
          traveler.driverLicense !== undefined,
          isNewTraveler,
          undefined,
          entryPoint
        );
      }}
      isMobile={matchesMobile}
      onContinue={handleContinue}
      onGoBack={handleGoBack}
      onProgressChange={onProgressChange}
      progress={progress}
      saveButtonClicked={saveButtonClicked}
      selectedTravelerIds={currentTravelerId ? [currentTravelerId] : []}
      selectionScreenHeaderElement={selectionScreenHeaderElement}
      setProgress={setProgress}
      setSelectedTravelerIds={(travelerIds: string[]) => {
        trackEvent({
          eventName: SELECT_TRAVELERS,
          properties: {},
        });
        if (useLocalId) {
          setLocalTravelerId(travelerIds);
        } else {
          setUserSelectedPassengerIds({
            userSelectedPassengerIds: travelerIds,
          });
        }
      }}
      setShowErrors={setShowErrors}
      showErrors={showErrors}
      titles={{
        travelerInfoTitle: matchesMobile
          ? constants.TRAVELER_INFO_TEXT
          : constants.TRAVELER_INFO_TITLE_UPDATED,
        travelerInfoSubtitle: matchesMobile
          ? constants.TRAVELER_INFO_SUBTITLE_MOBILE
          : constants.TRAVELER_INFO_SUBTITLE,
        frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
        additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
        adultTitle: constants.ADULT_TITLE,
        childTitle: constants.CHILD_TITLE,
        infantSeatTitle: constants.INFANT_SEAT_TITLE,
        infantLapTitle: constants.INFANT_LAP_TITLE,
        addTravelers: matchesMobile
          ? onTravelersStep
            ? constants.ADD_TRAVELER_INFO_TEXT
            : constants.TRAVELER_INFO_TEXT
          : constants.TRAVELER_INFO_TITLE_UPDATED,
        completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
        completeTravelerProfileSubtitle: constants.COMPLETE_PROFILE_SUBTITLE,
      }}
      travelers={travelers}
      wrapButton={isMobile && onTravelersStep}
      onClickAddNewTraveler={() => {
        trackEvent({
          eventName: CLICKED_ADD_TRAVELER,
          properties: addTrackingProperties(expState.trackingProperties, {
            entry_type: "checkout",
          } as ClickedAddTravelerProperties),
        });
      }}
      tenant={config.TENANT}
      trackEvent={trackEvent}
      bottomContent={showVoidWindowNotice ? <VoidWindowNotice /> : undefined}
    />
  );
};
