import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { setLocationAutocompleteError } from "../../actions/actions";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getHasLocationAutocompleteError,
  getLocation,
  getRoomsCount,
  getUntilDate,
} from "../../reducer";
import { MobileHotelSearchControlV2 } from "./component";

const mapDispatchToProps = {
  setLocationAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => ({
  hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
  value: getLocation(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  roomsCount: getRoomsCount(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelSearchV2ConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelSearchControl = withRouter(
  connector(MobileHotelSearchControlV2)
);
