import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { IStoreState } from "../../../../reducers/types";
import { getSession, hotelBookTypeSelector } from "../../reducer";
import { getPurchaseRequestMetaProps, getPurchaseApis } from "../../utils";

export function* closeSessionSaga(_: actions.ICloseSession) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    const hotelBookType = hotelBookTypeSelector(state);
    const { version } = getPurchaseRequestMetaProps({ hotelBookType });
    const { closeSession } = getPurchaseApis(version);

    if (session) {
      yield closeSession({
        token: session,
      });
      yield put(actions.clearSession());
    }
  } catch (e) {
    yield put(actions.clearSession());
  }
}
