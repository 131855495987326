import axios from "axios";
import { hotelPriceFreezePrefix } from "../paths";
import { config } from "../../config";
import { LodgingPriceFreezeBatchOfferRequest, LodgingPriceFreezeBatchOfferResponse } from "redmond";

const END_POINT: string = `${hotelPriceFreezePrefix}/batchOffers`;

export const fetchHotelPriceFreezeOffers = (body: LodgingPriceFreezeBatchOfferRequest): Promise<LodgingPriceFreezeBatchOfferResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(END_POINT, body, {
                baseURL: config.baseURL,
            });
            resolve(response.data);
        } catch (e) {
            reject(e);
        }
    });
}
