import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import { getSearchedNightCount } from "../../../availability/reducer";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getPetsCount,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import { HotelShopCallState } from "../../reducer/state";
import {
  getCheapestProduct,
  getHotelShopCallState,
  getHotelShopCancellationSummary,
  getHotelShopChosenProduct,
  getHotelShopSelectedLodging,
  getSearchDistanceFromShopProperties,
  getSimilarHotels,
  getSimilarHotelsAvailabilityCallState,
  getSimilarHotelsAvailabilityTrackingProperties,
  getViewedHotelDetailsProperties,
  hasHotelShopFailed,
  hotelShopProgressSelector,
  showOfferBasedOnSelectedLodgingSelector,
} from "../../reducer/selectors";
import { MobileShop } from "./component";
import { actions } from "../../actions";
import { baseHotelPriceFreezeOfferSelector } from "../../../freeze/reducer";
import { userPriceDropAmountSelector } from "../../../ancillary/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodging: getHotelShopSelectedLodging(state),
    cheapestProduct: getCheapestProduct(state),
    hotelShopCallInProgress:
      getHotelShopCallState(state) === HotelShopCallState.InProcess,
    chosenProduct: getHotelShopChosenProduct(state),
    hasFailed: hasHotelShopFailed(state),
    hotelShopStep: hotelShopProgressSelector(state),
    similarHotels: getSimilarHotels(state),
    similarHotelsAvailabilityCallState:
      getSimilarHotelsAvailabilityCallState(state),
    selectedAccountReferenceId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    similarHotelsAvailabilityTrackingProperties:
      getSimilarHotelsAvailabilityTrackingProperties(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    viewedHotelDetailsProperties: getViewedHotelDetailsProperties(state),
    searchDistanceFromShopProperties:
      getSearchDistanceFromShopProperties(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    roomsCount: getRoomsCount(state),
    petsCount: getPetsCount(state),
    hotelShopCallState: getHotelShopCallState(state),
    nightCount: getSearchedNightCount(state),
    showOfferBasedOnSelectedLodging:
      showOfferBasedOnSelectedLodgingSelector(state),
    baseHotelPriceFreezeOffer: baseHotelPriceFreezeOfferSelector(state),
    userPriceDropAmount: userPriceDropAmountSelector(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    cancellationSummary: getHotelShopCancellationSummary(state),
  };
};

export const mapDispatchToProps = {
  setHotelShopProgress: actions.setHotelShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShop = withRouter(connector(MobileShop));
