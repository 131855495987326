import dayjs from "dayjs";
import { getTotalPriceText } from "halifax";
import { FiatPrice } from "redmond";

export const CURRENT_PRICE_FROZEN_TITLE = `Current price being frozen:`;
export const FROZEN_DURATION_TITLE = `Frozen price duration:`;
export const COST_TO_FREEZE_TITLE = "Cost to freeze x 1 room:";

export const TAXES_AND_FEES_NOT_INCLUDED = "Taxes and fees not included";

export const PRICE_FREEZE_EXPLANATION_TEXTS = (cap: FiatPrice) => {
  return {
    depositAppliedText:
      "When you book with your price freeze, <strong>the amount you pay today will be applied toward your final booking price</strong>.",
    increaseText: `<strong>If the price goes up after you freeze</strong>, we'll cover the difference up to ${getTotalPriceText(
      { price: cap }
    )} when you book through Capital One Travel.`,
    decreaseText:
      "<strong>If the price goes down after you freeze</strong>, you pay the lower price when you book through Capital One Travel.",
    noReserveText:
      "Freezing the price doesn't reserve this room, but <strong>we'll give you a full refund</strong> if it isn't available when you try to book.",
  };
};

export const READ_TERMS = "Read terms and conditions";

export const COST_TO_FREEZE = (fiat: FiatPrice) => {
  return `${getTotalPriceText({ price: fiat })}`;
};

export const CURRENT_PRICE_FROZEN = (fiat: FiatPrice) => {
  return `${getTotalPriceText({ price: fiat })} per night`;
};

export const FROZEN_DURATION = (durationHours: number) => {
  return `${dayjs.duration({ hours: durationHours }).asDays()} days`;
};
