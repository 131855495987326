import React from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import { HotelFreezeReviewItineraryConnectorProps } from "./container";
import "./styles.scss";
import { HotelSummaryPanel } from "halifax";

export interface IHotelFreezeReviewItineraryProps
  extends HotelFreezeReviewItineraryConnectorProps,
    RouteComponentProps {
  title?: string;
  isMobile?: boolean;
}

export const HotelFreezeReviewItinerary = ({
  title,
  isMobile,
  selectedLodging,
  fromDate,
  untilDate,
}: IHotelFreezeReviewItineraryProps) => {
  // TODO: correct hotel type once the corresponding state value is known
  const renderHotelSummaryPanel = (selectedLodging: any) => {
    return (
      <>
        <HotelSummaryPanel
          className="price-freeze-hotel-summary"
          selectedLodging={selectedLodging}
          checkIn={fromDate}
          checkOut={untilDate}
          isMobile={isMobile}
          hideImage={false}
        />
      </>
    );
  };

  return (
    <>
      <Box
        className={clsx("hotel-freeze-review-itinerary-root", {
          mobile: isMobile,
          //   "from-review-itinerary": !isCurrentLowestPrice,
        })}
      >
        <Box className="hotel-freeze-review-itinerary-container">
          <Box className="title-and-change-button-section">
            {title && (
              <Box className="title-wrapper">
                <Typography className="title-text" variant="h2">
                  {title}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className="review-itinerary-section">
            <Box className={clsx("hotel-summary-panel-wrapper")}>
              {renderHotelSummaryPanel(selectedLodging)}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
