import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { verifyPaymentMethod } from "../../../../api/v0/book/payment-methods/verifyPaymentMethod";
import {
  AddPaymentProperties,
  ADD_PAYMENT,
  PaymentVerifyResult,
  PaymentVerifyResultEnum,
  HOTEL_PRICE_FREEZE_CLICK_ADD_PAYMENT,
  HotelBookType,
  Success,
  HotelPriceFreezePurchaseOfferProperties,
  getHotelPriceFreezePurchaseOfferProperties,
  NEW_PAYMENT_METHOD_ADDED,
  NewPaymentMethodAddedProperties,
} from "redmond";
import { hotelBookTypeSelector } from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { getHotelShopChosenPriceFreezeOffer } from "../../../shop/reducer";
import { addTrackingProperties } from "../../../../context/experiments";
export function* verifyPaymentMethodSaga({
  payload,
  accountId,
  isVCN,
}: actions.IVerifyPaymentMethod) {
  try {
    const state: IStoreState = yield select();
    const hotelBookType = hotelBookTypeSelector(state);
    const hotelPriceFreezeOffer = getHotelShopChosenPriceFreezeOffer(state);
    const hotelPriceFreezePurchaseOfferProperties =
      getHotelPriceFreezePurchaseOfferProperties(hotelPriceFreezeOffer);

    const result: PaymentVerifyResult = yield verifyPaymentMethod(payload);
    switch (result.PaymentVerifyResult) {
      case PaymentVerifyResultEnum.CardDeclined:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.ExcessiveUserActivity:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.GenericError:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.Success:
        yield put(actions.verifyPaymentMethodCallStateSuccess());
        // Immediately set the selected payment method on addition.
        yield put(
          actions.setSelectedPaymentMethodId({
            paymentMethodId: (result as Success).payment.id,
            accountId,
          })
        );
        const properties: AddPaymentProperties = {
          success: true,
          is_vcn: isVCN,
        };
        handleTrackEvent(
          properties,
          hotelBookType,
          hotelPriceFreezePurchaseOfferProperties
        );
        trackEvent({
          eventName: NEW_PAYMENT_METHOD_ADDED,
          properties: addTrackingProperties(undefined, {
            entry_type: "checkout",
          } as NewPaymentMethodAddedProperties),
        });
        yield put(actions.listPaymentMethods());
        return;
      default:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
    }
    const properties: AddPaymentProperties = {
      success: false,
      is_vcn: isVCN,
    };
    handleTrackEvent(
      properties,
      hotelBookType,
      hotelPriceFreezePurchaseOfferProperties
    );
  } catch (e) {
    const state: IStoreState = yield select();
    const hotelBookType = hotelBookTypeSelector(state);
    const hotelPriceFreezeOffer = getHotelShopChosenPriceFreezeOffer(state);
    const hotelPriceFreezePurchaseOfferProperties =
      getHotelPriceFreezePurchaseOfferProperties(hotelPriceFreezeOffer);
    const properties: AddPaymentProperties = {
      success: false,
      is_vcn: isVCN,
    };
    handleTrackEvent(
      properties,
      hotelBookType,
      hotelPriceFreezePurchaseOfferProperties
    );
    yield put(
      actions.verifyPaymentMethodCallStateFailed(
        PaymentVerifyResultEnum.GenericError
      )
    );
  }
}

const handleTrackEvent = (
  properties: AddPaymentProperties,
  hotelBookType: HotelBookType,
  hotelPriceFreezePurchaseOfferProperties?: HotelPriceFreezePurchaseOfferProperties
) => {
  switch (hotelBookType) {
    case HotelBookType.PRICE_FREEZE_PURCHASE:
      trackEvent({
        eventName: HOTEL_PRICE_FREEZE_CLICK_ADD_PAYMENT,
        properties: {
          success: properties.success,
          ...hotelPriceFreezePurchaseOfferProperties,
        },
      });
      break;
    case HotelBookType.PRICE_FREEZE_EXERCISE:
    case HotelBookType.DEFAULT:
    default:
      trackEvent({
        eventName: ADD_PAYMENT,
        properties,
      });
      break;
  }
};
