import React, { useState } from "react";
import { PriceFreezeActionBox, getTotalPriceText } from "halifax";
import { RouteComponentProps } from "react-router";
import { PriceFreezeActionConnectorProps } from "./container";
import { PriceFreezeExplanationModal } from "../PriceFreezeExplanationModal/component";
import * as constants from "./constants";

export interface IPriceFreezeActionProps
  extends PriceFreezeActionConnectorProps,
    RouteComponentProps {
  onClickHandler: () => void;
  size: "small" | "medium" | "large";
  setPriceFreezeEnabled?: (arg: boolean) => void;
  onInfoClickHandler?: () => void;
  onInfoContinueClickHandler?: () => void;
}

export const PriceFreezeAction = (props: IPriceFreezeActionProps) => {
  const {
    baseHotelPriceFreezeOffer,
    onClickHandler,
    size,
    searchedRoomsCount,
    setPriceFreezeEnabled,
    onInfoClickHandler,
    onInfoContinueClickHandler,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  // checking searchedRoomsCount so that we only allow freezing one room for MVP
  if (baseHotelPriceFreezeOffer && searchedRoomsCount == 1) {
    const durationHours = baseHotelPriceFreezeOffer.durationHours;
    const cap = getTotalPriceText({
      price: baseHotelPriceFreezeOffer.pricing.cap.fiat,
    });
    const basePricePerNight = getTotalPriceText({
      price: baseHotelPriceFreezeOffer.pricing.depositPerNight.fiat,
    });
    const InfoClick = () => {
      const titles = {
        header: constants.HEADER,
        title: constants.TITLE(durationHours, basePricePerNight),
        infoText: constants.INFO_TEXT,
        increaseText: constants.INCREASE_TEXT(cap),
        decreaseText: constants.DECREASE_TEXT,
        refundText: constants.REFUND_TEXT,
        ctaText: constants.CTA_TEXT,
      };
      return (
        <PriceFreezeExplanationModal
          priceFreezeExplanationModalOpen={modalOpen}
          priceFreezeExplanationModalTitles={titles}
          setPriceFreezeExplanationModalOpen={(bool: boolean) =>
            setModalOpen(bool)
          }
          onContinue={
            onInfoContinueClickHandler
              ? onInfoContinueClickHandler
              : onClickHandler
          }
        />
      );
    };
    if (setPriceFreezeEnabled) {
      setPriceFreezeEnabled(true);
    }
    return (
      <PriceFreezeActionBox
        priceFreezeDurationHours={durationHours}
        priceFreezeCap={cap}
        onClickHandler={onClickHandler}
        onInfoClickHandler={(bool: boolean) => {
          if (onInfoClickHandler) {
            onInfoClickHandler();
          }
          setModalOpen(bool);
        }}
        isInfoModalOpen={modalOpen}
        infoModal={<InfoClick />}
        size={size}
      />
    );
  }
  if (setPriceFreezeEnabled) {
    setPriceFreezeEnabled(false);
  }
  return null;
};
