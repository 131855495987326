import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchHotelPriceFreezeDetailsSaga } from "./fetchHotelPriceFreezeDetailsSaga";
import { fetchHotelPriceFreezeOffersSaga } from "./fetchHotelPriceFreezeOffersSaga";
import { refundHotelPriceFreezeSaga } from "./refundHotelPriceFreezeSaga";
import { getHotelPriceFreezeCreditsSaga } from "./getHotelPriceFreezeCreditsSaga";
import { fetchHotelAvailabilityTrackingSaga } from "./fetchHotelAvailabilityTrackingSaga";

export function* watchFetchHotelPriceFreezeDetailsSaga() {
  yield takeLatest(
    actionTypes.FETCH_HOTEL_PRICE_FREEZE_DETAILS,
    fetchHotelPriceFreezeDetailsSaga
  );
}

export function* watchFetchHotelPriceFreezeOffersSaga() {
  yield takeLatest(
    actionTypes.FETCH_HOTEL_PRICE_FREEZE_OFFERS,
    fetchHotelPriceFreezeOffersSaga
  );
}

export function* watchRefundHotelPriceFreezeSaga() {
  yield takeLatest(
    actionTypes.REFUND_HOTEL_PRICE_FREEZE,
    refundHotelPriceFreezeSaga
  );
}

export function* watchGetHotelPriceFreezeCreditsSaga() {
  yield takeLatest(
    actionTypes.GET_HOTEL_PRICE_FREEZE_CREDITS,
    getHotelPriceFreezeCreditsSaga
  );
}

export function* watchFetchHotelAvailabilityTrackingSaga() {
  yield takeLatest(
    actionTypes.FETCH_HOTEL_AVAILABILITY_TRACKING,
    fetchHotelAvailabilityTrackingSaga
  );
}
