import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { scheduleBook } from "../../../../../book/actions/actions";
import { MobileHotelFreezeWorkflow } from "./component";
import {
  getIsBookingValid,
  getConfirmationEmail,
  getHotelBookChosenProductIndex,
  getHotelBookChosenRoomInfo,
  getHotelBookSelectedAvailability,
  getRoomsCountInBook,
  getRewardsPaymentAccountReferenceId,
} from "../../../../../book/reducer";
import { IStoreState } from "../../../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    isBookingValid: getIsBookingValid(state),
    confirmationEmail: getConfirmationEmail(state),
    roomInfoProduct: getHotelBookChosenRoomInfo(state),
    chosenProductIndex: getHotelBookChosenProductIndex(state),
    selectedLodging: getHotelBookSelectedAvailability(state),
    roomsCount: getRoomsCountInBook(state),
    //finalizedPriceFreeze: getFinalizedPriceFreeze(state),
    rewardsPaymentAccountReferenceId:
      getRewardsPaymentAccountReferenceId(state),
  };
};

const mapDispatchToProps = {
  scheduleBook,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelFreezeWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelFreezeWorkflow = withRouter(
  connector(MobileHotelFreezeWorkflow)
);
