import axios from "axios";
import { config } from "../../config";

import { ShopResponse, ShopRequest, VIEWED_HOTEL_SHOP_LOADING } from "redmond";
import { hotelShopApiPrefix } from "../paths";
import { trackEvent } from "../analytics/trackEvent";
import dayjs from "dayjs";

export const fetchHotelShop = (body: ShopRequest): Promise<ShopResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const startTime = dayjs();
      const res = await axios.post(hotelShopApiPrefix, body, {
        baseURL: config.baseURL,
      });

      trackEvent({
        eventName: VIEWED_HOTEL_SHOP_LOADING,
        properties: { runtime: dayjs().diff(startTime, "seconds", true) },
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
