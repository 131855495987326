import { Ancillary, AncillaryOpaqueValue } from "@b2bportal/purchase-api";
import { createSelector } from "@reduxjs/toolkit";
import {
  ProductCfarQuote,
  CfarQuoteResultEnum,
  CfarQuoteEnum,
  HotelCfarQuote,
  HotelCancellationPolicyV2,
  HotelCfarQuoteAdditionalInfo,
  ISearchCfarQuoteByIndex,
  ISearchCancellationPolicyByIndex,
  ISearchAdditionalInfoByIndex,
} from "redmond";
import { DO_NOT_APPLY_OPTION_KEY } from "..";
import {
  AVAILABLE,
  HOTELS_CFAR,
  HOTEL_CFAR_REFUND_DISPLAY,
  HOTELS_LFAR,
  HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_RR,
  HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES,
  HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT,
  HOTELS_CFAR_ELIGIBLE_POLICY,
  HOTELS_CFAR_ELIGIBLE_POLICY_PARTIALLY_REFUNDABLE,
  FINTECH_HOTEL_UX_UPDATED,
  HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL,
  HOTEL_CFAR_RR_MULTIPLE_CANCELLATION_POLICIES,
  HOTEL_PD_BANNER_COPY,
  HOTEL_PD_BANNER_COPY_PREMIER_AND_LIFESTYLE,
  HOTEL_DISPLAY_ROOM_PRICING_UPDATE,
  HOTEL_CFAR_MODEL_V1,
  CFAR_MODEL_RANDOM_PERCENT_16_26,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28,
  CFAR_MODEL_ADVANCE_DAYS_10_21,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28_COMPARISON,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_COPY,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_NEW_UX,
  CFAR_MODEL_RANDOM_PERCENT_5_30_FR_ELIGIBLE,
  HOTEL_CFAR_ROOM_ID_MATCH,
  CFAR_MODEL_STAR_AND_GBV_15_28_FR_ELIGIBLE_NEW_UX,
  HOTELS_TAXES_AND_FEES_EXPERIMENT,
} from "../../../../context/experiments";
import { IStoreState } from "../../../../reducers/types";

export const ancillaryExperimentsSelector = (state: IStoreState) =>
  state.hotelAncillary.experiments;

export const ancillaryStateSelector = (state: IStoreState) =>
  state.hotelAncillary;

export const hotelCfarVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTELS_CFAR]
);

export const isHotelCfarEnabledSelector = createSelector(
  hotelCfarVariantSelector,
  (hotelCfarVariant) => hotelCfarVariant === AVAILABLE
);

export const hotelCfarRefundDisplayVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTEL_CFAR_REFUND_DISPLAY]
);

export const isHotelCfarRefundDisplayShowAmountEnabledSelector = createSelector(
  hotelCfarRefundDisplayVariantSelector,
  (hotelCfarRefundDisplayVariant) =>
    hotelCfarRefundDisplayVariant ===
    HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_RR
);

export const isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector =
  createSelector(
    hotelCfarRefundDisplayVariantSelector,
    (hotelCfarRefundDisplayVariant) =>
      hotelCfarRefundDisplayVariant ===
      HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES
  );

export const isHotelCfarRefundDisplay80PercentRefundAmountEnabledSelector =
  createSelector(
    hotelCfarRefundDisplayVariantSelector,
    (hotelCfarRefundDisplayVariant) =>
      hotelCfarRefundDisplayVariant ===
      HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT
  );

export const isHotelCfarRefundDisplayNonRefPartialEnabledSelector =
  createSelector(
    hotelCfarRefundDisplayVariantSelector,
    (hotelCfarRefundExperimentVariant) =>
      hotelCfarRefundExperimentVariant ===
      HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL
  );

export const hotelCfarEligiblePolicyVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTELS_CFAR_ELIGIBLE_POLICY]
);

export const isRefundableRoomsWithPartiallyRefundablePolicyEnabledSelector =
  createSelector(
    hotelCfarEligiblePolicyVariantSelector,
    (hotelCfarEligiblePolicyVariant) =>
      hotelCfarEligiblePolicyVariant ===
      HOTELS_CFAR_ELIGIBLE_POLICY_PARTIALLY_REFUNDABLE
  );

export const fintechHotelUpdatedUxVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => {
    return experiments?.[FINTECH_HOTEL_UX_UPDATED];
  }
);

export const isFintechHotelUpdatedUxEnabledSelector = createSelector(
  fintechHotelUpdatedUxVariantSelector,
  (fintechHotelUpdatedUxVariant) => {
    return fintechHotelUpdatedUxVariant === AVAILABLE;
  }
);

export const hotelLfarVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTELS_LFAR]
);

export const isHotelLfarEnabledSelector = createSelector(
  hotelLfarVariantSelector,
  (hotelLfarVariant) => hotelLfarVariant === AVAILABLE
);

export const hotelPriceDropBannerCopyVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTEL_PD_BANNER_COPY]
);

export const hotelPriceDropBannerPremierAndLifestyleEnabledSelector =
  createSelector(
    hotelPriceDropBannerCopyVariantSelector,
    (hotelPriceDropBannerCopyVariant) => {
      return (
        hotelPriceDropBannerCopyVariant ===
        HOTEL_PD_BANNER_COPY_PREMIER_AND_LIFESTYLE
      );
    }
  );

export const hotelCfarRrMultipleCancellationPoliciesVariantSelector =
  createSelector(
    ancillaryExperimentsSelector,
    (experiments) => experiments?.[HOTEL_CFAR_RR_MULTIPLE_CANCELLATION_POLICIES]
  );

export const isHotelCfarRrMultipleCancellationPoliciesEnabledSelector =
  createSelector(
    hotelCfarRrMultipleCancellationPoliciesVariantSelector,
    (hotelCfarRrMultipleCancellationPoliciesVariant) =>
      hotelCfarRrMultipleCancellationPoliciesVariant == AVAILABLE
  );

export const hotelDisplayRoomPricingUpdateVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTEL_DISPLAY_ROOM_PRICING_UPDATE]
);

export const isHotelDisplayRoomPricingUpdateEnabledSelector = createSelector(
  hotelDisplayRoomPricingUpdateVariantSelector,
  (experiment) => experiment === AVAILABLE
);

export const userEligibleHotelPriceDropSelector = (state: IStoreState) =>
  state.hotelAncillary.priceDropEligible;

export const userPriceDropAmountSelector = (state: IStoreState) =>
  state.hotelAncillary.priceDropAmount;

export const fetchPriceDropEligibilityCallStateSelector = (
  state: IStoreState
) => state.hotelAncillary.fetchPriceDropEligibilityCallState;

export const isCustomizePageEnabledSelector = createSelector(
  isHotelCfarEnabledSelector,
  (isHotelCfarEnabled) => isHotelCfarEnabled
);

export const lodgingIdWithCfarQuotesSelector = (state: IStoreState) =>
  state.hotelAncillary.lodgingId;

export const roomInfoCfarQuotesSelector = (state: IStoreState) =>
  state.hotelAncillary.roomInfoCfarQuotes;

export const fetchCfarQuotesCallStateSelector = (state: IStoreState) =>
  state.hotelAncillary.fetchCfarQuoteCallState;

export const selectedCfarIdSelector = (state: IStoreState) =>
  state.hotelAncillary.selectedCfarId;

export const hasSelectedRefundableRoomSelector = (state: IStoreState) =>
  state.hotelAncillary.hasSelectedRefundableRoom;

export const hasCfarAttached = createSelector(
  selectedCfarIdSelector,
  (selectedCfarId): boolean =>
    !!selectedCfarId && selectedCfarId.value !== DO_NOT_APPLY_OPTION_KEY
);

export const getAllAncillaries = createSelector(
  selectedCfarIdSelector,
  (selectedCfarId): AncillaryOpaqueValue[] => {
    const ancillaries: AncillaryOpaqueValue[] = [];
    if (selectedCfarId && selectedCfarId.value !== DO_NOT_APPLY_OPTION_KEY) {
      ancillaries.push({
        type: Ancillary.CFAR,
        value: selectedCfarId,
      });
    }

    return ancillaries;
  }
);

// TODO - CFAR: add the selector that returns the CFAR offer by the room id
/*
  note: this selector is an intermediate solution until the room id gets added into the response;
  for example, use the following states from the shop/reducer to work with this selector:
    1) chosenRoomInfoIndex - getHotelShopChosenRoomInfoIndex
    2) chosenProductIndex - getHotelShopChosenProductIndex
*/
export const getHotelProductCfarQuoteByIndexSelector = createSelector(
  roomInfoCfarQuotesSelector,
  (
      roomInfoCfarQuotes
    ): ((props: ISearchCfarQuoteByIndex) => ProductCfarQuote | null) =>
    ({ roomInfoIndex, productIndex }: ISearchCfarQuoteByIndex) => {
      return (
        roomInfoCfarQuotes[roomInfoIndex]?.cfarQuotes[productIndex] ?? null
      );
    }
);

const getHotelCfarProductByRoomIdSelector = createSelector(
  roomInfoCfarQuotesSelector,
  (
      roomInfoCfarQuotes
    ): ((props: {
      selectedRoomId?: string;
      selectedRateId?: string;
    }) => ProductCfarQuote | undefined) =>
    ({ selectedRoomId, selectedRateId }) => {
      const roomInfoCfarQuotesByRoomId = roomInfoCfarQuotes.find(
        (roomInfoCfarQuote) =>
          roomInfoCfarQuote.roomInfo.roomId === selectedRoomId
      );

      return roomInfoCfarQuotesByRoomId?.cfarQuotes.find((quote) => {
        if (quote.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
          return selectedRateId === quote.rateId?.value;
        }
        return false;
      });
    }
);

export const getHotelCfarQuoteByRoomIdSelector = createSelector(
  getHotelCfarProductByRoomIdSelector,
  (
      getHotelCfarProductByRoomId
    ): ((props: {
      selectedRoomId?: string;
      selectedRateId?: string;
    }) => HotelCfarQuote | null) =>
    ({ selectedRoomId, selectedRateId }) => {
      const cfarProduct = getHotelCfarProductByRoomId({
        selectedRoomId,
        selectedRateId,
      });
      if (cfarProduct?.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
        if (
          cfarProduct.cfarQuoteResult.CfarQuoteResult ===
          CfarQuoteResultEnum.HotelCfarQuote
        ) {
          return cfarProduct.cfarQuoteResult;
        }
      }
      return null;
    }
);

export const getHotelCfarQuoteAdditionalInfoByRoomIdSelector = createSelector(
  getHotelCfarProductByRoomIdSelector,
  (
      getHotelCfarProductByRoomId
    ): ((props: {
      selectedRoomId?: string;
      selectedRateId?: string;
    }) => HotelCfarQuoteAdditionalInfo | null) =>
    ({ selectedRoomId, selectedRateId }) => {
      const cfarProduct = getHotelCfarProductByRoomId({
        selectedRoomId,
        selectedRateId,
      });
      if (cfarProduct?.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
        if (
          cfarProduct.cfarQuoteResult.CfarQuoteResult ===
          CfarQuoteResultEnum.HotelCfarQuote
        ) {
          return cfarProduct.additionalInfo;
        }
      }
      return null;
    }
);

export const getHotelCfarQuoteCancellationByRoomIdSelector = createSelector(
  getHotelCfarProductByRoomIdSelector,
  (
      getHotelCfarProductByRoomId
    ): ((props: {
      selectedRoomId?: string;
      selectedRateId?: string;
    }) => HotelCancellationPolicyV2 | null) =>
    ({ selectedRoomId, selectedRateId }) => {
      const cfarProduct = getHotelCfarProductByRoomId({
        selectedRoomId,
        selectedRateId,
      });
      if (cfarProduct?.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
        if (
          cfarProduct.cfarQuoteResult.CfarQuoteResult ===
          CfarQuoteResultEnum.HotelCfarQuote
        ) {
          return cfarProduct.cancellationPolicyV2;
        }
      }
      return null;
    }
);

export const getHotelCfarQuoteByIndexSelector = createSelector(
  getHotelProductCfarQuoteByIndexSelector,
  (
      getHotelProductCfarQuoteByIndex
    ): ((props: ISearchCfarQuoteByIndex) => HotelCfarQuote | null) =>
    (props: ISearchCfarQuoteByIndex) => {
      const productCfarQuote = getHotelProductCfarQuoteByIndex(props);

      if (productCfarQuote?.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
        const cfarQuoteResult = productCfarQuote.cfarQuoteResult;

        if (
          cfarQuoteResult.CfarQuoteResult === CfarQuoteResultEnum.HotelCfarQuote
        ) {
          return cfarQuoteResult;
        }
      }

      return null;
    }
);

export const getHotelCancellationPolicyByIndexSelector = createSelector(
  getHotelProductCfarQuoteByIndexSelector,
  (
      getHotelProductCfarQuoteByIndex
    ): ((
      props: ISearchCancellationPolicyByIndex
    ) => HotelCancellationPolicyV2 | null) =>
    (props: ISearchCancellationPolicyByIndex) => {
      const productCfarQuote = getHotelProductCfarQuoteByIndex(props);

      if (productCfarQuote?.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
        return productCfarQuote.cancellationPolicyV2;
      }

      return null;
    }
);

export const getHotelAdditionalInfoByIndexSelector = createSelector(
  getHotelProductCfarQuoteByIndexSelector,
  (
      getHotelProductCfarQuoteByIndex
    ): ((
      props: ISearchAdditionalInfoByIndex
    ) => HotelCfarQuoteAdditionalInfo | null) =>
    (props: ISearchAdditionalInfoByIndex) => {
      const productCfarQuote = getHotelProductCfarQuoteByIndex(props);

      if (productCfarQuote?.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
        return productCfarQuote.additionalInfo;
      }

      return null;
    }
);

export const hotelCfarModelV1VariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiment) => experiment?.[HOTEL_CFAR_MODEL_V1]
);

// we are checking if the user is enrolled in the new model pricing variants
// by checking !Any(legacy_variants).
// previosuly we were checking Any(new_variants), but that implementation
// was too restricitive with the new addition of variants that were
// falling under the new pricing model.
export const isHotelCfarModelV1EnabledSelector = createSelector(
  hotelCfarModelV1VariantSelector,
  (variant) => {
    if (!variant) {
      return false;
    }
    if (
      // these are the legacy (old) variants
      variant.variantName == CFAR_MODEL_ADVANCE_DAYS_10_21 ||
      variant.variantName == CFAR_MODEL_RANDOM_PERCENT_16_26 ||
      variant.variantName == CFAR_MODEL_ADVANCE_STAR_RATING_23_28 ||
      variant.variantName == CFAR_MODEL_ADVANCE_STAR_RATING_23_28_COMPARISON
    ) {
      return false;
    }
    return true;
  }
);

export const shouldDisableRefundableRoomsSelector = createSelector(
  ancillaryStateSelector,
  (ancillaryState) => ancillaryState.shouldDisableRefundableRooms
);

export const isHotelCfarModelV1RefundableRoomUpdatedCopySelector =
  createSelector(
    hotelCfarModelV1VariantSelector,
    (variant) =>
      variant?.variantName ===
      CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_COPY
  );

export const isHotelCfarModelV1RefundableRoomUpdatedUXSelector = createSelector(
  hotelCfarModelV1VariantSelector,
  (variant) => {
    if (variant?.variantName) {
      return [
        CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_NEW_UX,
        CFAR_MODEL_RANDOM_PERCENT_5_30_FR_ELIGIBLE,
        CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_COPY,
        CFAR_MODEL_STAR_AND_GBV_15_28_FR_ELIGIBLE_NEW_UX,
      ].includes(variant.variantName);
    }
    return false;
  }
);

export const matchCfarQuoteWithRoomIdVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTEL_CFAR_ROOM_ID_MATCH]
);

export const useMatchCfarQuoteWithRoomIdSelector = createSelector(
  matchCfarQuoteWithRoomIdVariantSelector,
  (variant) => variant === AVAILABLE
);

export const getHasAddedCfarFromModalSelector = createSelector(
  ancillaryStateSelector,
  (ancillaryState) => ancillaryState.hasAddedCfarFromModal ?? false
);

export const hotelTaxesAndFeesExperimentVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTELS_TAXES_AND_FEES_EXPERIMENT]
);
