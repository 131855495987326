import { AvailabilityResponse } from "redmond";
import { fetchHotelAvailability } from "../../../api/v0/availability/fetchHotelAvailability";
import Logger from "../../../utils/logger";
import { actions } from "../actions";
import { put } from "redux-saga/effects";

export function* fetchHotelAvailabilityTrackingSaga(
  action: actions.IFetchHotelAvailabilityTracking
) {
  try {
    const { request } = action;

    const response: AvailabilityResponse = yield fetchHotelAvailability(
      request
    );

    if (response.lodgings && response.trackingPropertiesV2) {
      yield put(
        actions.setHotelAvailabilityTracking(response.trackingPropertiesV2)
      );
    }
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setHotelAvailabilityTracking(null));
  }
}
