import { takeLatest } from "redux-saga/effects";

import { actionTypes } from "../actions";
import { fetchCfarQuotesSaga } from "./fetchCfarQuotesSaga";
import { fetchPriceDropEligibilitySaga } from "./fetchPriceDropEligibilitySaga";

export function* watchFetchCfarOffers() {
  yield takeLatest(actionTypes.FETCH_CFAR_QUOTES, fetchCfarQuotesSaga);
}

export function* watchFetchPriceDropEligibility() {
  yield takeLatest(
    actionTypes.FETCH_PRICE_DROP_ELIGIBILITY,
    fetchPriceDropEligibilitySaga
  );
}
