import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import { HotelFreezeReviewItinerary } from "./component";
import { getHotelShopSelectedLodging } from "../../../../../shop/reducer";
import { getFromDate, getUntilDate } from "../../../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedLodging: getHotelShopSelectedLodging(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelFreezeReviewItineraryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelFreezeReviewItinerary = withRouter(
  connector(HotelFreezeReviewItinerary)
);
