import React from "react";
import {
  Box,
  Typography,
  Popover,
  Link,
  InputAdornment,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { HotelPriceBreakdown } from "halifax";
import { HotelBookType } from "redmond";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { FrozenPriceSummary } from "../../../freeze/components";
import { PriceBreakdownDropdownConnectorProps } from "./container";
import { TRIP_TOTAL_TEXT } from "./textConstants";
import "./styles.scss";

export interface IPriceBreakdownDropdownProps
  extends PriceBreakdownDropdownConnectorProps,
    RouteComponentProps {
  popoverClassName?: string;
}

export const PriceBreakdownDropdown = ({
  pricingLineItems,
  rewardsAndTotalLineItems,
  tripTotal,
  popoverClassName,
  hotelBookType,
}: IPriceBreakdownDropdownProps) => {
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);
  const pricingItems = pricingLineItems[0];
  const totalItems = pricingLineItems[1];
  const tripTotalItem =
    totalItems?.find((item) => item.type === "total") ??
    totalItems?.find((item) => item.type === "due-today");
  const showFrozenPriceSummary =
    hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE;

  const togglePopover = () => {
    setOpenPopover((open) => !open);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  if (!tripTotal) {
    return null;
  }

  // TODO: refactor GenericDropdown component in Halifax so that it can display ReactNode for label, and then refactor this
  // to use the reworked GenericDropdown component.
  return (
    <>
      <Link
        className="price-breakdown-dropdown-root"
        onClick={togglePopover}
        component={"button"}
      >
        {/* note: this is dropdownLabel */}
        <Box className="total-price-label-container">
          <Typography
            className="trip-total"
            variant="subtitle2"
          >{`${TRIP_TOTAL_TEXT}:`}</Typography>
          <Typography className={clsx("value", "fiat")} variant="subtitle1">
            {`${tripTotal.fiat.currencyCode} ${tripTotalItem?.value}`}
          </Typography>
          {tripTotalItem?.rewardsValue !== undefined && (
            <>
              <Typography className="separator" variant="subtitle2">
                /
              </Typography>
              <Typography
                className={clsx("value", "rewards")}
                variant="subtitle2"
              >
                {tripTotalItem?.rewardsValue}
              </Typography>
            </>
          )}
        </Box>
        <InputAdornment
          className="total-price-adornment-icon-container"
          position="end"
        >
          <Box>
            <FontAwesomeIcon
              className={clsx("caret-icon", { open: openPopover })}
              icon={faCaretDown}
            />
          </Box>
        </InputAdornment>
      </Link>
      <Popover
        className={clsx("price-breakdown-popover-root", popoverClassName)}
        open={openPopover}
        PaperProps={{
          className: "price-breakdown-popover-paper",
        }}
        BackdropProps={{
          className: "price-breakdown-popover-backdrop",
          invisible: false,
        }}
        hideBackdrop={false}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableEnforceFocus
      >
        {showFrozenPriceSummary && (
          <Box className="frozen-price-summary-wrapper">
            <FrozenPriceSummary />
          </Box>
        )}
        {/* note: this is dropdownContent */}
        <Box className="hotel-price-breakdown-wrapper">
          <HotelPriceBreakdown
            className="pricing-line-items-breakdown"
            pricingItems={pricingItems}
            totalItems={totalItems}
            summaryItems={rewardsAndTotalLineItems}
          />
        </Box>
      </Popover>
    </>
  );
};
