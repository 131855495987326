import React, { useState, useMemo, useCallback } from "react";
import {
  TRAVEL_WALLET_DRAWER_TITLES,
  getRestrictedATCText,
  getShouldShowAnnualTravelCreditBanner,
  getTravelOfferCardContent,
  isOfferHSPTiered,
  TravelOfferCardMinimal,
  TravelWalletDetailsModal,
  TravelWalletDrawer,
  useDeviceTypes,
  TRAVEL_WALLET_MORE_INFO_TEXT,
  TRAVEL_CREDIT_TOOLTIP_TEXT,
  TRAVEL_OFFERS_TOOLTIP_TEXT,
} from "halifax";
import { InView } from "react-intersection-observer";
import { useExperimentIsVariant } from "@capone/experiments";
import { RouteComponentProps } from "react-router";
import {
  ENGAGED_OFFER_CTA,
  Funnel,
  TravelWalletOffer,
  VIEWED_TRAVEL_OFFER,
  VIEW_CREDIT_HISTORY,
  VIEW_TRAVEL_WALLET,
} from "redmond";

import { TravelWalletDrawerConnectorProps } from "./container";
import * as constants from "./constants";
import { TravelCreditBalanceCard } from "../TravelCreditBalanceCard";
import {
  getExperimentVariant,
  CREDIT_OFFER_STACKING_V1,
  AVAILABLE,
  useExperiments,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  ANNUAL_TRAVEL_CREDITS,
  TRAVEL_SALE,
  TRAVEL_SALE_LEAD_UP,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE_VARIANTS,
  CONTROL,
} from "../../../../context/experiments";
import { ClientContext } from "../../../../App";
import { PATH_HOME, PATH_TRAVEL_SALE } from "../../../../utils/paths";
import {
  TRAVEL_SALES_EVENT_ACTIVE_CTA,
  TRAVEL_SALES_EVENT_LEADUP_SUBTITLE,
} from "./constants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import "./styles.scss";

export interface IHotelTravelWalletDrawerProps
  extends TravelWalletDrawerConnectorProps,
    RouteComponentProps {
  drawerOpen?: boolean;
  setDrawerOpen?: (open: boolean) => void;
}

export const HotelTravelWalletDrawer = ({
  activeOffers,
  allOffers,
  rewardsAccounts,
  travelCredit,
  creditBreakdown,
  history,
  drawerOpen,
  setDrawerOpen,
}: IHotelTravelWalletDrawerProps) => {
  const [selectedOffer, setSelectedOffer] = useState<
    { offer: TravelWalletOffer; index?: number } | undefined
  >(undefined);

  const firstName =
    React.useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const travelOffers =
    travelSalesEventVariant === TRAVEL_SALE_LEAD_UP ? activeOffers : allOffers;

  const totalWalletCount = useMemo(() => {
    return travelCredit ? travelOffers.length + 1 : travelOffers.length;
  }, [travelOffers, travelCredit]);

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = React.useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  const { shouldShowVentureBanner, shouldShowVentureXBanner } =
    getShouldShowAnnualTravelCreditBanner(rewardsAccounts, creditBreakdown);

  const showAnnualTravelCreditBanner =
    isAnnualTravelCreditsExperiment &&
    (shouldShowVentureBanner || shouldShowVentureXBanner);

  const getOfferCTAs = useCallback(
    (
      offer: TravelWalletOffer,
      includeViewOfferDetailsCTA?: boolean,
      offerRowIndex?: number
    ) => {
      const offerIndexToUse =
        offerRowIndex !== undefined ? offerRowIndex + 1 : undefined;
      const trackOfferEngagementEvent = () => {
        trackEvent({
          eventName: ENGAGED_OFFER_CTA,
          properties: {
            location: "hotel_wallet_drawer",
            entry_type:
              matchesMobile && !includeViewOfferDetailsCTA ? "modal" : "card",
            index_row_id: offerIndexToUse,
            funnel: offer.funnels.join(","),
          },
          encryptedProperties: [
            offer.trackingPropertiesV2?.encryptedProperties ?? "",
          ],
        });
      };

      const funnels = offer.funnels;
      const funnelButton = funnels.map((funnel) => {
        switch (funnel) {
          case Funnel.Air:
            return {
              text: "Search flights",
              onClick: () => {
                history.push("/flights");
                trackOfferEngagementEvent();
              },
            };
          case Funnel.Lodging:
            if (
              offer.descriptions &&
              offer.descriptions[0].includes("Premier Collection")
            ) {
              return {
                text: "Search premium stays",
                onClick: () => {
                  history.push("/premium-stays/");
                  trackOfferEngagementEvent();
                },
              };
            }
            return {
              text: "Search hotels",
              onClick: () => {
                history.push(PATH_HOME);
                trackOfferEngagementEvent();
              },
            };
          case Funnel.Homes:
          case Funnel.Home:
            return {
              text: "Search Vacation Rentals",
              onClick: () => {
                history.push("/premium-stays/vacation-rentals");
                trackOfferEngagementEvent();
              },
            };
          case Funnel.Ground:
            return {
              text: "Search cars",
              onClick: () => {
                history.push("/cars");
                trackOfferEngagementEvent();
              },
            };
          case Funnel.Experiences:
            return {
              text: "Search experiences",
              onClick: () => {
                history.push("/experiences");
                trackOfferEngagementEvent();
              },
            };
        }
      });
      return [
        ...funnelButton,
        ...(includeViewOfferDetailsCTA
          ? [
              {
                text: "View offer details",
                onClick: () => {
                  setSelectedOffer({ offer, index: offerRowIndex });
                  trackOfferEngagementEvent();
                },
              },
            ]
          : []),
      ];
    },
    [history]
  );

  const renderTravelOffersCards = () => {
    return (
      <>
        {travelOffers.length >= 1 ? (
          travelOffers.map((offer, i) => {
            const offerContent = getTravelOfferCardContent(
              offer,
              isCreditAndOfferStackingExperimentV1
            );

            const offerLocation =
              offer.descriptions.length >= 3
                ? offer?.descriptions[2]
                : offer.descriptions[0];
            return (
              <InView
                as="div"
                onChange={(inView) => {
                  if (inView) {
                    trackEvent({
                      eventName: VIEWED_TRAVEL_OFFER,
                      properties: {
                        location: "hotel-travel-wallet-drawer",
                      },
                      encryptedProperties: [
                        offer?.trackingPropertiesV2?.encryptedProperties ?? "",
                      ],
                    });
                  }
                }}
                threshold={0.8}
              >
                <TravelOfferCardMinimal
                  key={offer.id}
                  title={
                    isOfferHSPTiered(offer.descriptions)
                      ? `<strong>${offer.shopPageBanner}</strong>` ||
                        offer.title
                      : offer.title
                  }
                  expirationString={offerContent.expirationDetail}
                  validityString={offerContent.applicabilityDetail}
                  ctas={getOfferCTAs(offer, true, i)}
                  offerFunnels={offer.funnels}
                  offerLocation={offerLocation}
                  imageId={offer.imageId}
                />
              </InView>
            );
          })
        ) : (
          <TravelOfferCardMinimal
            title={constants.NO_OFFERS_AVAIL_TITLE}
            body={constants.NO_OFFER_AVAIL_BODY}
          />
        )}
      </>
    );
  };

  return (
    <>
      {selectedOffer && (
        <TravelWalletDetailsModal
          openModal={!!selectedOffer}
          setOpenModal={() => setSelectedOffer(undefined)}
          offerCardProps={{
            offerContent: getTravelOfferCardContent(
              selectedOffer.offer,
              isCreditAndOfferStackingExperimentV1
            ),
            ctas: matchesMobile
              ? getOfferCTAs(selectedOffer.offer, false, selectedOffer.index)
              : undefined,
            buttonClassName: "b2b",
            titleLabelText: isOfferHSPTiered(selectedOffer.offer.descriptions)
              ? constants.HSP_OFFER_TITLE_LABEL
              : undefined,
          }}
          termsType={matchesMobile ? "accordion" : "link"}
          isMobile={matchesMobile}
          offerFunnels={selectedOffer.offer.funnels}
          offerLocation={selectedOffer.offer.descriptions[2]}
          isTravelSale={travelSalesEventVariant !== CONTROL}
          imageId={selectedOffer.offer.imageId}
          onViewCreditHistory={() =>
            trackEvent({
              eventName: VIEW_CREDIT_HISTORY,
              properties: {},
            })
          }
        />
      )}
      <TravelWalletDrawer
        creditBalanceCard={
          <TravelCreditBalanceCard
            isTravelCreditHistoryExperiment={isTravelCreditHistoryExperiment}
            isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
            isMobile={matchesMobile}
            onViewCreditHistory={() =>
              trackEvent({
                eventName: VIEW_CREDIT_HISTORY,
                properties: {},
              })
            }
          />
        }
        offersCards={renderTravelOffersCards()}
        travelCreditTooltipText={TRAVEL_CREDIT_TOOLTIP_TEXT(
          isCreditAndOfferStackingExperimentV1,
          !isCorpHideTravelOffers
        )}
        travelOfferTooltipText={TRAVEL_OFFERS_TOOLTIP_TEXT(
          isCreditAndOfferStackingExperimentV1
        )}
        moreInfoText={TRAVEL_WALLET_MORE_INFO_TEXT(!isCorpHideTravelOffers)}
        drawerTitles={TRAVEL_WALLET_DRAWER_TITLES(
          travelOffers,
          !isCorpHideTravelOffers
        )}
        isMobile={matchesMobile}
        offersCount={totalWalletCount}
        rewardsAccounts={rewardsAccounts}
        showAnnualCreditBanner={showAnnualTravelCreditBanner}
        firstName={firstName}
        restrictedATCText={getRestrictedATCText(
          rewardsAccounts,
          isAnnualTravelCreditsExperiment
        )}
        creditBreakdown={creditBreakdown}
        travelSalesEventBannerPlacement={
          travelSalesEventVariant !== CONTROL
            ? travelSalesEventVariant
            : undefined
        }
        travelSalesBannerProps={
          travelSalesEventVariant !== CONTROL
            ? {
                subtitle:
                  travelSalesEventVariant === TRAVEL_SALE_LEAD_UP
                    ? TRAVEL_SALES_EVENT_LEADUP_SUBTITLE
                    : constants.TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
                ctaText:
                  travelSalesEventVariant === TRAVEL_SALE_LEAD_UP
                    ? constants.TRAVEL_SALES_EVENT_LEADUP_CTA
                    : TRAVEL_SALES_EVENT_ACTIVE_CTA,
                ctaOnClick: () => {
                  const path = `${PATH_TRAVEL_SALE}?entryType=travel_wallet_drawer_hotels`;
                  matchesMobile
                    ? history.push(path)
                    : window.open(path, "_blank");
                },
              }
            : undefined
        }
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        showFloatingEntryPoint={!setDrawerOpen}
        includeOffers={!isCorpHideTravelOffers}
        onOpenDrawer={() =>
          trackEvent({
            eventName: VIEW_TRAVEL_WALLET,
            properties: {},
          })
        }
      />
    </>
  );
};
