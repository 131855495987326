import React, { useEffect, useMemo, useState } from "react";
import { HotelShopChooseRoom, MultiroomSuffixText } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { CorpRoomInfoProducts, SelectedTravelOfferScreen } from "redmond";
import { DesktopHotelShopChooseRoomConnectorProps } from "./container";
import { CalendarPickerButton } from "../../../search/components/HotelSearchControl/components/CalendarPickerButton";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components/TravelWalletDetailsBanner";

import {
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  HOTEL_COLOR_CALENDAR,
  HOTEL_COLOR_CALENDAR_VARIANTS,
  HOTEL_COLOR_CALENDAR_WITH_PRICING,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { OccupancySelection } from "../../../search/components/HotelSearchControl/components/OccupancySelection";
import { showOfferBasedOnSelectedLodgingSelector } from "../../reducer";
import { config } from "../../../../api/config";
import "./styles.scss";
import { getRoomPolicyComplianceType } from "@capone/common/src/utils/hotelShopPolicyCompliance";

export interface DesktopHotelShopChooseRoomProps
  extends DesktopHotelShopChooseRoomConnectorProps,
    RouteComponentProps {}

export const DesktopHotelShopChooseRoom = (
  props: DesktopHotelShopChooseRoomProps
) => {
  const { selectedLodging, showOfferBasedOnSelectedLodging } = props;
  const expState = useExperiments();
  const [showOfferBanner, setShowOfferBanner] = useState(false);

  useEffect(() => {
    setShowOfferBanner(showOfferBasedOnSelectedLodging);
  }, [showOfferBasedOnSelectedLodgingSelector]);

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const colorCalendarExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    HOTEL_COLOR_CALENDAR,
    HOTEL_COLOR_CALENDAR_VARIANTS
  );

  return (
    <HotelShopChooseRoom
      {...props}
      datepicker={
        <CalendarPickerButton
          classes={["room-calendar-button"]}
          hideSeparator
          showCalenderPricingColors={colorCalendarExperimentVariant !== CONTROL}
          showCalendarPricing={
            colorCalendarExperimentVariant === HOTEL_COLOR_CALENDAR_WITH_PRICING
          }
        />
      }
      onClickContinue={() =>
        document
          .getElementsByClassName("reserve-cta")[0]
          ?.scrollIntoView({ behavior: "smooth", block: "end" })
      }
      buttonClassName="b2b"
      showOffer={isTravelWalletOfferExperiment}
      hotelOffer={
        showOfferBanner ? (
          <TravelWalletDetailsBanner
            onDismiss={() => setShowOfferBanner(false)}
            offer={selectedLodging?.bestOfferThisLodging!}
            showButton={false}
            className="hotel-shop-offer"
            screen={SelectedTravelOfferScreen.HOTEL_DETAILS}
            {...props}
            bannerTextType="shop"
          />
        ) : undefined
      }
      occupancySelection={<OccupancySelection showTotalTravelers={true} />}
      suffixText={<MultiroomSuffixText roomsCount={props.roomsCount} />}
      policyComplianceType={getRoomPolicyComplianceType(
        props.roomInfoProducts as CorpRoomInfoProducts[]
      )}
      tenant={config.TENANT}
      roomsCount={props.roomsCount}
    />
  );
};
