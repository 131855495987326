export const HOTEL_PRICE_FREEZE_PURCHASE_TITLE =
  "Complete hotel price freeze purchase";
export const PORTAL_TITLE = "Capital One Travel Portal";
export const SEARCH_HOTEL_TITLE = "Search a hotel";
export const SEARCH_STAYS_TITLE = "Search a stay";
export const SELECT_HOTEL_TITLE = "Select a hotel";
export const SELECT_STAYS_TITLE = "Select a stay";
export const SELECT_ROOM_TITLE = "Select a room";
export const HOTEL_CHECKOUT_TITLE = "Complete Hotel Booking";
export const HOTEL_CONFIRMATION_TITLE = "Booking Confirmation";
