import { put, select } from "redux-saga/effects";
import Logger from "../../../utils/logger";
import { actions } from "../actions";
import { fetchCalendarBuckets } from "../../../api/v0/search/fetchCalendarBuckets";
import {
  CalendarPriceBucket,
  HotelPriceCalendarRequest,
  IBucketedDate,
  IIdLodgings,
  IMonthBucket,
  LodgingCollectionEnum,
} from "redmond";
import { getLocation } from "../reducer";
import { IStoreState } from "../../../reducers/types";
import dayjs from "dayjs";

export function* fetchCalendarBucketsSaga() {
  try {
    const state: IStoreState = yield select();
    const location = getLocation(state);
    const payload: HotelPriceCalendarRequest = {
      lodgingSelection: (location?.id as IIdLodgings).lodgingSelection,
      startDate: dayjs().format("YYYY-MM-DD"),
      lodgingCollection: LodgingCollectionEnum.NoCollection,
    };
    const { buckets } = yield fetchCalendarBuckets(payload);

    const monthBuckets = transformDateBuckets(buckets);
    yield put(
      actions.setCalendarBuckets(buckets as CalendarPriceBucket[], monthBuckets)
    );
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setLocationCategories([]));
    yield put(actions.setCalendarBucketsError(true));
  }
}

export const transformDateBuckets = (
  dateBuckets: CalendarPriceBucket[]
): IMonthBucket[] => {
  const processedMonths = dateBuckets.reduce(
    (months, { dates }, bucketIndex) => {
      const reducedMonths = dates.reduce(
        (monthBuckets: IMonthBucket[], dateString: string) => {
          const date = dayjs(dateString).toDate();
          const bucketedDate: IBucketedDate = { bucket: bucketIndex, date };
          const currentMonthIndex = dayjs(date).month();
          let existingMonthBucket = monthBuckets.find(({ monthIndex }) => {
            return monthIndex === currentMonthIndex;
          });

          if (typeof existingMonthBucket === "undefined") {
            existingMonthBucket = { monthIndex: currentMonthIndex, dates: [] };
            monthBuckets.push(existingMonthBucket);
          }

          existingMonthBucket.dates.push(bucketedDate);

          return monthBuckets;
        },
        months
      );

      return reducedMonths;
    },
    [] as IMonthBucket[]
  );
  const sortedMonths = processedMonths.map((processedMonth) => {
    const sortedDates = processedMonth.dates.sort(
      (a: IBucketedDate, b: IBucketedDate) => dayjs(a.date).diff(b.date)
    );
    return { monthIndex: processedMonth.monthIndex, dates: sortedDates };
  });

  return sortedMonths;
};
