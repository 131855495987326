import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { PriceBreakdown } from "./component";
import {
  getPollPriceQuoteCallState,
  getHotelPricingLineItems,
  getHotelRewardsAndTotalLineItems,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    pollPriceQuoteCallState: getPollPriceQuoteCallState(state),
    pricingLineItems: getHotelPricingLineItems(state),
    rewardsAndTotalLineItems: getHotelRewardsAndTotalLineItems(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceBreakdownConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceBreakdown = withRouter(connector(PriceBreakdown));
