import { put, select } from "redux-saga/effects";
import { CorpSessionInfo, RewardsAccount } from "redmond";

import { actions } from "../../actions";
import { getRewardsAccounts } from "../../reducer";
import { fetchRewardsAccounts } from "../../../../api/v0/rewards/fetchRewardsAccounts";
import { getBusinessEligibleAccounts, isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";

export function* fetchRewardsAccountsSaga({
  refreshCache,
  sessionInfo,
}: actions.IFetchRewardsAccounts) {
  try {
    const savedAccounts: RewardsAccount[] = yield select(getRewardsAccounts);
    if (refreshCache || savedAccounts.length < 1) {
      const rewardsAccounts: RewardsAccount[] = yield fetchRewardsAccounts();
      yield put(
        actions.setRewardsAccounts(
          isCorpTenant(config.TENANT)
            ? getBusinessEligibleAccounts(
                rewardsAccounts,
                sessionInfo as CorpSessionInfo
              )
            : rewardsAccounts
        )
      );
    } else {
      yield put(
        actions.setRewardsAccounts(
          isCorpTenant(config.TENANT)
            ? getBusinessEligibleAccounts(
                savedAccounts,
                sessionInfo as CorpSessionInfo
              )
            : savedAccounts
        )
      );
    }
  } catch (e) {
    yield put(actions.fetchRewardsAccountsCallStateFailed());
  }
}
