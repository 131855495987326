import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchHotelAvailabilitySaga } from "./fetchHotelAvailabilitySaga";
import { fetchViewHotelsNearLocationsSaga } from "./fetchViewHotelsNearSaga";
import { fetchUserHotelPreferencesSaga } from "./fetchUserHotelPreferencesSaga";
import { listPaymentMethodsSaga } from "./listPaymentMethodsSaga";

export function* watchFetchHotelAvailability() {
  yield takeLatest(
    actionTypes.FETCH_INITIAL_HOTEL_AVAILABILITY,
    fetchHotelAvailabilitySaga
  );
  yield takeLatest(
    actionTypes.FETCH_MORE_HOTEL_AVAILABILITY,
    fetchHotelAvailabilitySaga
  );
}

export function* watchFetchViewHotelsNearLocations() {
  yield takeLatest(
    actionTypes.FETCH_VIEW_HOTELS_NEAR_LOCATION_CATEGORIES,
    fetchViewHotelsNearLocationsSaga
  );
}

export function* watchFetchUserHotelPreferences() {
  yield takeLatest(
    actionTypes.FETCH_USER_HOTEL_PREFERENCES,
    fetchUserHotelPreferencesSaga
  );
}

export function* watchListHotelAvailabilityPaymentMethods() {
  yield takeLatest(actionTypes.LIST_PAYMENT_METHODS, listPaymentMethodsSaga);
}
