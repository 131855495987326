import { isCorpTenant } from "@capone/common";
import { useExperimentsById } from "@capone/experiments";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Box, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  MobileOutlinedTextInput,
  MobilePopoverCard,
  PassengerCountPicker,
  PassengerCountPickerType,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { GuestsSelection } from "redmond";

import { config } from "../../../../../../api/config";
import {
  CORP_MAXIMUM_ROOMS_COUNT,
  CORP_MAXIMUM_TRAVELERS_COUNT,
  MAXIMUM_ROOMS_COUNT,
  MAXIMUM_TRAVELERS_COUNT,
  STAYS_MAXIMUM_ROOMS_COUNT,
  STAYS_MAXIMUM_TRAVELERS_COUNT,
} from "../../../../textConstants";
import { MobileOccupancySelectionConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  STAYS_SEARCH,
  getExperimentVariant,
  AVAILABLE,
  useExperiments,
} from "../../../../../../context/experiments";

export interface IMobileOccupancySelectionProps
  extends MobileOccupancySelectionConnectorProps,
    RouteComponentProps {
  showRoomCountsOnly?: boolean;
  showTotalTravelers?: boolean;
  onClickApply?: (arg: any) => void;
  onSetLocalPassengerCount?: (adults: number, children: number[]) => void;
  onSetLocalRoomsCount?: (rooms: number) => void;
  onSetLocalPetsCount?: (pets: number) => void;
  localAdultsCount?: number;
  localChildrenCount?: number[];
  localRoomsCount?: number;
  localPetsCount?: number;
  hideIcon?: boolean;
  autoFocus?: boolean;
  showClosePassengerModalButton?: boolean;
}
export const OccupancySelection = (props: IMobileOccupancySelectionProps) => {
  const {
    adultsCount,
    children,
    roomsCount,
    petsCount,
    setOccupancyCounts,
    setRoomsCount,
    setPetsCount,
    showRoomCountsOnly = false,
    showTotalTravelers = false,
    onClickApply,
    onSetLocalPassengerCount,
    onSetLocalRoomsCount,
    onSetLocalPetsCount,
    localAdultsCount,
    localChildrenCount,
    localRoomsCount,
    localPetsCount,
    hideIcon,
    autoFocus = true,
    showClosePassengerModalButton = false,
  } = props;

  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    React.useState(false);

  const adultsCountToUse = localAdultsCount ?? adultsCount;
  const childrenToUse = localChildrenCount ?? children;
  const roomsCountToUse = localRoomsCount ?? roomsCount;
  const petsCountToUse = localPetsCount ?? petsCount;
  const expState = useExperiments();

  const isMultiroomAmadeusEnabled =
    isCorpTenant(config.TENANT) &&
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";
  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const maximumRoomsCount = isMultiroomAmadeusEnabled
    ? CORP_MAXIMUM_ROOMS_COUNT
    : staysSearchEnabled
    ? STAYS_MAXIMUM_ROOMS_COUNT
    : MAXIMUM_ROOMS_COUNT;

  const maximumTravelersCount = isMultiroomAmadeusEnabled
    ? CORP_MAXIMUM_TRAVELERS_COUNT
    : staysSearchEnabled
    ? STAYS_MAXIMUM_TRAVELERS_COUNT
    : MAXIMUM_TRAVELERS_COUNT;
  return (
    <Box className={clsx("mobile-occupancy-selection", "multiroom")}>
      <MobileOutlinedTextInput
        className="num-traveler-count"
        prefixString={textConstants.INPUT_LABEL(
          adultsCountToUse,
          childrenToUse,
          roomsCountToUse,
          showRoomCountsOnly,
          showTotalTravelers
        )}
        icon={!hideIcon ? faUser : undefined}
        customEndIcon={<Icon name={IconName.B2BEditPencil} />}
        autoFocus={autoFocus}
        showCloseIcon={false}
        onClick={() => setOpenPassengerCountPicker(true)}
        value={""}
      />
      <MobilePopoverCard
        open={openPassengerCountPicker}
        className={clsx("mobile-passenger-count-picker-popup", "b2b")}
        contentClassName="mobile-passenger-count-picker-popup-container"
        centered={true}
        onClose={() => setOpenPassengerCountPicker(false)}
        topRightButton={
          showClosePassengerModalButton ? (
            <ActionLink
              className="modal-close-button"
              onClick={() => setOpenPassengerCountPicker(false)}
              content={<CloseButtonIcon className="close-button-icon" />}
              label="Close"
              showTappableArea
            />
          ) : undefined
        }
      >
        <PassengerCountPicker
          titles={{
            ...textConstants.MODAL_TITLES_WITH_ROOMS(
              config.TENANT,
              maximumRoomsCount
            ),
            ...(staysSearchEnabled
              ? {
                  petsTitle: textConstants.PETS_TITLE,
                  petsSubtitle: (
                    <>
                      <Tooltip
                        placement="bottom"
                        title={
                          <Typography
                            variant="subtitle2"
                            className="tooltip-text"
                            dangerouslySetInnerHTML={{
                              __html: textConstants.PETS_TOOLTIP,
                            }}
                          />
                        }
                        classes={{
                          popper: "pets-info-tooltip-popper",
                          tooltip: "pets-info-tooltip-text",
                        }}
                        enterTouchDelay={50}
                        leaveTouchDelay={2500}
                        tabIndex={0}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: "#676868",
                              lineHeight: "22px",
                            }}
                          >
                            {textConstants.PETS_SUBTITLE}
                          </Typography>
                          <Icon
                            className="icon-grey"
                            name={IconName.InfoCircle}
                          />
                        </div>
                      </Tooltip>
                    </>
                  ),
                }
              : {}),
          }}
          counts={{
            adults: adultsCountToUse,
            children: childrenToUse,
            rooms: roomsCountToUse,
            pets: petsCountToUse,
          }}
          setPassengerCounts={(counts: PassengerCountPickerType) => {
            const { adults, children, rooms, pets } = counts as GuestsSelection;
            if (onSetLocalPassengerCount && onSetLocalRoomsCount) {
              onSetLocalPassengerCount(adults, children);
              onSetLocalRoomsCount(rooms);
            } else {
              setOccupancyCounts({ adults, children });
              setRoomsCount(rooms);
            }
            if (onSetLocalPetsCount) {
              onSetLocalPetsCount(pets || 0);
            } else {
              setPetsCount(pets || 0);
            }
            onClickApply && onClickApply(counts);
          }}
          onClickApply={() => {
            setOpenPassengerCountPicker(false);
          }}
          className="b2b"
          showChildAgeInputs
          maximumRoomsCount={maximumRoomsCount}
          maximumTravelersCount={maximumTravelersCount}
        />
      </MobilePopoverCard>
    </Box>
  );
};
