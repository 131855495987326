import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { eventsToDispatch } from "./utils/events";

let container = null;

export const render = (containerId, data) => {
  container = createRoot(document.getElementById(containerId));

  if (!container) return;
  container.render(<App {...data} />);
};

export const unMount = (containerId) => {
  if (!container) return;
  container.unmount();
  container = null;
};

export const subscribe = (eventName, eventHandler) => {
  if (!eventsToDispatch[eventName]) {
    console.warn(
      `This micro frontend does not support the ${eventName} event!`
    );
    return;
  }
  window.addEventListener(eventName, eventHandler);
};

export const unSubscribe = (eventName, eventHandler) => {
  if (!eventsToDispatch[eventName]) {
    console.warn(
      `This micro frontend does not support the ${eventName} event!`
    );
    return;
  }
  window.removeEventListener(eventName, eventHandler, false);
};

export const customEvents = eventsToDispatch;

window.ptHotelsModule = {
  render,
  unMount,
  subscribe,
  unSubscribe,
};
