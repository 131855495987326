import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getFromDate,
  getUntilDate,
  getLocation,
  getAdultsCount,
  getRoomsCount,
  getChildren,
} from "../../../../reducer";
import { MobileCalendarPickerModal } from "./component";
import { withRouter } from "react-router";

const mapDispatchToProps = {
  setFromDate: actions.setFromDate,
  setUntilDate: actions.setUntilDate,
};
const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    searchLocation: getLocation(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    roomsCount: getRoomsCount(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCalendarPickerModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCalendarPickerModal = withRouter(
  connector(MobileCalendarPickerModal)
);
