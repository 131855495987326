import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AvailabilityErrorModal } from "./component";
import { getHotelAvailabilityCallState } from "../../reducer";
import { HotelAvailabilityCallState } from "../../reducer/state";
import { fetchInitialHotelAvailability } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    hasFailed:
      getHotelAvailabilityCallState(state) ===
      HotelAvailabilityCallState.Failed,
  };
};

const mapDispatchToProps = {
  fetchInitialHotelAvailability,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityErrorModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityErrorModal = withRouter(
  connector(AvailabilityErrorModal)
);
