import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import { scheduleBook } from "../../../../../book/actions/actions";

import { DesktopHotelFreezeWorkflow } from "./component";
import {
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getHotelBookChosenRoomInfo,
  getHotelBookChosenProductIndex,
  getHotelBookSelectedAvailability,
  getRoomsCountInBook,
  getFreezeBookingProgress,
  getIsBookingValid,
  isPriceFreezePurchaseTravelerStepCompleteSelector,
} from "../../../../../book/reducer";
import { getHotelShopChosenPriceFreezeOffer } from "../../../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    isBookingValid: getIsBookingValid(state),
    contactInfo: {
      email: getConfirmationEmail(state) ?? "",
      phone: getConfirmationPhoneNumber(state) ?? "",
    },
    roomInfoProduct: getHotelBookChosenRoomInfo(state),
    chosenProductIndex: getHotelBookChosenProductIndex(state),
    selectedLodging: getHotelBookSelectedAvailability(state),
    roomsCount: getRoomsCountInBook(state),
    freezeBookingProgress: getFreezeBookingProgress(state),
    isPriceFreezePurchaseTravelerStepComplete:
      isPriceFreezePurchaseTravelerStepCompleteSelector(state),
    priceFreezeOffer: getHotelShopChosenPriceFreezeOffer(state),
  };
};

const mapDispatchToProps = {
  scheduleBook,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopHotelFreezeWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopHotelFreezeWorkflow = withRouter(
  connector(DesktopHotelFreezeWorkflow)
);
