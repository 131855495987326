import { takeLatest } from "redux-saga/effects";

import {
  FETCH_DISTANCE_FROM_LOCATION_CATEGORIES,
  FETCH_HOTEL_SHOP,
  FETCH_NEARBY_LODGINGS,
} from "../actions/constants";
import { fetchDistanceFromLocations } from "./fetchDistanceFromLocationAutocompleteSaga";
import fetchHotelShopSaga from "./fetchHotelShopSaga";
import { fetchNearbyLodgingsSaga } from "./fetchNearbyLodgingsSaga";

export function* watchFetchHotelShopSaga() {
  yield takeLatest(FETCH_HOTEL_SHOP, fetchHotelShopSaga);
}

export function* watchFetchDistanceFromLocationCategories() {
  yield takeLatest(
    FETCH_DISTANCE_FROM_LOCATION_CATEGORIES,
    fetchDistanceFromLocations
  );
}

export function* watchFetchNearbyLodgingsSaga() {
  yield takeLatest(
    FETCH_NEARBY_LODGINGS,
    fetchNearbyLodgingsSaga
  );
}
