import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { FrozenPriceSummary } from "./component";
import { hotelFrozenPriceSummaryDetailsSelector } from "../../reducer";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    frozenPriceSummaryDetails: hotelFrozenPriceSummaryDetailsSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FrozenPriceSummaryConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFrozenPriceSummary = connector(
  withRouter(FrozenPriceSummary)
);
